export class ResellerThemeModel {
  constructor(
    public resellerName: string,
    public themeStylesFile: string,
    public loginBackgroundColor: string,
    public imagePath: string,
    public portalTitle: string,
    public hasTrademark: boolean,
    public tempLogTableHeaderBackgroundColor: string,
    public tempLogHeaderBackgrounColor: string,
    public invalidLinkComponentImageBackgroundColorCSSClass: string,
    public portalShortName: string
  ) {}
}
