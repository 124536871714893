import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogCobrowseService {
  private showDialogSubject = new BehaviorSubject(false);

  showDialog(value: boolean): void {
    // inside component
    this.showDialogSubject.next(value);
  }

  onShowDialog(): Observable<boolean> {
    // inside dialog
    return this.showDialogSubject.asObservable();
  }
}
