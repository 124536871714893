<div class="container login-container">
  <div class="login-form">
    <div class="text-center logos-container">
      <img id="logo-container" [src]="brandImagesAssets + 'FullLogo.svg'" />
      <div *ngIf="resellerLogoURL && brandLogoURL" class="d-flex justify-content-between mt-3 branding-logos-container">
        <img class="my-auto logo" [src]="resellerLogoURL" />
        <div class="my-auto separator"></div>
        <img class="my-auto logo" [src]="brandLogoURL" />
      </div>
    </div>
    <!-- <div>
      <div #recaptcha ></div>
    </div> -->
    <div class="col">
      <form class="rounded" [formGroup]="formGroup" (ngSubmit)="submitForm()">
        <p class="alert-message text-center" *ngIf="message" [innerHTML]="message | translate"></p>
        <p class="alert-message text-center font-weight-bold" *ngIf="message && failedAttempts > 1 && leftAttempts > 0">{{ 'error.ATTEMPS_LEFT' | translate: { attempts: leftAttempts } }}</p>
        <p class="account-name bold">
          {{ accountName }}
        </p>
        <mat-form-field class="login-full-width">
          <input matInput formControlName="username" [placeholder]="'email' | translate | titlecase" autofocus />
        </mat-form-field>
        <mat-form-field class="login-full-width">
          <input matInput type="password" formControlName="password" [placeholder]="'password' | translate | titlecase" autocomplete="off" />
        </mat-form-field>
        <div>
          <label *ngIf="!punchoutSession">
            <a mat-button class="clickable-text" color="accent" [routerLink]="[]" (click)="forgotPassword()">{{ 'login_page.forgot_password' | translate }}</a>
          </label>
        </div>
        <div [style.display]="displayCaptcha ? 'block' : 'none'">
          <div #recaptcha></div>
        </div>
        <div class="text-center">
          <button mat-flat-button color="accent" [disabled]="!formGroup.valid || (isCaptchaRequired && recaptchaResponse == '') || blockedUser" type="submit" class="login-button btn btn-def btn-block">
            {{ 'login_page.login_button' | translate }}
          </button>
        </div>
        <div class="mt-4 text-center">
          <label>
            <a mat-button class="clickable-text" color="accent" (click)="returnToAccountSelection()">{{ 'login_page.return_to_account_selection' | translate }}</a>
          </label>
        </div>
      </form>
    </div>
    <!--Language Selection-->
    <div class="col-3 mr-auto ml-auto mt-3 no-padding">
      <nome-languages-dropdown class="outside-toolbar"></nome-languages-dropdown>
    </div>
  </div>
  <nome-login-footer-message />
</div>
