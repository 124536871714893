import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nome-header-list-footer',
  templateUrl: './header-list-footer.component.html',
  styleUrls: ['./header-list-footer.component.scss'],
  imports: [CommonModule, TranslateModule],
  standalone: true
})
export class HeaderListFooterComponent {
  @Input('conflictingData') conflictingData: string[];
  @Input('headerMessage') headerMessage: string;
  @Input('footerMessage') footerMessage: string;
}
