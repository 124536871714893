import { Injectable } from '@angular/core';
import { BrandModel, BrandSettingsModel } from '../models/brand-model';
import { ApiService } from '../services/api/api.service';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BrandService {
  baseServiceUrl = `${environment.baseAPIUrl}/${environment.version}/accounts/`;

  public currentBrandSubject: Subject<BrandModel> = new Subject<BrandModel>();

  constructor(private apiService: ApiService) { }

  private brandStorageKey = 'currentBrand';
  public selectedBrand: BrandModel;
  // Brands
  getBrands(accountId: number): Observable<BrandModel[]> {
    let url = this.baseServiceUrl + `${accountId}/brands`;
    return this.apiService.getRequest(url, false).pipe(
      map((response) => {
        // if (response.statusCode === 202)
        return <BrandModel[]>response.results.records.brands;
      })
    );
  }

  getBrandSettings(accountId: number): Observable<BrandSettingsModel> {
    let url = this.baseServiceUrl + `${accountId}/brands`;
    return this.apiService.getRequest(url, false).pipe(
      map((response) => {
        return <BrandSettingsModel>response.results.records.brandSettings;
      })
    );
  }

  getBrandsForCurrentUser(accountId: number): Observable<BrandModel[]> {
    // let url = this.baseServiceUrl + `${accountId}/brands`;
    let url = this.baseServiceUrl + `${accountId}/brands/?Check_by=current_user`;

    return this.apiService.getRequest(url, true).pipe(
      map((response) => {
        return <BrandModel[]>response.results.records.brands;
      })
    );
  }

  setCurrentBrand(brand: BrandModel) {
    if (brand) {
      this.currentBrandSubject.next(brand);
      this.setBrandStorage(brand);
      this.selectedBrand = brand;
    }
  }

  getBrandStorage(): number {
    if (localStorage.getItem(this.brandStorageKey)) {
      const strBrand = localStorage.getItem(this.brandStorageKey);
      const brand: BrandModel = JSON.parse(strBrand);
      return brand.brandId;
      // return parseInt(localStorage.getItem(this.brandStorageKey), 10);
    }
    return null;
  }

  getBrandIsPublisher(): boolean {
    const strBrand = localStorage.getItem(this.brandStorageKey);
    if (strBrand !== null) {
      const brand: BrandModel = JSON.parse(strBrand);
      return brand.isPublisher;
    }
    return false;
  }

  setBrandStorage(brand: BrandModel) {
    const strBrand = JSON.stringify(brand);
    localStorage.setItem(this.brandStorageKey, strBrand);
    // localStorage.setItem(this.brandStorageKey, String(brand.brandId));
  }

  clearBrand() {
    this.selectedBrand = null;
    localStorage.removeItem(this.brandStorageKey);
  }
}
