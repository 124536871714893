import { CalendarEvent, CalendarEventAction } from 'angular-calendar';
import { addDays, addHours } from 'date-fns';
import { HOURS_COLOR_PALETTE } from '../constants.config';
import { IdColor } from '../models/id-color.model';
import { SessionTimeObject } from '../models/session-time-object.model';
import { SessionModel } from '../models/session.model';
import { generateGradientColorsForGroups, notDefined } from './app.helpers';

export enum CalendarAction {
  Edit = 'Edit',
  Disable = 'Disable',
  DisableAll = 'Disable All',
  Enable = 'Enable',
  EnableAll = 'Enable All',
  Delete = 'Delete',
  Schedule_All = 'Schedule All',
  Clear_All = 'Clear All'
}

export enum TimeOfTheDay {
  Breakfast = 'breakfast',
  Lunch = 'lunch',
  Night = 'night',
  // Afternoon = 'afternoon',
  Overnight = 'overnight',
  Disabled = 'disabled',
  FullDay = 'fullday',
  Task = 'task'
}

export const timeOfTheDayColors = new Map<TimeOfTheDay, { primary: string; secondary: string }>([
  // primary is for border and secondary is for background
  [TimeOfTheDay.Overnight, { primary: '#2b5233', secondary: '#60bf73' }],
  [TimeOfTheDay.Breakfast, { primary: '#1e90ff', secondary: '#D1E8FF' }],
  [TimeOfTheDay.Lunch, { primary: '#2b3f52', secondary: '#5e8bb5' }],
  [TimeOfTheDay.Night, { primary: '#423434', secondary: '#8c6f6f' }],
  [TimeOfTheDay.Disabled, { primary: '#696666', secondary: '#8c8989' }],
  [TimeOfTheDay.FullDay, { primary: '#919191', secondary: '#c9c0d6' }],
  [TimeOfTheDay.Task, { primary: '#000000', secondary: '#ffffff' }]
]);

export const timeOfTheDayRange = new Map<number, TimeOfTheDay>([
  [0, TimeOfTheDay.Overnight],
  [1, TimeOfTheDay.Overnight],
  [2, TimeOfTheDay.Overnight],
  [3, TimeOfTheDay.Overnight],
  [4, TimeOfTheDay.Overnight],
  [5, TimeOfTheDay.Overnight],
  [6, TimeOfTheDay.Breakfast],
  [7, TimeOfTheDay.Breakfast],
  [8, TimeOfTheDay.Breakfast],
  [9, TimeOfTheDay.Breakfast],
  [10, TimeOfTheDay.Breakfast],
  [11, TimeOfTheDay.Breakfast],
  [12, TimeOfTheDay.Lunch],
  [13, TimeOfTheDay.Lunch],
  [14, TimeOfTheDay.Lunch],
  [15, TimeOfTheDay.Lunch],
  [16, TimeOfTheDay.Lunch],
  [17, TimeOfTheDay.Lunch],
  [18, TimeOfTheDay.Night],
  [19, TimeOfTheDay.Night],
  [20, TimeOfTheDay.Night],
  [21, TimeOfTheDay.Night],
  [22, TimeOfTheDay.Night],
  [23, TimeOfTheDay.Night]
]);

export function convertSessionTimeStringToObject(time: string): SessionTimeObject {
  const hourMinuteSeconds = time.split(':');
  const millisecond = time.split('.')[1];
  return {
    hour: parseInt(hourMinuteSeconds[0], 10),
    minute: parseInt(hourMinuteSeconds[1], 10),
    second: parseInt(hourMinuteSeconds[2], 10),
    millisecond: parseInt(millisecond, 10)
  };
}

function getHourBasedSessionsMap(sessions: SessionModel[]): Map<number, number[]> {
  const hourBasedSessionsMap = new Map<number, number[]>();
  sessions.forEach((session) => {
    const startHour = parseInt(session.startTime, 10);
    if (!isNaN(startHour)) {
      // I don't want to include anytime session
      const sessionsAtThisHour = hourBasedSessionsMap.get(startHour);
      if (notDefined(sessionsAtThisHour)) {
        hourBasedSessionsMap.set(startHour, [session.sessionId]);
      } else {
        const sessionIds = hourBasedSessionsMap.get(startHour);
        if (sessionIds.indexOf(session.sessionId) === -1) {
          // I don't want to duplicate session ids
          sessionIds.push(session.sessionId);
        }
        hourBasedSessionsMap.set(startHour, sessionIds);
      }
    }
  });
  return hourBasedSessionsMap;
}

function getIdsAndColors(hourBasedSessions: Map<number, number[]>, hoursColorPalette = HOURS_COLOR_PALETTE): IdColor[] {
  const idsAndColors = [];
  Array.from(hourBasedSessions.entries()).forEach((hourSessions) => {
    const hour = hourSessions[0];
    const sessionIds = hourSessions[1];
    const hourColorPalette = hoursColorPalette.get(hour);
    const hourColors = generateGradientColorsForGroups(hourColorPalette[0], hourColorPalette[1], hourColorPalette[2], sessionIds);
    idsAndColors.push(...hourColors.map((x) => ({ id: x.name, color: x.value })));
  });
  return idsAndColors;
}

export function getSessionColors(sessions: SessionModel[], hoursColorPalette = HOURS_COLOR_PALETTE): IdColor[] {
  const HourBasedSessionIds = getHourBasedSessionsMap(sessions);
  const sessionColors = getIdsAndColors(HourBasedSessionIds, hoursColorPalette);
  return sessionColors;
}

export function getSessionColor(sessionColors: IdColor[], sessionId: number): string {
  const sessionColor = sessionColors.find((sessionColor) => sessionColor.id === sessionId);
  return sessionColor ? sessionColor.color : null;
}

export function mapSessionsToCalendarEvents(sessions: SessionModel[], disableActions: CalendarEventAction[], enableActions: CalendarEventAction[], oldColoringStrategy = true): CalendarEvent[] {
  const d = new Date();
  const todayIndex = d.getDay();
  const calendarEvents: CalendarEvent[] = [];
  const sessionColors = getSessionColors(sessions);
  sessions.forEach((session) => {
    session.occurrences.forEach((occ) => {
      let usedOccurence = occ.day;
      let usedTodayIndex = todayIndex;
      if (occ.day == 0) {
        usedOccurence = 7;
      }
      if (todayIndex == 0) {
        usedTodayIndex = 7;
      }
      const occurenceDate = addDays(d, usedOccurence - usedTodayIndex);
      const startHour = parseInt(session.startTime.split(':')[0]);
      const startMinute = parseInt(session.startTime.split(':')[1]);
      const decimalStartMinute = startMinute / 60;
      const startDateAdd = startHour + parseFloat(decimalStartMinute.toString());
      const endHour = parseInt(session.endTime.split(':')[0]);
      const endMinute = parseInt(session.endTime.split(':')[1]);
      const decimalEndMinute = endMinute / 60;
      const endDateAdd = endHour + parseFloat(decimalEndMinute.toString());
      const calendarEvent: CalendarEvent = { start: d, title: '' };
      calendarEvent.title = session.name;
      const timeOfDay = timeOfTheDayRange.get(startHour);
      const sessionColor = occ.isEnabled ? getSessionColor(sessionColors, session.sessionId) : timeOfTheDayColors.get(TimeOfTheDay.Disabled).secondary;
      const sessionColorObject = oldColoringStrategy ? timeOfTheDayColors.get(occ.isEnabled ? timeOfDay : TimeOfTheDay.Disabled) : { primary: sessionColor, secondary: sessionColor };
      calendarEvent.color = sessionColorObject;
      calendarEvent.cssClass = 'title-class';
      occurenceDate.setHours(0, 0, 0, 0);
      calendarEvent.start = addHours(occurenceDate, startDateAdd);
      calendarEvent.end = addHours(occurenceDate, endDateAdd);
      calendarEvent.actions = session.isSubscription ? [] : !occ.isEnabled ? enableActions : disableActions; //if the session is subscribed from then no action will be displayed
      calendarEvent.meta = {
        id: session.sessionId,
        description: session.description,
        occurrences: session.occurrences.map((x) => x.day),
        disabled: !occ.isEnabled,
        timeOfDay: timeOfDay,
        occurrenceIndex: occurenceDate.getDay(),
        ...session
      };

      calendarEvents.push(calendarEvent);
    });
  });

  return calendarEvents;
}
