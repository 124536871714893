import { UntypedFormGroup, AbstractControl, NgForm, ValidatorFn, ValidationErrors } from '@angular/forms';
import { notDefined } from '../helpers/app.helpers';

export function formIsValid(formGroup: UntypedFormGroup) {
  if (formGroup) {
    if (!formGroup.valid) {
      return false;
    }
    return true;
  }
  return false;
}

export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }
  if (abstractControl['controls']) {
    for (const controlName in abstractControl['controls']) {
      if (abstractControl['controls'][controlName]) {
        if (hasRequiredField(abstractControl['controls'][controlName])) {
          return true;
        }
      }
    }
  }
  return false;
};

export function noZeroAllowed(control: AbstractControl): ValidationErrors | null {
  return control.value != '0' ? null : { noZeroAllowed: true };
}
export function noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
  const isWhitespace = (control.value || '').trim().length === 0;
  return !isWhitespace ? null : { whitespace: true };
}
export function alphaNumericDashUnderscore(control: AbstractControl): ValidationErrors | null {
  let regExp = /^[a-zA-Z0-9-_]+$/;

  return !control.value || regExp.test(control.value)
    ? null
    : {
        alphaNumericDashUnderscore: true
      };
}
export function clearValidators(control: AbstractControl, value: any): void {
  control.clearValidators();
  control.updateValueAndValidity();
  control.patchValue(value);
}

export function setControlValidators(control: AbstractControl, validators: ValidatorFn | ValidatorFn[]): void {
  control.setValidators(validators);
  control.updateValueAndValidity();
}

export function markFormAsSubmitted(form: NgForm): void {
  (form.submitted as any) = true;
}
