<div class="invalid-activation-link">
  <div class="logo-section text-center p-5">
    <img [src]="brandImagesAssets + 'FullLogo.svg'" [alt]="appTitle" style="width: 200px" />
  </div>
  <div class="content-section text-center">
    <ng-container [ngSwitch]="reason">
      <ng-container *ngSwitchCase="apiResponseType.LINK_TOKEN_EXPIRED">
        <p class="mb-0">
          {{ 'invalid_link_page.expired_link_reason_message' | translate }}
        </p>
        <p class="mb-0">
          {{ 'invalid_link_page.new_link_sent' | translate }}
        </p>
      </ng-container>
      <ng-container *ngSwitchCase="apiResponseType.INVALID_LINK_TOKEN">
        <p class="mb-0">
          {{ 'invalid_link_page.invalid_link_reason_message' | translate }}
        </p>
        <p class="mb-0">
          {{ 'invalid_link_page.contact_your_system_administrator' | translate }}
        </p>
      </ng-container>
    </ng-container>
  </div>
  <div class="footer-section w-100 text-center pl-2 pr-2" [class]="invalidLinkComponentImageBackgroundColorCSSClass">
    <img [src]="brandImagesAssets + 'LinkErrorLogo.svg'" [alt]="appTitle" style="max-width: 100%; margin-top: -92px" />
  </div>
</div>
