import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../core/auth/auth.service';
import { AccountInfo } from '../models/account-info.model';
import { SubscriptionType } from '../models/subscription-type.enum';
import { APIResponseModel } from '../models/api-response';
import { SubscriberAccount } from '../models/subscriber-account.model';
import { TreeNode } from '../models/Tree-Node.model';
import { MetaData } from '../models/metaData.model';
import { SubscriberStore } from '../models/subscriber-store.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  baseServiceUrl = `${environment.baseAPIUrl}${environment.version}/accounts/`;

  constructor(private apiService: ApiService, private authService: AuthService) {}

  setSecondaryLanguageConfigAndGetPortalBaseUrl(res: AccountInfo) {
    this.authService.setSecondaryLanguageConfig(res.hasSecondaryLanguage);
    this.authService.setAccountPortalBaseUrl(res.portalBaseUrl);

    //Update local storage flags
    this.authService.setAllowRefreshLocationCode(res.allowRefreshLocationCode);
    this.authService.setHasPriceOptions(res.hasPriceOptions);
    this.authService.setHasMultiLabeling(res.hasMultiLabeling);
    this.authService.setDisplayItemThumbnail(res.displayItemThumbnail);

    this.authService.setKnowledgeBaseFlag(res.knowledgeBaseEnabled);
    this.authService.setKnowledgeBaseURL(res.knowledgeBaseURL);
    this.authService.setSupportChatFlag(res.supportChatEnabled);
    this.authService.setSupportId(res.supportId);

    //Update the flag that sets if the account has access to the new subscription flow
    this.authService.setUseNewSubscriptionFlow(res.useNewSubscriptionFlow);
  }

  getAccountConfig(accountId: number): Observable<AccountInfo> {
    const url = this.baseServiceUrl + `${accountId}`;
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  getSubscribingAccounts(accountId: number, typeId: SubscriptionType, isSense: boolean): Observable<APIResponseModel<SubscriberAccount[]>> {
    const url = `${this.baseServiceUrl}${accountId}/subscriptions/subscribers?typeId=${typeId}&isSense=${isSense}`;
    return this.apiService.getRequest(url);
  }

  getAccountLocations(accountId: number, isPublisherViewingSubscriberLocations: boolean = false, isSense: boolean = false): Observable<APIResponseModel<TreeNode>> {
    const url = `${this.baseServiceUrl}${accountId}/locations?isPublisherViewingSubscriberLocations=${isPublisherViewingSubscriberLocations}&isSense=${isSense}`;
    return this.apiService.getRequest(url);
  }

  getAccountStores(accountId: number, subscribers: string, start = 0, limit = 100, searchKeyword = '', isPublisherViewingSubscriberLocations: boolean = false, isSense: boolean = false): Observable<any> {
    const url = `${this.baseServiceUrl}${accountId}/locations?accounts=${subscribers}&start=${start}&limit=${limit}&searchKeyword=${searchKeyword}&isPublisherViewingSubscriberLocations=${isPublisherViewingSubscriberLocations}&isSense=${isSense}`;
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }
}
