import { Component, Input, OnInit } from '@angular/core';
import { ActionTypeEnum, EshaLogItem } from '../../../../models/esha-log-entry.model';

@Component({
  selector: 'nome-event-log-item',
  templateUrl: './event-log-item.component.html',
  styleUrls: ['./event-log-item.component.scss']
})
export class EventLogItemComponent implements OnInit {
  ActionTypeEnum = ActionTypeEnum;
  @Input() logs: EshaLogItem[] = [];
  @Input() title = '';
  @Input() actionType: ActionTypeEnum = 1;
  constructor() {}

  ngOnInit() {}
}
