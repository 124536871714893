import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { MenuComponent, ContextMenuService, MenuPackage } from '../../../components/custom-context-menu/public_api';
import { TranslationKeyAction } from '../../models/translation-key-action.model';
import {} from '../../../components/custom-context-menu/menu.component';

@Component({
  selector: 'nome-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  animations: [trigger('menu', [state('enter', style({ opacity: 1 })), state('exit, void', style({ opacity: 0 })), transition('* => *', animate('120ms ease-in'))])]
})
export class ContextMenuComponent extends MenuComponent {
  items: TranslationKeyAction[] = [];

  constructor(menuPackage: MenuPackage, contextMenuService: ContextMenuService) {
    super(menuPackage, contextMenuService);
    this.items = menuPackage.context;
  }

  emitItemAndCloseContextMenu(item: TranslationKeyAction): void {
    this.contextMenuService.closeAll(item);
  }
}
