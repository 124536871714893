import { Injectable } from '@angular/core';
import { AuthService } from '../../core/auth/auth.service';
import { environment } from '../../../environments/environment';
import { defined, encodeSpecialCharacters } from '../helpers/app.helpers';
import { ApiService } from './api/api.service';
import { ResellerCheckService } from './reseller-check.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KnowledgeBaseService {
  constructor(private apiService: ApiService, private authService: AuthService, private resellerCheckService: ResellerCheckService) {}

  openKnowledgeBasePortalInNewTab(token: string): void {
    let kbUrl = this.authService.getKnowledgeBaseUrl();
    if (defined(token)) {
      window.open(`${kbUrl}?st=${encodeSpecialCharacters(token)}`, '_blank');
    } else {
      window.open(kbUrl, '_blank');
    }
  }

  getKBTokenObservable(accountId: number, userEmail: string): Observable<string> {
    const url = `${environment.baseAPIUrl}${environment.version}/accounts/${accountId}/knowledgebase/token`;
    const jsonBody = { email: userEmail };

    return this.apiService.postRequest(url, jsonBody).pipe(
      map((res) => res.results.st),
      catchError(() => of(''))
    );
  }

  onGetKBToken(token: string): void {
    this.authService.setKnowledgeBaseToken(token);
    this.authService.kbTokenChanged.next();
  }

  configureKnowledgeBaseTokenForZYPortal(accountId: number, userEmail: string): void {
    if (this.resellerCheckService.isZYReseller()) {
      this.getKBTokenObservable(accountId, userEmail).subscribe((token) => this.onGetKBToken(token));
    }
  }
}
