import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackbarService } from '../../../services/snackbar.service';
import { defined } from '../../../../shared/helpers/app.helpers';

@Component({
  selector: 'nome-cobrowse-dialog',
  templateUrl: './cobrowse-dialog.component.html',
  styleUrls: ['./cobrowse-dialog.component.scss']
})
export class CobrowseDialogComponent {
  get message(): string {
    return this.data.message;
  }
  get code(): string {
    return this.data.code;
  }
  formattedCode: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialog: MatDialogRef<CobrowseDialogComponent>, private snackbarService: SnackbarService, private clipboard: Clipboard) {
    if (data?.code) {
      this.formattedCode = `${this.code.substring(0, 3)} - ${this.code.substring(3, 6)}`;
    }
  }

  copyToClipboard(): void {
    if (defined(this.code)) {
      this.clipboard.copy(this.code);
      this.snackbarService.openSnackBar('copy_to_clipboard.copied-successfully');
    }
    this.closeDialog();
  }

  private closeDialog(): void {
    this.dialog.close();
  }
}
