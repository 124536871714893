import { Router, Params, ActivatedRoute } from '@angular/router';
import { Injectable, OnInit } from '@angular/core';
import { AuthService } from './../../core/auth/auth.service';
import * as _ from 'lodash';
import { defined } from '../helpers/app.helpers';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private previousRoute: string;
  private previousRoutesList: string[] = [];
  private path: string;
  private currentPage: string;
  private blnDisplayBreadCrumb: boolean = true;
  readonly sidenavMinWidth = 260;
  sidenavMaxWidth = window.innerWidth / 2;
  constructor(public router: Router, private route: ActivatedRoute, private authService: AuthService) {
    if (this.authService) {
      const tenantName = this.authService.getTenantName();
      this.setPath(tenantName, '');
    }
  }

  /**
   * Function used to check if the breadcrumb should be displayed;
   */
  getBlnDisplayBreadCrumb(): boolean {
    return this.blnDisplayBreadCrumb;
  }

  /**
   * Function used to set the DisplayBreadCrumb
   * @param blnDisplayBreadCrumb boolean
   */
  setBlnDisplayBreadCrumb(blnDisplayBreadCrumb: boolean) {
    this.blnDisplayBreadCrumb = blnDisplayBreadCrumb;
  }

  getBaseRoute(): string {
    return this.router.url.split('?')[0];
  }

  getQueryParams(): Params {
    return this.route.snapshot.queryParams;
  }

  /**
   * Function used to return the path to be set in the main app interface
   */
  getPath(): { path: string; currentPage: string } {
    const tenantName = this.authService.getTenantName();

    this.setPath(tenantName, '');
    return { path: this.path, currentPage: this.currentPage };
  }
  /**
   * Function used to set the path to be shown in the main app interface
   * @param path string
   * @param currentPage string
   */
  setPath(path: string, currentPage: string) {
    this.path = path;
    this.currentPage = currentPage;
  }

  clearPath() {
    this.path = '';
    this.currentPage = '';
    this.previousRoute = '';
    this.previousRoutesList = [];
  }

  navigateToUrl(url: string, queryparams?) {
    this.router.navigate([url], { queryParams: queryparams });
  }

  navigateToUrlWithoutParams(url: string) {
    this.router.navigate([url]);
  }

  getSelectedCategory(): number {
    if (this.route.snapshot.queryParams['catID']) {
      return parseInt(this.route.snapshot.queryParams['catID'], 10);
    }

    return null;
  }

  getSelectedLocationId(): number {
    if (this.route.snapshot.queryParams['locationId']) {
      return parseInt(this.route.snapshot.queryParams['locationId'], 10);
    }

    return null;
  }

  setPreviousRoute(route: string, enforceLocalStorage: boolean = false) {
    this.previousRoute = decodeURIComponent(route);
    if (enforceLocalStorage) {
      localStorage.setItem('previousRoute', route);
    }
  }
  getPreviousRoute(enforceLocalStorage: boolean = false): string {
    if (enforceLocalStorage && defined(localStorage.getItem('previousRoute'))) {
      this.previousRoute = decodeURIComponent(localStorage.getItem('previousRoute'));
    }
    return this.previousRoute;
  }

  setPreviousRoutesList(route: string, pop?: boolean, index?: number) {
    if (pop) this.previousRoutesList.splice(index, 1);
    else this.previousRoutesList.push(decodeURIComponent(route));
  }
  getPreviousRoutesList(): string[] {
    return this.previousRoutesList;
  }

  // goBack() {
  //   if (this.previousRoute) {
  //     this.router.navigate([this.previousRoute]);
  //   } else {
  //     this.router.navigate([`/${this.router.routerState.snapshot.url.split('/')[1]}`]);
  //   }
  // }

  goBack(previousPath?: string) {
    let prevRoute = previousPath ? previousPath : this.previousRoute;
    if (prevRoute) {
      if (prevRoute.includes('?')) {
        let params = this.getParams(prevRoute.split('?')[1]);
        let paramsArray = params.map((x) => ({ name: x.split('=')[0], value: x.split('=')[1] }));
        this.router.navigate([prevRoute.split('?')[0]], { queryParams: _.mapValues(_.keyBy(paramsArray, 'name'), 'value') });
      } else {
        this.router.navigate([prevRoute]);
      }
    } else {
      this.router.navigate([`/${this.router.routerState.snapshot.url.split('/')[1]}`]);
    }
  }

  getParams(paramsPath): string[] {
    return paramsPath.split('&');
  }
  // sidenav resizing functions 
  get sidenavWidth(): number {
    return parseInt(
      getComputedStyle(document.body).getPropertyValue('--sidenav-width'),
      10
    );
  }

  setSidenavWidth(width: number) {
    const clampedWidth = Math.min(
      Math.max(width, this.sidenavMinWidth),
      this.sidenavMaxWidth
    );

    document.body.style.setProperty('--sidenav-width', `${clampedWidth}px`);
  }
}
