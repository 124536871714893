import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SecurityUtilsService {
  constructor(private sanitizer: DomSanitizer) {}

  secureURL(url: string) {
    return this.sanitizer.sanitize(SecurityContext.URL, url);
  }

  secureStyle(style: string) {
    return this.sanitizer.sanitize(SecurityContext.STYLE, style);
  }

  secureHTML(html: string) {
    return this.sanitizer.sanitize(SecurityContext.HTML, html);
  }

  secureText(text: string) {
    return this.sanitizer.sanitize(SecurityContext.NONE, text);
  }
}
