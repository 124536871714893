import { validationParams } from './error-model';

export enum FormErrosEnum {
  required = 'required',
  maxLength = 'maxlength',
  max = 'max',
  min = 'min',
  noZeroAllowed = 'noZeroAllowed',
  positiveInteger = 'positiveInteger',
  matDatepickerMax = 'matDatepickerMax',
  matDatepickerMin = 'matDatepickerMin',
  matDatepickerParse = 'matDatepickerParse',
  matDatepickerFilter = 'matDatepickerFilter',
  confirmPassword = 'ConfirmPassword',
  minLength = 'minlength',
  deviceRefreshTokenExpiryInvalid = 'deviceRefreshTokenExpiryInvalid',
  email = 'email',
  invalidNumber = 'invalidNumber',
  validCountryPhone = 'validCountryPhone',
  owlDateTimeMax = 'owlDateTimeMax',
  owlDateTimeParse = 'owlDateTimeParse',
  invalidLocation = 'invalidLocation',
  extensionFileValidator = 'extensionFileValidator',
  alphaNumericDashUnderscore = 'alphaNumericDashUnderscore',
  unique = 'unique',
  imageTypeError = 'imageTypeError',
  imageSizeError = 'imageSizeError',
  imageSizeError50KB = 'imageSizeError50KB',
  imageSizeError500KB = 'imageSizeError500KB',
  imageSizeError2MB = 'imageSizeError2MB',
  svgFilesNotSupported = 'svgFilesNotSupported',
  formatNotSupported = 'formatNotSupported',
  logoBannerWrongDimensions = 'logoBannerWrongDimensions',
  wrongExtension = 'wrongExtension',
  isAliasNameNotValid = 'isAliasNameNotValid',
  isAliasNameNotUnique = 'isAliasNameNotUnique',
  pattern = 'pattern',
  uomIsNotGram = 'uomIsNotGram',
  invalid_value = 'invalid_value',
  dateIntervalExceeding = 'dateIntervalExceeding',
  formatNotSupportedPngOnly = 'formatNotSupportedPngOnly',
  whitespace = 'whitespace',
  noWhiteSpaceAtStart = 'noWhiteSpaceAtStart',
  noWhiteSpaceAtEnd = 'noWhiteSpaceAtEnd',
  cannotContainWhitespace = 'cannotContainWhitespace',
  oNotAllowed = 'oNotAllowed',
  invalidInputType = 'invalidInputType',
  duplicatedNameExist = 'duplicatedNameExist',
  decimalNotAllowed = 'decimalNotAllowed',
  atLeastOneIsFilled = 'atLeastOneIsFilled',
  validFile = 'validFile',
  uniqueInFormArray = 'uniqueInFormArray',
  displayNameUnique = 'displayNameUnique',
  selectedDeliveryDateInvalid = 'selectedDeliveryDateInvalid',
  lessThanOrEqualTo = 'lessThanOrEqualTo',
  greaterThanOrEqualTo = 'greaterThanOrEqualTo',
  greaterThan = 'greaterThan',
  lessThan = 'lessThan',
  MinItemsInArray = 'minItemsInArray',
  Intersection = 'intersection',
  notSame = 'notSame',
  start = 'start',
  end = 'end',
  rangeIntersection = 'rangeIntersection',
  overlap = 'overlap',
  gap = 'gap',
  alreadyExist = 'alreadyExist',
  customFieldsImageSupportedFormats = 'customFieldsImageSupportedFormats',
  maxCombinationsLessThanTwoIfPriceOptionsExist = 'maxCombinationsLessThanTwoIfPriceOptionsExist',
  dualLabelNotUniq = 'dual_label_not_uniq',
  startDateGreaterThanEndDate = 'startDateGreaterThanEndDate',
  endDateLessThanStartDate = 'endDateLessThanStartDate',
  startDateEqualEndDate = 'startDateEqualEndDate',
  endDateEqualStartDate = 'endDateEqualStartDate',
  invalidGUIDFormat = 'invalidGUIDFormat',
  labelObjectInUse = 'labelObjectInUse'
}

export const errorMessages = new Map<string, string>([
  [FormErrosEnum.max, 'form_validation.max'],
  [FormErrosEnum.maxLength, 'form_validation.max-length'],
  [FormErrosEnum.minLength, 'form_validation.min-value'],
  [FormErrosEnum.min, 'form_validation.min'],
  [FormErrosEnum.required, 'required'],
  [FormErrosEnum.noZeroAllowed, 'value_greater_than_zero'],
  [FormErrosEnum.positiveInteger, 'form_validation.positive-int'],
  [FormErrosEnum.matDatepickerMax, 'form_validation.max_date'],
  [FormErrosEnum.matDatepickerMin, 'form_validation.min_date'],
  [FormErrosEnum.matDatepickerParse, 'invalid_date'],
  [FormErrosEnum.matDatepickerFilter, 'invalid_date'],
  [FormErrosEnum.confirmPassword, 'activate_page.error.passwords-doesnt-match'],
  [FormErrosEnum.deviceRefreshTokenExpiryInvalid, 'error.TENANT_REFRESH_TOKEN_LESS_THAN_USER_TOKEN'],
  [FormErrosEnum.email, 'form_validation.invalid_email'],
  [FormErrosEnum.invalidNumber, 'account_manager.users.pin-number'],
  [FormErrosEnum.validCountryPhone, 'Invalid phone number'],
  [FormErrosEnum.owlDateTimeMax, 'date_future_error'],
  [FormErrosEnum.owlDateTimeParse, 'form_validation.dateTime_error'],
  [FormErrosEnum.invalidLocation, 'analytics.reports_only_for_stores_and_folders'],
  [FormErrosEnum.extensionFileValidator, 'form_validation.image_extension'],
  [FormErrosEnum.alphaNumericDashUnderscore, 'alphanumeric_dashes_underscores_allowed'],
  [FormErrosEnum.unique, ''],
  [FormErrosEnum.imageTypeError, 'form_validation.file-type-image'],
  [FormErrosEnum.imageSizeError, 'form_validation.file-size-error'],
  [FormErrosEnum.imageSizeError50KB, 'form_validation.file-size-error-50-kb'],
  [FormErrosEnum.imageSizeError500KB, 'form_validation.file-size-error-500-kb'],
  [FormErrosEnum.imageSizeError2MB, 'form_validation.file-size-error-2-mb'],
  [FormErrosEnum.svgFilesNotSupported, 'form_validation.svg_extension_not_supported'],
  [FormErrosEnum.formatNotSupported, 'form_validation.not-supported-file'],
  [FormErrosEnum.logoBannerWrongDimensions, 'form_validation.banner_logo_wrong_dimensions'],
  [FormErrosEnum.wrongExtension, 'error.FILE_WRONG_FORMAT'],
  [FormErrosEnum.isAliasNameNotValid, 'form_validation.only_letters_and_numbers'],
  [FormErrosEnum.isAliasNameNotUnique, 'form_validation.unique_field'],
  [FormErrosEnum.pattern, 'form_validation.location_pattern'],
  [FormErrosEnum.uomIsNotGram, 'uom-gram'],
  [FormErrosEnum.dateIntervalExceeding, 'date_interval_exceeding_custom'],
  [FormErrosEnum.formatNotSupportedPngOnly, 'form_validation.not_supported_file_png'],
  [FormErrosEnum.whitespace, 'required'],
  [FormErrosEnum.noWhiteSpaceAtStart, 'form_validation.field_should_not_start_with_space'],
  [FormErrosEnum.noWhiteSpaceAtEnd, 'form_validation.field_should_not_end_with_space'],
  [FormErrosEnum.cannotContainWhitespace, 'distribution_manager.divisions.details.code_validation_error'],
  [FormErrosEnum.oNotAllowed, 'equipment_details.eui_letter_O'],
  [FormErrosEnum.invalidInputType, 'invalid_input_value'],
  [FormErrosEnum.duplicatedNameExist, 'name_should_be_unique'],
  [FormErrosEnum.decimalNotAllowed, 'form_validation.int-error'],
  [FormErrosEnum.atLeastOneIsFilled, 'form_validation.at_least_one_field_should_be_filled'],
  [FormErrosEnum.validFile, 'form_validation.invalid_file_extension'],
  [FormErrosEnum.uniqueInFormArray, 'form_validation.field_unique'],
  [FormErrosEnum.displayNameUnique, 'form_validation.display_name_unique'],
  [FormErrosEnum.selectedDeliveryDateInvalid, 'order_cutoff_time_has_passed_error_msg'],
  [FormErrosEnum.lessThanOrEqualTo, 'form_validation.max'],
  [FormErrosEnum.greaterThanOrEqualTo, 'form_validation.min'],
  [FormErrosEnum.lessThan, 'form_validation.min_check_against_max'],
  [FormErrosEnum.greaterThan, 'form_validation.max_check_against_min'],
  [FormErrosEnum.MinItemsInArray, 'form_validation.missing_selection'],
  [FormErrosEnum.Intersection, 'form_validation.duplicate_recurrences'],
  [FormErrosEnum.notSame, 'profile_manager.error.error-password-do-not-match'],
  [FormErrosEnum.start, 'account_manager.account-info.start-error'],
  [FormErrosEnum.end, 'account_manager.account-info.end-error'],
  [FormErrosEnum.rangeIntersection, 'account_manager.account-info.range-error'],
  [FormErrosEnum.overlap, 'account_manager.account-info.range-intersection-error'],
  [FormErrosEnum.gap, 'account_manager.account-info.gap-error'],
  [FormErrosEnum.alreadyExist, 'form_validation.name_already_exists'],
  [FormErrosEnum.customFieldsImageSupportedFormats, 'custom_fields_image_supported_formats'],
  [FormErrosEnum.maxCombinationsLessThanTwoIfPriceOptionsExist, 'menu_manager.menu.bulk_edit.error.max_combinations_price_options'],
  [FormErrosEnum.startDateGreaterThanEndDate, 'form_validation.start_date_greater_than_end_date'],
  [FormErrosEnum.endDateLessThanStartDate, 'form_validation.end_date_less_than_start_date'],
  [FormErrosEnum.startDateEqualEndDate, 'form_validation.start_date_equal_to_end_date'],
  [FormErrosEnum.endDateEqualStartDate, 'form_validation.end_date_equal_to_start_date'],
  [FormErrosEnum.invalidGUIDFormat, 'form_validation.invalid_guid_format'],
  [FormErrosEnum.labelObjectInUse, 'label_designer.errors.object_in_use']
]);
