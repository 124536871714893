import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { KeycloackAuthGuard } from '../auth/keycloak-auth.guard';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard  {
  constructor(private authService: AuthService, private keycloakService: KeycloakService, private keycloakAuthGuardService: KeycloackAuthGuard) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.authService.getAuthenticateUsingKeycloakOIDC()) {
      return this.isAccessAllowed(state);
    } else {
      const token = this.authService.getAuthToken();
      if (token) {
        const tokenExpired = this.authService.isTokenExpired();
        if (tokenExpired) {
          //If the token is expired, then we allow the login page to open
          return true;
        }

        //IF the token is not yet expired, we need to redirect to the default portal dashboard
        this.authService.redirectToDashboard();
        return false;
      } else return true;
    }
  }

  public async isAccessAllowed(state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const isLoggedIn = await this.keycloakService.isLoggedIn();
    if (!isLoggedIn || this.keycloakService.isTokenExpired()) {
      if (state.url === '/login') {
        this.authService.redirectToEmailValidation();
        return false;
      }
      return true;
    } else {
      this.authService.redirectToDashboard();
      return false;
    }
  }
}
