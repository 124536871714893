import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RecipeComponentsModel, DynamicRecipeDetails } from '../models/recipe-components.model';
import { Ingredients } from '../models/product-ingredient.model';
import { RecipeBasicInclusive } from '../models/recipe-details.model';
import { RecipeIngredientsModel } from '../models/recipe-ingredients.model';
import { ComponentType } from '../models/component-type.enum';
import { RecipeRecipesModel, RecipeRecipesGroupedModel } from '../models/recipe-recipes.model';
import { RecipeType } from '../models/recipe-type.enum';
import { UnitOfMeasure } from '../../../shared/models/UnitOfMeasure.model';

@Injectable({
  providedIn: 'root'
})
export class RecipeMappingHelpers {
  constructor(private translateService: TranslateService) {}

  mapRecipesToAssignedComponents(recipes: RecipeBasicInclusive[], assigned_recipes: RecipeRecipesGroupedModel[], unitsOfMeasure: UnitOfMeasure[], recipeUnits: UnitOfMeasure[]): RecipeComponentsModel[] {
    let dynamic_recipes = assigned_recipes.filter((x) => x.typeId == RecipeType.DYNAMIC || x.typeId == RecipeType.STATIC_DYNAMIC);
    let static_recipes = assigned_recipes.filter((x) => x.typeId == RecipeType.STATIC);
    let recipeAssignedComponents: RecipeComponentsModel[] = [];
    recipeAssignedComponents = recipes.map((recipe, index) => {
      let recipe_static = static_recipes.find((x) => x.assignedRecipeId == recipe.recipeId);
      let recResult = new RecipeComponentsModel(
        recipe.name,
        ComponentType.RECIPES,
        recipe.recipeId,
        recipe.isSelected,
        recipe_static ? recipe_static.displayOrder : index + 1,
        recipe.factor,
        recipe.uoM ? recipe.uoM : recipeUnits.find((unit) => unit.symbol == 'Servings').id,
        null,
        recipe_static ? recipe_static.typeId : null
      );

      recResult.componentName_Secondary = recipe.name_Secondary;
      recResult.isAlreadySelected = recipe.isSelected;
      recResult.hasSelection = recipe.hasSelection;
      recResult.defaultUoMUnit = recipe.measurementUnitId
        ? [
            ...recipeUnits,
            new UnitOfMeasure(
              recipe.measurementUnitId,
              unitsOfMeasure.find((u) => u.id == recipe.measurementUnitId).name,
              unitsOfMeasure.find((u) => u.id == recipe.measurementUnitId).symbol,
              unitsOfMeasure.find((u) => u.id == recipe.measurementUnitId).description
            )
          ]
        : recipeUnits;
      recResult.isCreatedAtLocation = recipe_static ? recipe_static.isCreatedAtLocation : recipe.isCreatedAtLocation;
      recResult.canBeAssigned = recipe_static ? recipe_static.canBeAssigned : recipe.canBeAssigned;
      return recResult;
    });
    dynamic_recipes.forEach((recipe) => {
      recipeAssignedComponents.push(
        new RecipeComponentsModel(
          recipe.name,
          ComponentType.RECIPES,
          recipe.assignedRecipeId,
          true,
          recipe.displayOrder,
          null,
          null,
          null,
          recipe.typeId,
          null,
          new DynamicRecipeDetails(
            recipe.name,
            recipe.name_Secondary,
            recipe.typeId == RecipeType.STATIC_DYNAMIC ? 2 : 1,
            recipe.minSelection,
            recipe.maxSelection,
            recipe.dynamicAssignedRecipes.map((ar) => {
              let recResult = new RecipeComponentsModel(
                ar.dynamicName,
                ar.dynamicTypeId,
                ar.dynamicAssignedComponentId,
                false,
                ar.dynamicDisplayOrder,
                ar.dynamicFactor,
                ar.dynamicUoM,
                null,
                ar.dynamicTypeId == ComponentType.RECIPES ? RecipeType.STATIC : null,
                ar.dynamicDisplayName,
                null,
                ar.dynamicMeasurementUnitId
                  ? ar.dynamicTypeId == ComponentType.RECIPES
                    ? [
                        ...recipeUnits,
                        new UnitOfMeasure(
                          ar.dynamicMeasurementUnitId,
                          unitsOfMeasure.find((u) => u.id == ar.dynamicMeasurementUnitId).name,
                          unitsOfMeasure.find((u) => u.id == ar.dynamicMeasurementUnitId).symbol,
                          unitsOfMeasure.find((u) => u.id == ar.dynamicMeasurementUnitId).description
                        )
                      ]
                    : [
                        new UnitOfMeasure(
                          ar.dynamicMeasurementUnitId,
                          unitsOfMeasure.find((u) => u.id == ar.dynamicMeasurementUnitId).name,
                          unitsOfMeasure.find((u) => u.id == ar.dynamicMeasurementUnitId).symbol,
                          unitsOfMeasure.find((u) => u.id == ar.dynamicMeasurementUnitId).description
                        )
                      ]
                  : recipeUnits
              );
              recResult.componentName_Secondary = ar.dynamicName_Secondary;
              recResult.componentDisplayName_Secondary = ar.dynamicDisplayName_Secondary;
              recResult.isCreatedAtLocation = ar.dynamicIsCreatedAtLocation;
              return recResult;
            })
          )
        )
      );
    });

    return recipeAssignedComponents;
  }

  mapIngredientsToAssignedComponents(ingredients: Ingredients[], assigned_ingredients: RecipeIngredientsModel[], unitsOfMeasure: UnitOfMeasure[]): RecipeComponentsModel[] {
    let recipeAssignedComponents: RecipeComponentsModel[] = [];

    ingredients.forEach((ingredient) => {
      let assigned_ing = assigned_ingredients.find((x) => x.ingredientId == ingredient.ingredientId);
      let recipeComponent: RecipeComponentsModel;
      const defaultDisplayOrder = recipeAssignedComponents.length + 1 + ingredients.length;
      let isCreatedAtLocation = false;
      if (assigned_ing) {
        recipeComponent = new RecipeComponentsModel(
          assigned_ing.name,
          ComponentType.INGREDIENTS,
          assigned_ing.ingredientId,
          true,
          assigned_ing.displayOrder ? assigned_ing.displayOrder : defaultDisplayOrder,
          assigned_ing.quantity,
          assigned_ing.uoM
        );
        recipeComponent.componentName_Secondary = assigned_ing.name_Secondary;
        isCreatedAtLocation = assigned_ing.isCreatedAtLocation;
      } else {
        recipeComponent = new RecipeComponentsModel(ingredient.name, ComponentType.INGREDIENTS, ingredient.ingredientId, false, defaultDisplayOrder);
        recipeComponent.uom = ingredient.measurementUnitId;
        recipeComponent.componentName_Secondary = ingredient.name_Secondary;
        isCreatedAtLocation = ingredient.isCreatedAtLocation;
      }
      recipeComponent.defaultUoMUnit = [
        new UnitOfMeasure(
          ingredient.measurementUnitId,
          unitsOfMeasure.find((u) => u.id == ingredient.measurementUnitId).name,
          unitsOfMeasure.find((u) => u.id == ingredient.measurementUnitId).symbol,
          unitsOfMeasure.find((u) => u.id == ingredient.measurementUnitId).description
        )
      ];
      recipeComponent.isAlreadySelected = recipeComponent.isSelected;
      recipeComponent.isCreatedAtLocation = isCreatedAtLocation;
      recipeAssignedComponents.push(recipeComponent);
    });

    return recipeAssignedComponents;
  }

  Map_AssignedRecipesIngredients_To_AssignedComponents(
    assigned_ingredients: RecipeIngredientsModel[],
    assigned_recipes: RecipeRecipesGroupedModel[],
    unitsOfMeasure: UnitOfMeasure[],
    recipeUnits: UnitOfMeasure[]
  ): RecipeComponentsModel[] {
    let dynamic_recipes = assigned_recipes.filter((x) => x.typeId == RecipeType.DYNAMIC || x.typeId == RecipeType.STATIC_DYNAMIC);
    let static_recipes = assigned_recipes.filter((x) => x.typeId == RecipeType.STATIC);
    let recipeAssignedComponents: RecipeComponentsModel[] = [];
    recipeAssignedComponents = static_recipes.map((recipe_static, index) => {
      // let recipe_static = static_recipes.find(x => x.assignedRecipeId == recipe.recipeId);
      let recResult = new RecipeComponentsModel(
        recipe_static.name,
        ComponentType.RECIPES,
        recipe_static.assignedRecipeId,
        true,
        recipe_static ? recipe_static.displayOrder : index + 1,
        recipe_static.factor,
        recipe_static.uoM ? recipe_static.uoM : recipeUnits.find((unit) => unit.symbol == 'Servings').id,
        null,
        recipe_static ? recipe_static.typeId : null
      );
      recResult.isDisabledForSubscriber = recipe_static.isDisabledForSubscriber;
      recResult.componentName_Secondary = recipe_static.name_Secondary;
      recResult.isAlreadySelected = true;
      recResult.hasSelection = recipe_static.hasSelection;
      recResult.defaultUoMUnit =
        recipe_static.measurementUnitId && unitsOfMeasure.find((u) => u.id == recipe_static.measurementUnitId)
          ? [
              ...recipeUnits,
              new UnitOfMeasure(
                recipe_static.measurementUnitId,
                unitsOfMeasure.find((u) => u.id == recipe_static.measurementUnitId).name,
                unitsOfMeasure.find((u) => u.id == recipe_static.measurementUnitId).symbol,
                unitsOfMeasure.find((u) => u.id == recipe_static.measurementUnitId).description
              )
            ]
          : recipeUnits;
      recResult.isCreatedAtLocation = recipe_static ? recipe_static.isCreatedAtLocation : recipe_static.isCreatedAtLocation;
      return recResult;
    });
    dynamic_recipes.forEach((recipe) => {
      recipeAssignedComponents.push(
        new RecipeComponentsModel(
          recipe.name,
          ComponentType.RECIPES,
          recipe.assignedRecipeId,
          true,
          recipe.displayOrder,
          null,
          null,
          null,
          recipe.typeId,
          null,
          new DynamicRecipeDetails(
            recipe.name,
            recipe.name_Secondary,
            recipe.typeId == RecipeType.STATIC_DYNAMIC ? 2 : 1,
            recipe.minSelection,
            recipe.maxSelection,
            recipe.dynamicAssignedRecipes.map((ar) => {
              let recResult = new RecipeComponentsModel(
                ar.dynamicName,
                ar.dynamicTypeId,
                ar.dynamicAssignedComponentId,
                false,
                ar.dynamicDisplayOrder,
                ar.dynamicFactor,
                ar.dynamicUoM,
                null,
                ar.dynamicTypeId == ComponentType.RECIPES ? RecipeType.STATIC : null,
                ar.dynamicDisplayName,
                null,
                ar.dynamicMeasurementUnitId && unitsOfMeasure.find((u) => u.id == ar.dynamicMeasurementUnitId)
                  ? ar.dynamicTypeId == ComponentType.RECIPES
                    ? [
                        ...recipeUnits,
                        new UnitOfMeasure(
                          ar.dynamicMeasurementUnitId,
                          unitsOfMeasure.find((u) => u.id == ar.dynamicMeasurementUnitId).name,
                          unitsOfMeasure.find((u) => u.id == ar.dynamicMeasurementUnitId).symbol,
                          unitsOfMeasure.find((u) => u.id == ar.dynamicMeasurementUnitId).description
                        )
                      ]
                    : [
                        new UnitOfMeasure(
                          ar.dynamicMeasurementUnitId,
                          unitsOfMeasure.find((u) => u.id == ar.dynamicMeasurementUnitId).name,
                          unitsOfMeasure.find((u) => u.id == ar.dynamicMeasurementUnitId).symbol,
                          unitsOfMeasure.find((u) => u.id == ar.dynamicMeasurementUnitId).description
                        )
                      ]
                  : recipeUnits
              );
              recResult.componentName_Secondary = ar.dynamicName_Secondary;
              recResult.componentDisplayName_Secondary = ar.dynamicDisplayName_Secondary;
              recResult.isCreatedAtLocation = ar.dynamicIsCreatedAtLocation;
              recResult.hasSelection = ar.hasSelection;
              recResult.isDisabledForSubscriber = ar.isDisabledForSubscriber;
              return recResult;
            })
          )
        )
      );
    });
    assigned_ingredients.forEach((assigned_ing) => {
      let recipeComponent: RecipeComponentsModel;
      if (assigned_ing) {
        recipeComponent = new RecipeComponentsModel(
          assigned_ing.name,
          ComponentType.INGREDIENTS,
          assigned_ing.ingredientId,
          true,
          assigned_ing.displayOrder ? assigned_ing.displayOrder : recipeAssignedComponents.length + 1,
          assigned_ing.quantity,
          assigned_ing.uoM
        );
        recipeComponent.componentName_Secondary = assigned_ing.name_Secondary;
      }
      recipeComponent.defaultUoMUnit = unitsOfMeasure.find((u) => u.id == assigned_ing.uoM)
        ? [
            new UnitOfMeasure(
              assigned_ing.uoM,
              unitsOfMeasure.find((u) => u.id == assigned_ing.uoM).name,
              unitsOfMeasure.find((u) => u.id == assigned_ing.uoM).symbol,
              unitsOfMeasure.find((u) => u.id == assigned_ing.uoM).description
            )
          ]
        : null;
      recipeComponent.isAlreadySelected = recipeComponent.isSelected;
      recipeComponent.isCreatedAtLocation = assigned_ing.isCreatedAtLocation;
      recipeComponent.isDisabledForSubscriber = assigned_ing.isDisabledForSubscriber;
      recipeAssignedComponents.push(recipeComponent);
    });

    return recipeAssignedComponents;
  }

  mapRecipesToPopupComponents(assigned_components: RecipeComponentsModel[], recipes: RecipeBasicInclusive[], ingredientUnitsOfMeasure: UnitOfMeasure[], recipeUnitsOfMeasure: UnitOfMeasure[]): RecipeComponentsModel[] {
    let recipeAssignedComponents: RecipeComponentsModel[] = [];
    let static_recipes = assigned_components.filter((x) => x.typeId == ComponentType.RECIPES);
    recipeAssignedComponents = recipes.map((recipe, index) => {
      let recipe_static = static_recipes.find((x) => x.componentId == recipe.recipeId);
      let recResult = new RecipeComponentsModel(
        recipe.name,
        ComponentType.RECIPES,
        recipe.recipeId,
        recipe_static ? true : false,
        recipe_static ? recipe_static.displayOrder : index + 1,
        recipe.factor,
        recipe.uoM ? recipe.uoM : recipeUnitsOfMeasure.find((unit) => unit.symbol == 'Servings').id,
        null,
        recipe_static ? recipe_static.recipeType : null
      );

      recResult.componentName_Secondary = recipe.name_Secondary;
      recResult.isAlreadySelected = recipe_static ? true : false;
      recResult.hasSelection = recipe_static ? recipe_static.hasSelection : recipe.hasSelection;
      recResult.defaultUoMUnit = recipe.measurementUnitId
        ? [
            ...recipeUnitsOfMeasure,
            new UnitOfMeasure(
              recipe.measurementUnitId,
              ingredientUnitsOfMeasure.find((u) => u.id == recipe.measurementUnitId).name,
              ingredientUnitsOfMeasure.find((u) => u.id == recipe.measurementUnitId).symbol,
              ingredientUnitsOfMeasure.find((u) => u.id == recipe.measurementUnitId).description
            )
          ]
        : recipeUnitsOfMeasure;
      recResult.isCreatedAtLocation = recipe_static ? recipe_static.isCreatedAtLocation : recipe.isCreatedAtLocation;
      recResult.canBeAssigned = recipe_static ? recipe_static.canBeAssigned : recipe.canBeAssigned;
      return recResult;
    });

    return recipeAssignedComponents;
  }

  mapIngredientsToPopupComponents(assigned_components: RecipeComponentsModel[], ingredients: Ingredients[], ingredientUnitsOfMeasure: UnitOfMeasure[]): RecipeComponentsModel[] {
    let recipeAssignedComponents: RecipeComponentsModel[] = [];
    let static_ingredients = assigned_components.filter((x) => x.typeId == ComponentType.INGREDIENTS);

    ingredients.forEach((ingredient) => {
      let assigned_ing = static_ingredients.find((x) => x.componentId == ingredient.ingredientId);
      let recipeComponent: RecipeComponentsModel;
      const defaultDisplayOrder = recipeAssignedComponents.length + 1 + ingredients.length;
      let isCreatedAtLocation = false;
      if (assigned_ing) {
        recipeComponent = new RecipeComponentsModel(
          assigned_ing.componentName,
          ComponentType.INGREDIENTS,
          assigned_ing.componentId,
          true,
          assigned_ing.displayOrder ? assigned_ing.displayOrder : defaultDisplayOrder,
          assigned_ing.unit,
          assigned_ing.uom
        );
        recipeComponent.componentName_Secondary = assigned_ing.componentName_Secondary;
        isCreatedAtLocation = assigned_ing.isCreatedAtLocation;
      } else {
        recipeComponent = new RecipeComponentsModel(ingredient.name, ComponentType.INGREDIENTS, ingredient.ingredientId, false, defaultDisplayOrder);
        recipeComponent.uom = ingredient.measurementUnitId;
        recipeComponent.componentName_Secondary = ingredient.name_Secondary;
        isCreatedAtLocation = ingredient.isCreatedAtLocation;
      }
      recipeComponent.defaultUoMUnit = [
        new UnitOfMeasure(
          ingredient.measurementUnitId,
          ingredientUnitsOfMeasure.find((u) => u.id == ingredient.measurementUnitId).name,
          ingredientUnitsOfMeasure.find((u) => u.id == ingredient.measurementUnitId).symbol,
          ingredientUnitsOfMeasure.find((u) => u.id == ingredient.measurementUnitId).description
        )
      ];
      recipeComponent.isAlreadySelected = recipeComponent.isSelected;
      recipeComponent.isCreatedAtLocation = isCreatedAtLocation;
      recipeAssignedComponents.push(recipeComponent);
    });

    return recipeAssignedComponents;
  }

  Map_Recipe_To_Correct_Order(recipeAssignedComponents: RecipeComponentsModel[]): RecipeComponentsModel[] {
    recipeAssignedComponents.forEach((element, index) => {
      element.displayOrder = index + 1;
    });

    return recipeAssignedComponents;
  }
}
