import { Pipe, PipeTransform } from '@angular/core';
import { getObjectPropertyValue } from '../helpers/app.helpers';
import { LabelDetailsModel } from '../../modules/label-designer/models/label-model';

@Pipe({
  name: 'labelName'
})
export class LabelNamePipe implements PipeTransform {
  transform(labelId: number | string, labels: LabelDetailsModel[]): string {
    return getObjectPropertyValue(
      labels.find((x) => (x.labelId === labelId) || (x.key === labelId)),
      'name'
    );
  }
}
