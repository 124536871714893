import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { hasUpperCase, stringIsDefinedAndNotEmpty } from '../helpers/app.helpers';

@Injectable({
  providedIn: 'root'
})
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    const enFile = require('../../../assets/i18n/en.json');
    const containsDots = params.key.includes('.') && !params.key.endsWith('.');
    return stringIsDefinedAndNotEmpty(enFile[params.key]) ? enFile[params.key] : containsDots ? 'N/A' : params.key;
  }
}
