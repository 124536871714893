import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SnackbarService } from '../../shared/services/snackbar.service';
import { LoginService } from '../services/login.service';
import { LocalStorageFlags } from '../../shared/models/enums/local-storage-flags.enum';
import { AuthService } from '../auth/auth.service';
import { PunchOutModeEnum } from '../../shared/models/enums/punch-out-mode.enum';
import { AccountManagerService } from '../../modules/account-manager/services/account-manager.service';
import { RoleService } from '../services/role.service';

@Component({
  selector: 'nome-punchout-login',
  templateUrl: './punchout-login.component.html',
  styleUrls: ['./punchout-login.component.scss']
})
export class PunchoutLoginComponent implements OnInit {
  isReseller = false;

  constructor(
    private route: ActivatedRoute,
    private loginService: LoginService,
    private authService: AuthService,
    private router: Router,
    private accountManagerService: AccountManagerService,
    private roleService: RoleService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    const code = this.route.snapshot.queryParamMap.get('code');
    const requestId = this.route.snapshot.queryParamMap.get('requestId');
    const targetpage = this.route.snapshot.queryParamMap.get('targetpage');
    if (!code || !requestId) {
      this.router.navigateByUrl('/login');
    } else {
      const punchOutMode = this.route.snapshot.queryParamMap.get('PunchOutMode');
      const resellerName = this.route.snapshot.queryParamMap.get('ResellerName');

      // for tetsing only will be replaced with punchout api
      this.loginService.punchoutLogin(code, requestId, resellerName).subscribe(
        (response) => {
          localStorage.setItem(LocalStorageFlags.punchoutSession, 'True');
          this.authService.setPunchOutMode(punchOutMode as PunchOutModeEnum);
          this.authService.setPunchOutRequestId(requestId);

          this.accountManagerService.getUserModules(response.results.accountId).subscribe((modules) => {
            this.authService.setCurrentUserModules(modules);
            // Role service related info
            this.roleService.setUserAuthorizedModules(this.authService.getCurrentUserModules());
            this.loginService.loginSuccess(response, targetpage);
          });
        },
        (error) => {
          this.snackbarService.openWarningSnackBar('login_page.error.something-wrong', 'Ok');
          this.router.navigateByUrl(`/login?punchoutSession=true&punchOutMode=${punchOutMode}`);
        }
      );
    }
  }
}
