import { FactProfile } from './../../../shared/models/facts-profile.model';
import { Widget, WidgetEditFromPanelDTO, WidgetAddDTO } from './../models/widget.model';
import { LabelSize } from './../../settings/models/label-size.model';
import { CustomField, CustomFieldTypeEnum } from './../../../shared/models/custom-field.model';
import { AccountInfo } from './../../../shared/models/account-info.model';
import { PaginationResponse } from './../../../shared/models/pagination-response.model';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../shared/services/api/api.service';
import { UpdateLabelTemplate } from '../models/label-template.model';
import { EmptyLabel, LabelAddDTO } from './../models/create-label.model';
import { LabelDetailsModel, LabelUsage } from './../models/label-model';
import * as _ from 'lodash';

import { addPaginationParametersToUrl, appendQueryParamIfDefined, arrayIsDefinedAndNotEmpty, defined } from '../../../shared/helpers/app.helpers';
import { GetLimits } from '../../../shared/models/enums/GetRequestLimits.enum';
import { NutritionFactFlatItem } from '../models/nutrition-fact-flatitem.model';
import { ComponentTypes } from '../models/enums/component-types.enum';
import { Editor, ThermalLabelItem } from '../models/label-designer-objects/NeoTypes.model';
import { SerialNumberSourceEnum } from '../models/enums/serial-number-source.enum';
import { getCompositeFieldsText } from '../helpers/label-designer.helper';
import { DateFormatService } from '../../../shared/services/date-format.service';
import { AtmaFields } from '../models/atma-fields.model';
import { DesignerSectionItem } from '../models/designer-section.model';
import { TranslateService } from '@ngx-translate/core';
import { RoundingEnum } from '../models/enums/labelItemProperties.enum';
import { DEFAULT_LABEL_DATA } from '../constants.config';

@Injectable({
  providedIn: 'root'
})
export class LabelDesignerService {
  newLabel: EmptyLabel;
  newWidget: WidgetAddDTO;
  managerOnDutyEnabled: boolean;
  selectedLabel: LabelDetailsModel;
  showAddButton: Subject<boolean> = new Subject();
  hideLocationSubject: Subject<boolean> = new Subject();
  currentPage: Subject<string> = new Subject();
  hasDualNutrionalPanels: boolean;
  hasDualNutritionPanelsSubject: Subject<boolean> = new Subject();
  hasAddonCompositeField: Subject<boolean> = new Subject();
  labelSizes: LabelSize[];
  addButtonHighlight: Subject<boolean> = new Subject<boolean>();
  shiftKeyPress: Subject<boolean> = new Subject<boolean>();
  shiftKeyRelease: Subject<boolean> = new Subject<boolean>();
  importedLabel: any;
  importedWidget: any;
  templateLabel: any;
  labelSizeEnum;
  isContinuousInit: boolean = false;
  keepTransformPopupOpen: boolean = false;
  hasRecipe: boolean = false;
  hasScaleModels: boolean = false;
  public labelCreationSubscription: Subject<boolean> = new Subject<boolean>();
  public noResultAction: Subject<string> = new Subject();
  baseServiceUrl = `${environment.baseAPIUrl}/${environment.version}/accounts/`;

  locationSubject: Subject<number> = new Subject<number>();
  currentLocationId: number;
  factProfiles: FactProfile[];
  originatingPage: string;
  userFontSize: number;
  nutritionalPanelCount: number;
  labelItemUndoList = {};
  tenantTypeId: number;
  accountScaleUomSymbol: string;
  labelSize: LabelSize;
  generateEPC: boolean = DEFAULT_LABEL_DATA.rfidSettings.generateEPC;
  serialNumber: SerialNumberSourceEnum = DEFAULT_LABEL_DATA.rfidSettings.serialNumber as SerialNumberSourceEnum;

  calculatedFieldRoundingOptions = [
    { label: 'half_round_up', value: RoundingEnum.HALF_UP },
    { label: 'round_up', value: RoundingEnum.UP },
    { label: 'round_down', value: RoundingEnum.DOWN }
  ];
  decimalPlaceDefaultValues: Map<ComponentTypes, number> = new Map<ComponentTypes, number>([
    [ComponentTypes.PRODUCT_CALCULATED_FIELD, -1],
    [ComponentTypes.PRODUCT_DISCOUNTED_CALCULATED_FIELD, 0],
    [ComponentTypes.CALCULATED_FIELD, 2]
  ]);

  constructor(private apiService: ApiService, private dfs: DateFormatService, private translateService: TranslateService) {}
  setLocationId(locationId: number) {
    this.locationSubject.next(locationId);
    this.currentLocationId = locationId;
  }

  getAllLabels(
    accountId: number,
    start: number,
    limit: number,
    locationId?: number,
    onlyResellerLabels?: boolean,
    searchKeyword?: string,
    sortBy?: string,
    sortDirection?: string,
    showIsAvailableForRemotePrintingLabels?: boolean,
    includeRfidLabels: boolean = false
  ): Observable<PaginationResponse<LabelDetailsModel[]>> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/labels`;
      if (locationId && !onlyResellerLabels) {
        url = url + `?locationId=${locationId}`;
      }
      if (onlyResellerLabels) url = url + `?onlyResellerLabels=true`;
      if (showIsAvailableForRemotePrintingLabels || showIsAvailableForRemotePrintingLabels === false)
        url = url + (url.includes('?') ? `&isAvailableForRemotePrinting=${showIsAvailableForRemotePrintingLabels}` : `?isAvailableForRemotePrinting=${showIsAvailableForRemotePrintingLabels}`);
      url = addPaginationParametersToUrl(url, start, limit, searchKeyword, sortBy, sortDirection);
      url += url.includes('?') ? `&includeRfidLabels=${includeRfidLabels}` : `?includeRfidLabels=${includeRfidLabels}`;
      return this.apiService.getRequest(url).pipe(map((response) => response.results));
    }
  }

  getLabels(accountId: number, brandId?: number): Observable<PaginationResponse<LabelDetailsModel[]>> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/labels`;
      if (brandId) {
        url += `?BrandId=${brandId}`;
      }
      return this.apiService.getRequest(url).pipe(map((response) => response.results));
    }
  }

  onLabelCreationSuccess() {
    this.labelCreationSubscription.next(true);
  }

  getLatestLabels(accountId: number, brandId?: number, limit: number = 6): Observable<LabelDetailsModel[]> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/labels?MaxRecentLabelsNbr=${limit}`;
      if (brandId) {
        url += `&BrandId=${brandId}`;
      }
      return this.apiService.getRequest(url).pipe(
        map((response) => {
          return <LabelDetailsModel[]>response.results;
        })
      );
    }
  }

  getLabelById(accountId: number, labelId: number, labelType: string = 'label', isCreatedFromTemplate: boolean = false): Observable<LabelDetailsModel[]> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/labels/${labelId}`;
      if (labelType === 'template') {
        url = this.baseServiceUrl + `${accountId}/labelTemplates/${labelId}`;
        if (isCreatedFromTemplate) {
          url += '/details';
        }
      }
      return this.apiService.getRequest(url).pipe(
        map((res) => {
          return res.results;
        })
      );
    }
  }

  deleteLabel(accountId: number, labelId: number, forceDelete: boolean = false): Observable<any> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/labels/${labelId}`;
      if (forceDelete) url = `${url}?forceUpdate=${forceDelete}`;

      return this.apiService.deleteRequest(url).pipe(map((response) => response.results));
    }
  }

  createLabel(accountId: number, label: EmptyLabel): Observable<any> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/labels`;
      return this.apiService.postRequest(url, label).pipe(map((response) => response.results));
    }
  }

  cloneLabel(accountId: number, labelId: number, label: LabelAddDTO): Observable<LabelDetailsModel> {
    if (accountId && labelId) {
      let url = this.baseServiceUrl + `${accountId}/labels/${labelId}`;
      return this.apiService.postRequest(url, label).pipe(map((response) => response.results));
    }
  }

  cloneLabelTemplate(accountId: number, labelId: number, label: LabelAddDTO): Observable<LabelDetailsModel> {
    if (accountId && labelId) {
      let url = this.baseServiceUrl + `${accountId}/labelTemplates/${labelId}`;
      return this.apiService.postRequest(url, label).pipe(map((response) => response.results));
    }
  }

  ImportTemplate(accountId: number, form: any, file: File): Observable<any> {
    if (accountId) {
      let imageFile: FormData = new FormData();
      imageFile.append('imageFile', file);
      imageFile.append('name', form.name);
      imageFile.append('description', form.description);
      imageFile.append('Code', form.code);
      let url = this.baseServiceUrl + `${accountId}/labels/labelTemplateImage`;
      return this.apiService.postRequest(url, imageFile).pipe(map((response) => response.results));
    }
  }

  updateLabelDetails(accountId: number, form: any, labelId: number, hasRemotePrinting: boolean = false, remotePrintingOutdated: boolean = false, type: string = 'label'): Observable<any> {
    if (accountId) {
      const labelObject = { name: form.name, description: form.description, isAvailableForRemotePrinting: false, remotePrintingOutdated: false };
      const templateObject = { name: form.name, description: form.description };
      if (hasRemotePrinting) {
        labelObject.isAvailableForRemotePrinting = form.isAvailableForRemotePrinting;
        labelObject.remotePrintingOutdated = remotePrintingOutdated;
      }
      const object = type === 'template' ? templateObject : labelObject;
      const url = this.baseServiceUrl + `${accountId}/labels/labelTemplateImage/${labelId}`;
      return this.apiService.putRequest(url, object).pipe(map((response) => response.results));
    }
  }

  updateLabelContent(accountId: number, labelJsonArrayString: string, labelId: number): Observable<any> {
    console.log('labelJsonArrayString');
    if (accountId) {
      const url = this.baseServiceUrl + `${accountId}/labels/${labelId}/content`;
      return this.apiService.putRequest(url, { contentJson: labelJsonArrayString }).pipe(map((response) => response.results));
    }
  }

  setSelectedLabel(label: LabelDetailsModel) {
    this.selectedLabel = label;
  }

  getSelectedLabel(): LabelDetailsModel {
    return this.selectedLabel;
  }

  getLabelJsonContent(accountId: number, labelId: number) {
    const url = `${this.baseServiceUrl}${accountId}/labels/${labelId}/imageContent`;
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  updateLabelTemplate(labelTemplate: UpdateLabelTemplate[]) {
    let url = `${this.baseServiceUrl}${labelTemplate[0].accountId}/labels/labelTemplate/${labelTemplate[0].labelId}`;

    if (labelTemplate[0].isTemplate) {
      url = `${this.baseServiceUrl}${labelTemplate[0].accountId}/labelTemplates/edit/${labelTemplate[0].labelId}`;
      return this.apiService.putRequest(url, labelTemplate).pipe(map((response) => response.results));
    }

    return this.apiService.putRequest(url, labelTemplate).pipe(map((response) => response.results));
  }

  createLabelTemplate(labelTemplate: UpdateLabelTemplate[], type: string) {
    let url = `${this.baseServiceUrl}${labelTemplate[0].accountId}/labels/labelTemplate/`;
    if (type === 'template') {
      url = `${this.baseServiceUrl}${labelTemplate[0].accountId}/labelTemplates/`;
    }
    const body = type === 'template' ? labelTemplate[1] : labelTemplate;
    return this.apiService.postRequest(url, body).pipe(map((response) => response.results));
  }

  getAccountInfo(accountId: number): Observable<AccountInfo> {
    const url = this.baseServiceUrl + `${accountId}`;
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  getCustomFieldsByTenantId(accountId: number, start: number, limit: number): Observable<PaginationResponse<CustomField[]>> {
    const url = this.baseServiceUrl + `${accountId}/customfields?start=${start}&limit=${limit}&supportSerialNumber=true`;
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  getLabelSizes(accountId: number, labelSizeId?: number, hideDOWLabelSizes: boolean = false): Observable<PaginationResponse<LabelSize[]>> {
    let url = `${this.baseServiceUrl}${accountId}/labelsize?start=0&limit=${GetLimits.CARDS}`;

    if (labelSizeId) {
      url += `&labelSizeId=${labelSizeId}`;
    }

    if (hideDOWLabelSizes) {
      url = appendQueryParamIfDefined(url, 'hideDOWLabelSizes', hideDOWLabelSizes);
    }

    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  getLabelSizeById(accountId: number, labelSizeId: number): Observable<LabelSize> {
    const url = `${this.baseServiceUrl}${accountId}/labelsize/${labelSizeId}`;
    return this.apiService.getRequest(url).pipe(
      map((response) => response.results),
      tap((labelSize: LabelSize) => {
        this.labelSize = labelSize;
      })
    );
  }

  getLabelSizeEnum(accountId: number, labelSizeId: number): Observable<{ dowId: number }> {
    const url = `${this.baseServiceUrl}${accountId}/labelConfiguration/dow?labelSizeId=${labelSizeId}`;
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  hideLocationDropdown(value: boolean): void {
    this.hideLocationSubject.next(value);
  }
  setCurrentPage(value: string): void {
    this.currentPage.next(value);
  }

  // create a widget model
  // tslint:disable-next-line: max-line-length
  getAllWidgets(accountId: number, start: number, limit: number, onlyResellerWidgets?: boolean, searchKeyword?: string, sortBy?: string, sortDirection?: string): Observable<PaginationResponse<Widget[]>> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/labels/widgets`;
      if (onlyResellerWidgets) {
        url = url + `?onlyResellerWidgets=true`;
      }
      url = addPaginationParametersToUrl(url, start, limit, searchKeyword, sortBy, sortDirection);
      return this.apiService.getRequest(url).pipe(map((response) => response.results));
    }
  }

  updateWidgetDetails(accountId: number, widgetId: number, widgetDetails: WidgetEditFromPanelDTO) {
    const url = this.baseServiceUrl + `${accountId}/labels/widgets/${widgetId}`;
    return this.apiService.putRequest(url, widgetDetails).pipe(map((response) => response.results));
  }

  deleteWidget(accountId: number, widgetId: number, forceDelete: boolean = false) {
    let url = this.baseServiceUrl + `${accountId}/labels/widgets/${widgetId}`;
    url = appendQueryParamIfDefined(url, 'forceDelete', forceDelete);

    return this.apiService.deleteRequest(url).pipe(map((response) => response.results));
  }

  cloneWidget(accountId: number, widgetId: number, widget: WidgetAddDTO): Observable<Widget> {
    if (accountId && widgetId) {
      const url = this.baseServiceUrl + `${accountId}/labels/widgets/${widgetId}`;
      return this.apiService.postRequest(url, widget).pipe(map((response) => response.results));
    }
  }

  addWidget(accountId: number, widgetversion: WidgetAddDTO[]): Observable<Widget> {
    if (accountId) {
      const url = this.baseServiceUrl + `${accountId}/labels/widgets/template`;
      return this.apiService.postRequest(url, { versions: widgetversion, deletedVersions: [] }).pipe(map((response) => response.results));
    }
  }
  updateWidget(accountId: number, widgetversion: WidgetAddDTO[], widgetId: number, deletedVersions: number[]): Observable<Widget> {
    if (accountId) {
      const url = this.baseServiceUrl + `${accountId}/labels/widgets/template/${widgetId}`;
      return this.apiService.putRequest(url, { versions: widgetversion, deletedVersions: deletedVersions }).pipe(map((response) => response.results));
    }
  }

  getWidgetById(accountId: number, widgetId: number): Observable<Widget[]> {
    if (accountId) {
      const url = this.baseServiceUrl + `${accountId}/labels/widgets/${widgetId}`;
      return this.apiService.getRequest(url).pipe(map((response) => response.results));
    }
  }

  getFactsProfiles(accountId: number, start?: number, limit?: number, searchKeyword?: string): Observable<PaginationResponse<FactProfile[]>> {
    let url = `${environment.baseAPIUrl}${environment.version}/nutritionfacts/${accountId}/profile`;
    url = addPaginationParametersToUrl(url, start, limit, searchKeyword);
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  getDefaultFactsProfile(accountId: number): Observable<FactProfile[]> {
    const url = `${environment.baseAPIUrl}${environment.version}/accounts/${accountId}/profile`;
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  getFactsProfileById(factProfileId: number): Observable<NutritionFactFlatItem[]> {
    const url = `${environment.baseAPIUrl}${environment.version}/nutritionfacts/profile/${factProfileId}/facts`;
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  getWidgetsByType(accountId: number, type: number) {
    let url = this.baseServiceUrl + `${accountId}/labels/widgets`;
    // case widgets requested are nutrition panel
    if (type === 2) {
      url += '/nutritionPanels';
    } else {
      url += '?type=1';
    }
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }
  publishLabel(accountId: number, labelId: number, locationId: number, publishAt: Date) {
    const url = `${this.baseServiceUrl}${accountId}/labels/${labelId}/publish?locationId=${locationId}`;
    const data = {
      publishAt: publishAt
    };
    return this.apiService.putRequest(url, data).pipe(map((response) => response));
  }

  checkUniqueContraints(accountId: number, name: string, labelId?: number, widgetIds?: number[], labelSizeId?: number, hideWarningPopup: boolean = false, showLoader: boolean = true) {
    const url = `${this.baseServiceUrl}${accountId}/labels/checkUniqueConstraints`;
    const data = {
      name,
      labelId,
      widgetIds,
      labelSizeId,
      hideWarningPopup
    };

    return this.apiService.postRequest(url, data, false, showLoader).pipe(map((response) => response));
  }

  validateWidgetName(accountId: number, widgetName: string, widgetId?: number, oldWidgetName?: string, hideWarningPopup: boolean = false, showLoader: boolean = true) {
    const url = `${this.baseServiceUrl}${accountId}/labels/widgets/name/validate`;
    const data = { widgetName, widgetId, hideWarningPopup, oldWidgetName };
    return this.apiService.putRequest(url, data, false, showLoader).pipe(map((res) => res));
  }

  getAllLabelTemplates(accountId: number, start: number, limit: number, locationId?: number, searchKeyword?: string, sortBy?: string, sortDirection?: string): Observable<PaginationResponse<LabelDetailsModel[]>> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/labelTemplates`;
      if (locationId) {
        url = url + `?locationId=${locationId}`;
      }
      url = addPaginationParametersToUrl(url, start, limit, searchKeyword, sortBy, sortDirection);
      return this.apiService.getRequest(url).pipe(map((response) => response.results));
    }
  }

  getAllLabelTemplatesForLabelDesigner(
    accountId: number,
    start: number,
    limit: number,
    locationId?: number,
    searchKeyword?: string,
    sortBy?: string,
    sortDirection?: string
  ): Observable<PaginationResponse<LabelDetailsModel[]>> {
    if (accountId) {
      let url = this.baseServiceUrl + `${accountId}/labelTemplates/list`;
      if (locationId) {
        url = url + `?locationId=${locationId}`;
      }
      url = addPaginationParametersToUrl(url, start, limit, searchKeyword, sortBy, sortDirection);
      return this.apiService.getRequest(url).pipe(map((response) => response.results));
    }
  }

  deleteTemplate(accountId: number, templateId: number) {
    const url = this.baseServiceUrl + `${accountId}/labelTemplates/${templateId}`;
    return this.apiService.deleteRequest(url).pipe(map((response) => response.results));
  }

  getLabelConflicts(id: number, locationId: number): Observable<LabelUsage> {
    const url = `${this.apiService.v1Accounts}labels/${id}/conflictedItems/${locationId}`;
    return this.apiService.getRequest<LabelUsage>(url).pipe(map((res) => res.results));
  }

  labelHasDiscardDateCheckedItem(editor: Editor): ThermalLabelItem | boolean {
    if (editor) {
      const HasCompositeDiscardDateChecked = editor._tl.items.find((item) => item.comments.compositeFields?.find((subItem) => subItem.type === ComponentTypes.DISCARD_DATE && subItem.hasReferenceShelfLife));
      const HasDiscardDateChecked = editor._tl.items.find((item) => item.comments.type === ComponentTypes.DISCARD_DATE && item.comments.hasReferenceShelfLife);
      return HasDiscardDateChecked || HasCompositeDiscardDateChecked;
    }
    return false;
  }

  getAtmaFields(accountId: number): Observable<AtmaFields[]> {
    const url = this.baseServiceUrl + `${accountId}/labels/atmaFields`;
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  getAtmaFieldsFormLocalConfig(accountId: number, localConfig: {}, returnDefaults: boolean = true): Observable<AtmaFields[]> {
    const url = this.baseServiceUrl + `${accountId}/labels/atmaFields/filter?returnDefaults=${returnDefaults}`;

    return this.apiService.postRequest(url, localConfig).pipe(map((response) => response.results));
  }

  updateAtmaFields(accountId: number, fields: AtmaFields[]) {
    const url = `${this.baseServiceUrl}${accountId}/labels/atmaFields`;
    const data = {
      fields: fields
    };
    return this.apiService.putRequest(url, data).pipe(map((response) => response));
  }

  saveItemStatus(item: any, isGroup = false) {
    const itemId = item.uuid ? item.uuid : item.widgetId ? item.widgetId : null;
    if (itemId) {
      if (this.labelItemUndoList[itemId]) {
        this.labelItemUndoList[itemId]?.push(_.cloneDeep(item));
      } else {
        this.labelItemUndoList[itemId] = [_.cloneDeep(item)];
      }
    }

    if (isGroup) {
      if (this.labelItemUndoList['group']) {
        this.labelItemUndoList['group'].push(_.cloneDeep(item));
      } else {
        this.labelItemUndoList['group'] = [_.cloneDeep(item)];
      }
    }
  }

  deleteItemStatusHistory(itemId: string) {
    delete this.labelItemUndoList[itemId];
  }

  removeItemStatusState(itemId: string) {
    _.remove(this.labelItemUndoList[itemId], _.last(this.labelItemUndoList[itemId]));
  }

  getItemPreviousState(itemId: string) {
    return _.nth(this.labelItemUndoList[itemId], -2);
  }

  canvasContainsEPCs(editor: Editor): boolean {
    const compositeFields = editor._tl.items.map((object) => object.comments.compositeFields).flat();
    const containsEPC = compositeFields.some((field) => field?.type === ComponentTypes.RFID_EPC);

    return containsEPC;
  }

  removeEPCsFromCanvas(editor: Editor, customFields: DesignerSectionItem[]): void {
    editor._tl.items.forEach((object) => {
      const compositeFields = object.comments.compositeFields;

      if (arrayIsDefinedAndNotEmpty(compositeFields)) {
        object.comments.compositeFields = compositeFields.filter((field) => field.type !== ComponentTypes.RFID_EPC);
        if (object.comments.type === ComponentTypes.COMPOSITE_FIELD) {
          object.text = getCompositeFieldsText(object.comments.compositeFields, this.dfs, object.comments.type, customFields, this.translateService);
          object.refresh();
        }
      }
    });
  }

  isRfidConfigValid(accountId) {
    const url = `${environment.baseAPIUrl}${environment.version}/accounts/${accountId}/labels/validateRFIDConfig`;
    return this.apiService.getRequest<{ valid: boolean }>(url).pipe(map((response) => response.results.valid));
  }

  addDecimalCFsToCalculatedFieldFirstTermDropdown(customFields: DesignerSectionItem[], dropdownOptions: any[], foodAccountType: boolean, isRetailAccount: boolean): void {
    const decimalCFs = customFields.filter((cf) => cf.typeId === CustomFieldTypeEnum.Double);
    const sortedDecimalCFs = _.sortBy(decimalCFs, ['name']);
    const options = sortedDecimalCFs.map((cf) => ({ label: cf.name, map_value: cf.map_value } as any));
    const priceIndex = dropdownOptions.findIndex((option) => option.map_value === ComponentTypes.ITEM_PRICE);
    const originalPriceIndex = dropdownOptions.findIndex((option) => option.map_value === ComponentTypes.PRODUCT_ORIGINAL_PRICE);
    let index: number;
    if (foodAccountType) {
      index = priceIndex + 1;
    } else if (isRetailAccount) {
      index = originalPriceIndex + 1;
    }
    dropdownOptions.splice(index, 0, ...options);
  }
  addDecimalCFsToCalculatedFieldSecondTermDropdown(customFields: DesignerSectionItem[], dropdownOptions: any[], foodAccountType: boolean, isRetailAccount: boolean): void {
    const decimalCFs = customFields.filter((cf) => cf.typeId === CustomFieldTypeEnum.Double);
    const sortedDecimalCFs = _.sortBy(decimalCFs, ['name']);
    const options = sortedDecimalCFs.map((cf) => ({ label: cf.name, map_value: cf.map_value } as any));
    const priceIndex = dropdownOptions.findIndex((option) => option.map_value === ComponentTypes.ITEM_PRICE);
    let index: number;
    if (foodAccountType) {
      index = priceIndex + 1;
    } else if (isRetailAccount) {
      index = dropdownOptions.length;
    }
    dropdownOptions.splice(index ?? dropdownOptions.length, 0, ...options);
  }
  getDefaultDecimalPlace(type: ComponentTypes) {
    return this.decimalPlaceDefaultValues.get(type) ?? 0;
  }
}
