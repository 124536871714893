import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakLoginOptions } from 'keycloak-js';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { defined } from '../../../shared/helpers/app.helpers';
import { CookiesFlags, CookiesValues } from '../../../shared/models/enums/cookies.enum';
import { LocalStorageFlags } from '../../../shared/models/enums/local-storage-flags.enum';
import { Tenant } from '../../../shared/models/tenant.model';
import { LoaderService } from '../../../shared/services/loader.service';
import { LocalizationService } from '../../../shared/services/localization/localization.service';
import { NavigationService } from '../../../shared/services/navigation.service';
import { ThemeService } from '../../../shared/services/theme.service';
import { UtilService } from '../../../shared/services/util.service';
import { AuthService } from '../../auth/auth.service';
import { CookieService } from '../../auth/cookie.service';
import { LoginService } from '../../services/login.service';
import { ApiResponseCodeEnum } from '../../../shared/models/enums/ApiResponseCode.enum';
import { RUNTIME_CONFIGURATION } from '../../../shared/tokens';
import { RuntimeConfiguration } from '../../../shared/models/runtime-configuration.model';
import { ResellerCheckService } from '../../../shared/services/reseller-check.service';

@Component({
  selector: 'nome-email-validation',
  templateUrl: './email-validation.component.html',
  styleUrls: ['./email-validation.component.scss']
})
export class EmailValidationComponent implements OnInit {
  formGroup: UntypedFormGroup;
  brandImagesAssets: string;
  resellerLoginId?: number = null;
  showBlockedUserInfoMessage = false;
  disableNextButton = false;
  notZYPortal = this.resellerCheckService.isZYReseller() === false;
  constructor(
    private resellerCheckService: ResellerCheckService,
    private route: ActivatedRoute,
    private authService: AuthService,
    protected keycloakService: KeycloakService,
    private loginService: LoginService,
    private navigationService: NavigationService,
    public utilService: UtilService,
    private themeService: ThemeService,
    public localizationService: LocalizationService,
    private loaderService: LoaderService,
    private cookieService: CookieService,
    @Inject(RUNTIME_CONFIGURATION) public runtimeConfiguration: RuntimeConfiguration
  ) {
    let loginId = this.authService.getResellerLoginId();
    this.resellerLoginId = loginId != null ? +this.authService.getResellerLoginId() : null;
    this.formGroup = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required, Validators.email])
    });
    this.authService.setZYSupportChatFeature();
  }

  ngOnInit(): void {
    // Check if the reseller is not configured to be authenticated through Keycloak, redirect the user to the account selection page
    const authenticateUsingKeycloakOIDC = this.authService.getAuthenticateUsingKeycloakOIDC();
    if (!authenticateUsingKeycloakOIDC) return this.authService.redirectToAccountSelection();

    this.getBrandInformation();
    this.loginService.clearData();
    this.authService.clearStorageAndFirstLoad();
    this.setBackgroundColor();

    if (this.authService.shouldShowCookieConsentDialog()) {
      this.authService.showCookieConsentDialog().subscribe((next) => {
        if (next) {
          this.cookieService.setCookie({ name: CookiesFlags.cookieConsent, value: CookiesValues.allow, expireDays: 400 });
          this.seemlessLogin();
        }
      });
    } else {
      this.seemlessLogin();
    }
    this.formGroup.get('email').valueChanges.subscribe((next) => {
      this.disableNextButton = false;
    });
  }

  seemlessLogin() {
    const emailParam = this.route.snapshot.queryParamMap.get('email');
    if (defined(emailParam)) {
      this.formGroup.patchValue({
        email: emailParam
      });

      if (this.formGroup.invalid) {
        this.formGroup.patchValue({
          email: undefined
        });
        return this.authService.redirectToEmailValidation();
      }

      this.navigateToLogIn();
    } else {
      this.navigateToLogIn();
    }
  }

  async navigateToLogIn() {
    this.showBlockedUserInfoMessage = false;
    this.disableNextButton = false;
    if (this.formGroup.invalid) return;
    const invalidTime = await this.authService.checkClientAndServerTimeDiff();
    if (invalidTime) {
      return;
    }
    this.loaderService.showLoader();
    const email = this.formGroup.getRawValue().email;

    forkJoin([
      //First we should check if the Account Name or Account Code already exists in our DB
      this.loginService.validateEmail(email, this.resellerLoginId).pipe(
        map((result) => {
          const accountName = result.accountName;
          const maskedAccountName = accountName.replace(new RegExp(',', 'g'), '0x2C');
          let idpHint = result.loginMethods && result.loginMethods.length > 0 ? result.loginMethods[0].alias : 'none';
          const resellerSubRoute = localStorage.getItem(LocalStorageFlags.resellerSubRoute);
          // TODO: Handle ${state.url}
          const redirectUri = resellerSubRoute ? `${window.location.origin}/${resellerSubRoute}/dashboard` : window.location.origin + '/dashboard';

          // redirect to keyloack login
          let options: KeycloakLoginOptions = {
            redirectUri: redirectUri,
            loginHint: idpHint == 'none' ? `${email},${maskedAccountName},${this.resellerLoginId ? this.resellerLoginId : -1}` : `${email}`,
            // Check for external authentication login method and pass it as idp hint
            idpHint: idpHint,
            locale: this.localizationService.getCurrentLanguage()
          };
          this.keycloakService.login(options);
        }),
        catchError((e) => {
          this.loaderService.hideLoader();
          if (e.results.code === ApiResponseCodeEnum.USER_BLOCKED_CAN_NOT_LOGIN) {
            this.showBlockedUserInfoMessage = true;
            this.disableNextButton = true;
          }
          return of(e);
        })
      )
    ]).subscribe();
  }

  getBrandInformation() {
    this.brandImagesAssets = this.themeService.getbrandImagesAssets();
  }
  setBackgroundColor() {
    this.themeService.setBackgroundColor();
  }
  unsetBackgroundColor() {
    this.themeService.unsetBackgroundColor();
  }
  ngOnDestroy() {
    this.unsetBackgroundColor();
    this.navigationService.setBlnDisplayBreadCrumb(true);
  }
  displayFn(tenant: Tenant) {
    if (tenant) {
      return tenant.name;
    }
  }
}
