import { resolve } from 'dns';
import { KeycloakEventType, KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { environment } from '../../../environments/environment';
import { LocalStorageFlags } from '../../shared/models/enums/local-storage-flags.enum';
import { LoginService } from '../services/login.service';

export function initializer(keycloak: KeycloakService): () => Promise<boolean> {
  const authenticateUsingKeycloakOIDC = localStorage.getItem(LocalStorageFlags.authenticateUsingKeycloakOIDC);
  if (authenticateUsingKeycloakOIDC && authenticateUsingKeycloakOIDC === 'true') {

    const url = localStorage.getItem(LocalStorageFlags.identityServerURL);
    const realm = localStorage.getItem(LocalStorageFlags.resellerAlias);
    const options: KeycloakOptions = {
      config: {
        url: url,
        realm: realm,
        clientId: environment.OIDCClient
      },
      loadUserProfileAtStartUp: true,
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
        checkLoginIframe: false
      },
      bearerExcludedUrls: []
    };

    return () => keycloak.init(options);
  } else {
    return () => Promise.resolve(true);
  }
}
