export class PathfinderSettings {
  constructor(
    public contrast: number,
    public speed: number,
    public supplyPosition: number,
    public gs1: boolean,
    public code128: boolean,
    public code128Length1: number,
    public code128Length2: number,
    public code39: boolean,
    public code39Length1: number,
    public code39Length2: number,
    public l2of5: boolean,
    public l2of5Length1: number,
    public l2of5Length2: number,
    public upc_ean: boolean,
    public qrCode: boolean
  ) {}
}
