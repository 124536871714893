import { Injectable } from '@angular/core';
import { DateFormatService } from '../../../../shared/services/date-format.service';
import { LocalizationService } from '../../../../shared/services/localization/localization.service';
import {
  SensorReadingModel,
  SensorAlertCountModel,
  SensorAlertReadingModel,
  SensorReadingsAndAlerts,
  SensorAlertMap,
  SensorAlertCountByLocation,
  SenseMapAlert,
  SensorAlertMapResponse,
  MetaModel
} from '../../models/sense/sense-data.model';
import { ChartModel, ChartSerie } from '../../models/Report.model';
import * as Enumerable from 'linq-es2015';
import { deepClone, defined, isNullOrEmpty, toDecimalPrecision, transformToTopMin, stringIsDefinedAndNotEmpty, definedAndNotEmptyString } from '../../../../shared/helpers/app.helpers';
import { IGrouping } from 'linq-es2015/lib/enumerable';
import { SenseStatus } from '../../models/SenseStatus.enum';
import { languageDefaultDateFormat } from '../../../../shared/constants.config';
import { TranslateService } from '@ngx-translate/core';
import { getSenseStatusColor, getSenseAlertColor, getSenseAlertOrder, getSenseStatusOrder, getSenseStatusOrderCards, getSignalStatusColor, getSenseStatusOrderPerEquipmen, createGap } from '../reports-helper';
import { senseAlertOrder, SenseAlert } from '../../models/SenseAlert.enum';
import { DashboardTypeGrouping } from '../../models/DashboardTypeGrouping.enum';
import { MatTableDataSource } from '@angular/material/table';
import { Sensor } from '../../models/sense/equipments.model';
import { MapLocation } from '../../components/nome-google-map-chart/models/gmap.models';
import { NodeTypeEnum } from '../../../../shared/models/enums/node-type.enum';
import { BrandConfiguration } from '../../models/TemperatureV2.model';
import { ReportSubject } from '../../models/ReportSubject.enum';
import { TitleCasePipe } from '../../../../pipes/title-case/title-case.pipe';

@Injectable({
  providedIn: 'root'
})
export class SenseReportsMappingHelpers {
  constructor(private dfs: DateFormatService, private localizationService: LocalizationService, private translateService: TranslateService, public titlecasePipe: TitleCasePipe) {}

  // SENSE-0: Sensors Status
  //Chart Model
  MapTo_SensorsStatus_Chart(data: SensorReadingModel[]): { chart: ChartSerie[]; series: string[]; total: number } {
    let chartData: ChartSerie[] = [];
    let series: string[] = [];
    let displayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data)).ToArray();

    let grouppedArray = Enumerable.asEnumerable(displayData)
      .GroupBy((g: SensorReadingModel) => g.status)
      .Select((group: IGrouping<SenseStatus, SensorReadingModel>) => {
        return {
          key: group.key,
          value: Enumerable.asEnumerable(group)
            .Distinct((g) => g.sensorId)
            .Count()
        };
      })
      .ToArray();
    if (grouppedArray) {
      grouppedArray = this._fillMissingStatuses(grouppedArray);

      grouppedArray.forEach((group) => {
        chartData.push(new ChartSerie(group.key, group.value));
        series = series.concat(group.key);
      });
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return {
      chart: chartData,
      series: series,
      total: Enumerable.asEnumerable(data)
        .Distinct((c) => c.sensorId)
        .Count()
    };
  }

  // SENSE-2: Sensors Out Of Range
  //Chart Model
  MapTo_SensorsOutOfRange_Chart(data: SensorReadingModel[], groupingType: DashboardTypeGrouping = DashboardTypeGrouping.SENSORS): { chart: ChartModel[]; series: string[] } {
    const key = groupingType == DashboardTypeGrouping.SENSORS ? 'sensorId' : 'equipmentId';
    let chartData: ChartModel[] = [];
    let displayData: SensorReadingModel[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data)).ToArray();
    chartData = Enumerable.asEnumerable(displayData)
      .OrderBy((d) => d.equipmentName)
      .GroupBy((g: SensorReadingModel) => g.equipmentName)
      // .Where((g: IGrouping<string, SensorReadingModel>) => Enumerable.asEnumerable(g).Any(c => c.status == SenseStatus.OutOfRange))
      .Select((group: IGrouping<string, SensorReadingModel>) => {
        let groupData = Enumerable.asEnumerable(group);
        const equipmentId = groupData.FirstOrDefault() ? groupData.FirstOrDefault().equipmentId : null;
        const noPrefixLocationNumber = groupData.FirstOrDefault() ? groupData.FirstOrDefault().noPrefixLocationNumber : null;
        const totalNumber = groupData.Distinct((g) => g[key]).Count();

        const partitionExtra = {
          noPrefixLocationNumber,
          equipmentId,
          equipmentName: group.key,
          sensors:
            groupingType == DashboardTypeGrouping.SENSORS
              ? groupData
                  .Distinct((g) => g[key])
                  .OrderBy((g) => getSenseStatusOrder(<SenseStatus>g.status))
                  .ToArray()
              : []
        };

        let groupSeries = [];
        for (let item in SenseStatus) {
          const amount = groupData
            .OrderBy((g) => getSenseStatusOrderPerEquipmen(<SenseStatus>g.status))
            .Distinct((g) => g[key])
            .Where((c) => c.status == item)
            .Count();
          if (amount) {
            if (item === SenseStatus.Inactive && groupingType !== DashboardTypeGrouping.SENSORS) {
              groupSeries.push(new ChartSerie(SenseStatus.NoReadings, amount, partitionExtra));
            } else {
              groupSeries.push(new ChartSerie(item, amount, partitionExtra));
            }
          }
        }

        const extra = {
          total: totalNumber,
          itemTitle: groupingType == DashboardTypeGrouping.SENSORS ? 'analytics.sense_dashboard.sensors' : 'analytics.sense_dashboard.equipments'
        };

        return new ChartModel(group.key, groupSeries, extra);
      })
      .ToArray();
    const sortedChartData = Enumerable.asEnumerable(chartData)
      .OrderByDescending((d) => d.extra.total)
      .ToArray();
    return { chart: sortedChartData, series: [SenseStatus.OutOfRange, SenseStatus.InRange, SenseStatus.Inactive, SenseStatus.NoReadings] };
  }

  // SENSE-1: Equipment Out Of Range - Corporate View
  // Table model
  MapTo_CorporateEquipmentOutOfRange_Table(
    data: SensorReadingModel[],
    reportSubject?: ReportSubject
  ): { day: string; equipment: string; humidity: number; battery: number; temperature: number; status: string; color: string; expandedDetail: MatTableDataSource<any>[] }[] {
    return this._mapToEquipmentStatusView_Table(data, null, reportSubject);
  }

  //Sense-1: Sensors Latest Readings
  //Tabular model
  MapTo_SensorsLatestReadings_Table(
    data: SensorReadingModel[],
    filterByStatus?: SenseStatus,
    reportSubject?: ReportSubject
  ): { day: string; sensorId: string; equipment: string; humidity: string; battery: string; temperature: string; status: string; color: string }[] {
    const showUnitName = reportSubject === ReportSubject.SENSORS_LATEST_READINGS;
    let displayData = Enumerable.asEnumerable(deepClone(data))
      .Where((d) => d.status == filterByStatus || !filterByStatus)
      .OrderBy((g) => getSenseStatusOrder(<SenseStatus>g.status))
      .ToArray()
      .map((row) => {
        return {
          day: `${this.dfs.formatDate(new Date(row.date), languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).longDateTime, this.localizationService.getCurrentLanguage())}`,
          sensorId: row.sensorId,
          equipment: row.equipmentName + (stringIsDefinedAndNotEmpty(row.unitName) && showUnitName ? ` - ${row.unitName}` : ``),
          equipmentUnit: row.unitName,
          location: row.noPrefixLocationNumber,
          humidity: `${toDecimalPrecision(row.humidity, true)}`,
          battery: `${toDecimalPrecision(row.battery, true)}`,
          temperature: `${toDecimalPrecision(row.temperature)}`,
          status: this.translateService.instant(`analytics.sense_dashboard.status.${row.status}`),
          color: getSenseStatusColor(row.status),
          rssi: toDecimalPrecision(row.rssi, true),
          signalColor: row.status === SenseStatus.Inactive ? getSenseStatusColor(row.status) : getSignalStatusColor(row.rssi).color,
          signalText: getSignalStatusColor(row.rssi).text,
          expectedRange: getSensorExpectedRange(row)
        };
      });
    return displayData;
  }

  public topMinDate(response: SensorReadingsAndAlerts) {
    if (response.data.filter((x) => defined(x.topMinDate)).length === 0) {
      response.data.forEach((x) => (x.topMinDate = this.dfs.formatDate(transformToTopMin(x.date), 'yyyy-MM-dd HH:mm')));
    }
    if (response.alerts.filter((x) => defined(x.topMinDate)).length === 0) {
      response.alerts.forEach((x) => (x.topMinDate = this.dfs.formatDate(transformToTopMin(x.time), 'yyyy-MM-dd HH:mm')));
    }
  }

  private MapTo_GroupEquipmentChartAverageBy_NEW(key: string, response: SensorReadingsAndAlerts, forceInteger = false): { chart: ChartModel[]; series: string[] } {
    const { data, alerts } = response;
    this.topMinDate(response);
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let grouppedEquipments = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.date)
      .GroupBy((g: SensorReadingModel) => g.equipmentId)
      .ToArray();
    if (grouppedEquipments) {
      //grouping by date
      const grouppedByDate = grouppedEquipments.map((_grouppedArray) => {
        return Enumerable.asEnumerable(_grouppedArray)
          .GroupBy((g: SensorReadingModel) => g.topMinDate)
          .ToArray();
      });

      if (grouppedByDate) {
        grouppedByDate.forEach((grouppedEquipment) => {
          if (grouppedEquipment) {
            const group = [];
            grouppedEquipment.forEach((grouppedDate: any) => {
              const _tempArray = [];
              grouppedDate.forEach((x) => {
                _tempArray.push(x[key]);
              });
              // const elements: any[] = grouppedDate.map((element: any) => element['temperature']);
              const avg = toDecimalPrecision(
                Enumerable.asEnumerable(_tempArray).Average((element) => element),
                forceInteger
              );
              if (grouppedDate.length > 0) {
                const { equipmentName, equipmentId, sensorId, unitName } = grouppedDate[0];
                group.push({ date: `${grouppedDate.key}`, avg, equipmentName, equipmentId, sensorId, unitName });
              }
            });
            if (group.length > 0) {
              const chartName = this.getChartName(group[0].equipmentName, group[0].unitName);
              let chartGroup = new ChartModel(chartName, [], { equipmentId: group[0].equipmentId, equipmentName: chartName });
              const _data = group.map((s) => new ChartSerie(new Date(s.date), toDecimalPrecision(s.avg, forceInteger), chartGroup.extra));
              const _alerts = alerts
                .filter((alert) => alert.equipmentId === group[0].equipmentId)
                .map((alert) => new ChartSerie(new Date(alert.time), toDecimalPrecision(alert.displayValue, forceInteger), { alert: true }, new Date(alert.time), alert.displayValue, 3));
              chartGroup.series = [..._data, ..._alerts];
              series = series.concat(chartName);
              chartData.push(chartGroup);
            }
          }
        });
      }
    }

    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  private getChartName(equipmentTypeName: string, unitName): string {
    let chartName = equipmentTypeName;
    if (definedAndNotEmptyString(unitName)) chartName += ` - ${unitName}`;
    return chartName;
  }

  //Sense-2 : Sensors Temperatures
  MapTo_SensorsTemperatures_Chart(response: SensorReadingsAndAlerts, groupingType: DashboardTypeGrouping, interval: string): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    switch (groupingType) {
      case DashboardTypeGrouping.SENSORS:
        const customChart = this.MapToCustomChart_Temperatures(response);
        series = customChart.series;
        chartData = customChart.chart;
        break;
      case DashboardTypeGrouping.EQUIPMENTS:
        const chartAverage = this.MapTo_GroupEquipmentChartAverageBy_NEW('temperature', response);
        series = chartAverage.series;
        chartData = chartAverage.chart;
        break;
      default:
        break;
    }

    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return {
      chart: createGap(chartData, this.getSenseChartGapPeriod(interval, groupingType), <moment.unitOfTime.Diff>'minute'),
      series: series
    };
  }

  private getSenseChartGapPeriod(interval: string, groupingType: DashboardTypeGrouping): number {
    let interpolationPeriod = +interval.split('min')[0];
    if (groupingType === DashboardTypeGrouping.SENSORS) return interpolationPeriod;
    // this equation is based on picking 2 points in every gap interval => get the topMin of each one then get the difference between them
    else {
      interpolationPeriod += 9;
      return interpolationPeriod - ((2 * interpolationPeriod) % 10) + (interpolationPeriod % 10);
    }
  }

  MapToCustomChart_Temperatures(response: SensorReadingsAndAlerts): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let { data, alerts } = response;
    let displayData: any[] = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.date)
      .ToArray();
    let grouppedEquipments = Enumerable.asEnumerable(displayData)
      .GroupBy((g: SensorReadingModel) => g.sensorId)
      .ToArray();
    if (grouppedEquipments) {
      grouppedEquipments.forEach((group) => {
        let chartGroup = new ChartModel(group.key);
        let _data = group.map((s) => new ChartSerie(new Date(s.date), toDecimalPrecision(s.temperature)));
        let _alerts = alerts.filter((alert) => alert.sensorId === group.key).map((alert) => new ChartSerie(new Date(alert.time), alert.displayValue, { alert: true }, new Date(alert.time), alert.displayValue, 2));
        chartGroup.series = [..._data, ..._alerts];
        series = series.concat(group.key);
        chartData.push(chartGroup);
      });
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  MapTo_SensorsTemperatures_Table(response: SensorReadingsAndAlerts, groupingType: DashboardTypeGrouping): { day: string; sensorId: string; temperature: number }[] {
    const { data } = response;
    let displayData = [];
    let displayDataArray = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.date)
      .ToArray();
    switch (groupingType) {
      case DashboardTypeGrouping.SENSORS:
        displayData = displayDataArray.map((item: SensorReadingModel) => {
          return {
            day: `${this.dfs.formatDate(item.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).longDateTime, this.localizationService.getCurrentLanguage())}`,
            sensorId: item.sensorId,
            temperature: toDecimalPrecision(item.temperature)
          };
        });
        break;
      case DashboardTypeGrouping.EQUIPMENTS:
        const chartAverage = this.MapTo_GroupEquipmentChartAverageBy_NEW('temperature', response);
        chartAverage.chart.forEach((element) => {
          element.series.forEach((serie) => {
            const formattedDate = `${this.dfs.formatDate(serie.name, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).longDateTime, this.localizationService.getCurrentLanguage())}`;
            displayData.push({
              day: formattedDate,
              equipmentName: element.name,
              temperature: toDecimalPrecision(serie.value)
            });
          });
        });
        displayData.sort((a, b) => new Date(a.day).getTime() - new Date(b.day).getTime());
        break;
      default:
        break;
    }
    return displayData;
  }

  //Sense-3 : Sensors Humidity
  MapTo_SensorsHumidity_Chart(data: SensorReadingModel[], groupingType: DashboardTypeGrouping, meta: MetaModel): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let displayData: any[] = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.date)
      .ToArray();
    let grouppedEquipments = [];
    switch (groupingType) {
      case DashboardTypeGrouping.SENSORS:
        grouppedEquipments = Enumerable.asEnumerable(displayData)
          .GroupBy((g: SensorReadingModel) => g.sensorId)
          .ToArray();

        if (grouppedEquipments) {
          grouppedEquipments.forEach((group) => {
            let chartGroup = new ChartModel(group.key);
            chartGroup.series = group.map((s) => new ChartSerie(new Date(s.date), s.humidity));
            series = series.concat(group.key);
            chartData.push(chartGroup);
          });
        }
        break;
      case DashboardTypeGrouping.EQUIPMENTS:
        const chartAverage = this.MapTo_GroupEquipmentChartAverageBy_NEW('humidity', { data, alerts: [] }, true);
        series = chartAverage.series;
        chartData = chartAverage.chart;
        break;
      default:
        break;
    }

    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return {
      chart: createGap(chartData, this.getSenseChartGapPeriod(meta.interval.split('min')[0], groupingType), 'minute'),
      series: series
    };
  }

  MapTo_SensorsHumidity_Table(data: SensorReadingModel[], groupingType: DashboardTypeGrouping): { day: string; sensorId: string; humidity: number }[] {
    let displayData = [];
    let displayDataArray = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.date)
      .ToArray();
    switch (groupingType) {
      case DashboardTypeGrouping.SENSORS:
        displayData = displayDataArray.map((item: SensorReadingModel) => {
          return {
            day: `${this.dfs.formatDate(
              this.dfs.dateFromUTCToLocal(item.date),
              `${languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).longDateTime}`,
              this.localizationService.getCurrentLanguage()
            )}`,
            sensorId: item.sensorId,
            humidity: toDecimalPrecision(item.humidity, true)
          };
        });
        break;
      case DashboardTypeGrouping.EQUIPMENTS:
        const chartAverage = this.MapTo_GroupEquipmentChartAverageBy_NEW('humidity', { data, alerts: [] }, true);
        chartAverage.chart.forEach((element) => {
          element.series.forEach((serie) => {
            const formattedDate = `${this.dfs.formatDate(serie.name, `${languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).longDateTime}`, this.localizationService.getCurrentLanguage())}`;
            displayData.push({
              day: formattedDate,
              equipmentName: element.name,
              humidity: toDecimalPrecision(serie.value, true)
            });
          });
        });

        displayData.sort((a, b) => new Date(a.day).getTime() - new Date(b.day).getTime());
        break;
      default:
        break;
    }
    return displayData;
  }

  MapTo_GroupEquipmentChartAverageBy(key: string, response: SensorReadingsAndAlerts, forceInteger = false): { chart: ChartModel[]; series: string[] } {
    const { data, alerts } = response;
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let displayData: any[] = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.date)
      .ToArray();

    let grouppedEquipments = Enumerable.asEnumerable(displayData)
      .GroupBy((g: SensorReadingModel) => g.equipmentId)
      .ToArray();

    if (grouppedEquipments) {
      //grouping by date
      const grouppedByDate = grouppedEquipments.map((_grouppedArray) => {
        return Enumerable.asEnumerable(_grouppedArray)
          .GroupBy((g: SensorReadingModel) => g.topMinDate)
          .ToArray();
      });

      if (grouppedByDate) {
        grouppedByDate.forEach((grouppedEquipment) => {
          if (grouppedEquipment) {
            const group = [];
            grouppedEquipment.forEach((grouppedDate: any) => {
              const elements: any[] = grouppedDate.map((element: any) => element[key]);
              const avg = toDecimalPrecision(
                Enumerable.asEnumerable(elements).Average((element) => element),
                forceInteger
              );
              if (grouppedDate.length > 0) {
                const { equipmentName, equipmentId, sensorId } = grouppedDate[0];
                group.push({ date: `${grouppedDate.key}`, avg, equipmentName, equipmentId, sensorId });
              }
            });
            if (group.length > 0) {
              let chartGroup = new ChartModel(group[0].equipmentName, [], { equipmentId: group[0].equipmentId, equipmentName: group[0].equipmentName });
              chartGroup.series = group.map((s) => {
                const found = alerts.find((alert) => {
                  return alert.topMinDate === s.date && s.sensorId === alert.sensorId;
                });
                const avg = toDecimalPrecision(s.avg, forceInteger);
                return found ? new ChartSerie(new Date(s.date), avg, chartGroup.extra, new Date(s.date), avg, 3) : new ChartSerie(new Date(s.date), avg, chartGroup.extra);
              });
              series = series.concat(group[0].equipmentName);
              chartData.push(chartGroup);
            }
          }
        });
      }
    }

    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  //Sense-3 : Sensors Battery
  MapTo_SensorsBattery_Chart(data: SensorReadingModel[]): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let displayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.date)
      .ToArray();
    let grouppedArray = Enumerable.asEnumerable(displayData)
      .GroupBy((g: SensorReadingModel) => g.sensorId)
      .ToArray();
    if (grouppedArray) {
      grouppedArray.forEach((group) => {
        let chartGroup = new ChartModel(group.key);
        chartGroup.series = group.map((s) => new ChartSerie(new Date(s.date), s.batteryLevel));
        series = series.concat(group.key);
        chartData.push(chartGroup);
      });
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  MapToCustomChart_Battery(response: SensorReadingsAndAlerts): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let displayData: any[] = [];
    let { data, alerts, meta } = response;
    displayData = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.date)
      .ToArray();
    let grouppedArray = Enumerable.asEnumerable(displayData)
      .GroupBy((g: SensorReadingModel) => g.sensorId)
      .ToArray();
    if (grouppedArray) {
      grouppedArray.forEach((group) => {
        let chartGroup = new ChartModel(group.key);
        chartGroup.series = group.map((s) => {
          const found = alerts.find((alert) => new Date(alert.time).toLocaleString() === new Date(s.date).toLocaleString());
          return found ? new ChartSerie(new Date(s.date), s.batteryLevel, undefined, new Date(s.date), s.batteryLevel, 3) : new ChartSerie(new Date(s.date), s.batteryLevel);
        });
        series = series.concat(group.key);
        chartData.push(chartGroup);
      });
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return {
      chart: createGap(chartData, this.getSenseChartGapPeriod(meta.interval, DashboardTypeGrouping.SENSORS), 'minute'),
      series: series
    };
  }

  MapTo_SensorsBattery_Table(data: SensorReadingModel[]): { day: string; sensorId: string; battery: string }[] {
    let displayData = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.date)
      .ToArray()
      .map((item: SensorReadingModel) => {
        return {
          day: `${this.dfs.formatDate(
            this.dfs.dateFromUTCToLocal(item.date),
            `${languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).longDateTime}`,
            this.localizationService.getCurrentLanguage()
          )}`,
          sensorId: item.sensorId,
          battery: `${item.batteryLevel}`
        };
      });
    return displayData;
  }

  //Sense-5 : Sensors Alerts Count
  MapTo_SensorsAlertsCount_Chart(data: SensorAlertCountModel[], groupingType: DashboardTypeGrouping): { chart: ChartModel[]; series: string[] } {
    const dataMapped = this._MapTo_SensorsAlertsCountBySensor(data, groupingType);
    let chartData: ChartModel[] = dataMapped.chart;
    let series: string[] = Enumerable.asEnumerable(dataMapped.series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  MapTo_SensorsAlertsCount_Table(data: SensorAlertCountModel[], groupingType: DashboardTypeGrouping): { sensorId: string; alertType: string; numberOfAlerts: number }[] {
    let displayData = [];
    const titleCaseAlertType: any = (alertType: string) => this.titlecasePipe.transform(alertType);
    const mappedData = this._MapTo_SensorsAlertsCountBySensorData(data, groupingType);
    mappedData.forEach((parent) => {
      parent.children.forEach((child) => {
        const element = {
          sensorId: child.extra.sensorId,
          equipment: child.extra.equipmentName,
          alertType: this.translateService.instant(`analytics.sense_dashboard.alert.${titleCaseAlertType(child.extra.alertType)}`),
          numberOfAlerts: child.values,
          color: getSenseAlertColor(titleCaseAlertType(child.extra.alertType))
        };
        displayData.push(element);
      });
    });
    displayData.sort(function (a, b) {
      return b.numberOfAlerts - a.numberOfAlerts;
    });
    return displayData;
  }

  private _MapTo_SensorsAlertsCountBySensorData(data: SensorAlertCountModel[], groupingType: DashboardTypeGrouping) {
    let displayData: SensorAlertCountModel[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data))
      .OrderByDescending((item) => item.numberOfAlerts)
      .ThenByDescending((item) => item.alertType)
      .ToArray();
    let groupedSensors = Enumerable.asEnumerable(displayData)
      .GroupBy((g: SensorAlertCountModel) => (groupingType === DashboardTypeGrouping.SENSORS ? g.sensorId : g.equipmentId))
      .ToArray();

    /*NEW*/
    return Enumerable.asEnumerable(groupedSensors)
      .Select((gs: IGrouping<string, SensorAlertCountModel>) => {
        var groupedValuesByAlertType = Enumerable.asEnumerable(gs)
          .GroupBy((al) => al.alertType)
          .Select((al: IGrouping<SenseAlert, SensorAlertCountModel>) => {
            let values = Enumerable.asEnumerable(al);
            let { equipmentName, alertType, sensorId, sensorName, equipmentId, unitName } = values.First() ? values.First() : null;
            return {
              alert: al.key,
              values: values.Sum((v) => v.numberOfAlerts),
              extra: {
                sensorId,
                sensorName,
                equipmentId,
                equipmentName: this.getChartName(equipmentName, unitName),
                alertType
              }
            };
          })
          .ToArray();
        var children = groupedValuesByAlertType;
        var element = children.length > 0 && children[0].extra.equipmentName !== null ? children[0].extra.equipmentName : gs.key;
        return {
          key: groupingType === DashboardTypeGrouping.SENSORS ? gs.key : element,
          children
        };
      })
      .ToArray();
  }

  private _MapTo_SensorsAlertsCountBySensor(data: SensorAlertCountModel[], groupingType: DashboardTypeGrouping): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    const titleCaseAlertType: any = (alertType: string) => this.titlecasePipe.transform(alertType);
    const mappedData = this._MapTo_SensorsAlertsCountBySensorData(data, groupingType);
    chartData = mappedData.map(
      (parent) =>
        new ChartModel(
          parent.key,
          parent.children.map((child) => new ChartSerie(titleCaseAlertType(child.alert), child.values, child.extra)),
          parent.children.length > 0 ? parent.children[0].extra : null
        )
    );
    mappedData.forEach((parent) => parent.children.forEach((child) => series.push(titleCaseAlertType(child.alert))));
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  //Sense-6: Alerts history grid
  //Tabular model
  MapTo_SensorsAlertsHistory_Table(
    data: SensorAlertReadingModel[]
  ): { alertTime: string; recoveryTime: string; sensorId: string; equipment: string; alertType: string; recordedValue: string; expectedRange: string; color: string }[] {
    let displayData = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((d) => getSenseAlertOrder(<SenseAlert>this.titlecasePipe.transform(d.alertType)))
      .ToArray()
      .map((row) => {
        const alertType: any = this.titlecasePipe.transform(row.alertType);
        return {
          alertTime: `${this.dfs.formatDate(new Date(row.alertTime), languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).longDateTime, this.localizationService.getCurrentLanguage())}`,
          recoveryTime: row.recoveryTime
            ? `${this.dfs.formatDate(
                this.dfs.dateFromUTCToLocal(row.recoveryTime),
                `${languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).longDateTime}`,
                this.localizationService.getCurrentLanguage()
              )}`
            : '',
          sensorId: `${row.sensorId}`,
          equipment: row.equipmentName + (definedAndNotEmptyString(row.unitName) ? ` - ${row.unitName}` : ''),
          alertType: this.translateService.instant(`analytics.sense_dashboard.alert.${this.titlecasePipe.transform(row.alertType)}`),
          recordedValue: toDecimalPrecision(parseFloat(row.recordedValue)),
          expectedRange: row.expectedRange,
          color: row.recoveryTime ? getSenseAlertColor(alertType) : getSenseAlertColor(SenseAlert.NotRecovered)
        };
      });
    return displayData;
  }

  //Equipments view
  //Sense-1: Equipments Latest Readings
  //Tabular model
  // 2 - Used for cards filtering on both Store & Location views
  MapTo_EquipmentsLatestReadings_Table(
    data: SensorReadingModel[],
    filterByStatus?: SenseStatus,
    reportSubject?: ReportSubject
  ): { day: string; equipment: string; humidity: number; battery: number; temperature: number; status: string; color: string; expandedDetail: MatTableDataSource<any>[] }[] {
    let displayData = this._mapToEquipmentStatusView_Table(data, filterByStatus, reportSubject);
    return displayData;
  }

  // SENSE-0: Equipments Status
  //Chart Model
  MapTo_EquipmentsStatus_Chart(data: SensorReadingModel[], locationType: NodeTypeEnum): { chart: ChartSerie[]; series: string[]; total: number } {
    let chartData: ChartSerie[] = [];
    let series: string[] = [];
    let displayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data)).ToArray();
    let total = 0;
    const calculatedEquipmentStatuses = this._calculateEquipmentStatuses(displayData, locationType);
    calculatedEquipmentStatuses.forEach((group) => {
      chartData.push(new ChartSerie(group.key, group.value));
      series = series.concat(group.key);
    });
    calculatedEquipmentStatuses.forEach((equipmentStatus) => {
      total += equipmentStatus.value;
    });
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series, total: total };
  }

  MapTo_SensorsAlertsHistoryEquipments_Table(
    data: SensorAlertReadingModel[]
  ): { day: string; equipment: string; humidity: number; battery: number; temperature: number; status: string; color: string; expandedDetail: MatTableDataSource<any>[] }[] {
    let displayData = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((d) => getSenseAlertOrder(<SenseAlert>this.titlecasePipe.transform(d.alertType)))
      .GroupBy((d) => d.equipmentId)
      .Select((g: IGrouping<SenseAlert, SensorAlertReadingModel>) => {
        let groupData = Enumerable.asEnumerable(g);
        const arrayData = groupData.ToArray();
        let numberOfAlerts = groupData.Count();
        const firstElement = arrayData[0];
        const lastAlertTime = arrayData[arrayData.length - 1].alertTime;
        let data = {
          id: firstElement.equipmentId,
          equipmentName: firstElement.equipmentName + (definedAndNotEmptyString(firstElement.unitName) ? ` - ${firstElement.unitName}` : ''),
          numberOfAlerts,
          alertTime: lastAlertTime
        };
        let child = groupData.ToArray().map((row) => {
          const alertType: any = this.titlecasePipe.transform(row.alertType);
          return {
            sub_alertTime: `${this.dfs.formatDate(
              this.dfs.dateFromUTCToLocal(row.alertTime),
              `${languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).longDateTime}`,
              this.localizationService.getCurrentLanguage()
            )}`,
            sub_recoveryTime: row.recoveryTime
              ? `${this.dfs.formatDate(
                  this.dfs.dateFromUTCToLocal(row.recoveryTime),
                  `${languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).longDateTime}`,
                  this.localizationService.getCurrentLanguage()
                )}`
              : '',
            sub_sensorId: row.sensorId,
            sub_equipment: row.equipmentName + (definedAndNotEmptyString(row.unitName) ? ` - ${row.unitName}` : ''),
            sub_alertType: this.translateService.instant(`analytics.sense_dashboard.alert.${alertType}`),
            sub_recordedValue: toDecimalPrecision(+row.recordedValue),
            sub_expectedRange: row.expectedRange,
            sub_color: getSenseAlertColor(alertType)
          };
        });
        return { row: data, child: child };
      })
      .ToArray()
      .map((item) => {
        return {
          id: item.row.id,
          equipment: item.row.equipmentName,
          numberOfAlerts: item.row.numberOfAlerts,
          alertTime: `${this.dfs.formatDate(
            this.dfs.dateFromUTCToLocal(item.row.alertTime),
            `${languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).longDateTime}`,
            this.localizationService.getCurrentLanguage()
          )}`,
          expandedDetail: new MatTableDataSource(item.child)
        };
      });
    let rows = [];
    displayData.forEach((element) => {
      rows.push(element, { detailRow: true, element });
    });
    return rows;
  }

  //Corporate View
  //Sensors Alerts Map
  MapTo_SensorsAlerts_Map(data: SensorAlertMap[], groupingType: DashboardTypeGrouping): MapLocation[] {
    let mappedData: MapLocation[];
    let grouppedData = Enumerable.asEnumerable(deepClone(data))
      .Where((l) => l.long != null && l.lat != null && l.nodeId != null)
      .GroupBy((l) => `${l.long}_${l.lat}_${l.nodeId}`)
      .Select((gd) => {
        let groupData = Enumerable.asEnumerable(gd);
        let key = gd.key.split('_');
        return new SensorAlertMap(
          groupData
            .Select((d) => d.noPrefixLocationNumber)
            .ToArray()
            .join(', '),
          +key[0],
          +key[1],
          new SenseMapAlert(
            groupData.Sum((d) => d.devices.total),
            groupData.Sum((d) => d.devices.countWithAlerts),
            groupData.Average((d) => d.devices.alertsFreePercentage),
            groupData.FirstOrDefault().devices.color
          ),
          new SenseMapAlert(
            groupData.Sum((d) => d.devices.total),
            groupData.Sum((d) => d.devices.countWithAlerts),
            groupData.Average((d) => d.devices.alertsFreePercentage),
            groupData.FirstOrDefault().equipment.color
          ),
          +key[2],
          groupData.Count() > 1 ? null : groupData.FirstOrDefault().brandId
        );
      })
      .ToArray();
    let key = groupingType == DashboardTypeGrouping.SENSORS ? 'devices' : 'equipment';
    mappedData = grouppedData.map((d) => {
      return new MapLocation(
        d.locationName,
        d.locationName,
        {
          percentage: d[key].alertsFreePercentage,
          count: d[key].countWithAlerts,
          total: d[key].total,
          title: groupingType == DashboardTypeGrouping.SENSORS ? 'sensors' : 'equipment'
        },
        { lat: d.lat, lng: d.long },
        d.nodeId,
        d.brandId
      );
    });
    return mappedData;
  }

  MapTo_SensorsAlertsMap_Table(_response: SensorAlertMapResponse, groupingType: DashboardTypeGrouping): { location: string; percentage: string; complianceRate: string; color: string }[] {
    const response = deepClone(_response);
    let key = groupingType == DashboardTypeGrouping.SENSORS ? 'devices' : 'equipment';
    let displayData = Enumerable.asEnumerable(response.data)
      .Where((l) => l.long != null && l.lat != null && l.nodeId != null)
      .Select((row) => {
        return {
          location: row.noPrefixLocationNumber,
          percentage: `${toDecimalPrecision(row[key].alertsFreePercentage, true)}`,
          complianceRate: this.titlecasePipe.transform(this.translateService.instant(`ranges.${row[key].complianceName.toLowerCase()}`)),
          color: row[key].color,
          displayOrder: row[key].displayOrder
        };
      })
      .OrderBy((l) => l.displayOrder)
      .ThenBy((l) => l.location)
      .ToArray();
    return displayData;
  }

  MapTo_SensorsAlertsMap_LegendRowsBrandConfiguration(brandConfiguration: BrandConfiguration[]): { class: string; text: string; displayOrder: number; rangeStart: number; rangeEnd: number }[] {
    let displayData = Enumerable.asEnumerable(deepClone(brandConfiguration))
      .OrderBy((l) => l.displayOrder)
      .Select((row) => {
        return {
          class: `custom-clustericon-${row.displayOrder}`,
          text: `${row.rangeStart}-${row.rangeEnd}% ${this.titlecasePipe.transform(this.translateService.instant('ranges.' + row.complianceName.toLowerCase()))}`,
          displayOrder: row.displayOrder,
          rangeStart: row.rangeStart,
          rangeEnd: row.rangeEnd
        };
      })
      .ToArray();
    return displayData;
  }

  MapTo_SensorsAlertsCountByLocation_Chart(data: SensorAlertCountByLocation[], groupingType: DashboardTypeGrouping): { chart: ChartModel[]; series: string[] } {
    const displayData = Enumerable.asEnumerable(deepClone(data)).Where((d) => d.locationName != null && d.alertType != null);

    let dataMapped = displayData
      .GroupBy((gd) => gd.noPrefixLocationNumber)
      .Select((gd) => {
        let groupData = Enumerable.asEnumerable(gd);
        let groupByAlert = groupData
          .GroupBy((a) => a.alertType)
          .Select((a) => {
            let aData = Enumerable.asEnumerable(a);
            return {
              locationName: gd.key,
              alertType: a.key,
              count: aData.Sum((ad) => ad.numberOfAlerts),
              brandId: aData.FirstOrDefault() ? aData.FirstOrDefault().brandId : null,
              nodeId: aData.FirstOrDefault() ? aData.FirstOrDefault().nodeId : null
            };
          })
          .OrderByDescending((d) => d.count)
          .ThenBy((d) => d.locationName)
          .ThenBy((d) => d.alertType);
        let groupSeries = groupByAlert
          .Select((d) => {
            return new ChartSerie(this.titlecasePipe.transform(d.alertType), d.count, { brandId: d.brandId, nodeId: d.nodeId });
          })
          .ToArray();
        return new ChartModel(gd.key, groupSeries);
      })
      .OrderByDescending((d) => d.series[0].value)
      .ToArray();
    let chartData: ChartModel[] = dataMapped;
    let series: string[] = displayData
      .Select((d) => this.titlecasePipe.transform(d.alertType))
      .Distinct((alertType) => alertType)
      .ToArray();
    return { chart: chartData, series: series };
  }

  MapTo_SensorsAlertsCountByLocation_Table(data: SensorAlertCountByLocation[], groupingType: DashboardTypeGrouping): { location: string; numberOfAlerts: number; alertType: string; color: string }[] {
    let displayData = Enumerable.asEnumerable(deepClone(data))
      .GroupBy((a) => a.noPrefixLocationNumber)
      .SelectMany((gd) => {
        let groupData = Enumerable.asEnumerable(gd);
        let groupByAlert = groupData.GroupBy((a) => a.alertType);
        return groupByAlert.Select((d) => {
          return new SensorAlertCountByLocation(
            groupData.FirstOrDefault().brandId,
            groupData.FirstOrDefault().nodeId,
            gd.key,
            d.key,
            Enumerable.asEnumerable(d).Sum((a) => a.numberOfAlerts)
          );
        });
      })
      .Where((d) => d.locationName != null)
      .OrderByDescending((d) => d.numberOfAlerts)
      .ThenBy((d) => d.locationName)
      .ThenBy((d) => d.alertType)
      .ToArray()
      .map((row) => {
        return {
          location: row.locationName,
          numberOfAlerts: row.numberOfAlerts,
          alertType: this.translateService.instant(`analytics.sense_dashboard.alert.${this.titlecasePipe.transform(row.alertType)}`),
          color: getSenseAlertColor(<SenseAlert>this.titlecasePipe.transform(row.alertType))
        };
      });
    return displayData;
  }

  private _fillMissingStatuses(grouppedArray: { key: SenseStatus; value: number }[]): { key: SenseStatus; value: number }[] {
    const statuses = Enumerable.asEnumerable([SenseStatus.InRange, SenseStatus.OutOfRange, SenseStatus.Inactive]);
    let missingStatuses = Enumerable.asEnumerable(statuses).Where((c) => !grouppedArray.map((g) => g.key).includes(c));
    if (missingStatuses.Any()) {
      grouppedArray = grouppedArray.concat(
        missingStatuses
          .Select((s) => {
            return { key: s, value: 0 };
          })
          .ToArray()
      );
    }
    grouppedArray = Enumerable.asEnumerable(grouppedArray)
      .OrderBy((g) => getSenseStatusOrderCards(<SenseStatus>g.key))
      .ToArray();
    return grouppedArray;
  }

  private _setEquipmentStatusCount(data: { key: string; value: number }[], _senseStatus: SenseStatus, value: number) {
    const status = data.find((d) => d.key === _senseStatus);
    if (status) {
      status.value = value;
    }
  }

  private _getEquipmentStatusCount(data: { key: string; value: number }[], _senseStatus: SenseStatus): number {
    const status = data.find((d) => d.key === _senseStatus);
    if (status) {
      return status.value;
    }
    return 0;
  }

  private _calculateEquipmentStatuses(_sensors: SensorReadingModel[], locationType: NodeTypeEnum): { key: string; value: number }[] {
    const statuses = Enumerable.asEnumerable([SenseStatus.InRange, SenseStatus.OutOfRange, SenseStatus.NoReadings]);
    const data = statuses.ToArray().map((status) => {
      return {
        key: status,
        value: 0
      };
    });
    const equipment = Enumerable.asEnumerable(deepClone(_sensors))
      .GroupBy((sensor) => sensor.equipmentId)
      .ToArray();

    let noreadingsEquipmentCount = 0;
    let outofrangeEquipmentCount = 0;
    let inrangeEquipmentCount = 0;
    if (equipment) {
      equipment.forEach((equipmentGroup) => {
        const allInactiveEquipmentNb = Enumerable.asEnumerable(equipmentGroup)
          .Where((g) => g.status === SenseStatus.Inactive)
          .Count();
        const allUnlinkedEquipmentNb = Enumerable.asEnumerable(equipmentGroup)
          .Where((g) => g.status === SenseStatus.Unlinked)
          .Count();
        const allNoreadingsEquipmentNb = Enumerable.asEnumerable(equipmentGroup).Count() === allInactiveEquipmentNb + allUnlinkedEquipmentNb;
        const atLeastOneOutOfRangeEquipmentNb =
          Enumerable.asEnumerable(equipmentGroup)
            .Where((g) => g.status === SenseStatus.OutOfRange)
            .Count() > 0;
        if (allNoreadingsEquipmentNb) {
          noreadingsEquipmentCount++;
        } else if (atLeastOneOutOfRangeEquipmentNb) {
          outofrangeEquipmentCount++;
        } else {
          inrangeEquipmentCount++;
        }
      });
    }
    this._setEquipmentStatusCount(data, SenseStatus.InRange, inrangeEquipmentCount);
    this._setEquipmentStatusCount(data, SenseStatus.OutOfRange, outofrangeEquipmentCount);
    this._setEquipmentStatusCount(data, SenseStatus.NoReadings, noreadingsEquipmentCount);
    return Enumerable.asEnumerable(data)
      .OrderBy((g) => getSenseStatusOrderCards(<SenseStatus>g.key))
      .ToArray();
  }

  private _mapToEquipmentStatusView_Table(
    data: SensorReadingModel[],
    filterByStatus?: SenseStatus,
    reportSubject?: ReportSubject
  ): { day: string; equipment: string; humidity: number; battery: number; temperature: number; status: string; color: string; expandedDetail: MatTableDataSource<any>[] }[] {
    const isStoreLevel = reportSubject === ReportSubject.EQUIPMENTS_LATEST_READINGS;
    const orderedData = Enumerable.asEnumerable(deepClone(data)).OrderByDescending((d) => d.date);
    const groupedData = orderedData.GroupBy((d) => d.equipmentId);
    let displayData = groupedData
      .Select((g: IGrouping<string, SensorReadingModel>) => {
        let groupData = Enumerable.asEnumerable(g);

        let eqStatus = SenseStatus.InRange;
        if (groupData.Any((d) => d.status == SenseStatus.OutOfRange)) {
          eqStatus = SenseStatus.OutOfRange;
        } else if (groupData.All((d) => d.status == SenseStatus.Unlinked || d.status == SenseStatus.Inactive)) {
          eqStatus = SenseStatus.NoReadings;
        }

        const lastRecord = groupData.FirstOrDefault((x) => x.status === eqStatus || (eqStatus === SenseStatus.NoReadings && (x.status == SenseStatus.Unlinked || x.status == SenseStatus.Inactive)));
        if (lastRecord) {
          let id = lastRecord.equipmentId;
          let humidity = `${toDecimalPrecision(lastRecord.humidity, true)}`;
          let battery = `${toDecimalPrecision(lastRecord.battery, true)}`;
          let temperature = `${toDecimalPrecision(lastRecord.temperature)}`;
          let expectedRange = lastRecord.expectedRange;
          let rssi = `${toDecimalPrecision(lastRecord.rssi)}`;

          let status = lastRecord.status;
          let date = lastRecord.date;
          let noPrefixLocationNumber = lastRecord ? lastRecord.noPrefixLocationNumber : '';
          status = status === SenseStatus.Inactive || status === SenseStatus.Unlinked ? SenseStatus.NoReadings : status;

          let data = new SensorReadingModel(
            id,
            status,
            null,
            null,
            g.key,
            lastRecord.equipmentName + (isStoreLevel && lastRecord.unitName ? ` - ${lastRecord.unitName}` : ``),
            lastRecord.unitName,
            lastRecord.date,
            +humidity,
            +battery,
            +battery,
            +temperature,
            noPrefixLocationNumber,
            expectedRange,
            date,
            undefined,
            null,
            null,
            definedAndNotEmptyString(rssi) ? +rssi : null
          );
          let child = groupData
            .OrderBy((d) => getSenseStatusOrder(<SenseStatus>d.status))
            .ToArray()
            .map((row) => {
              return {
                sub_day: `${this.dfs.formatDate(
                  this.dfs.dateFromUTCToLocal(row.date),
                  `${languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).longDateTime}`,
                  this.localizationService.getCurrentLanguage()
                )}`,
                sub_sensorId: row.sensorId,
                sub_equipment: row.equipmentName + (isStoreLevel && stringIsDefinedAndNotEmpty(row.unitName) ? ` - ${row.unitName}` : ``),
                sub_equipmentUnit: row.unitName,
                sub_humidity: `${toDecimalPrecision(row.humidity, true)}`,
                sub_battery: `${toDecimalPrecision(row.battery, true)}`,
                sub_rssi: `${toDecimalPrecision(row.rssi, true)}`,
                sub_temperature: `${toDecimalPrecision(row.temperature)}`,
                sub_location: row.noPrefixLocationNumber,
                sub_status: this.translateService.instant(`analytics.sense_dashboard.status.${row.status}`),
                color: getSenseStatusColor(row.status),
                signalColor: row.status === SenseStatus.Inactive ? getSenseStatusColor(row.status) : getSignalStatusColor(row.rssi).color,
                signalText: getSignalStatusColor(row.rssi).text,
                expectedRange: getSensorExpectedRange(row)
              };
            });
          return { row: data, child: child };
        }
      })
      .Where((d) => d.row.status == filterByStatus || !filterByStatus)
      .OrderBy((d) => getSenseStatusOrder(<SenseStatus>d.row.status))
      .ToArray()
      .map((item) => {
        return {
          id: item.row.id,
          day: `${this.dfs.formatDate(
            this.dfs.dateFromUTCToLocal(item.row.date),
            `${languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).longDateTime}`,
            this.localizationService.getCurrentLanguage()
          )}`,
          equipment: item.row.equipmentName,
          equipmentUnit: item.row.equipmentUnitName,
          humidity: `${toDecimalPrecision(item.row.humidity, true)}`,
          battery: `${toDecimalPrecision(item.row.battery, true)}`,
          rssi: `${toDecimalPrecision(item.row.rssi, true)}`,
          temperature: `${toDecimalPrecision(item.row.temperature)}`,
          location: item.row.noPrefixLocationNumber,
          status:
            filterByStatus === SenseStatus.NoReadings
              ? this.translateService.instant(`analytics.sense_dashboard.status.${SenseStatus.NoReadings}`)
              : this.translateService.instant(`analytics.sense_dashboard.status.${item.row.status}`),
          color: getSenseStatusColor(item.row.status),
          signalColor: item.row.status === SenseStatus.NoReadings ? getSenseStatusColor(item.row.status) : getSignalStatusColor(item.row.rssi).color,
          signalText: getSignalStatusColor(item.row.rssi).text,
          expandedDetail: new MatTableDataSource(item.child),
          expectedRange: getSensorExpectedRange(item.row)
        };
      });
    let rows = [];
    displayData.forEach((element, index) => {
      rows.push({ ...element, rowIndex: index });
      element.expandedDetail.data.forEach((innerElement) => {
        rows.push({ detailRow: true, ...innerElement, parentRowIndex: index });
      });
    });
    return rows;
  }
}
export function getSensorExpectedRange(sensor: SensorReadingModel): string {
  return sensor.status === SenseStatus.NoReadings || sensor.status === SenseStatus.Inactive ? '' : sensor.expectedRange;
}
