import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CalendarEvent, CalendarEventAction } from 'angular-calendar';
import { addDays, addHours, subDays } from 'date-fns';
import * as Enumerable from 'linq-es2015';
import { RRule } from 'rrule';
import { TimeOfTheDay, timeOfTheDayColors } from '../../../shared/helpers/calendar.helper';
import { RecurringEvent } from '../../../shared/models/calendar.model';
import { ItemModelRow } from '../../menu-manager/models/section.model';
import { TaskScheduleEnum } from '../../menu-manager/models/task-schedule.enum';
import { allWeekLongRRULE, daysOfWeekIndices, daysOfWeekRRULE, weekDaysRRULE } from '../models/days-of-week.helper';
import { TempWorkflowTemplate } from '../models/temp-workflow-template.enum';
import { TempGroupedWorkflowModel, TemplateModel, TempWorkflowModel } from '../models/temp-workflow.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsMappingHelpers {
  constructor(private translateService: TranslateService) { }

  MapTasksToCalendarEvents(tasks: ItemModelRow[], taskScheduleTypeValue: TaskScheduleEnum): CalendarEvent[] {
    let calendarEvents: CalendarEvent[] = [];
    let recurringEvents: RecurringEvent[] = [];

    let d = new Date();
    let todayIndex = d.getDay();
    var usedTodayIndex = todayIndex;
    if (todayIndex == 0) {
      usedTodayIndex = 7;
    }

    tasks.forEach((task) => {
      task.recurrences.forEach((rec) => {
        var recurringEvent: RecurringEvent = new RecurringEvent();
        recurringEvent.title = `${task.name} - ${task.detailName}`;
        recurringEvent.extras = { suffix: task.suffix };
        recurringEvent.color = timeOfTheDayColors.get(TimeOfTheDay.Task);
        recurringEvent.badges = rec.badges;

        switch (taskScheduleTypeValue) {
          case TaskScheduleEnum.DAILY_WEEKLY:
            if (rec.cronExpression.startsWith('0 1')) {
              if (rec.cronExpression.endsWith('MON-FRI')) {
                recurringEvent.rrule = {
                  freq: RRule.WEEKLY,
                  byweekday: weekDaysRRULE
                };

                recurringEvents.push(recurringEvent);
              } else {
                recurringEvent.rrule = {
                  freq: RRule.WEEKLY,
                  byweekday: allWeekLongRRULE
                };

                recurringEvents.push(recurringEvent);
              }
            } else if (rec.cronExpression.startsWith('0 0 * *')) {
              let weekDays = rec.cronExpression.split('0 0 * * ')[1];
              if (weekDays) {
                recurringEvent.rrule = {
                  freq: RRule.WEEKLY,
                  byweekday: weekDays.split(',').map((x) => daysOfWeekRRULE.get(x))
                };

                recurringEvents.push(recurringEvent);
              }
            }
            break;
          case TaskScheduleEnum.MONTHLY_YEARLY:
            if (rec.cronExpression.startsWith('0 0') && (rec.cronExpression.endsWith('*') || rec.cronExpression.includes('#') || rec.cronExpression.endsWith('L'))) {
              if (rec.cronExpression.includes('/')) {
                if (rec.cronExpression.endsWith('*')) {
                  let splittedCron = rec.cronExpression.split('0 0 ');
                  let monthDay = parseInt(splittedCron[1].split(' ')[0]);
                  let every_number_of_month = parseInt(splittedCron[1].split(' ')[1].split('/')[1]);
                  let month_array = [1];
                  let added_month = month_array[0] + every_number_of_month;
                  while (added_month <= 12) {
                    month_array.push(added_month);
                    added_month = month_array[month_array.length - 1] + every_number_of_month;
                  }
                  recurringEvent.rrule = {
                    freq: RRule.MONTHLY
                  };
                  recurringEvent.byeverymonthday = month_array.map((x) => ({
                    month: x,
                    day: monthDay
                  }));
                  recurringEvent.everynumberofmonth = every_number_of_month;

                  recurringEvents.push(recurringEvent);
                } else {
                  let splittedCron = rec.cronExpression.split('0 0 * ');
                  let every_number_of_month = parseInt(splittedCron[1].split(' ')[0].split('/')[1]);
                  let day_and_number = splittedCron[1].split(' ')[1];
                  let day = day_and_number.includes('#') ? day_and_number.split('#')[0] : day_and_number.split('L')[0];
                  let day_number = day_and_number.includes('#') ? day_and_number.split('#')[1] : 'L';
                  let month_array = [1];
                  let added_month = month_array[0] + every_number_of_month;
                  while (added_month <= 12) {
                    month_array.push(added_month);
                    added_month = month_array[month_array.length - 1] + every_number_of_month;
                  }
                  recurringEvent.rrule = {
                    freq: RRule.MONTHLY
                  };
                  recurringEvent.byeverymonthday = month_array.map((x) => ({
                    month: x,
                    day: this.getDaysDates(daysOfWeekIndices.get(day), x - 1, day_number)
                  }));

                  recurringEvents.push(recurringEvent);
                }
              } else {
                if (rec.cronExpression.endsWith('*')) {
                  let splittedCron = rec.cronExpression.split('0 0 ');
                  let monthDay = parseInt(splittedCron[1].split(' ')[0]);
                  let monthIndex = parseInt(splittedCron[1].split(' ')[1]);
                  recurringEvent.rrule = {
                    freq: RRule.YEARLY,
                    bymonth: monthIndex,
                    bymonthday: monthDay
                  };

                  recurringEvents.push(recurringEvent);
                } else {
                  let splittedCron = rec.cronExpression.split('0 0 * ');
                  let monthIndex = parseInt(splittedCron[1].split(' ')[0]);
                  let day_and_number = splittedCron[1].split(' ')[1];
                  let day = day_and_number.includes('#') ? day_and_number.split('#')[0] : day_and_number.split('L')[0];
                  let day_number = day_and_number.includes('#') ? day_and_number.split('#')[1] : 'L';
                  recurringEvent.rrule = {
                    freq: RRule.YEARLY,
                    bymonth: monthIndex,
                    bymonthday: this.getDaysDates(daysOfWeekIndices.get(day), monthIndex - 1, day_number)
                  };

                  recurringEvents.push(recurringEvent);
                }
              }
            }
            break;
        }
      });
    });

    switch (taskScheduleTypeValue) {
      case TaskScheduleEnum.DAILY_WEEKLY:
        recurringEvents.forEach((event, index) => {
          const rule: RRule = new RRule({
            ...event.rrule,
            dtstart: subDays(d, usedTodayIndex - 1),
            until: addDays(d, 7 - usedTodayIndex)
          });

          const { title, color } = event;
          rule.all().forEach((date) => {
            date.setHours(0, 0, 0, 0);
            calendarEvents.push({
              title,
              color,
              start: addHours(date, index == 0 ? index * 1.5 : index * 1.5 + 0.5),
              end: addHours(date, index == 0 ? (index + 1) * 1.5 : (index + 1) * 1.5 + 0.5),
              meta: { badges: event.badges, suffix: event.extras.suffix }
            });
          });
        });
        break;
      case TaskScheduleEnum.MONTHLY_YEARLY:
        recurringEvents.forEach((event, index) => {
          const { title, color } = event;

          if (event.rrule.freq == RRule.YEARLY) {
            calendarEvents.push({
              title,
              color,
              start: new Date(new Date().getFullYear(), event.rrule.bymonth - 1, event.rrule.bymonthday),
              meta: { badges: event.badges, suffix: event.extras.suffix, month_day: { month: event.rrule.bymonth, day: event.rrule.bymonthday } }
            });
          } else if (event.rrule.freq == RRule.MONTHLY) {
            if (event.byeverymonthday) {
              event.byeverymonthday.forEach((monthDay) => {
                calendarEvents.push({
                  title,
                  color,
                  start: new Date(new Date().getFullYear(), monthDay.month - 1, monthDay.day),
                  meta: { badges: event.badges, suffix: event.extras.suffix, month_day: monthDay }
                });
              });
            }
          }
        });
        break;
    }

    return calendarEvents;
  }

  getDaysDates(dayIndex: number, monthIndex: number, dayNumber: string) {
    var d = new Date(),
      month = monthIndex,
      days = [];

    d.setDate(1);

    if (d.getMonth() > month) {
      while (d.getMonth() !== month) {
        d.setDate(d.getDate() - 30);
      }
    } else if (d.getMonth() < month) {
      while (d.getMonth() !== month) {
        d.setDate(d.getDate() + 30);
      }
    }

    d.setDate(1);

    // Get the first Specified day in the month
    while (d.getDay() !== dayIndex) {
      d.setDate(d.getDate() + 1);
    }

    // Get all the other Specified days in the month
    while (d.getMonth() === month) {
      days.push(new Date(d.getTime()));
      d.setDate(d.getDate() + 7);
    }

    return dayNumber != 'L' ? days[parseInt(dayNumber) - 1].getDate() : days[days.length - 1].getDate();
  }

  BuildAllWeekLongEvent(actions: CalendarEventAction[]): CalendarEvent {
    let d = new Date();
    let todayIndex = d.getDay();
    var usedTodayIndex = todayIndex;
    let calendarEvent: CalendarEvent = { start: d, title: '' };
    if (todayIndex == 0) {
      usedTodayIndex = 7;
    }

    calendarEvent.title = this.translateService.instant('menu_manager.temp_menu_designer.anytime');
    calendarEvent.color = timeOfTheDayColors.get(TimeOfTheDay.FullDay);
    calendarEvent.start = subDays(d, usedTodayIndex - 1);

    calendarEvent.actions = actions;
    calendarEvent.allDay = true;
    calendarEvent.meta = { calEvent: true };

    return calendarEvent;
  }

  MapValuesToCalendarEventsMeta(calendarEvents: CalendarEvent[], allAssignedItems: ItemModelRow[]): CalendarEvent[] {
    calendarEvents.forEach((element) => {
      if (element.meta.calEvent) {
        let assignedItemsList = allAssignedItems.filter((x) => x.ingredientWorkflowScheduleAnyTime);
        element.meta.value = assignedItemsList ? assignedItemsList.length : null;
        element.meta.assignedItems = assignedItemsList ? assignedItemsList : null;
      } else {
        let assignedItemsList = allAssignedItems.filter((x) =>
          x.recurrences && x.recurrences.length > 0 ? (x.recurrences[0].sessions ? x.recurrences[0].sessions.find((s) => s.session == element.meta.id) : null) : null
        );
        element.meta.value = assignedItemsList ? assignedItemsList.length : null;
        element.meta.assignedItems = assignedItemsList ? assignedItemsList : null;
      }
    });

    return calendarEvents;
  }

  MapTempWorkflowToGroupedResult(tempWorkflows: TempWorkflowModel[]): TempGroupedWorkflowModel[] {
    console.log({ tempWorkflows })
    let results: TempGroupedWorkflowModel[] = [];
    let groupedTemplates = Enumerable.asEnumerable(tempWorkflows)
      .GroupBy((template: TempWorkflowModel) =>
        template.typeId === TempWorkflowTemplate.Ingredient
          ? template.workflowCategory.toLowerCase()
          : template.typeId === TempWorkflowTemplate.Other
          ? this.translateService.instant('other')
          : template.typeId === TempWorkflowTemplate.Equipment
          ? this.translateService.instant('equipment')
          : this.translateService.instant('advanced')
      )
      .ToArray();
    groupedTemplates.forEach((group) => {
      let workflowModel: TempGroupedWorkflowModel = new TempGroupedWorkflowModel(
        group.key,
        group.map(
          (val) =>
            new TemplateModel(
              val.name,
              val.rangeId,
              val.tempUoMId,
              val.min,
              val.max,
              val.isUnitsSupported,
              val.imageUrl,
              null,
              val.workflowId,
              null,
              null,
              val.typeId,
              null,
              null,
              val.inputTypeId,
              val.inputTypeName,
              val.inUse
            )
        )
      );
      results.push(workflowModel);
    });
    return results;
  }
}
