export enum SenseAlert {
  Temperature = 'Temperature',
  Battery = 'Battery',
  Inactive = 'Inactive',
  NotRecovered = 'NotRecovered'
}

export const senseAlertColor = new Map<SenseAlert, string>([
  [SenseAlert.Temperature, '#e55239'],
  [SenseAlert.Battery, '#faa027'],
  [SenseAlert.Inactive, '#8aa2b6'],
  [SenseAlert.NotRecovered, '#FF0000']
]);

export const senseAlertOrder = new Map<SenseAlert, number>([
  [SenseAlert.Temperature, 1],
  [SenseAlert.Battery, 2],
  [SenseAlert.Inactive, 3],
  [SenseAlert.NotRecovered, 4]
]);
