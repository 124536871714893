import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of, Subscription } from 'rxjs';
import { InformativeDialogComponent } from '../../../shared/components/dialogs/informative-dialog/informative-dialog.component';
import { Tenant } from '../../../shared/models/tenant.model';
import { NavigationService } from '../../../shared/services/navigation.service';
import { ThemeService } from '../../../shared/services/theme.service';
import { UtilService } from '../../../shared/services/util.service';
import { LoginService } from '../../services/login.service';
import { catchError, map } from 'rxjs/operators';
import { LocalStorageFlags } from '../../../shared/models/enums/local-storage-flags.enum';
import { KeycloakLoginOptions } from 'keycloak-js';
import { defined } from '../../../shared/helpers/app.helpers';
import { KeycloakService } from 'keycloak-angular';
import { AuthService } from '../../auth/auth.service';
import { LocalizationService } from '../../../shared/services/localization/localization.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'nome-account-selection',
  templateUrl: './account-selection.component.html',
  styleUrls: ['./account-selection.component.scss']
})
export class AccountSelectionComponent implements OnInit {
  accountName: string;
  brandImagesAssets: string;
  apiStatusSubscription: Subscription;
  resellerLoginId?: number = null;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    protected keycloakService: KeycloakService,
    private loginService: LoginService,
    private navigationService: NavigationService,
    public utilService: UtilService,
    private matDialog: MatDialog,
    private themeService: ThemeService,
    public localizationService: LocalizationService,
    private translateService: TranslateService
  ) {
    let loginId = this.authService.getResellerLoginId();
    this.resellerLoginId = loginId != null ? +this.authService.getResellerLoginId() : null;
    this.authService.setZYSupportChatFeature();
  }

  ngOnInit(): void {
    // Check of the reseller is configured to be authenticated through Keycloak, redirect the user to the email validation page
    const authenticateUsingKeycloakOIDC = this.authService.getAuthenticateUsingKeycloakOIDC();
    if (authenticateUsingKeycloakOIDC) return this.authService.redirectToEmailValidation();

    this.getBrandInformation();
    this.loginService.clearData();
    this.setBackgroundColor();
    this.navigationService.setBlnDisplayBreadCrumb(false);
    this.apiStatusSubscription = this.utilService.isAPIAlive().subscribe(
      (res) => {
        // called to trigger the  updated version update
      },
      (err) => {
        console.log(err);
      }
    );
  }

  async navigateToLogIn() {
    const invalidTime = await this.authService.checkClientAndServerTimeDiff();
    if (invalidTime) {
      return;
    }
    if (this.accountName.includes('@')) {
      this.matDialog.open(InformativeDialogComponent, {
        data: { title: this.translateService.instant('at_warning.title'), message: this.translateService.instant('at_warning.message') },
        width: '600px',
        autoFocus: false
      });
      return;
    }
    if (this.accountName && this.accountName !== '') {
      forkJoin([
        //First we should check if the Account Name or Account Code already exists in our DB
        this.loginService.validateAccount(this.accountName, this.resellerLoginId).pipe(
          map((result) => {
            if (result.accountIsValid) {
              this.authService.setResellerName(result.resellerTenantName);
              if (this.authService.shouldOpenLoginWarningDialog()) {
                this.authService.openLoginWarningDialog();
                return;
              }

              this.accountName = result.accountName;
              this.authService.setResellerName(result.resellerTenantName);
              this.authService.setTenantName(this.accountName);
              this.authService.redirectToLogin(this.accountName);
            }
            return result;
          }),
          catchError((e) => {
            return of(e);
          })
        )
      ]).subscribe();
    }
  }

  getBrandInformation() {
    this.brandImagesAssets = this.themeService.getbrandImagesAssets();
  }

  setBackgroundColor() {
    this.themeService.setBackgroundColor();
  }
  unsetBackgroundColor() {
    this.themeService.unsetBackgroundColor();
  }
  ngOnDestroy() {
    this.unsetBackgroundColor();
    this.navigationService.setBlnDisplayBreadCrumb(true);
    if (this.apiStatusSubscription) this.apiStatusSubscription.unsubscribe();
  }
  displayFn(tenant: Tenant) {
    if (tenant) {
      return tenant.name;
    }
  }
}
