import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/auth/auth.service';
import { addPaginationParametersToUrl, appendQueryParamIfDefined } from '../../../shared/helpers/app.helpers';
import { AccountInfo } from '../../../shared/models/account-info.model';
import { Account, AccountModel } from '../../../shared/models/account.model';
import { APIResponseModel } from '../../../shared/models/api-response';
import { BrandModel, BrandRecordsModel, BrandSettingsModel } from '../../../shared/models/brand-model';
import { Module } from '../../../shared/models/module.model';
import { NewTenant } from '../../../shared/models/new-tenant.model';
import { NewUser } from '../../../shared/models/new-user.model';
import { PaginationResponse } from '../../../shared/models/pagination-response.model';
import { PermissionModule, RoleTypeModel } from '../../../shared/models/role-type.model';
import { Role } from '../../../shared/models/role.model';
import { ApiService } from '../../../shared/services/api/api.service';
import { LoaderService } from '../../../shared/services/loader.service';
import { UserProfile } from '../../profile-manager/models/user-profile.model';
import { AccountUpdate } from '../models/account-update.model';
import { BrandUpdate } from '../models/brand-update.model';
import { DeviceFeature } from '../models/device-feature.model';
import { User } from './../../../shared/models/user.model';
import { TenantSetting } from './../../../shared/models/tenant-setting.model';
import { NutritionFactsService } from '../../nutrition-facts/services/nutritional-facts.service';
import { SubscriptionModel } from '../models/subscription.model';
import { APICconfig, APIType, APITypeEnum } from '../models/api-config';
import { AccountAdditionalConfig } from '../models/account-additional-config.model';
import { EshaLogEntry, EshaLogItem } from '../models/esha-log-entry.model';
import { MatDialog } from '@angular/material/dialog';
import { EventInfoDialogComponent } from '../components/dialogs/event-info-dialog/event-info-dialog.component';
import { BrandAddModel } from '../models/brand.model';
import { AccountUpdateFlags } from '../models/account-update-flags.model';
import { TenantModule } from '../models/tenant-module-option.model';
import { SetPasswordModel } from '../models/set-password.model';
import { SortDirection } from '@angular/material/sort';
import { LocationSubscriptionModel } from '../models/location-subscription.model';
import { NavigationSection } from '../../../shared/models/navigation-section.model';
import { ResellerTenantType } from '../models/reseller-tenant-type.model';
import { RFIDConfig, RFIDInterfaceType } from '../models/rfid-config';
import { ADFLogEntry } from '../models/adf-log-entry.model';
import { PathfinderSettings } from '../models/pathfinder-settings.model';
import { DateFormatService } from '../../../shared/services/date-format.service';

@Injectable({
  providedIn: 'root'
})
export class AccountManagerService {
  baseServiceUrl = `${environment.baseAPIUrl}${environment.version}/accounts/`;
  get v1Accounts(): string {
    return `${environment.baseAPIUrl}${environment.version}/accounts/${this.accountId}/`;
  }
  get v2Accounts(): string {
    return `${environment.baseAPIUrl}${environment.version2}/accounts/${this.accountId}/`;
  }
  get accountId(): number {
    return this.authService.getCurrentUserAccountId();
  }
  public onBrandcreationSucess: Subject<boolean> = new Subject<boolean>();
  public userCreationSuccess: Subject<boolean> = new Subject<boolean>();
  public accountCreationSuccess: Subject<boolean> = new Subject<boolean>();
  public roleCreationsuccess: Subject<boolean> = new Subject<boolean>();
  public currentPath: Subject<string> = new Subject();
  public addButtonDisabled: Subject<boolean> = new Subject<boolean>();
  public getChildAccounts: Subject<number> = new Subject<number>();
  public noResultsFound: Subject<string> = new Subject<string>();
  private brandSettings: BrandSettingsModel;

  constructor(
    private apiService: ApiService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private nfs: NutritionFactsService,
    private dialog: MatDialog,
    private dateFormatService: DateFormatService
  ) {}

  getEshaLog(start: number, limit: number, from: string, to: string, searchValue: string, sortBy?: string, sortDirection?: string, filterByUpdate?: boolean): Observable<PaginationResponse<EshaLogEntry[]>> {
    let url = `${this.v1Accounts}EshaLogs`;
    url = addPaginationParametersToUrl(url, start, limit, searchValue);
    url = appendQueryParamIfDefined(url, 'fromDate', from);
    url = appendQueryParamIfDefined(url, 'toDate', to);
    url = appendQueryParamIfDefined(url, 'olsonTimeZone', this.dateFormatService.getTimeZoneArea());
    url = appendQueryParamIfDefined(url, 'sortBy', sortBy);
    url = appendQueryParamIfDefined(url, 'sortDirection', sortDirection);
    if (filterByUpdate === true) url = appendQueryParamIfDefined(url, 'filterBy', 'updated');
    return this.apiService.getRequest(url).pipe(map((res) => res.results));
  }
  getEshLogsItems(id): Observable<PaginationResponse<EshaLogItem>> {
    const url = `${this.v1Accounts}EshaLogs/${id}/items`;
    return this.apiService.getRequest(url).pipe(map((res) => res.results));
  }

  getAccountsLocationSubscriptions(
    accountId: number,
    start?: number,
    limit?: number,
    searchKeyword?: string,
    sortBy: string = 'publisherLocationName',
    sortDirection: string = 'asc'
  ): Observable<PaginationResponse<LocationSubscriptionModel[]>> {
    let url = this.baseServiceUrl + `${accountId}/location-subscriptions`;
    url = addPaginationParametersToUrl(url, start, limit, searchKeyword);
    url = appendQueryParamIfDefined(url, 'sortBy', sortBy);
    url = appendQueryParamIfDefined(url, 'sortDirection', sortDirection);

    return this.apiService.getRequest(url).pipe(map((res) => res.results));
  }

  requestFullSync(typeId: APITypeEnum): Observable<PaginationResponse<EshaLogEntry[]>> {
    let url = `${this.v1Accounts}fullSync?interfaceTypeId=${typeId}`;
    //url += typeId == APITypeEnum.ESHA || typeId == APITypeEnum.WEBTRITION ? 'EshaFullSync' : 'HswsSync';
    return this.apiService.getRequest(url).pipe(map((res) => res.results));
  }

  onUserCreationSuccess() {
    this.userCreationSuccess.next(true);
  }

  getUserInfo(userId: number, accountId: number): Observable<UserProfile> {
    ///accounts/{accountId}/users/{userid}/profile
    let url = this.baseServiceUrl + `${accountId}/users/${userId}/profile`;

    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  getUsersByAccountId(accountId: number, start?: number, limit?: number, locationId?: number, searchKeyword?: string, filter?: string): Observable<PaginationResponse<User[]>> {
    let url = this.baseServiceUrl + `${accountId}/users`;
    if (locationId) {
      url += `?locationId=${locationId}`;
    }
    if (filter) {
      if (locationId) {
        url += '&';
      } else {
        url += '?';
      }
      url += `filter=${filter}`;
    }

    url = addPaginationParametersToUrl(url, start, limit, searchKeyword);
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  getAccountFlagStatus(tenantId: number): Observable<AccountUpdateFlags[]> {
    let url = `${environment.baseAPIUrl}${environment.version}/resellers/${this.accountId}/accounts/${tenantId}/flagstatus`;
    return this.apiService.getRequest<AccountUpdateFlags[]>(url).pipe(map((response) => response.results));
  }
  getSubscriptionsByAccountId(accountId: number, brandId: number, typeId?: number, start?: number, limit?: number, searchKeyword?: string): Observable<PaginationResponse<SubscriptionModel[]>> {
    let url = this.baseServiceUrl + `${accountId}/subscriptions`;
    if (brandId) {
      url += `?BrandId=${brandId}`;
    }

    if (typeId) {
      url += (brandId ? '&' : '?') + `TypeId=${typeId}`;
    }
    url = addPaginationParametersToUrl(url, start, limit, searchKeyword);
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  updateSubscriptionStatus(accountId: number, subscriptionId: number, subscriberAccountId: number, subscriptionEntityId: number, susbcriptionTypeId: number, statusId: number) {
    const url = this.baseServiceUrl + `${accountId}/subscriptions/${subscriptionId}`;
    const jsonBody = { subscriptionId: subscriptionId, subscriberAccountId: subscriberAccountId, subscriptionEntityId: subscriptionEntityId, susbcriptionTypeId: susbcriptionTypeId, statusId: statusId };
    return this.apiService.putRequest(url, jsonBody).pipe(map((response) => response));
  }

  approveLocationSubscription(accountId: number, locationId: number, subscriptionId: number) {
    const url = this.baseServiceUrl + `${accountId}/approve-location-subscription`;
    const jsonBody = {
      accountId: accountId,
      locationId: locationId,
      subscriptionId: subscriptionId
    };

    return this.apiService.putRequest(url, jsonBody).pipe(map((response) => response));
  }

  rejectLocationSubscription(accountId: number, locationId: number, subscriptionId: number) {
    const url = this.baseServiceUrl + `${accountId}/reject-location-subscription`;
    const jsonBody = {
      accountId: accountId,
      locationId: locationId,
      subscriptionId: subscriptionId
    };

    return this.apiService.putRequest(url, jsonBody).pipe(map((response) => response));
  }

  getUnassignedUsersByAccountId(accountId: number, start?: number, limit?: number, searchKeyword?: string): Observable<PaginationResponse<User[]>> {
    let url = this.baseServiceUrl + `${accountId}/users/unassigned`;
    url = addPaginationParametersToUrl(url, start, limit, searchKeyword);
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  getRolesByAccountId(accountId: number, start?: number, limit?: number, searchKeyword?: string, restrictedListForResellers: boolean = false): Observable<PaginationResponse<Role[]>> {
    let url = this.baseServiceUrl + `${accountId}/roles`;
    let isForReseller: boolean = false;
    isForReseller = this.authService.getIsReseller();
    if (isForReseller) {
      if (restrictedListForResellers) url = `${url}?isForReseller=${restrictedListForResellers}`;
    } else {
      //to provide only portal roles
      // url = `${url}?isForTerminal=${false}&isForReseller=${false}`;
      url = `${url}?isForReseller=${false}`;
    }
    url = addPaginationParametersToUrl(url, start, limit, searchKeyword);
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  getAccountInfo(accountId: number, hideLoader?: boolean): Observable<AccountInfo> {
    const url = this.baseServiceUrl + `${accountId}`;
    return this.apiService.getRequest(url, hideLoader).pipe(
      map((response) => {
        this.nfs.accountHasDualPanels = response.results.hasDualNutrionalPanels;
        return response.results;
      })
    );
  }

  getNavigationSectionsAndAssignedModules(accountId: number, roleId: number): Observable<NavigationSection[]> {
    let url = `${this.baseServiceUrl}${accountId}/roles/${roleId}/sections`;
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  getRoleWithPermissionsById(accountId: number, roleId: number, unassignedperm: boolean = true): Observable<any> {
    let url = this.baseServiceUrl + `${accountId}/roles/${roleId}/permissions`;
    if (unassignedperm) {
      url = url + `?include=unassigned&&showOnlyAssignedModules=true`;
    }
    return this.apiService.getRequest(url).pipe(
      map((response: APIResponseModel<any>) => {
        return response;
      })
    );
  }

  getListOfAccounts(
    resellerId: number,
    filterOption?: string,
    start?: number,
    limit?: number,
    searchKeyword?: string,
    sortBy: string = 'name',
    sortDirection: SortDirection = 'desc'
  ): Observable<PaginationResponse<Account[]>> {
    let url = `${environment.baseAPIUrl}${environment.version}/resellers/${resellerId}/accounts`;
    if (filterOption) {
      url = `${url}?status=${filterOption}`;
    }
    url = addPaginationParametersToUrl(url, start, limit, searchKeyword);
    url = appendQueryParamIfDefined(url, 'sortBy', sortBy);
    url = appendQueryParamIfDefined(url, 'sortDirection', sortDirection);

    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  getListOfAccountsBasic(
    resellerId: number,
    start?: number,
    limit?: number,
    searchKeyword?: string,
    sortBy: string = 'name',
    sortDirection: SortDirection = 'desc',
    status?: number,
    tenantTypeId?: number
  ): Observable<PaginationResponse<AccountModel[]>> {
    let url = `${environment.baseAPIUrl}${environment.version}/resellers/${resellerId}/accounts/basic`;
    url = addPaginationParametersToUrl(url, start, limit, searchKeyword);
    url = appendQueryParamIfDefined(url, 'sortBy', sortBy);
    url = appendQueryParamIfDefined(url, 'sortDirection', sortDirection);
    url = appendQueryParamIfDefined(url, 'status', status);
    url = appendQueryParamIfDefined(url, 'tenantTypeId', tenantTypeId);

    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  addUser(accountId: number, user: NewUser) {
    let url = this.baseServiceUrl + `${accountId}/users`;
    return this.apiService.postRequest(url, user).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateUser(accountId: number, user: NewUser) {
    let url = this.baseServiceUrl + `${accountId}/users/${user.userId}`;
    return this.apiService.putRequest(url, user).pipe(map((response) => response));
  }

  updateAccountMode(accountId: number, CurrentMode: string, NextMode: string) {
    let url = this.baseServiceUrl + `${accountId}/mode`;
    const jsonBody = { CurrentMode: CurrentMode, NextMode: NextMode };
    return this.apiService.putRequest(url, jsonBody).pipe(map((response) => response));
  }

  updateUserProfile(
    accountId: number,
    userId: number,
    firstName: string,
    lastName: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    zipCode: string,
    country: string,
    countryISOCode: string,
    phoneNumberCountryCode: string,
    phoneNumber: string
  ) {
    let url = this.baseServiceUrl + `${accountId}/users/${userId}/profile`;
    const jsonBody = {
      firstName: firstName,
      lastName: lastName,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      state: state,
      zipCode: zipCode,
      country: country,
      countryISOCode: countryISOCode,
      phoneNumberCountryCode: phoneNumberCountryCode,
      phoneNumber: phoneNumber
    };
    return this.apiService.putRequest(url, jsonBody).pipe(map((response) => response));
  }

  updateDeviceFeatureOption(
    accountId: number,
    managerOnDuty: boolean,
    reprinting: boolean,
    reprintingApproval: boolean,
    allowUnregisteringDevice: boolean,
    allowPrintingDialog: boolean,
    showIngredients: boolean,
    priceOptionsSelection: boolean,
    presetsConfiguration: boolean,
    itemDisplayOptionId?: number,
    minimumFontSize?: number,
    size?: number,
    userSessionTimeoutMinutes?: number,
    requirePinForUsers?: boolean,
    collapseCategoriesInLabeling?: boolean,
    collapseCategoriesInMedia?: boolean,
    haccpReportStartDay?: number,
    printServerPort?: number,
    userManagement?: number,
    unitsOfMeasureId?: number,
    syncPrinterSettings?: boolean,
    syncPrinterSettingsParams?: number[],
    clearSearchAfterPrint?: boolean,
    timersPerRow?: number,
    epcValidation?: boolean,
    autoDisplayPrinterDialog?: boolean,
    selectedPrinters?: number[]
  ) {
    let url = this.baseServiceUrl + `${accountId}/DeviceFeatures`;
    const deviceFeature = new DeviceFeature(
      managerOnDuty,
      reprinting,
      reprintingApproval,
      allowUnregisteringDevice,
      allowPrintingDialog,
      showIngredients,
      priceOptionsSelection,
      presetsConfiguration,
      itemDisplayOptionId,
      itemDisplayOptionId == 3 ? null : minimumFontSize,
      itemDisplayOptionId == 3 ? size : null,
      userSessionTimeoutMinutes,
      requirePinForUsers,
      collapseCategoriesInLabeling,
      collapseCategoriesInMedia,
      haccpReportStartDay,
      printServerPort,
      userManagement,
      unitsOfMeasureId,
      syncPrinterSettings,
      syncPrinterSettingsParams,
      clearSearchAfterPrint,
      timersPerRow,
      epcValidation,
      autoDisplayPrinterDialog,
      selectedPrinters
    );
    return this.apiService.putRequest(url, deviceFeature).pipe(map((response) => response));
  }
  updatePathfinderSettings(
    accountId: number,
    contrast: number,
    speed: number,
    supplyPosition: number,
    gs1: boolean,
    code128: boolean,
    code128Length1: number,
    code128Length2: number,
    code39: boolean,
    code39Length1: number,
    code39Length2: number,
    l2of5: boolean,
    l2of5Length1: number,
    l2of5Length2: number,
    upc_ean: boolean,
    qrCode: boolean
  ) {
    let url = this.baseServiceUrl + `${accountId}/PathfinderSettings`;
    const pathfinderSetting = new PathfinderSettings(
      contrast,
      speed,
      supplyPosition,
      gs1,
      code128,
      code128Length1,
      code128Length2,
      code39,
      code39Length1,
      code39Length2,
      l2of5,
      l2of5Length1,
      l2of5Length2,
      upc_ean,
      qrCode
    );
    return this.apiService.putRequest(url, pathfinderSetting).pipe(map((response) => response));
  }

  getBrandsByAccountId(accountId: number, start?: number, limit?: number, searchKeyword?: string): Observable<PaginationResponse<BrandRecordsModel>> {
    let url = this.baseServiceUrl + `${accountId}/brands`;
    url = addPaginationParametersToUrl(url, start, limit, searchKeyword);
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  getAllBrands(): Observable<BrandModel[]> {
    let url = `${environment.baseAPIUrl}${environment.version}/brands`;
    return this.apiService.getRequest(url).pipe(
      map((response: APIResponseModel<BrandModel[]>) => {
        return response.results;
      })
    );
  }

  getBrandsAvailableForAccount(accountId: number): Observable<BrandModel[]> {
    let url = `${environment.baseAPIUrl}${environment.version}/brands?accountId=${accountId}&check_by=accountId`;
    return this.apiService.getRequest(url).pipe(
      map((response: APIResponseModel<BrandModel[]>) => {
        return response.results;
      })
    );
  }

  assignBrandToAccount(accountId: number, brandId: number, franchiseId: string, isPublisher: boolean): Observable<any> {
    const jsonBody = { franchiseId: franchiseId, isPublisher: isPublisher };
    const url = this.baseServiceUrl + `${accountId}/brands/${brandId}`;
    return this.apiService.putRequest(url, jsonBody).pipe(map((response) => response.results));
  }

  updatePassword(accountId: number, userId: number, password: string, oldPassword: string): Observable<any> {
    const jsonBody = { password: password, oldPassword: oldPassword };
    const url = this.baseServiceUrl + `${accountId}/users/${userId}/password`;
    return this.apiService.putRequest(url, jsonBody).pipe(map((response) => response.results));
  }

  setPassword(accountId: number, userPassword: SetPasswordModel): Observable<any> {
    const url = this.baseServiceUrl + `${accountId}/users/password/set`;
    return this.apiService.putRequest(url, userPassword).pipe(map((response) => response.results));
  }

  unassignBrandFromAccount(accountId: number, brandId: number): Observable<any> {
    const url = this.baseServiceUrl + `${accountId}/brands/${brandId}`;
    return this.apiService.deleteRequest(url).pipe(map((response) => response.results));
  }

  onBrandCreationSuccess() {
    this.onBrandcreationSucess.next(true);
  }

  getBrandSettings() {
    return this.brandSettings;
  }

  setBrandSettings(brandSettings: BrandSettingsModel) {
    this.brandSettings = brandSettings;
  }

  getResellerBrands(resellerAccountId: number, accountId: number, start?: number, limit?: number, searchKeyword?: string): Observable<PaginationResponse<BrandRecordsModel>> {
    let url = `${environment.baseAPIUrl}${environment.version}/resellers/${resellerAccountId}/accounts/${accountId}/brands`;
    url = addPaginationParametersToUrl(url, start, limit, searchKeyword);
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  addResellerBrand(accountId: number, brandAddModel: BrandAddModel, imageRecognitionLabelFile?: File) {
    let brandFormData = this.buildBrandFormData(brandAddModel, null, imageRecognitionLabelFile);
    let url = `${environment.baseAPIUrl}${environment.version}/resellers/${accountId}/accounts/${accountId}/brands`;
    return this.apiService.postRequest(url, brandFormData).pipe(map((response) => response.results));
  }

  updateBrandDetails(brandId: number, accountId: number, brandUpdateObject: BrandUpdate, imageRecognitionLabelFile?: File): Observable<any> {
    let brandFormData = this.buildBrandFormData(null, brandUpdateObject, imageRecognitionLabelFile);
    let url = `${environment.baseAPIUrl}${environment.version}/resellers/${accountId}/accounts/${accountId}/brands/${brandId}`;
    return this.apiService.putRequest(url, brandFormData).pipe(map((response) => response.results));
  }

  updateAccount(resellerId: number, accountUpdate: AccountUpdate): any {
    let url = `${environment.baseAPIUrl}${environment.version}/resellers/${resellerId}/accounts/${accountUpdate.accountId}`;
    return this.apiService.putRequest(url, accountUpdate).pipe(map((response) => response.results));
  }

  addAccount(resellerId: number, newTenant: NewTenant): Observable<any> {
    let url = `${environment.baseAPIUrl}${environment.version}/resellers/${resellerId}/accounts`;
    return this.apiService.postRequest(url, newTenant).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updateAccountCustExtId(resellerId: number, accountId: number, custExtId: string): any {
    let url = `${environment.baseAPIUrl}${environment.version}/resellers/${resellerId}/accounts/${accountId}/customerExtId`;
    return this.apiService.putRequest(url, { custExtId: custExtId }).pipe(map((response) => response.results));
  }

  updateAccountDeviceTokensExpiry(resellerId: number, accountId: number, setting: TenantSetting): any {
    let url = `${environment.baseAPIUrl}${environment.version}/resellers/${resellerId}/accounts/${accountId}/tokens`;
    return this.apiService.putRequest(url, setting).pipe(map((response) => response.results));
  }

  updateUserPassword(accountId: number, userId: number, password: { password: string }) {
    const url = `${this.baseServiceUrl}${accountId}/users/${userId}/password/reset`;
    return this.apiService.putRequest(url, password).pipe(map((response) => response.results));
  }

  uploadTemplateFile(accountId: number, file: any, locationId?: number): Observable<unknown> {
    let url = `${this.baseServiceUrl}${accountId}/bulk/Users`;
    const myfile: FormData = new FormData();
    if (file !== undefined) {
      myfile.append('File', file);
    }
    if (locationId) myfile.append('locationId', locationId.toString());
    return this.apiService.patchRequest(url, myfile).pipe(map((response) => response.results));
  }

  onAccountCreationSuccess() {
    this.accountCreationSuccess.next(true);
  }

  onRoleCreationSuccess() {
    this.roleCreationsuccess.next(true);
  }

  sendActivationLink(accountId: number, users: number[]) {
    const url = `${this.baseServiceUrl}${accountId}/Users/sendActivationLink`;
    return this.apiService.postRequest(url, users).pipe(map((response) => response.results));
  }

  createRole(accountId: number, role: RoleTypeModel) {
    const url = `${this.baseServiceUrl}${accountId}/roles`;
    return this.apiService.postRequest(url, role).pipe(map((response) => response.results));
  }

  updateRole(accountId: number, role: RoleTypeModel) {
    const url = `${this.baseServiceUrl}${accountId}/roles/${role.roleId}`;
    return this.apiService.putRequest(url, role).pipe(map((response) => response.results));
  }

  updateRolePermission(accountId: number, roleId: number, permisionConfigured: PermissionModule) {
    const url = `${this.baseServiceUrl}${accountId}/roles/${roleId}/modulepermissions`;
    return this.apiService.patchRequest(url, permisionConfigured).pipe(map((response) => response.results));
  }

  getModules(accountId: number): Observable<Module[]> {
    const url = `${this.baseServiceUrl}${accountId}/modulepermissions`;
    return this.apiService.getRequest(url, false).pipe(map((response) => response.results));
  }

  changeUserPassword(password: string, confirmPassword: string, token: string) {
    const url = `${environment.baseAPIUrl}/${environment.version}/auth/changepassword`;
    const body = { password: password, confirmPassword: confirmPassword, token: token };

    return this.apiService.postRequest(url, body, true).pipe(
      map((response: APIResponseModel<any>) => {
        return response;
      })
    );
  }

  triggerDIS(accountId: number) {
    const url = `${environment.baseAPIUrl}/${environment.version}/accounts/${accountId}/dis`;
    return this.apiService.postRequest(url, null).pipe(
      map((response: APIResponseModel<any>) => {
        return response;
      })
    );
  }

  bulkDeactivateUsers(accountId: number, users) {
    const url = `${this.baseServiceUrl}${accountId}/Users/bulkDeactivate`;
    return this.apiService.patchRequest(url, users).pipe(map((response) => response.results));
  }
  bulkResetPassword(accountId: number, users, accountName: string, resellerLoginId?: number) {
    const body = { UserIds: users, AccountName: accountName };
    let url = `${this.baseServiceUrl}${accountId}/Users/forgotpassword`;
    if (resellerLoginId != null) {
      url += `?resellerLoginId=${resellerLoginId}`;
    }
    return this.apiService.postRequest(url, body).pipe(map((response) => response.results));
  }

  exportUser(accountId: number, locationId: number) {
    let fileName = `users.csv`;
    let url = `${this.baseServiceUrl}${accountId}/users/export?locationId=${locationId}`;

    return this.apiService.getRequest(url, true, false, true).subscribe((res) => {
      const blob = new Blob(['\ufeff', <any>res], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = fileName;
      a.href = downloadUrl;
      a.click();
    });
  }

  manageUserLocation(accountId: number, locationId: number, file: any): Observable<unknown> {
    let url = `${this.baseServiceUrl}${accountId}/users/manageUserLocation?locationId=${locationId}`;
    const myfile: FormData = new FormData();
    if (file !== undefined) {
      myfile.append('File', file);
    }
    return this.apiService.patchRequest(url, myfile).pipe(map((response) => response.results));
  }

  getAPIConfig(accountId: number, resellerId?: number): Observable<APICconfig> {
    let url = resellerId ? `${environment.baseAPIUrl}${environment.version}/resellers/${resellerId}/accounts/` : this.baseServiceUrl;
    url += `${accountId}/api-config`;
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  updateAPIConfig(resellerId: number, accountId: number, config: APICconfig): any {
    let url = `${environment.baseAPIUrl}${environment.version}/resellers/${resellerId}/accounts/${accountId}/api-config`;
    var data = {
      typeId: config.typeId,
      baseURL: config.baseURL,
      clientId: config.clientId,
      unitIds: config.unitIds,
      username: config.username,
      password: config.password,
      grantType: config.grantType,
      groupNames: config.groupNames,
      brandId: config.brandId,
      parentNodeId: config.parentNodeId,
      sid: config.sid
    };
    return this.apiService.putRequest(url, data).pipe(map((response) => response.results));
  }

  getRFIDConfig(accountId: number, resellerId?: number, typeId?: number): Observable<RFIDConfig> {
    let url = resellerId ? `${environment.baseAPIUrl}${environment.version}/resellers/${resellerId}/accounts/` : this.baseServiceUrl;
    url += `${accountId}/rfid-config`;
    if (typeId) {
      url += `/${typeId}`;
    }
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  updateRFIDConfig(resellerId: number, accountId: number, config: RFIDConfig): any {
    let url = `${environment.baseAPIUrl}${environment.version}/resellers/${resellerId}/accounts/${accountId}/rfid-config`;
    var data = {
      organizationName: config.organizationName,
      subscriptionKey: config.subscriptionKey,
      typeId: config.typeId,
      tenant: config.tenant,
      tenantTypeId: config.tenantTypeId,
      url: config.url,
      autoSyncLocations: config.autoSyncLocations,
      enableAutoSyncForMenuItems: config.enableAutoSyncForMenuItems,
      atmaFieldMap: config.atmaFieldMap
    };
    return this.apiService.putRequest(url, data).pipe(map((response) => response.results));
  }

  getRFIDInterfaceTypes(accountId: number, resellerId?: number): Observable<RFIDInterfaceType[]> {
    let url = `${environment.baseAPIUrl}${environment.version}/resellers/${resellerId}/accounts/${accountId}/rfid-interfacetypes`;
    return this.apiService.getRequest<RFIDInterfaceType[]>(url).pipe(map((response) => response.results));
  }

  generateTerminalsInformationReport(accountId: number, timezone: number) {
    let fileName = `devices-information-report.csv`;
    let url = `${this.baseServiceUrl}${accountId}/terminals/generateInformationReport?timezone=${timezone}`;

    return this.apiService.getRequest(url, true, false, true).subscribe((res) => {
      const blob = new Blob(['\ufeff', <any>res], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = fileName;
      a.href = downloadUrl;
      a.click();
    });
  }

  exportCustomerReportByFunctionality(accountId: number, translationPayload: any) {
    let url = `${environment.baseAPIUrl}${environment.version}/resellers/${accountId}/accounts/generateAccountFeaturesReport`;

    return this.apiService.postRequest(url, translationPayload, false, true, false, true).subscribe((res) => {
      const blob = new Blob(['\ufeff', <any>res], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = translationPayload.filename;
      a.href = downloadUrl;
      a.click();
    });
  }

  uploadImage(accountId: number, type: number, file: File) {
    let imageFile: FormData = new FormData();
    imageFile.append('imageFile', file);
    let url = `${environment.baseAPIUrl}${environment.version}/resellers/${accountId}/accounts/${accountId}/brands/uploadImage?type=${type}`;

    return this.apiService.postRequest(url, imageFile).pipe(map((response) => response.results));
  }

  getTempTaskConfigurationDetails(accountId: number) {
    let url = `${environment.baseAPIUrl}${environment.version2}/accounts/${accountId}/tempconfiguration/configuration`;
    return this.apiService.getRequest(url).pipe(map((response) => response.results[0]));
  }

  getSenseConfigurationDetails(accountId: number) {
    let url = `${environment.baseAPIUrl}${environment.version2}/accounts/${accountId}/sense/configuration`;
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  saveTempTaskConfigurationDetails(
    accountId: number,
    sessions: number,
    measurements: number,
    tempUoMId: number,
    allowPerformingCorrectiveActionsLater: boolean,
    reportingInputTypes: number[],
    highlightRequiredTasks: boolean
  ) {
    let url = `${environment.baseAPIUrl}${environment.version2}/accounts/${accountId}/tempconfiguration/configuration`;
    let postData = {
      minimumNumberOfSessions: sessions,
      minimumIngredientMeasurement: measurements,
      tempUoMId,
      allowPerformingCorrectiveActionsLater,
      reportingInputTypes,
      highlightRequiredTasks
    };
    return this.apiService.postRequest(url, postData).pipe(map((response) => response.results));
  }

  updateAccountAdditionalConfig(accountId: number, additionalConfig: AccountAdditionalConfig) {
    let url = `${this.baseServiceUrl}${accountId}/AdditionalConfig`;
    return this.apiService.putRequest(url, additionalConfig).pipe(map((response) => response.results));
  }

  getTenantModuleOptions(accountId?: number, tenantTypeId?: number): Observable<TenantModule[]> {
    let url = `${environment.baseAPIUrl}${environment.version}/resellers/${this.accountId}/accounts/module-options`;
    url = appendQueryParamIfDefined(url, 'accountId', accountId);
    url = appendQueryParamIfDefined(url, 'tenantTypeId', tenantTypeId);
    return this.apiService.getRequest<TenantModule[]>(url).pipe(map((response) => response.results));
  }

  getInterfaceTypesByTenant(accountId: number): Observable<APIType[]> {
    let url = `${this.baseServiceUrl}${accountId}/interfacetypes`;
    return this.apiService.getRequest<APIType[]>(url).pipe(map((response) => response.results));
  }

  disableAddButton(disable: boolean) {
    this.addButtonDisabled.next(disable);
  }

  triggerGetShildAccounts(resellerAccountId: number) {
    this.getChildAccounts.next(resellerAccountId);
  }

  public openDialog(item, logs) {
    this.dialog.open(EventInfoDialogComponent, {
      data: {
        item: item,
        logs: logs
      }
    });
  }

  buildImageRecognitionExcelFile(accountId: number, brandId?: number) {
    let url = `${environment.baseAPIUrl}${environment.version}/resellers/${accountId}/accounts/${accountId}/brands/imagerecognition/csv`;
    url += brandId ? `?brandId=${brandId}` : '';
    return this.apiService.getRequest(url, true, false, true).pipe(map((res) => res));
  }

  buildUserExcelFile(accountId: number) {
    let url = `${this.baseServiceUrl}${accountId}/bulk/Users`;
    return this.apiService.getRequest(url, true, false, true).pipe(map((res) => res));
  }

  private buildBrandFormData(brandAddModel?: BrandAddModel, brandUpdateObject?: BrandUpdate, imageRecognitionLabelFile?: File): FormData {
    let brandFormData: FormData = new FormData();
    if (brandAddModel) {
      brandFormData.append('name', brandAddModel.name.toString());
      brandFormData.append('shortName', brandAddModel.shortName.toString());
      brandFormData.append('prefix', brandAddModel.prefix.toString());
      if (brandAddModel.logoFileName) {
        brandFormData.append('logoFileName', brandAddModel.logoFileName.toString());
      }
      if (brandAddModel.logoURL) {
        brandFormData.append('logoURL', brandAddModel.logoURL.toString());
      }
      if (brandAddModel.bannerFileName) {
        brandFormData.append('bannerFileName', brandAddModel.bannerFileName.toString());
      }
      if (brandAddModel.bannerURL) {
        brandFormData.append('bannerURL', brandAddModel.bannerURL.toString());
      }
      if (brandAddModel.mobileLogoFileName) {
        brandFormData.append('mobileLogoFileName', brandAddModel.mobileLogoFileName.toString());
      }
      if (brandAddModel.mobileLogoURL) {
        brandFormData.append('mobileLogoURL', brandAddModel.mobileLogoURL.toString());
      }
      if (brandAddModel.mobileBannerFileName) {
        brandFormData.append('mobileBannerFileName', brandAddModel.mobileBannerFileName.toString());
      }
      if (brandAddModel.mobileBannerURL) {
        brandFormData.append('mobileBannerURL', brandAddModel.mobileBannerURL.toString());
      }
      brandFormData.append('tempLogHeaderBackgroundColor', brandAddModel.tempLogHeaderBackgroundColor ? brandAddModel.tempLogHeaderBackgroundColor.toString() : '');
      brandFormData.append('tempLogHeaderTextColor', brandAddModel.tempLogHeaderTextColor ? brandAddModel.tempLogHeaderTextColor.toString() : '');
      brandFormData.append('tempLogTableBackgroundColor', brandAddModel.tempLogTableBackgroundColor ? brandAddModel.tempLogTableBackgroundColor.toString() : '');
      brandFormData.append('tempLogTableTextColor', brandAddModel.tempLogTableTextColor ? brandAddModel.tempLogTableTextColor.toString() : '');
      brandFormData.append('tempLogSectionBackgroundColor', brandAddModel.tempLogSectionBackgroundColor ? brandAddModel.tempLogSectionBackgroundColor.toString() : '');
      brandFormData.append('tempLogSectionTextColor', brandAddModel.tempLogSectionTextColor ? brandAddModel.tempLogSectionTextColor.toString() : '');
      brandFormData.append('isResellerSpecific', brandAddModel.isResellerSpecific.toString());
      brandFormData.append('assignedResellerIds', brandAddModel.assignedResellerIds.toString());
      brandFormData.append('isSupportSmallDevices', brandAddModel.isSupportSmallDevices.toString());
    } else if (brandUpdateObject) {
      if (brandUpdateObject.logoFileName) {
        brandFormData.append('logoFileName', brandUpdateObject.logoFileName.toString());
      }
      if (brandUpdateObject.logoURL) {
        brandFormData.append('logoURL', brandUpdateObject.logoURL.toString());
      }
      if (brandUpdateObject.bannerFileName) {
        brandFormData.append('bannerFileName', brandUpdateObject.bannerFileName.toString());
      }
      if (brandUpdateObject.bannerURL) {
        brandFormData.append('bannerURL', brandUpdateObject.bannerURL.toString());
      }
      if (brandUpdateObject.mobileLogoFileName) {
        brandFormData.append('mobileLogoFileName', brandUpdateObject.mobileLogoFileName.toString());
      }
      if (brandUpdateObject.mobileLogoURL) {
        brandFormData.append('mobileLogoURL', brandUpdateObject.mobileLogoURL.toString());
      }
      if (brandUpdateObject.mobileBannerFileName) {
        brandFormData.append('mobileBannerFileName', brandUpdateObject.mobileBannerFileName.toString());
      }
      if (brandUpdateObject.mobileBannerURL) {
        brandFormData.append('mobileBannerURL', brandUpdateObject.mobileBannerURL.toString());
      }
      brandFormData.append('tempLogHeaderBackgroundColor', brandUpdateObject.tempLogHeaderBackgroundColor ? brandUpdateObject.tempLogHeaderBackgroundColor.toString() : '');
      brandFormData.append('tempLogHeaderTextColor', brandUpdateObject.tempLogHeaderTextColor ? brandUpdateObject.tempLogHeaderTextColor.toString() : '');
      brandFormData.append('tempLogTableBackgroundColor', brandUpdateObject.tempLogTableBackgroundColor ? brandUpdateObject.tempLogTableBackgroundColor.toString() : '');
      brandFormData.append('tempLogTableTextColor', brandUpdateObject.tempLogTableTextColor ? brandUpdateObject.tempLogTableTextColor.toString() : '');
      brandFormData.append('tempLogSectionBackgroundColor', brandUpdateObject.tempLogSectionBackgroundColor ? brandUpdateObject.tempLogSectionBackgroundColor.toString() : '');
      brandFormData.append('tempLogSectionTextColor', brandUpdateObject.tempLogSectionTextColor ? brandUpdateObject.tempLogSectionTextColor.toString() : '');
      brandFormData.append('newAssignedResellerIds', brandUpdateObject.newAssignedResellerIds.join().toString());
      brandFormData.append('isSupportSmallDevices', brandUpdateObject.isSupportSmallDevices.toString());
    }
    if (imageRecognitionLabelFile) brandFormData.append('File', imageRecognitionLabelFile);
    return brandFormData;
  }

  getUserModules(accountId: number, tokenType?: number, userId?: number): Observable<any> {
    if (accountId) {
      let url = `${this.baseServiceUrl}${accountId}/Users/rolesAndPermissions?loginType=${tokenType ? tokenType : 1}`;
      if (userId) {
        url += `&userId=${userId}`;
      }
      return this.apiService.getRequest(url).pipe(
        map((response: any) => {
          // TODO: Update all occurences where module permissions peroperties are parsed as tiel case ex. Name, Permission.. to be parsed as camel case similar to all API calls
          return response.results.modules.map((module) => ({ Name: module.name, Permissions: module.permissions, IsVisible: module.isVisible }));
        })
      );
    }
  }

  getTenantTypesByReseller(resellerAccountId: number): Observable<ResellerTenantType[]> {
    let url = `${environment.baseAPIUrl}${environment.version}/reseller/${resellerAccountId}/tenantTypes`;
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  uploadDCLogo(resellerAccountId: number, accountId: number, file: File) {
    const imageFile: FormData = new FormData();
    imageFile.append('imageFile', file);
    const url = `${environment.baseAPIUrl}${environment.version}/resellers/${resellerAccountId}/accounts/${accountId}/Logo`;
    return this.apiService.postRequest(url, imageFile).pipe(map((response) => response.results));
  }

  getADFLog(locationId: number, start: number, limit: number, from: string, to: string, searchValue: string, sortBy?: string, sortDirection?: string): Observable<PaginationResponse<ADFLogEntry[]>> {
    let url = `${this.v2Accounts}catalog/adfevent/logs`;
    url = addPaginationParametersToUrl(url, start, limit, searchValue);
    url = appendQueryParamIfDefined(url, 'locationId', locationId);
    url = appendQueryParamIfDefined(url, 'fromDate', from);
    url = appendQueryParamIfDefined(url, 'toDate', to);
    url = appendQueryParamIfDefined(url, 'sortBy', sortBy);
    url = appendQueryParamIfDefined(url, 'sortDirection', sortDirection);
    return this.apiService.getRequest(url).pipe(map((res) => res.results));
  }
}
