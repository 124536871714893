import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { APIHttpResponseCode, APIResponseModel } from '../../shared/models/api-response';
import { AuthService } from '../auth/auth.service';
import { ErrorHandlingService } from '../services/error-handling.service';

@Injectable()
export class HttpAPIInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private errorHandlingService: ErrorHandlingService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!req.url.includes('.svg')) {
      req = req.clone({
        headers: req.headers.set('Accept', 'application/json')
      });
    } else {
      req = req.clone({
        headers: req.headers.set('Accept', 'image/svg+xml')
      });
    }

    const authToken = this.authService.getAuthToken();
    if (authToken) {
      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + authToken)
      });
    }

    return next.handle(req).pipe(
      map((response: HttpResponse<any>) => {
        if (response && response.body && response.body.statusCode) {
          if (!this.isSuccessResponse(response.body)) {
            throw response.body;
          }
        }
        return response;
      }),
      catchError((err: any) => {
        if (req.url.toLowerCase().endsWith('login')) {
          return observableThrowError(err);
        }
        return this.errorHandlingService.handleError(err, req.url, req.method, req.body?.hideWarningPopup);
      })
    );
  }

  isSuccessResponse(response: APIResponseModel<any>): boolean {
    if (
      (response.statusCode == APIHttpResponseCode.ok || response.statusCode == APIHttpResponseCode.created || response.statusCode == APIHttpResponseCode.accepted) &&
      (!response.results || !response.results.code || !response.results.message)
    )
      return true;
    return false;
  }
}
