import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlavoredMessageComponent } from './flavored-message/flavored-message.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [FlavoredMessageComponent],
  imports: [CommonModule, MatIconModule],
  exports: [FlavoredMessageComponent]
})
export class FlavoredMessageModule {}
