<mat-sidenav-container id="main-container" style="height: 100%; position: initial" [class.sidenav-tree-available]="visible">
  <mat-sidenav
    [mode]="mainSidenavMode"
    [opened]="false"
    style="border: 0px; overflow: visible; display: block !important"
    #mainSidenav
    class="visible menu"
    (mouseenter)="openDrawerAfterADelay()"
    (mouseleave)="closeDrawer()"
    (closedStart)="closeDrawer()"
  >
    <div joyrideStep="sidenav" [stepContent]="sidenavContent" class="sidenav sidenav-light" #sideNav>
      <ng-template #sidenavContent>
        <h2>{{ 'help.dashboard.sidenav.title' | translate }}</h2>
        <div class="joyride-content-wrapper">
          {{ 'help.dashboard.sidenav.text' | translate }}
        </div>
      </ng-template>
      <mat-toolbar class="sidenav-light m-0 p-0">
        <div class="app-branding m-0 p-0">
          <div class="logo-center">
            <img [src]="brandImagesAssets + (isDrawerOpen ? 'SmallLogoIcon.svg' : 'MainSideNavIcon.svg')" class="app-logo" />
          </div>
          <img [hidden]="!isDrawerOpen" src="{{ brandImagesAssets }}FullLogoWhite.svg" class="app-logo-text" />
        </div>
      </mat-toolbar>

      <ng-container [ngTemplateOutlet]="impersonationTemplate" [ngTemplateOutletContext]="{ $implicit: false }"></ng-container>
      <mat-list class="list-menu">
        <div
          class="mat-list-item-container"
          *ngFor="let section of sections; trackBy: sectionTrackFn"
          [class.active]="(!section.hasSubSections && sectionActive(section.mainSection.link)) || (section.hasSubSections && sectionActive(section.subSections[0].link))"
        >
          <a id="{{ section.mainSection.title }}" class="sidenav-item menu-list-item menu-list-item-hyperlink" mat-list-item *ngIf="!section.hasSubSections" [routerLink]="section.mainSection.link">
            <img class="menu-item-logo" *ngIf="section.icon" [src]="brandImagesAssets + section.icon" />
            <span *ngIf="isDrawerOpen" class="menuTitle">{{ section.mainSection.title | translate | titlecase }}</span>
          </a>
          <div *ngIf="section.hasSubSections">
            <div class="menu-flex">
              <div class="menu-list-item pt-2">
                <img class="menu-item-logo" *ngIf="section.icon" [src]="brandImagesAssets + section.icon" />
              </div>
              <div class="table-cell">
                <mat-expansion-panel class="exp-panel" [expanded]="section.isToggled" (opened)="onSectionOpen(section)" (closed)="closeSection(section)">
                  <mat-expansion-panel-header class="panel-expansion-icon" [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                    <mat-panel-title id="{{ section.mainSection.title }}">
                      <span class="exp-panel-title" *ngIf="isDrawerOpen">{{ section.mainSection.title | translate | titlecase }}</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-nav-list>
                    <a
                      id="{{ subSection.title }}"
                      mat-list-item
                      class="subsectionTitle"
                      *ngFor="let subSection of section.subSections; trackBy: subsectionTrackFn"
                      [routerLink]="subSection.link"
                      [routerLinkActive]="'active'"
                      (click)="onSubSectionClicked(subSection)"
                    >
                      {{ subSection.noTitleCase ? (subSection.title | translate) : (subSection.title | translate | titlecase) }}
                    </a>
                  </mat-nav-list>
                </mat-expansion-panel>
              </div>
            </div>
          </div>
        </div>
      </mat-list>

      <mat-list class="list-menu"> </mat-list>
    </div>
  </mat-sidenav>
  <mat-sidenav class="right-side-navigator" [disableClose]="true" position="end" mode="over" [(opened)]="rightSideNavigatorOpened">
    <ng-template #rightSideNavigator></ng-template>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-sidenav-container style="height: 100%; position: initial">
      <mat-sidenav
        #mainTreeSidenav
        [hidden]="!visible"
        [mode]="treeSidenavMode"
        [opened]="treeSidenavMode == 'side' ? true : false"
        style="left: 50px; border: 0px"
        [attr.role]="'navigation'"
        joyrideStep="tree"
        stepPosition="right"
        [stepContent]="treeContent"
        [disableClose]="treeSidenavCloseDisabled"
        class="sidenav-section"
      >
        <div class="resize-handle" (mousedown)="startResizing($event)"></div>
        <ng-template #treeContent>
          <ng-container [ngSwitch]="currentTreeType">
            <ng-container *ngSwitchCase="treeTypeEnum.Location">
              <h2>{{ 'help.location-manager.tree.title' | translate }}</h2>
              <div class="joyride-content-wrapper">
                {{ 'help.location-manager.tree.text' | translate }}
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="treeTypeEnum.MenuItemCategory">
              <h2>{{ 'help.menu-manager.menu-items.tree.title' | translate }}</h2>
              <div class="joyride-content-wrapper">
                {{ 'help.menu-manager.menu-items.tree.text' | translate }}
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="treeTypeEnum.ProductCategory">
              <h2>{{ 'help.menu-manager.products.tree.title' | translate }}</h2>
              <div class="joyride-content-wrapper">
                {{ 'help.menu-manager.products.tree.text' | translate }}
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="treeTypeEnum.IngredientCategory">
              <h2>{{ 'help.menu-manager.ingredients.tree.title' | translate }}</h2>
              <div class="joyride-content-wrapper">
                {{ 'help.menu-manager.ingredients.tree.text' | translate }}
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="treeTypeEnum.MediaCategory">
              <h2>{{ 'help.media-manager.tree.title' | translate }}</h2>
              <div class="joyride-content-wrapper">
                {{ 'help.media-manager.tree.text' | translate }}
              </div>
            </ng-container>
          </ng-container>
        </ng-template>
        <nome-tree
          [lazyLoad]="lazyLoadTheTree"
          (nodeExpandedAndRequestingItsChildren)="getNodeChildren($event)"
          (search)="searchInTree($event)"
          [treetitle]="treeTitle"
          [treeSubtitle]="treeSubtitle"
          [hidden]="!visible"
          [treeCmp]="treeComponent"
          (nodeSelected)="treeNodeSelected($event)"
          [treeItems]="dataTreeItems"
          [isPopulatedInMenuItem]="isPopulatedInMenuItem"
          [currentTreeType]="currentTreeType"
        ></nome-tree>
      </mat-sidenav>
      <mat-sidenav-content id="main-page-inner-sidenav-content" [ngClass]="visible && treeSidenavMode == 'side' ? 'main-content-margin' : 'main-content-nomargin'" class="overflow-visible overflow-x-hidden">
        <div class="router-container" #container>
          <div class="row no-margin left_margin fullheight">
            <div class="col no-padding">
              <div class="row toolbar-container">
                <mat-toolbar color="primary" class="strip-style app-toolbar">
                  <div class="burger-icon-wrapper" *ngIf="!visible">
                    <img (mouseenter)="openDrawer()" class="pointer visible-tree" [src]="brandImagesAssets + 'MainToolBarLeftIcon.svg'" />
                  </div>
                  <button *ngIf="treeSidenavMode !== 'side' && visible" (click)="mainTreeSidenav.toggle()" class="hamburger" mat-flat-button>
                    <i class="fas fa-sitemap" style="font-size: 18px"></i>
                  </button>
                  <ng-container [ngTemplateOutlet]="impersonationTemplate" [ngTemplateOutletContext]="{ $implicit: true }"></ng-container>
                  <div joyrideStep="userProfile" [stepContent]="userProfileContent" [ngClass]="!(isImpersonatingAccount || isImpersonatingResellerAccount) ? 'extendWidth' : ''" class="userName-pos">
                    <ng-template #userProfileContent>
                      <h2>{{ 'help.dashboard.userProfile.title' | translate }}</h2>
                      <div class="joyride-content-wrapper">
                        {{ 'help.dashboard.userProfile.text' | translate }}
                      </div>
                    </ng-template>
                    <nome-languages-dropdown class="mr-3 inside-toolbar"></nome-languages-dropdown>
                    <a (click)="help()" class="mr-4 pointer" matTooltip="{{ 'help_tooltip' | translate }}">
                      <img class="help-image mr4" [src]="assets + 'images/Help.svg'" />
                      <!-- <mat-icon class="impersonation-icon" svgIcon="exit_impersonation" class="ml-2"></mat-icon> -->
                    </a>
                    <span id="username" class="current-user-style">{{ currentUser }}</span>
                    <mat-icon
                      matBadge="{{ authService.appRequiresUpdate ? '!' : '' }}"
                      matBadgeSize="small"
                      matBadgePosition="above after"
                      matBadgeColor="accent"
                      class="userName-icon-size pointer"
                      [matMenuTriggerFor]="LogoutBtn"
                      (click)="openMenu($event)"
                      svgIcon="user"
                    ></mat-icon>

                    <mat-menu #LogoutBtn="matMenu" yPosition="below" xPosition="after">
                      <button *ngIf="authService.appRequiresUpdate" class="context-button d-flex flex-row align-items-center txt-color-p1" mat-menu-item (click)="updateApp()">
                        <span class="ml-2">{{ 'app_update_available' | translate }}</span>
                      </button>
                      <button class="context-button d-flex flex-row align-items-center txt-color-p1" mat-menu-item (click)="navigateToProfileScreen()">
                        <img class="icon-size" [src]="assets + 'images/UserProfile.svg'" />
                        <span class="ml-2">{{ 'profile' | translate }}</span>
                      </button>
                      <button class="context-button d-flex flex-row align-items-center txt-color-p1" mat-menu-item (click)="navigateToReleaseNotes()">
                        <img class="icon-size" [src]="assets + 'images/MultipleFiles.svg'" />
                        <span class="ml-2">{{ 'release_manager.release_notes' | translate }}</span>
                      </button>
                      <!-- <button *ngIf="hasViewAccountInfoModulePermission" mat-menu-item
                        class="context-button d-flex flex-row align-items-center txt-color-p1"
                        (click)="navigateToAccountInfoScreen()" [nomeHasPermission]="modules.ACCOUNT_INFO"
                        [permission]="permissions.VIEW" [checkVisibility]="true">
                        <img class="icon-size" [src]=" assets + 'images/AccountInfo.svg' ">
                        <span class="ml-2"> {{'accountInfo' | translate }} </span>
                      </button> -->
                      <button *ngIf="isImpersonatingAccount" mat-menu-item class="context-button d-flex flex-row align-items-center txt-color-p1" (click)="exitImpersonationMode()">
                        <mat-icon class="menu-item-icon">exit_to_app</mat-icon>
                        <span class="ml-2"> {{ 'return_to_reseller_view' | translate }}</span>
                      </button>

                      <button *ngIf="isImpersonatingResellerAccount" mat-menu-item class="context-button d-flex flex-row align-items-center txt-color-p1" (click)="exitResellerImpersonationMode()">
                        <mat-icon class="menu-item-icon">exit_to_app</mat-icon>
                        <span class="ml-2"> {{ 'return_to_reseller_view' | translate }}</span>
                      </button>

                      <mat-divider></mat-divider>

                      <button
                        mat-menu-item
                        [matMenuTriggerFor]="UserLink"
                        class="context-button d-flex flex-row align-items-center txt-color-p1"
                        *ngIf="!this.authService.getIsReseller() && linkedAccounts && linkedAccounts.length > 0 && !punchoutSession"
                      >
                        <img class="icon-size" [src]="assets + 'images/Refresh.svg'" />
                        <span class="ml-2"> {{ 'switch_account' | translate }}</span>
                      </button>

                      <button mat-menu-item class="context-button d-flex flex-row align-items-center txt-color-p1" (click)="logout()" *ngIf="!punchoutSession">
                        <img class="icon-size" [src]="assets + 'images/Logout.svg'" />
                        <span class="ml-2"> {{ 'logout' | translate }}</span>
                      </button>
                    </mat-menu>

                    <mat-menu #UserLink="matMenu">
                      <button *ngFor="let account of linkedAccounts" mat-menu-item (click)="switchAccount(account.userId, account.accountId)">
                        <img *ngIf="account.name == authService.getTenantName()" class="check-icon-size mr-1" [src]="assets + 'images/Check.svg'" />
                        <span [ngClass]="account.name == authService.getTenantName() ? 'ml-2' : 'ml-4'">{{ account.name }}</span>
                      </button>
                    </mat-menu>
                  </div>
                </mat-toolbar>
              </div>
              <div class="row no-margin no-margin-container overflow-visible">
                <div class="col" id="top-of-page" joyrideStep="main" [prevTemplate]="prevButton" [nextTemplate]="nextButton" [doneTemplate]="doneButton">
                  <nome-breadcrumbs [allowBootstrap]="true" class="disableFirstBreadCrumb"></nome-breadcrumbs>
                  <ng-template #prevButton>
                    <button color="accent" mat-button>{{ 'help.prev' | translate }}</button>
                  </ng-template>
                  <ng-template #nextButton>
                    <button color="accent" mat-button>{{ 'help.next' | translate }}</button>
                  </ng-template>
                  <ng-template #doneButton>
                    <button mat-flat-button color="accent" class="button-dimensions text-center w-100 rounded">{{ 'help.done' | translate }}</button>
                  </ng-template>
                  <router-outlet (deactivate)="onRouterOutletDeactivate()"></router-outlet>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #impersonationTemplate let-insideToolbar>
  <div *ngIf="isImpersonatingResellerAccount && resellerImpersonationModeData !== null" class="impersonation">
    <div class="currently-viewing">{{ 'currently_viewing' | translate }}</div>
    <div class="rest">
      <span class="impersonated-name" matTooltip="{{ resellerImpersonationModeData.accountName }}">{{ resellerImpersonationModeData.accountName }}</span>
      <a (click)="exitResellerImpersonationMode()" matTooltip="{{ 'return_to_reseller_view' | translate }}" class="pointer">
        <mat-icon class="impersonation-icon" svgIcon="exit_impersonation" class="ml-2" [ngClass]="{ 'accent-text-color': insideToolbar, 'exit-impersonation-icon-inside-sidenav': !insideToolbar }"></mat-icon>
      </a>
    </div>
  </div>
  <div *ngIf="isImpersonatingAccount && impersonationModeData !== null" class="impersonation">
    <div class="currently-viewing">{{ 'currently_viewing' | translate }}</div>
    <div class="rest">
      <span class="impersonated-name" matTooltip="{{ impersonationModeData.accountName }}">{{ impersonationModeData.accountName }}</span>
      <a (click)="exitImpersonationMode()" matTooltip="{{ 'return_to_reseller_view' | translate }}" class="pointer">
        <mat-icon class="impersonation-icon" svgIcon="exit_impersonation" class="ml-2" [ngClass]="{ 'accent-text-color': insideToolbar, 'exit-impersonation-icon-inside-sidenav': !insideToolbar }"></mat-icon>
      </a>
    </div>
  </div>
</ng-template>
