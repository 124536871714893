import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './core/main/main.component';
import { AuthGuardService } from './core/auth/auth-guard.service';
import { LoginComponent } from './core/login/login.component';
import { UnauthorizedComponent } from './core/unauthorized/unauthorized.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { OpsChecklistUnsupportedComponent } from './core/ops-checklist-unsupported/ops-checklist-unsupported.component';
import { ActivateComponent } from './core/activate/activate.component';
import { InvalidLinkComponent } from './core/invalid-link/invalid-link.component';
import { ForgotPasswordComponent } from './core/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './core/change-password/change-password.component';
import { PunchoutLoginComponent } from './core/punchout-login/punchout-login.component';
import { LoginGuard } from './core/guards/login.guard';
import { AccountSelectionComponent } from './core/login/account-selection/account-selection.component';
import { EmailValidationComponent } from './core/login/email-validation/email-validation.component';
import { DistributionCompanyResolverService } from './modules/distribution-manager/resolvers/distribution-company-resolver';
import { localGuard } from './modules/developer/local.guard';
import { maintenanceGuard } from './core/guards/maintenance.guard';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
        canActivateChild: [AuthGuardService]
      },
      {
        path: 'labeldesigner',
        loadChildren: () => import('./modules/label-designer/label-designer.module').then((m) => m.LabelDesignerModule),
        canActivateChild: [AuthGuardService]
      },
      {
        path: 'workflow',
        loadChildren: () => import('./modules/workflow/workflow.module').then((m) => m.WorkflowModule)
      },
      {
        path: 'menumanager',
        loadChildren: () => import('./modules/menu-manager/menu-manager.module').then((m) => m.MenuManagerModule),
        canActivateChild: [AuthGuardService]
      },
      {
        path: 'catalogmanager',
        loadChildren: () => import('./modules/catalog-manager/catalog-manager.module').then((m) => m.CatalogManagerModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'locationsmanager',
        loadChildren: () => import('./modules/location-manager/location-manager.module').then((m) => m.LocationManagerModule),
        canActivateChild: [AuthGuardService]
      },
      {
        path: 'accountmanager',
        loadChildren: () => import('./modules/account-manager/account-manager.module').then((m) => m.AccountManagerModule),
        canActivateChild: [AuthGuardService]
      },
      {
        path: 'nutritionfacts',
        loadChildren: () => import('./modules/nutrition-facts/nutrition-facts.module').then((m) => m.NutritionFactsModule),
        canActivateChild: [AuthGuardService]
      },
      {
        path: 'mediamanager',
        loadChildren: () => import('./modules/media-manager/media-manager.module').then((m) => m.MediaManagerModule),
        canActivateChild: [AuthGuardService]
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
        canActivateChild: [AuthGuardService]
      },
      {
        path: 'analytics',
        loadChildren: () => import('./modules/analytics/analytics.module').then((m) => m.AnalyticsModule),
        canActivateChild: [AuthGuardService]
      },
      {
        path: 'profilemanager',
        loadChildren: () => import('./modules/profile-manager/profile-manager.module').then((m) => m.ProfileManagerModule),
        canActivateChild: [AuthGuardService]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivateChild: [AuthGuardService]
      },
      {
        path: 'resellers',
        loadChildren: () => import('./modules/reseller-manager/reseller-manager.module').then((m) => m.ResellerManagerModule),
        canActivateChild: [AuthGuardService]
      },
      {
        path: 'releasemanager',
        loadChildren: () => import('./modules/release-manager/release-manager.module').then((m) => m.ReleaseManagerModule),
        canActivateChild: [AuthGuardService]
      },
      {
        path: 'logs',
        loadChildren: () => import('./modules/logs/logs.module').then((m) => m.LogsModule),
        canActivateChild: [AuthGuardService]
      },
      {
        path: 'checklist',
        loadChildren: () => import('./modules/checklist/checklist.module').then((m) => m.ChecklistModule),
        canActivateChild: [AuthGuardService]
      },
      {
        path: 'inventory-manager',
        loadChildren: () => import('./modules/inventory-manager/inventory-manager.module').then((m) => m.InventoryManagerModule),
        canActivateChild: [AuthGuardService]
      },
      {
        path: 'distributor-manager',
        loadChildren: () => import('./modules/distribution-manager/distribution-manager.routes').then((r) => r.DISTRIBUTION_MANAGER_ROUTES),
        canActivateChild: [AuthGuardService],
        resolve: { distributionCompany: DistributionCompanyResolverService }
      },
      {
        path: 'timermanager',
        loadChildren: () => import('./modules/timer-manager/timer-manager.module').then((m) => m.TimerManagerModule),
        canActivateChild: [AuthGuardService]
      },
      {
        path: 'developer/translations-manager',
        loadComponent: () => import('./modules/developer/translations-manager/translations-manager.component').then((m) => m.TranslationsManagerComponent),
        canActivate: [localGuard]
      },
      {
        path: 'hardware-manager',
        loadChildren: () => import('./modules/hardware-manager/hardware-manager.module').then((m) => m.HardwareManagerModule),
        canActivateChild: [AuthGuardService]
      }
    ],
    runGuardsAndResolvers: 'always'
    /*
      //we have pages in progress without module and need to present them with no guard
       canActivate: [AuthGuardService],
       canActivateChild: [AuthGuardService]
      */
  },
  { path: 'maintenance', loadComponent: () => import('./core/maintenance/maintenance.component').then((m) => m.MaintenanceComponent), canActivate: [maintenanceGuard] },
  { path: 'punchout', component: PunchoutLoginComponent },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'login/account',
    component: AccountSelectionComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'login/email',
    component: EmailValidationComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'activate',
    component: ActivateComponent
  },
  {
    path: 'changepassword',
    component: ChangePasswordComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'pagenotfound',
    component: PageNotFoundComponent
  },
  {
    path: 'invalidlink',
    component: InvalidLinkComponent
  },
  {
    path: 'featurequiresphysicallocation',
    component: OpsChecklistUnsupportedComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
