import { APP_BASE_HREF, CommonModule, CurrencyPipe, DecimalPipe, UpperCasePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Injectable, APP_INITIALIZER } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppRoutingModule } from '../app-routing.module';
import { BrandService } from '../shared/services/brand.service';
import { BreadcrumbsService } from '../shared/services/breadcrumbs.service';
import { DateFormatService } from '../shared/services/date-format.service';
import { LoaderService } from '../shared/services/loader.service';
import { NavigationService } from '../shared/services/navigation.service';
import { NomeBreadcrumbService } from '../shared/services/nome-custom-breadcrumb.service';
import { SnackbarService } from '../shared/services/snackbar.service';
import { SharedModule } from '../shared/shared.module';
import { ApiService } from './../shared/services/api/api.service';
import { NFService } from './../shared/services/nutrition-facts.service';
import { CountryService } from './../shared/services/country.service';
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { HttpAPIInterceptor } from './interceptors/http-api-interceptor';
import { MainComponent } from './main/main.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { OpsChecklistUnsupportedComponent } from './ops-checklist-unsupported/ops-checklist-unsupported.component';
import { ErrorHandlingService } from './services/error-handling.service';
import { LoginErrorHandlingService } from './services/login-error-handling.service';
import { LoginService } from './services/login.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { LanguageService } from '../shared/services/language.service';
import { AccountService } from '../shared/services/account.service';
import { ActivateService } from './services/activate.service';
import { InvalidLinkComponent } from './invalid-link/invalid-link.component';
import { CanDeactivateGuard } from './can-deactivate/can-deactivate-guard';
import { JoyrideModule } from 'ngx-joyride';
import { HelpService } from '../shared/services/help.service';
import { LocalizationService } from '../shared/services/localization/localization.service';
import { ReportsMappingHelpers } from '../modules/analytics/helpers/reports-mapper';
import { SenseReportsMappingHelpers } from '../modules/analytics/helpers/sense/sense-reports-mapper';
import { SettingsMappingHelpers } from '../modules/settings/helpers/settings-mapper';
import { TempMenuMappingHelpers } from '../modules/menu-manager/helpers/temp-menu.mapper';
import { RecipeMappingHelpers } from '../modules/menu-manager/helpers/recipe.mapper';
import { BlobErrorInterceptorService } from './interceptors/blob-error-interceptor.service';
import { CustomHttpParamEncoder } from '../shared/classes/CustomHttpParamEncoder';
import { MatStepperIntl } from '@angular/material/stepper';
import { CustomMatStepperIntlService } from './services/custom-mat-stepper-intl.service';
import { PunchoutLoginComponent } from './punchout-login/punchout-login.component';
import { ForceSaveGuard } from './force-save/force-save-guard.ts';
import { ConfigData } from './config-data';
import { ImpersonationService } from './services/impersonation.service';
import { initializer } from './auth/keycloak-initializer';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { KeycloackAuthGuard } from './auth/keycloak-auth.guard';
import { EmailValidationComponent } from './login/email-validation/email-validation.component';
import { LoginWarningDialogComponent } from './dialogs/login-warning-dialog/login-warning-dialog.component';
import { LanguagesDropdownComponent } from '../shared/components/languages-dropdown/languages-dropdown.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntlService } from './services/custom-mat-paginator-intl.service';
import { RuntimeConfigurationService } from './services/runtime-configuration.service';
import { Observable } from 'rxjs';
import { RuntimeConfiguration } from '../shared/models/runtime-configuration.model';
import { RUNTIME_CONFIGURATION } from '../shared/tokens';
import { MaintenanceInterceptor } from './interceptors/maintenance.interceptor';
import { LoginFooterMessageComponent } from '../shared/components/login-footer-message/login-footer-message.component';

export function getTranslationFile(localizationService: LocalizationService) {
  return () => {
    return localizationService.initLanguage().toPromise();
  };
}

function getRuntimeConfigurationObservable(runtimeConfigurationService: RuntimeConfigurationService): () => Observable<RuntimeConfiguration> {
  return () => runtimeConfigurationService.getRuntimeConfiguration();
}

function getRuntimeConfiguration(runtimeConfigurationService: RuntimeConfigurationService): RuntimeConfiguration {
  return runtimeConfigurationService.runtimeConfiguration;
}

@NgModule({
  declarations: [MainComponent, UnauthorizedComponent, PageNotFoundComponent, InvalidLinkComponent, PunchoutLoginComponent, OpsChecklistUnsupportedComponent, EmailValidationComponent, LoginWarningDialogComponent],
  imports: [CommonModule, SharedModule, AppRoutingModule, JoyrideModule.forChild(), KeycloakAngularModule, LanguagesDropdownComponent, LoginFooterMessageComponent],
  exports: [AppRoutingModule],
  providers: [
    BreadcrumbsService,
    NomeBreadcrumbService,
    ApiService,
    NavigationService,
    DateFormatService,
    LoginService,
    AuthService,
    AuthGuardService,
    JwtHelperService,
    ErrorHandlingService,
    LoginErrorHandlingService,
    LoaderService,
    SnackbarService,
    BrandService,
    NFService,
    CountryService,
    ActivateService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpAPIInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BlobErrorInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MaintenanceInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: initializer, multi: true, deps: [KeycloakService] },
    { provide: APP_INITIALIZER, useFactory: getTranslationFile, deps: [LocalizationService], multi: true },
    { provide: APP_INITIALIZER, useFactory: getRuntimeConfigurationObservable, deps: [RuntimeConfigurationService], multi: true },
    { provide: RUNTIME_CONFIGURATION, useFactory: getRuntimeConfiguration, deps: [RuntimeConfigurationService] },
    KeycloackAuthGuard,
    LanguageService,
    AccountService,
    HelpService,
    CanDeactivateGuard,
    ForceSaveGuard,
    LocalizationService,
    ReportsMappingHelpers,
    SenseReportsMappingHelpers,
    SettingsMappingHelpers,
    TempMenuMappingHelpers,
    RecipeMappingHelpers,
    CustomHttpParamEncoder,
    { provide: MatStepperIntl, useClass: CustomMatStepperIntlService },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntlService },
    CurrencyPipe,
    DecimalPipe,
    {
      provide: APP_BASE_HREF,
      useFactory: (config: ConfigData) => {
        let baseHref = config[0].subroute ? '/' + config[0].subroute : '/';
        return baseHref;
      },
      deps: [ConfigData]
    },
    ImpersonationService,
    UpperCasePipe
  ]
})
export class CoreModule {}
