import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RESELLER_THEME_CONFIG } from '../constants.config';
import { ResellerThemeModel } from '../models/reseller-theme.model';
import { Reseller } from '../models/enums/reseller.enum';
import { ResellerCheckService } from './reseller-check.service';
import { SecurityUtilsService } from './security-utils.service';
import { RuntimeConfiguration } from '../models/runtime-configuration.model';
import { RUNTIME_CONFIGURATION } from '../tokens';
@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private favIcon: HTMLLinkElement = document.querySelector('#favIcon');
  private assets: string = environment.assets;
  private themeConfig: ResellerThemeModel;
  public themeImagePathChanged: Subject<string> = new Subject();
  private brandImagesAssets: string;
  private brandImagesFolder: string;

  get invalidLinkComponentImageBackgroundColorCSSClass(): string {
    return this.themeConfig.invalidLinkComponentImageBackgroundColorCSSClass;
  }

  constructor(private resellerCheckService: ResellerCheckService, private securityUtils: SecurityUtilsService, @Inject(RUNTIME_CONFIGURATION) private runtimeConfiguration: RuntimeConfiguration) {
    this.setCurrentResellerTheme();
  }

  isADTheme(): boolean {
    const adThemeSupportedByEnvironment = this.runtimeConfiguration.adThemeSupported;
    const adThemeForADResellerOnly = this.runtimeConfiguration.adThemeForADResellerOnly;
    const adReseller = this.resellerCheckService.isADReseller();
    const zyPortal = this.resellerCheckService.isZYReseller();

    return adThemeSupportedByEnvironment ? (adThemeForADResellerOnly ? zyPortal && adReseller : zyPortal) : false;
  }

  setCurrentResellerTheme(): void {
    const zyPortal = this.resellerCheckService.isZYReseller();

    if (this.isADTheme()) {
      this.setThemeByReseller(Reseller.AveryDennison);
    } else if (zyPortal) {
      this.setThemeByReseller(Reseller.ZIPPYYUM);
    } else {
      this.setThemeByReseller(Reseller.TRANSACT);
    }
  }

  getTempLogHeaderBackgroundColor(): string {
    return this.themeConfig.tempLogHeaderBackgrounColor;
  }

  getTempLogTableBackgroundColor(): string {
    return this.themeConfig.tempLogTableHeaderBackgroundColor;
  }

  private setThemeByReseller(reseller: string): void {
    this.themeConfig = RESELLER_THEME_CONFIG.find((e) => e.resellerName == reseller);
    this.setbrandImagesAssets();
    this.lazyLoadTheme(this.themeConfig.themeStylesFile);
    this.setBrowserTab();
  }

  getThemeFileName(): string {
    return this.themeConfig.themeStylesFile;
  }

  getPortalTitle(): string {
    return this.themeConfig.portalTitle;
  }

  getPortalShortName(): string {
    return this.themeConfig.portalShortName;
  }

  getbrandImagesAssets(): string {
    return this.brandImagesAssets;
  }

  getBrandImagesFolder(): string {
    return this.brandImagesFolder;
  }

  resellerHasTradeMark(): boolean {
    return this.themeConfig.hasTrademark;
  }

  setBackgroundColor(): void {
    document.body.style.backgroundColor = this.themeConfig.loginBackgroundColor;
  }

  unsetBackgroundColor(): void {
    document.body.style.removeProperty('background-color');
  }

  private setbrandImagesAssets(): void {
    this.brandImagesAssets = this.assets + this.themeConfig.imagePath;
    this.brandImagesFolder = this.themeConfig.imagePath;
    this.themeImagePathChanged.next(this.brandImagesAssets);
  }

  private setBrowserTab(): void {
    document.title = this.getPortalTitle();
    this.favIcon.href = this.securityUtils.secureURL(this.brandImagesAssets + 'Favicon.png');
  }

  private lazyLoadTheme(themeName: string): void {
    const style = document.createElement('link');
    style.id = 'app-theme';
    style.rel = 'stylesheet';
    style.href = themeName;
    document.head.appendChild(style);
  }
}
