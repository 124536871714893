import { MatCheckbox } from '@angular/material/checkbox';
import { CardActions } from './cardActions.enum';
import { Permissions } from '../../shared/models/enums/Permissions.enum';

export class Card {
  constructor(
    public id: number,
    public accountId: number,
    public brandId: number,
    public icon: string,
    public hasImage: boolean,
    public hasMenu: boolean,
    public hasUnassignButton: boolean,
    public parentCategoryId?: number,
    public bottomItems?: BottomText[],
    public menuButtons?: MenuButtons[],
    public checkBoxButton?: MatCheckbox,
    public clicked?: boolean,
    public required?: boolean,
    public enabled?: boolean,
    public name?: string,
    public description?: string,
    public mediaType?: string,
    public media?: string,
    public isAssigned?: Boolean,
    public isCustomized?: Boolean,
    public isInherited?: Boolean,
    public isCreatedAtLocation?: Boolean,
    public isMenuSubscription?: Boolean,
    public name_Secondary?: string,
    public brandLogo?: string,
    public isMenuSubscriptionPending?: boolean,
    public isMenuSubscriptionRejected?: boolean,
    public isMenuSubscriptionInvalid?: boolean,
    public isMediaCategorySubscription?: Boolean,
    public syncStatusId?: number,
    public type?: string,
    public isSubscription?: boolean,
    public title?: string,
    public subscriptionCodeInfo?: SubscriptionInfo,
    public isLocked?: boolean,
    public isDisabledForSubscriber?: boolean,
    public isUnassigned?: boolean,
    public labelSizeTitle?: string,
    public searchable?: boolean
  ) {}
  public locationId: number;
  public svgIcon?: string;
  public adfCode?: string;
  public adfEnabled?: boolean;
}

export class MenuButtons {
  constructor(public title: string, public action: CardActions, public isDefaultAllowed: boolean = true) {}
}

export class ToolbarButton {
  constructor(public title: string, public action: CardActions) {}
}

export class BottomText {
  constructor(public value: string, public className: string, public dateIcon?: string, public value_Secondary?: string, public dateIcon2?: string, public dateIconText?: string, public dateIcon2Text?: string) {}
}

export class SubscriptionInfo {
  constructor(public hasAutomaticAssignment?: boolean, public hasAutomaticAssignmentMessage?: string) {}
}

export interface CardButton {
  title: string;
  cardAction: CardActions;
  allowed?: boolean;
  permission?: Permissions;
  revertPermission?: boolean;
  isDefaultAllowed?: boolean;
}

export interface CardButtonSelection {
  action: CardActions;
  isDefaultAllowed?: boolean;
  permissions?: AllowedCondition[];
  title?: string;
  currentUserPermissionsUsed?: boolean;
}
export interface PermissionModel {
  permission: Permissions;
  revertPermission?: boolean;
}

export class SelectionLabels {
  constructor(public single: string, public multiple?: string) {}
}
export type AllowedCondition = boolean | Permissions | PermissionModel;
