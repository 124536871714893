export class APIResponseModel<T> {
  message: string;
  status: string;
  statusCode: number;
  results: T;
}

export enum APIHttpResponseCode {
  ok = 200,
  created = 201,
  accepted = 202,
  badrequest = 400,
  notfound = 404,
  unauthorized = 401,
  forbidden = 403
}
