import { UnitOfMeasure } from './../../../shared/models/UnitOfMeasure.model';
export class DesignerSection {
  constructor(
    public section_id: number,
    public section_title: string,
    public enabled: boolean,
    public section_items: DesignerSectionItem[],
    public authorizedByLocalKey?: string[],
    public bypassTitlecase?: boolean,
    public hiddenInObjectsList?: boolean
  ) {}
}

export class DesignerSectionItem {
  constructor(public name: string, public map_value: string) {}
  enabled?: boolean;
  typeId?: number;
  typeName?: string;
  id?: number;
  key?: string;
  uom?: UnitOfMeasure;
  is_secondary_language?: boolean;
  profileId?: number;
  profileName?: string;
  width?: number;
  height?: number;
  has_recipes?: boolean;
  has_scale_models?: boolean;
  rfid_label?: boolean;
  hasDualValue?: boolean;
  hasDailyValue?: boolean;
  isCategorySeperator?: boolean;
  referralId?: string;
  bypassTitlecase?: boolean;
  disabled?: boolean;
  tooltip?: string;
  icon?: string;
}

export interface DropItem {
  item: DesignerSectionItem;
  section: DesignerSection;
}
