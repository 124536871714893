import { UpperCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Case } from '../../shared/models/enums/case.enum';
import { TitleCasePipe } from '../title-case/title-case.pipe';

@Pipe({
  name: 'translateAndCase',
  pure: false // because the value returned by this pipe depends on the selected language
})
export class TranslateAndCasePipe implements PipeTransform {
  constructor(private translateService: TranslateService, private titlecasePipe: TitleCasePipe, private uppercasePipe: UpperCasePipe) {}

  transform(value: string, caseType: Case, interpolateParams?: Object): string {
    value = this.translateService.instant(value, interpolateParams);
    switch (caseType) {
      case Case.Title:
        return this.titlecasePipe.transform(value);
      case Case.Uppercase:
        return this.uppercasePipe.transform(value);
      case Case.Default:
      default:
        return value;
    }
  }
}
