export enum SignalRMethodNamesEnum {
  MOVE_LOCATION_NOTIFICATION = 'MoveLocation',
  DISCONNECT = 'Disconnect',
  REPORT_ON_DEMAND = 'ReportOnDemand',
  CATALOG_CREATED_EVENT_NOTIFICATION = 'catalogCreatedEventNotification',
  CATALOG_EDITED_EVENT_NOTIFICATION = 'catalogEditedEventNotification',
  CATALOG_DELETED_EVENT_NOTIFICATION = 'catalogDeletedEventNotification',
  PRODUCT_CREATED_EVENT_NOTIFICATION = 'CatalogProductCreatedEventNotification',
  CATALOG_PRODUCTS_ASSIGNED_UNASSIGNED_NOTIFICATION = 'CatalogProductsAssignedUnassignedEventNotification',
  PRODUCT_DELETED_EVENT_NOTIFICATION = 'CatalogProductDeletedEventNotification',
  PRODUCT_BULK_DELETED_EVENT_NOTIFICATION = 'CatalogProductBulkDeletedEventNotification',
  PRODUCT_EDITED_EVENT_NOTIFICATION = 'CatalogProductEditedEventNotification',
  NODE_CATALOGS_ASSIGNED_UNASSIGNED_EVENT_NOTIFICATION = 'NodeCatalogsAssignedUnassignedEventNotification',
  CATALOG_UNASSIGNED_FROM_NODE_EVENT_NOTIFICATION = 'CatalogUnassignedFromNodeEventNotification',
  CATALOGS_UNASSIGNED_FROM_NODE_EVENT_NOTIFICATION = 'CatalogsUnassignedFromNodeEventNotification',
  CATALOG_BULK_ADD_EVENT_NOTIFICATION = 'CatalogBulkAddEventNotification',
  LOCATION_ADF_ENABLED_NOTIFICATION = 'NodeADFEnabledEventNotification',
  LOCATION_ADF_DISABLED_NOTIFICATION = 'NodeADFDisabledEventNotification',
  CATALOG_PRODUCT_UNASSIGNED_EVENT_NOTIFICATION = 'CatalogProductUnassignedEventNotification',
  PUBLISH_ALL_MENUS_NOTIFICATION = 'PublishAllMenus',
  CATALOG_EXPORTED_EVENT_NOTIFICATION = 'catalogExportedEventNotification'
}
