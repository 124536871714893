import { Injectable } from '@angular/core';
import { ItemModelRow, SectionModel, ItemModel } from '../models/section.model';
import { TempMenuClass } from '../models/temp-menu.model';
import { TypeWorkflow } from '../../settings/models/type-workflow.enum';
import { IngredientDetailsModel } from '../models/ingredient-details.model';
import * as _ from 'lodash';
import * as Enumerable from 'linq-es2015';
import { SessionModel } from '../../../shared/models/session.model';
import { BadgeModel } from '../models/badge.model';
import { CalendarEvent } from 'angular-calendar';
import cronstrue from 'cronstrue';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TempMenuMappingHelpers {
  constructor(private translateService: TranslateService) {}

  Map_To_New_SectionModel(tempSection: SectionModel, assignedItems: ItemModelRow[]): SectionModel {
    tempSection.items = assignedItems.map((item) => new ItemModel(item.ingredientId, item.suffix, item.displayOrder, item.recurrences, item.ingredientWorkflowScheduleAnyTime, item.id, item.taskId));

    return tempSection;
  }

  Map_Items_To_AssignedWorkflows(tempMenuInfo: TempMenuClass, tempSection: SectionModel, itemType: TypeWorkflow, sessions: CalendarEvent[]): ItemModelRow[] {
    let result: ItemModelRow[] = [];
    let distinctSessions = sessions
      ? [...new Set(sessions.map((x) => x.title))].map((title) => ({
          id: sessions.find((val) => val.title == title).meta.id,
          name: title,
          primary: sessions.find((val) => val.title == title).color.primary,
          secondary: sessions.find((val) => val.title == title).color.secondary
        }))
      : null;
    if (tempSection.items && tempSection.items.length > 0) {
      tempSection.items.forEach((item) => {
        let itemRow: ItemModelRow;
        let ingredientInfo = tempMenuInfo.ingredientDetails.find((x) => x.id == item.ingredientId);
        if (ingredientInfo) {
          itemRow = new ItemModelRow(
            ingredientInfo.name,
            ingredientInfo.detailName,
            item.suffix,
            item.displayOrder,
            item.ingredientId,
            item.recurrences,
            item.ingredientWorkflowScheduleAnyTime,
            distinctSessions
              ? item.recurrences && item.recurrences.length > 0
                ? item.recurrences[0].sessions
                  ? distinctSessions.filter((x) => item.recurrences[0].sessions.find((s) => s.session == x.id)).map((val) => new BadgeModel(val.name, val.primary, val.secondary))
                  : null
                : null
              : null,
            item.id,
            item.taskId,
            tempSection.name,
            item.taskId && item.taskId != 0
          );
          result.push(itemRow);
        }
      });
    }

    return result;
  }

  Map_Items_To_AssignedTasks(tasks: ItemModelRow[], sessions: CalendarEvent[]): ItemModelRow[] {
    let distinctSessions = sessions
      ? [...new Set(sessions.map((x) => x.title))].map((title) => ({
          id: sessions.find((val) => val.title == title).meta.id,
          name: title,
          primary: sessions.find((val) => val.title == title).color.primary,
          secondary: sessions.find((val) => val.title == title).color.secondary
        }))
      : null;

    tasks.forEach((task) => {
      if (task.recurrences) {
        task.recurrences.forEach((rec) => {
          rec.badges = rec.sessions ? distinctSessions.filter((x) => rec.sessions.find((s) => s.session == x.id)).map((val) => new BadgeModel(val.name, val.primary, val.secondary)) : null;
        });
      }
    });

    return tasks;
  }

  Map_IngredientsDetails_To_Table_Row(tempMenuInfo: TempMenuClass): TempMenuClass {
    tempMenuInfo.ingredientDetails.forEach((ing) => {
      ing.sectionsRelated = [];
      if (tempMenuInfo.sections && tempMenuInfo.sections.length > 0) {
        let tempSections = tempMenuInfo.sections.filter((section) => (section.items && section.items.length > 0 ? section.items.find((item) => item.ingredientId == ing.id) : null));
        ing.sectionsRelated = tempSections ? tempSections : [];
      }
    });

    return tempMenuInfo;
  }

  Map_ToRefreshed_AssignedItems(assignedItems: any[], section: SectionModel): ItemModelRow[] {
    let backupAssignedItems = _.cloneDeep(assignedItems);

    backupAssignedItems.forEach((element) => {
      if (element instanceof IngredientDetailsModel || !element.ingredientId) {
        let item: ItemModelRow = new ItemModelRow(element.name, element.detailName, null, 0, element.id, element.recurrences, false, null, undefined, element.taskId, section.name, element.type == TypeWorkflow.Task);
        let assignedItemsFilter = backupAssignedItems.filter((x) => x.ingredientId == item.ingredientId && x instanceof ItemModelRow);
        if (assignedItemsFilter && assignedItemsFilter.length > 0) {
          item.suffix = assignedItemsFilter.length < 9 ? `0${assignedItemsFilter.length + 1}` : `${assignedItemsFilter.length + 1}`;
        }
        const indexItem = assignedItems.findIndex((x) => (x instanceof IngredientDetailsModel || !x.ingredientId) && x.id == item.ingredientId);
        assignedItems[indexItem] = item;
      }
    });

    return assignedItems;
  }

  Map_ToRefreshed_AllAssignedItems(allAssignedItems: ItemModelRow[], assignedItems: ItemModelRow[], section: SectionModel): ItemModelRow[] {
    assignedItems.forEach((element) => {
      let indexAssigned = allAssignedItems.findIndex((x) => x.id == element.id && x.ingredientId == element.ingredientId && x.detailName == element.detailName && element.suffix == element.suffix);
      if (indexAssigned == -1) {
        allAssignedItems.push(element);
      } else {
        allAssignedItems[indexAssigned] = element;
      }
    });

    return allAssignedItems;
  }

  Map_ToRefreshed_ItemModel(itemModels: any[]): ItemModel[] {
    let backupItemModels = _.cloneDeep(itemModels);

    backupItemModels.forEach((element) => {
      if (element instanceof ItemModelRow || element.name) {
        let item: ItemModel = new ItemModel(element.ingredientId, element.suffix, element.displayOrder, element.recurrences, element.ingredientWorkflowScheduleAnyTime, element.id, element.taskId);
        item.isTask == (element.taskId && element.taskId != 0) || element.isTask;
        let assignedItemsFilter = backupItemModels.filter((x) => x.ingredientId == item.ingredientId && x instanceof ItemModel);
        if (assignedItemsFilter && assignedItemsFilter.length > 0) {
          item.suffix = assignedItemsFilter.length < 9 ? `0${assignedItemsFilter.length + 1}` : `${assignedItemsFilter.length + 1}`;
        }
        const indexItem = itemModels.findIndex((x) => (x instanceof ItemModelRow || element.name) && x.id == item.id);
        itemModels[indexItem] = item;
      }
    });

    return itemModels;
  }

  Map_ToRefreshed_AllItems(allItems: any[]): IngredientDetailsModel[] {
    let backupAllItems = _.cloneDeep(allItems);

    allItems = backupAllItems.filter((element) => element instanceof IngredientDetailsModel || !element.ingredientId);

    return allItems;
  }

  Map_To_AssignedItems_Updated_DisplayOrder(assignedItems: ItemModelRow[]): ItemModelRow[] {
    assignedItems.forEach((element, index) => {
      element.displayOrder = index + 1;
    });

    return assignedItems;
  }

  Map_To_TempSections_Updated_DisplayOrder(tempSections: SectionModel[]): SectionModel[] {
    tempSections.forEach((element, index) => {
      element.displayOrder = index + 1;
    });

    return tempSections;
  }

  Map_To_ItemModel_Updated_DisplayOrder(itemModels: ItemModel[]): ItemModel[] {
    itemModels.forEach((element, index) => {
      element.displayOrder = index + 1;
    });

    return itemModels;
  }

  Map_To_TempSection(tempSections: SectionModel[], assignedItems: ItemModelRow[], index: number, previousIndex: number): SectionModel[] {
    let item = tempSections[index].items.find((x) => x.id == assignedItems[previousIndex].id && x.suffix == assignedItems[previousIndex].suffix);
    if (!item) {
      tempSections[index].items.push(assignedItems[previousIndex]);
      tempSections[index].items = this.Map_ToRefreshed_ItemModel(tempSections[index].items);
      tempSections[index].items = this.Map_To_ItemModel_Updated_DisplayOrder(tempSections[index].items);
    }

    return tempSections;
  }

  convertExpressionToReadable(input: string) {
    var sentence = cronstrue.toString(input, { locale: this.translateService.currentLang });
    if (sentence.includes('AM, ') && sentence.split('AM, ').length > 0) {
      sentence = sentence.split('AM, ')[1];
    } else if (sentence.includes('AM') && sentence.split('AM').length >= 0) {
      sentence = sentence.split('AM')[1];
    }
    return sentence;
  }
}
