export class Operators {
  operands = {
    '=': (a, b) => {
      return a == b;
    },
    '<>': (a, b) => {
      return a != b;
    },
    '<': (a, b) => {
      return a < b;
    },
    '>': (a, b) => {
      return a > b;
    },
    '<=': (a, b) => {
      return a <= b;
    },
    '>=': (a, b) => {
      return a >= b;
    },
    '+': (a, b) => {
      return a + b;
    }
  };
}

export enum Operator {
  Equals = '=',
  NotEquals = '<>',
  LessThan = '<',
  GreaterThan = '>',
  LessOrEqual = '<=',
  GreaterOrEqual = '>=',
  Plus = '+'
}

export enum TimeOperator {
  AM = 'am',
  PM = 'pm'
}

export const timeOperation = new Map<TimeOperator, number>([
  [TimeOperator.AM, 0],
  [TimeOperator.PM, 12]
]);
