import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateAndCasePipe } from './translate-and-case.pipe';

@NgModule({
  declarations: [TranslateAndCasePipe],
  imports: [TranslateModule],
  exports: [TranslateAndCasePipe],
  providers: [TranslateAndCasePipe]
})
export class TranslateAndCaseModule {}
