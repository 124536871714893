import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ComponentForceSave } from './component-force-save';

@Injectable()
export class ForceSaveGuard  {
  canDeactivate(component: ComponentForceSave): Observable<boolean> {
    if (component.unSaved) {
      return component.forceSave().pipe(
        map((res) => true),
        catchError(() => of(true))
      );
    } else {
      return of(true);
    }
  }
}
