import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreLoaderService {
  private selector = 'pre-loader-container';

  constructor(@Inject(DOCUMENT) private document: Document) {}

  private getElement(selector) {
    return this.document.getElementById(selector);
  }

  hide() {
    const el = this.getElement(this.selector);
    if (el) {
      if (!el.classList.contains('hidden')) {
        el.className += 'hidden';
      }
    }
  }
}
