import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './../../shared/services/api/api.service';
import { Observable } from 'rxjs/internal/Observable';
import { APIResponseModel } from '../../shared/models/api-response';

@Injectable({
  providedIn: 'root'
})
export class ImpersonationService {
  impersonationApiService = '/impersonation/token';
  constructor(private apiService: ApiService) {}

  getImpersonationTokens(accountId: number): Observable<APIResponseModel<any>> {
    let url = `${environment.baseAPIUrl}${environment.version}${this.impersonationApiService}`;
    return this.apiService.postRequest(url, { accountId });
  }
}
