import { CalendarEvent } from 'angular-calendar';
import { BadgeModel } from '../../modules/menu-manager/models/badge.model';

export class CalendarModel {
  public events: CalendarEvent[];
  public viewDate?: Date;
  public weekStartsOn?: number;
  public excludeDays?: number[];
  public activeDayIsOpen?: boolean = false;
  public activeDay?: Date;
  public locale?: string;
  public tooltipPlacement?: string = 'auto';
  public tooltipAppendToBody?: boolean = true;
  public tooltipDelay?: number | null = null;
  public precision?: 'days' | 'minutes' = 'days';
  public weekendDays?: number[];
  public snapDraggedEvents?: boolean = true;
  public hourSegments?: number = 2;
  public hourSegmentHeight?: number = 30;
  public dayStartHour?: number = 0;
  public dayStartMinute?: number = 0;
  public dayEndHour?: number = 23;
  public dayEndMinute?: number = 59;
  public eventSnapSize?: number;
  public daysInWeek?: number;
}

export class RecurringEvent {
  constructor() {}
  title: string;
  color: any;
  rrule?: {
    freq: any;
    bymonth?: number;
    bymonthday?: number;
    byweekday?: any;
  };
  everynumberofmonth?: number;
  byeverymonthday?: ByEveryMonthDay[];
  badges?: BadgeModel[];
  extras?: any;
}

export class ByEveryMonthDay {
  constructor(public month?: number, public day?: number) {}
}
