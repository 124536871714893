import { Injectable } from '@angular/core';
import { environment } from './../../../../src/environments/environment';
import { ApiService } from './api/api.service';
import { AuthService } from './../../../../src/app/core/auth/auth.service';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  baseServiceUrl = `${environment.baseAPIUrl}${environment.version}/util`;
  addButtonHighlight = new Subject();

  constructor(private apiService: ApiService, private authService: AuthService) {}

  getServerTime() {
    const url = `${this.baseServiceUrl}/servertime`;
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  isAPIAlive() {
    const url = `${environment.baseAPIUrl}/areyoualive`;
    return this.apiService.getRequest(url, false, true, false);
  }
}
