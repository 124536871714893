<div class="container">
  <div class="col-12 m-0">
    <div class="text-center">
      <img [src]="brandImagesAssets + 'PageNotFoundIcon.svg'" class="app-logo" />
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="mt-1 pt-1 col-12 text-center">
      <div class="row float-center">
        <div class="col-12 mb-0 mt-1">
          <p class="custom-message" [innerHTML]="'error.you_dont_have_permission_to_access_this_page' | translate"></p>
        </div>
        <div class="col-12 mb-4">
          <span class="custom-message">{{ 'error.please_contact_an_adminstrator_for_more_information' | translate }} </span>
        </div>
        <div class="col-12 mb-3">
          <button mat-button color="accent" (click)="navigateToDashboard()" [disableRipple]="true" class="clickable-text return-home-message">{{ 'error.return_home' | translate }}</button>
        </div>
        <div class="col-12">
          <img [src]="assets + 'images/Unt.svg'" class="float-center image-size" />
        </div>
      </div>
    </div>
  </div>
</div>
