import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { KeycloakTokenParsed } from 'keycloak-js';
import { LoginService } from '../services/login.service';
import { RoleService } from '../services/role.service';
import { AuthService } from './auth.service';
import { AccountManagerService } from '../../modules/account-manager/services/account-manager.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class KeycloackAuthGuard extends KeycloakAuthGuard {
  constructor(
    private jwtHelper: JwtHelperService,
    protected router: Router,
    protected keycloakService: KeycloakService,
    private authService: AuthService,
    private roleService: RoleService,
    private loginService: LoginService,
    private accountManagerService: AccountManagerService
  ) {
    super(router, keycloakService);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const isLoggedIn = await this.keycloakAngular.isLoggedIn();
    if (!isLoggedIn) {
      this.authService.clearStorageAndFirstLoad();
      this.authService.redirectToEmailValidation();
    } else {
      const impersonationMode = this.authService.getImpersonationMode();
      const token = impersonationMode ? this.authService.getParentAuthToken() : this.authService.getAuthToken();
      // check if the token is set
      if (!token) {
        // the token is not yet set
        // get token from keycloak instance
        this.keycloakService.getToken().then((token) => {
          // store token in local storage
          this.authService.setAuthToken(token);
          const loggedInUser = this.getLoggedUser();
          if (loggedInUser) {
            this.loginService.pipeLoginSuccess({
              results: {
                token,
                zyToken: loggedInUser.zyToken,
                firstName: loggedInUser.firstName,
                lastName: loggedInUser.lastName,
                isReseller: loggedInUser.isReseller,
                allowLabelingAndMediaDeactivation: loggedInUser.allowLabelingAndMediaDeactivation
              }
            });

            // get modules and permissions
            this.accountManagerService.getUserModules(loggedInUser.accountId).subscribe((modules) => {
              this.authService.setCurrentUserModules(modules);
              // Role service related info
              this.roleService.setUserAuthorizedModules(this.authService.getCurrentUserModules());

              this.loginService.loginSuccess({ results: { accountCode: '', accountId: loggedInUser.accountId } });
              return true;
            });
          }
        });
        return false;
      } else {
        if (this.jwtHelper.isTokenExpired(token) || this.keycloakService.isTokenExpired()) {
          if (this.keycloakService.isTokenExpired()) await this.keycloakService.updateToken();
          const newToken = await this.keycloakService.getToken();
          if (impersonationMode) {
            // if it's impersonation mode, set the new token for the parent (reseller) which was authenticated in keycloak
            this.authService.setParentAuthToken(newToken);
          } else {
            this.authService.setAuthToken(newToken);
          }
          this.redirectToStateURL(state);
        } else {
          if (!this.checkIfRouteIsAuthenticated(state.url)) {
            this.redirectToUnAuthorized();
          }
          if (!state.url.includes('releasemanager/release-notes-template')) {
            this.authService._showUpdateAppDialog(true);
          }
          return true;
        }
      }
    }
  }

  public getLoggedUser(): KeycloakTokenParsed | undefined {
    try {
      const userDetails: KeycloakTokenParsed | undefined = this.keycloakService.getKeycloakInstance().idTokenParsed;

      return userDetails;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  checkIfRouteIsAuthenticated(urlWithParam: string): boolean {
    const authorizedRoutes = this.roleService.getAuthorizedRoutes();
    let url = urlWithParam.split('?')[0];
    url = url.split('#')[0];
    if (url == '/') {
      return true;
    }
    let isAuthorized: boolean = false;
    // First Check  if we need to set the user allowed modules again
    this.roleService.setUserAuthorizedModules(this.authService.getModules());
    // Check if the url one of the existing URLS in the app
    isAuthorized = authorizedRoutes.filter((x) => this.checkIfRouteMatch(x, url)).length > 0;

    return isAuthorized;
  }
  checkIfRouteMatch(x: string, url: string): boolean {
    let match = false;
    let re = new RegExp(x, 'g');
    match = re.test(url);
    return match;
  }
  redirectToStateURL(state: RouterStateSnapshot) {
    this.router.navigate([`${state.url.split('?')[0]}`], { queryParams: state.root.queryParams });
    if (!this.checkIfRouteIsAuthenticated(state.url)) {
      this.redirectToUnAuthorized();
    }
  }
  redirectToUnAuthorized() {
    this.authService.redirectToUnAuthorized();
  }
}
