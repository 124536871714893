export enum UnitsOfMeasureEnum {
    // General Error and Success Codes 0 - 199
    INCHES = 1,
    MILLIMETERS = 2
}

export enum LabelDesignerUnitsOfMeasureEnum {
  INCH = 'inch',
  MILLIMETER = 'millimeter'
}
