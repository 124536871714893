export enum ProductComponentTypes {
  // General components
  DATE = 'currentDate',

  // Food Components
  ITEM_PRINT_NAME = 'Caption',
  ITEM_PRICE = 'Price',
  ITEM_NET_WEIGHT = 'NetWeightMeasure',
  GROUP_TAGS = 'GroupTags',
  BARCODE_VALUE = 'Barcode',
  ITEM_DESCRIPTION = 'Description',
  ITEM_PREP_DESCRIPTION = 'PrepDescription',
  ITEM_USE_BY_DESCRIPTION = 'UseByDescription',
  ITEM_SEC_PRINT_NAME = 'Caption_Secondary',
  ITEM_SEC_DESCRIPTION = 'Description_Secondary',
  CATEGORY = 'CategoryName',
  SEC_CATEGORY = 'CategoryName_Secondary',
  DISCARD_DATE = 'discardDate',
  RECIPES_FIELD = 'RecipesField',
  INGREDIENTS = 'Ingredients',
  ALLERGENS = 'Allergens',
  MENUITEM_CALORIES = 'Calories',
  VOICE_PICK = 'VoicePick',
  ITEM_SCALE_WEIGHT = 'ScaleWeight',
  ITEM_TARE_WEIGHT = 'TareWeightValue',
  PRICE_PER_WEIGHT = 'PricePerWeight',

  // Retail components
  PRODUCT_CAPTION = 'RetailProduct.caption',
  PRODUCT_DESCRIPTION = 'RetailProduct.description',
  PRODUCT_SKU = 'RetailProduct.SKU',
  PRODUCT_ORIGINAL_PRICE = 'RetailProduct.originalPrice',
  PRODUCT_CURRENT_PRICE = 'RetailProduct.currentPrice',
  PRODUCT_DISCOUNT_RATE = 'RetailProduct.discountRate',
  PRODUCT_BARCODE_VALUE = 'RetailProduct.barcodeValue',
  PRODUCT_SIZE = 'RetailProduct.size',
  PRODUCT_COLOR = 'RetailProduct.color',
  PRODUCT_DEPARTMENT = 'RetailProduct.department',
  PRODUCT_START_DATE = 'RetailProduct.startDate',
  PRODUCT_END_DATE = 'RetailProduct.endDate',
  PRODUCT_CURRENCY = 'RetailProduct.currency',
  PRODUCT_LABEL = 'RetailProduct.label',
  PRODUCT_PRINT_LABEL = 'RetailProduct.printLabel'
}
