import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnitOfMeasure } from '../models/UnitOfMeasure.model';
import { environment } from './../../../environments/environment';
import { Country } from './../models/country.model';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class NFService {
  baseServiceUrl = `${environment.baseAPIUrl}${environment.version}/nutritionfacts/`;

  public unitsOfMeasure: UnitOfMeasure[];
  public countries: Country[];
  constructor(private apiservice: ApiService) {}

  getCountriesList(): Observable<Country[]> {
    const url = `${this.baseServiceUrl}countries`;
    if (!this.countries || this.countries.length < 1) {
      return this.apiservice.getRequest(url).pipe(
        map((response) => {
          this.countries = response.results;
          return response.results;
        })
      );
    } else {
      return of(this.countries);
    }
  }

  getUnitsOfMeasure(): Observable<UnitOfMeasure[]> {
    const url = `${this.baseServiceUrl}measurementunits`;
    if (this.unitsOfMeasure && this.unitsOfMeasure.length > 0) {
      return of(this.unitsOfMeasure);
    }
    return this.apiservice.getRequest(url).pipe(
      map((response) => {
        this.unitsOfMeasure = response.results;
        return response.results;
      })
    );
  }
}
