import { SenseStatus } from '../SenseStatus.enum';
import { SenseAlert } from '../SenseAlert.enum';
import { BrandConfiguration } from '../TemperatureV2.model';
import { NoPrefixLocationNumber } from '../no-prefix-location-number.model';

export class SensorReadingModel implements NoPrefixLocationNumber {
  constructor(
    public id: any,
    public status: SenseStatus,
    public sensorId: string,
    public sensorName: string,
    public equipmentId: string,
    public equipmentName: string,
    public equipmentUnitName: string,
    public lastRecordingTime: Date,
    public humidity: number,
    public battery: number,
    public batteryLevel: number, //:(
    public temperature: number,
    public noPrefixLocationNumber: string,
    public expectedRange?: string,
    public date?: Date,
    public equipmentTypeId?: string,
    public topMinDate?: string,
    public unitName?: string,
    public rssi?: number
  ) {}
}

export class SensorAlertCountModel {
  constructor(public sensorId: string, public sensorName: string, public equipmentId: string, public equipmentName: string, public alertType: SenseAlert, public numberOfAlerts: number, public unitName?: string) {}
}

export class SensorAlertReadingModel {
  constructor(
    public id: any,
    public alertTime: Date,
    public recoveryTime: Date,
    public sensorId: string,
    public sensorName: string,
    public equipmentId: string,
    public equipmentName: string,
    public alertType: SenseAlert,
    public recordedValue: string,
    public expectedRange: string,
    public unitName?: string
  ) {}
}

export class AlertModel {
  constructor(public time: Date, public alertType: SenseAlert, public sensorId: string, public equipmentId: string, public equipmentName: string, public displayValue: number, public topMinDate?: string) {}
}

export class MetaModel {
  constructor(public readingsType: string, public interval: string) {}
}

export class SensorReadingsAndAlerts {
  constructor(public data: SensorReadingModel[], public alerts: AlertModel[], public meta?: MetaModel) {}
}

export class SensorAlertMap implements NoPrefixLocationNumber {
  noPrefixLocationNumber: string;
  constructor(public locationName: string, public long: number, public lat: number, public devices: SenseMapAlert, public equipment: SenseMapAlert, public nodeId: number, public brandId?: number) {}
}

export class SensorAlertMapResponse {
  constructor(public data: SensorAlertMap[], public brandConfiguration: BrandConfiguration[]) {}
}

export class SenseMapAlert {
  constructor(public total: number, public countWithAlerts: number, public alertsFreePercentage: number, public color: string) {}
}

export class SensorAlertCountByLocation implements NoPrefixLocationNumber {
  noPrefixLocationNumber: string;
  constructor(public brandId: string, public nodeId: number, public locationName: string, public alertType: SenseAlert, public numberOfAlerts: number) {}
}

export class SenseGenericResponse<T> {
  constructor(public results: T, public error: string) {}
}

// export class SensorModel {
//     constructor(
//         public equipmentID: number,
//         public equipmentKey: string,
//         public equipmentName: string,
//         public macAddress: string,
//         public firmware: string,
//         public location: string,
//         public storeKey: string
//     ) { }
// }
