import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';
import { defined } from '../../shared/helpers/app.helpers';
import { LocalStorageFlags } from '../../shared/models/enums/local-storage-flags.enum';
import { Section } from '../../shared/models/sidenavSection.model';
import { ConfigData } from '../config-data';
import { userAuthModules } from '../config/user-auth-modules';
import { RoleService } from '../services/role.service';
import { AuthService } from './auth.service';
import { KeycloackAuthGuard } from './keycloak-auth.guard';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {
  authorizedRoutes: string[] = [];

  constructor(private jwtHelper: JwtHelperService, private roleService: RoleService, private router: Router, private authService: AuthService, private keycloakAuthGuardService: KeycloackAuthGuard) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(childRoute, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // if the reseller is configured to authenticate using OIDC, run the authenticate by Keyclaok auth guard
    if (defined(localStorage.getItem(LocalStorageFlags.authenticateUsingKeycloakOIDC))) {
      if (this.authService.getAuthenticateUsingKeycloakOIDC()) {
        return this.keycloakAuthGuardService.isAccessAllowed(route, state);
      }
    } else {
      this.authService
        .getResellerConfigData()
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          let results = response.results;
          let config = results ? new ConfigData(results.subRoute, results.resellerId, results.authenticateUsingKeycloakOIDC, results.alias, results.keycloakURL, results.supportChatEnabled) : new ConfigData(null, null, false, null, environment.keycloakURL, false);
          
          localStorage.setItem(LocalStorageFlags.resellerSubRoute, config.subroute);
          localStorage.setItem(LocalStorageFlags.resellerLoginId, config.resellerLoginId);
          localStorage.setItem(LocalStorageFlags.authenticateUsingKeycloakOIDC, config.authenticateUsingKeycloakOIDC ? 'true': 'false');
          localStorage.setItem(LocalStorageFlags.resellerAlias, config.alias);
          localStorage.setItem(LocalStorageFlags.identityServerURL, config.keycloakURL);
          localStorage.setItem(LocalStorageFlags.supportChatEnabled, config.supportChatEnabled ? 'true': 'false');

          return this.canActivate(route, state);
        });

        return;
    }
    
    const token = this.authService.getAuthToken();
    this.authorizedRoutes = this.roleService.getAuthorizedRoutes();
    if (token) {
      if (this.jwtHelper.isTokenExpired(token)) {
        if (!this.authService.tokenBeingRefreshed.value) {
          this.authService.refreshToken().subscribe(
            (response) => {
              this.authService.setNewToken(response.results);
              this.redirectToStateURL(state);
              this.authService._showUpdateAppDialog(true);
              return true;
            },
            (err) => {
              this.authService.logout(null, state?.url);
              return false;
            }
          );
        } else {
          this.authService.tokenBeingRefreshed.subscribe((value) => {
            if (!value) {
              this.redirectToStateURL(state);
              this.authService._showUpdateAppDialog(true);
              return true;
            }
          });
        }
      } else {
        if (!this.checkIfRouteIsAuthenticated(state.url)) {
          this.redirectToUnAuthorized();
        }
        if (!state.url.includes('releasemanager/release-notes-template')) {
          this.authService._showUpdateAppDialog(true);
        }
        return true;
      }
    } else {
      this.authService.logout(null, state?.url);
      return false;
    }
  }

  checkIfRouteIsAuthenticated(urlWithParam: string): boolean {
    let url = urlWithParam.split('?')[0];
    url = url.split('#')[0];
    if (url == '/') {
      return true;
    }
    let isAuthorized: boolean = false;
    // First Check  if we need to set the user allowed modules again
    this.roleService.setUserAuthorizedModules(this.authService.getModules());
    // Check if the url one of the existing URLS in the app
    this.authorizedRoutes = this.roleService.getAuthorizedRoutes();
    isAuthorized = this.authorizedRoutes.filter((x) => this.checkIfRouteMatch(x, url)).length > 0;

    return isAuthorized;
  }

  checkIfRouteMatch(x: string, url: string): boolean {
    let match = false;
    let re = new RegExp(x, 'g');
    match = re.test(url);
    return match;
  }

  redirectToStateURL(state: RouterStateSnapshot) {
    this.router.navigate([`${state.url.split('?')[0]}`], { queryParams: state.root.queryParams });
    if (!this.checkIfRouteIsAuthenticated(state.url)) {
      this.redirectToUnAuthorized();
    }
  }

  redirectToUnAuthorized() {
    this.authService.redirectToUnAuthorized();
  }

  redirectToPageNotFound() {
    this.authService.redirectToUnAuthorized();
  }
}
