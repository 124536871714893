import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LocationTaskRecurrence } from '../../modules/settings/models/temp-ingredient-task.model';
import { TreeComponent } from '../components/tree/tree.component';
import { appendQueryParamIfDefined, defined } from '../helpers/app.helpers';
import { APIResponseModel } from '../models/api-response';
import { TreeTypeEnum } from '../models/enums/TreeTypes.enum';
import { PreSelectedNode, TreeNode } from '../models/Tree-Node.model';
import { ApiService } from './api/api.service';
import { TreeService } from './tree.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  baseServiceUrl = `${environment.baseAPIUrl}/${environment.version}/accounts/`;

  constructor(private apiService: ApiService, private treeService: TreeService) {}

  rescheduleWeeklyTempTask(locationId: number, checklistId: number, payload: { id: number; recurrences: Partial<LocationTaskRecurrence>[] }): Observable<APIResponseModel<any>> {
    const url = `${this.apiService.v2Accounts}tempconfiguration/${locationId}/tasks/${checklistId}/reschedule`;
    return this.apiService.postRequest(url, payload);
  }

  getNodeChildren(accountId: number, parentNodeId?: number, toBeUsedInTree = false, showLoader = true, includeStores: boolean = true) {
    if (accountId) {
      let url = '';
      if (parentNodeId) {
        url = this.baseServiceUrl + `${accountId}/locations/${parentNodeId}/locations`;
      } else {
        url = this.baseServiceUrl + `${accountId}/locations/root`;
      }

      url = appendQueryParamIfDefined(url, 'getChildren', toBeUsedInTree);
      url = appendQueryParamIfDefined(url, 'includeStores', includeStores);

      return this.apiService.getRequest(url, showLoader).pipe(map((response) => response.results));
    }
  }

  getLocationNodeChildren(accountId: number, node: TreeNode, treeComponent: TreeComponent, callback?: () => void, includeStores: boolean = true): void {
    this.getNodeChildren(accountId, node.id, true, false, includeStores).subscribe((nodeChildren) => {
      treeComponent.setNodeChildren(node, this.mapLocationNodesToTreeNodes(nodeChildren));
      if (defined(callback)) {
        callback();
      }
    });
  }

  getLocationTreeNodesWithoutStores(accountId: number, locationId: number, brandId: number, searchKeyword: string, treeComponent: TreeComponent) {
    this.treeService.getTreeByNodeId(locationId, accountId, brandId, TreeTypeEnum.Location, null, undefined, false, searchKeyword, false).subscribe((response) => {
      treeComponent.setTreeData(response.tree);
    });
  }

  getLocationTreeNodes(accountId: number, locationId: number, brandId: number, searchKeyword: string, treeComponent: TreeComponent, callback?: (response: PreSelectedNode) => void): void {
    this.treeService.getTreeByNodeId(locationId, accountId, brandId, TreeTypeEnum.Location, null, undefined, false, searchKeyword).subscribe((response) => {
      treeComponent.setTreeData(response.tree);
      if (defined(callback)) {
        callback(response);
      }
    });
  }

  checkIfSubscriberCanUnassignAllMenus(accountId: number, locationId: number): Observable<any> {
    const url = this.baseServiceUrl + `/${accountId}/locations/${locationId}/menus/hasMenuRestrictions`;
    return this.apiService.getRequest(url);
  }

  checkIfSubscriberCanUnassignAllChecklists(accountId: number, locationId: number): Observable<any> {
    const url = this.baseServiceUrl + `/${accountId}/locations/${locationId}/checklists/hasChecklistRestrictions`;
    return this.apiService.getRequest(url);
  }

  checkIfSubscriberCanUnassignAllTimers(accountId: number, locationId: number): Observable<any> {
    const url = this.baseServiceUrl + `/${accountId}/locations/${locationId}/timers/hasTimerRestrictions`;
    return this.apiService.getRequest(url);
  }

  private mapLocationNodesToTreeNodes(nodes: any[]): TreeNode[] {
    return nodes.map(
      (node) =>
        new TreeNode(
          node.id,
          node.name,
          null,
          node.hasChildren,
          false,
          false,
          [],
          node.type,
          false,
          null,
          null,
          null,
          node.path,
          node.pathName,
          null,
          null,
          null,
          null,
          null,
          null,
          node.subscriptionCode,
          node.moveLocationStatusId
        )
    );
  }
}
