import { TypeWorkflow } from './type-workflow.enum';

export class TempWorkflowModel {
  constructor(
    public typeId: number,
    public name?: string,
    public workflowCategory?: string,
    public rangeId?: number,
    public isUnitsSupported?: boolean,
    public tempUoMId?: number,
    public tenantId?: number,
    public min?: number,
    public max?: number,
    public imageContent?: any,
    public inputTypeId?: number,
    public imageUrl?: string,
    public rangeText?: string,
    public workflowId?: string,
    public resellerId?: number,
    public tenantName?: string,
    public inUse?: boolean,
    public inputTypeName?: string,
    public fileName?: string,
    public fileLink?: string,
    public file?: any,
    public tasksInUse?: boolean
  ) {}
}

export class TempWorkflowEditModel {
  constructor(
    public typeId: number,
    public name: string,
    public workflowCategory: string,
    public rangeId: number,
    public isUnitsSupported: boolean,
    public tempUoMId: number,
    public tenantId?: number,
    public min?: number,
    public max?: number,
    public imageContent?: File,
    public file?: File,
    public inputTypeId?: number
  ) {}
}

export class TempGroupedWorkflowModel {
  constructor(public key: string, public templates: TemplateModel[]) {}
}

export class TemplateModel {
  constructor(
    public name: string,
    public rangeId: number,
    public tempUoMId: number,
    public min?: number,
    public max?: number,
    public isUnitsSupported?: boolean,
    public imageUrl?: string,
    public rangeText?: string,
    public workflowId?: string,
    public tenantWorkflowId?: number,
    public tenantId?: number,
    public typeId: TypeWorkflow = TypeWorkflow.Workflow,
    public workflowType?: string,
    public workflowInputType?: string,
    public inputTypeId?: number,
    public inputTypeName?: string,
    public inUse?: boolean
  ) {}

  public isSubscription: boolean;
  public workflowTemplateName?: string;
  public key: string;
  activities?: AccountWorkflowTemplateActivity[];
  public isDisabledForSubscriber: boolean;
}

export interface AccountWorkflowTemplateActivity {
  id: string;
  state: object;
}

export class TemplateModelSingle extends TemplateModel {
  public imageClicked?: boolean;
  public menuItemTempId?: number;
  public tempSectionName?: string;
  public tmpSectionName?: string;
  public isUnitEnabled?: boolean;
  public unitsNames?: string;
  public tempSections?: number[];
}

export class MenuItemAssignedTempDetails {
  constructor(
    public menuItemTempId?: number,
    public tenantWorkflowId?: number,
    public isUnitEnabled: boolean = false,
    public unitsNames?: string,
    public tempSections?: number[],
    public typeId: TypeWorkflow = TypeWorkflow.Workflow
  ) {}
}
