import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomImageViewerComponent } from './custom-image-viewer.component';
import { MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { myCustomTooltipDefaults } from '../../shared/constant/mat-tooltip-const-settings';

@NgModule({
  declarations: [CustomImageViewerComponent],
  imports: [CommonModule, MatTooltipModule],
  exports: [CustomImageViewerComponent],
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }]
})
export class CustomImageViewerModule {
  static forRoot(): ModuleWithProviders<CustomImageViewerModule> {
    return {
      ngModule: CustomImageViewerModule
    };
  }
}
