import { LayoutModule } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JoyrideModule } from 'ngx-joyride';
import { AppComponent } from './app.component';
import { ActivateComponent } from './core/activate/activate.component';
import { ChangePasswordComponent } from './core/change-password/change-password.component';
import { CoreModule } from './core/core.module';
import { ForgotPasswordComponent } from './core/forgot-password/forgot-password.component';
import { LoginComponent } from './core/login/login.component';
import { SharedModule } from './shared/shared.module';
import { MyMissingTranslationHandler } from './shared/services/missing-translation-helper';
import { AccountSelectionComponent } from './core/login/account-selection/account-selection.component';
import { LanguagesDropdownComponent } from './shared/components/languages-dropdown/languages-dropdown.component';
import { NgxZendeskWebwidgetModule, NgxZendeskWebwidgetConfig } from '@nitsanzo/ngx-zendesk-webwidget';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { LoginFooterMessageComponent } from './shared/components/login-footer-message/login-footer-message.component';

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  accountUrl = 'averydennison.zendesk.com';
  callback(zE: any) {
    zE('messenger:set', 'locale', 'en');
    zE('messenger', 'hide');
  }
}
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${new Date().getTime()}`);
}

export function tokenGetter() {
  if (localStorage.getItem('userToken')) {
    return localStorage.getItem('userToken');
  }
  return null;
}

@NgModule({
  declarations: [AppComponent, LoginComponent, ActivateComponent, ForgotPasswordComponent, ChangePasswordComponent, AccountSelectionComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler }
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    SharedModule, // Import Shared Module (This to imported here and in feature modules)
    CoreModule, // Import Core Module (Only imported in app.module and only have providers:[])
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    JoyrideModule.forRoot(),
    LanguagesDropdownComponent,
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    MonacoEditorModule.forRoot(),
    LoginFooterMessageComponent
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
  // NOTE: Providers[] should not be added in the app.module, only in core.module,
})
export class AppModule {}
