import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomMatPaginatorIntlService extends MatPaginatorIntl implements OnDestroy {
  ofLabel = this.translate.instant('paginator.of');
  componentDestroyed = new Subject<void>();

  constructor(private translate: TranslateService) {
    super();
    this.getRealTimePaginatorLabelTranslations();
  }

  getRealTimePaginatorLabelTranslations() {
    this.translate
      .stream(['paginator.items_per_page', 'paginator.of', 'paginator.firstPage', 'paginator.lastPage', 'paginator.nextPage', 'paginator.previousPage'])
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((translations) => {
        this.setPaginatorLabels(translations);
      });
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
      return `0 ${this.ofLabel} ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.ofLabel} ${length}`;
  };

  private setPaginatorLabels(translations: { [prop: string]: string }): void {
    this.itemsPerPageLabel = translations['paginator.items_per_page'];
    this.ofLabel = translations['paginator.of'];
    this.firstPageLabel = translations['paginator.firstPage'];
    this.lastPageLabel = translations['paginator.lastPage'];
    this.nextPageLabel = translations['paginator.nextPage'];
    this.previousPageLabel = translations['paginator.previousPage'];
    this.changes?.next();
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }
}
