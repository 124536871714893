import { Injectable } from '@angular/core';
import { Language } from '../models/country.model';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api/api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  languagesAvailable: Language[] = [];
  baseServiceUrl = `${environment.baseAPIUrl}/${environment.version}/languages/`;

  constructor(private apiService: ApiService) {}

  languagesAvailable$(): Observable<Language[]> {
    return this.languagesAvailable && this.languagesAvailable.length > 0 ? of(this.languagesAvailable) : this.getLanguages();
  }

  // Languages
  private getLanguages(): Observable<Language[]> {
    let url = this.baseServiceUrl;
    return this.apiService.getRequest(url, true).pipe(
      map((response) => {
        this.languagesAvailable = <Language[]>response.results;
        return this.languagesAvailable;
      })
    );
  }
}
