export class Help {
  constructor(public pages: HelpPage[], public currentStep: string) {}
}

export class HelpPage {
  constructor(
    public name: string,
    public steps: string[],
    public currentStep: string,
    public firstRun: boolean,
    public completed: boolean,
    public wait?: number,
    public removeWhenReseller?: string[],
    public removeWhenSenseNotActive?: string[]
  ) {}
}
