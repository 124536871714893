export class MapLocation {
  constructor(
    public id: string,
    public name: string,
    public extra: { percentage: number; count: number; total: number; title: string },
    public coords: { lat: number; lng: number },
    public nodeId: number,
    public brandId?: number
  ) {}
}

export class MapLegend {
  public class: string;
  public text?: string;
  public customTextPercentages?: string;
  public customTextComplianceName?: string;
  public displayOrder: number;
  public rangeStart: number;
  public rangeEnd: number;
}
export interface MapConfig {
  fitBounds: boolean;
  clustedMarkers: string[];
  markers: string[];
}

export enum CLUSTER_CUSTOM_CALCULATOR {
  SENSE_CORPORATE_SENSORS_ALERTS = 1,
  TEMP_V2_CORPORATE_COMPLIANCE_RATE = 2
}
export const TEMP_MAP_CONFIG = {
  fitBounds: false,
  clustedMarkers: ['locations'],
  markers: ['locationName', 'sensorElementTemp']
};
export const SENSE_MAP_CONFIG = {
  fitBounds: true,
  clustedMarkers: ['locations', 'sensorElement'],
  markers: ['locationName', 'sensorElementSense']
};
