import { Pipe, PipeTransform } from '@angular/core';
import * as libphonenumber from 'google-libphonenumber';
import { isNullOrEmpty } from '../helpers/app.helpers';

@Pipe({ name: 'phoneFormat' })
export class PhoneNumberFormattingPipe implements PipeTransform {
  transform(tel: string, countryCode: string): string {
    if (isNullOrEmpty(tel)) {
      return '';
    }
    if (isNullOrEmpty(countryCode)) {
      return tel;
    }
    try {
      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
      const number = phoneUtil.parseAndKeepRawInput(tel, countryCode);
      var num = phoneUtil.formatInOriginalFormat(number, countryCode);
      return num;
    } catch (e) {
      return tel;
    }
  }
}
