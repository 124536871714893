export enum CardActions {
  BulkEdit = 'bulkEdit',
  SHOW_DETAILS = 'showDetails',
  CLONE = 'clone',
  DISABLE = 'disable',
  EDIT = 'edit',
  DELETE = 'delete',
  VIEW_CONTENT = 'view content',
  CONFIGURE = 'Configure',
  CONFIGUREV2 = 'Configure V2',
  UPDATEMODE = 'update mode',
  GENERATE_SUBSCRIPTION_CODE = 'Generate Code',
  GENERATE_QR_CODE = 'Generate QR Code',
  IMPORT = 'Import',
  EXPORT = 'Export',
  CLEAR_SUBSCRIPTION_CODE = 'Clear Code',
  PUBLISH_MENU = 'publish menu',
  PUBLISH_MENU_ITEM = 'publish menu item',
  CUSTOMIZE = 'customize',
  SEND_ACTIVATION_EMAIL = 'Send activation email',
  RESEND_ACTIVATION_EMAIL = 'Resend activation email',
  APPROVE = 'Approve',
  REJECT = 'Reject',
  UNSUBSCRIBE = 'UnSubscribe',
  ASSIGN = 'Assign',
  DIS = 'DIS',
  FETCH_LOGS = 'Request Logs',
  CANCEL_FETCH_LOGS = 'Cancel Logs Request',
  FULL_SYNC = 'Request Full Sync',
  CANCEL_FULL_SYNC = 'Cancel Full Sync',
  SETUP_EXTERNAL_INTERFACE = 'Setup External Interface',
  PUBLISH_LABEL = 'Publish Label',
  CLEAR_APP_DATA = 'Request Clear Ops Data',
  CANCEL_CLEAR_APP_DATA = 'Cancel Clear Ops Data Request',
  DESIGN = 'design',
  PUBLISH = 'publish',
  UNASSIGN = 'unassign',
  ENABLE = 'enable',
  EXPORT_PRICING = 'Export Pricing',
  FLAG_DEVICE_FAILED_TO_SYNC = 'Flag as failed to Sync',
  FLAG_DEVICE_READY_TO_SYNC = 'Flag as ready to Sync',
  VIEW_EVIDENCE = 'View Evidence',
  VIEW_GEOFENCE = 'View Geofence',
  CANCEL_SELECTION = 'Cancel Selection',
  MOVE = 'Move',
  SAVE = 'Save',
  SET_PASSWORD = 'Set Password',
  ORDER_NOW = 'Order Now',
  CREATE_TEMPLATE = 'Create Template',
  REPLACE = 'Replace',
  RESET_MASTER_LOCATION_CODE = 'Reset master location code',
  REMOTE_PRINT_TEMPLATE = 'Remote Print Template',
  IMPORT_CUSTOMIZATION = 'Import Customization',
  BULK_ADD = 'Import Add',
  RESET_PASSWORD = 'Reset Password',
  DESIGN_TEMPLATE = 'Design Template',
  ACCOUNT_DEVICE = 'Account Device',
  FORCE_APPROVE = 'Force Approve',
  SETUP_CONNECTED_CLOUD = 'Setup Connected Cloud',
  REPROCESS = 'Reprocess',
  SHOW_THUMBPRINT = 'Show Thumbprint',
  HIDE_THUMBPRINT = 'Hide Thumbprint',
  START_READER = 'Start',
  STOP_READER = 'Stop'
}
