<div class="container login-container">
  <div class="login-form">
    <div class="text-center"><img id="logo-container" [src]="brandImagesAssets + 'FullLogo.svg'" /></div>
    <div class="col" *ngIf="showForm; else message">
      <form class="rounded" [formGroup]="formGroup" (ngSubmit)="submitForm()">
        <p class="alert-message text-center" *ngIf="errMessage">{{ errMessage | translate }}</p>
        <p class="title mb-0">{{ 'forgot_password_page.title' | translate }}</p>
        <p>{{ 'forgot_password_page.subtitle' | translate }}</p>
        <mat-form-field class="login-full-width">
          <input matInput formControlName="email" placeholder="Email" autofocus />
        </mat-form-field>
        <button mat-flat-button color="accent" id="login-button" [disabled]="!formGroup.valid" type="submit" class="btn btn-def btn-block">{{ 'forgot_password_page.submit_button' | translate }}</button>
        <div class="mt-4 text-center">
          <label>
            <a mat-button color="accent" class="clickable-text" routerLink="/">{{ 'forgot_password_page.return_to_login_page' | translate }}</a>
          </label>
        </div>
      </form>
    </div>
    <ng-template #message>
      <h6 class="text-center">{{ 'forgot_password_page.email_sent_successfully' | translate }}</h6>
    </ng-template>
  </div>
</div>
