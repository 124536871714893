import { HttpParameterCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomHttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    if (!value || value == '') return '';

    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    if (!value || value == '') return value;

    return decodeURIComponent(value);
  }
}
