import { enableProdMode, PLATFORM_INITIALIZER } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { ConfigData } from './app/core/config-data';
import { LocalStorageFlags } from './app/shared/models/enums/local-storage-flags.enum';
import { environment } from './environments/environment';
import { onGetSubroute } from './app/shared/helpers/app.helpers';

if (environment.production) {
  enableProdMode();
}
fetchConfig().then((config) => {
  platformBrowserDynamic([{ provide: ConfigData, useValue: config, multi: true }])
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
});

/**
 * fetchConfig() request and headers needs to be defined outside angular since it will be called before angular services and components are registered
 */
function fetchConfig(): Promise<ConfigData> {
  const headers = new Headers();
  headers.append('portal-url', window.location.href); //The header that will be intercepted by the backend to split baseURL and subroute
  const request = new Request(`${environment.baseAPIUrl}${environment.version}/reseller/subroute`, {
    method: 'GET',
    headers: headers
  });
  return fetch(request)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      const config = onGetSubroute(response);

      return config;
    });
}
