<div class="align-items-center d-flex">
  <mat-form-field class="flex-grow-1">
    <mat-label>{{ 'label_designer.label' | translate | titlecase }}</mat-label>

    <mat-select
      disableOptionCentering
      [formControl]="control"
      [required]="required"
      [class.low-opacity]="isLabelDisabledForSubscriber(control.value)"
      [matTooltip]="isLabelDisabledForSubscriber(control.value) ? ('menu_manager.Subscribed_Label_Unavailable.tooltip_message' | translate) : (control.value | labelName : labels)"
      (selectionChange)="onSelectedValueChange($event)"
    >
      <div class="d-flex flex-row-reverse" *ngIf="supportClearSelection">
        <mat-option>
          <button mat-button color="accent" [disableRipple]="true" class="dropdown-clickable-text">
            {{ 'clear_selection' | translate }}
          </button>
        </mat-option>
      </div>

      <mat-option
        class="has-value"
        *ngFor="let label of labels"
        [value]="useKeyAsValue ? label.key : label.labelId"
        [disabled]="label.isDisabledForSubscriber"
        [hidden]="label.isDisabledForSubscriber && label.labelId != control.value"
        [matTooltip]="label.isDisabledForSubscriber ? ('menu_manager.Subscribed_Label_Unavailable.tooltip_message' | translate) : label.name"
      >
        <div class="row">
          <div class="col-7 option-text-container text-wrap">
            <span>{{ label.name }}</span>
          </div>
          <div class="col-5">
            <div class="option-image-wrapper">
              <img (mouseover)="previewImage(label.mediaUrl)" (mouseout)="hideImagePreview()" customToolTip [contentTemplate]="template" *ngIf="label.mediaUrl" class="cdk-drag-preview" [src]="label.mediaUrl" />
            </div>
          </div>
        </div>
      </mat-option>
    </mat-select>
    <mat-error>
      {{ control.errors | formErrors : translateService.currentLang }}
    </mat-error>
  </mat-form-field>

  <img
    *ngIf="isLabelWithInvalidNFProfile() && !isLabelDisabledForSubscriber(control.value)"
    class="nf-warning-icon ml-2"
    [src]="assets + warningIconPath"
    [matTooltip]="'menu_manager.invalid-nutritional-fact-profile-warning.tooltip-message' | translate"
  />
</div>
<ng-template #template>
  <img class="pop-up-image" [src]="previewImageUrl" />
</ng-template>
