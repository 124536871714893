<ng-container *ngIf="data">
  <h1 *ngIf="useTitleCase" mat-dialog-title class="dialog-title mat-headline-6">{{ data.title | translate : { itemName: data.itemName } | titlecase }}</h1>
  <h1 *ngIf="!useTitleCase" mat-dialog-title class="dialog-title mat-headline-6">{{ data.title | translate : { itemName: data.itemName } }}</h1>
  <div mat-dialog-content>
    <ng-container *ngIf="data.dynamicComponent; else nonDynamic">
      <ng-template #dynamicComponentPlaceholder></ng-template>
    </ng-container>
    <ng-template #nonDynamic>
      <ng-container *ngIf="data && data.multiText && data.multiText.length > 0; else singleText">
        <p *ngFor="let text of data.multiText">
          <span *ngIf="data.itemName; else noItemName">
            {{ text | translate : { itemName: data.itemName } }}
          </span>
          <ng-template #noItemName>
            <div class="text-center">{{ text | translate }}</div>
          </ng-template>
        </p>
      </ng-container>
      <ng-template #singleText>
        <p class="text-center">
          <span *ngIf="data.itemName; else singleTextNoItemName">
            {{ data.text | translate : { itemName: data.itemName } }}
          </span>
          <ng-template #singleTextNoItemName> {{ data.text | translate }} </ng-template>
        </p>
      </ng-template>
    </ng-template>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      <div mat-dialog-actions class="button-row text-center d-block">
        <button id="cancel" *ngIf="data.cancelText" (click)="cancel()" mat-stroked-button class="main-button text-center rounded mr-2">
          {{ data.cancelText | translate | titlecase }}
        </button>

        <button id="confirm" (click)="confirm()" mat-flat-button color="accent" class="main-button text-center rounded" cdkFocusInitial>
          {{ data.okayText | translate | titlecase }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
