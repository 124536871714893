export class DeviceFeature {
  constructor(
    public managerOnDuty: boolean,
    public reprinting: boolean,
    public reprintingApproval: boolean,
    public allowUnregisteringDevice: boolean,
    public allowPrintingDialog: boolean,
    public showIngredients: boolean,
    public priceOptionsSelection: boolean,
    public presetsConfiguration: boolean,
    public itemDisplayOptionId?: number,
    public minimumFontSize?: number,
    public size?: number,
    public userSessionTimeoutMinutes?: number,
    public requirePinForUsers?: boolean,
    public collapseCategoriesInLabeling?: boolean,
    public collapseCategoriesInMedia?: boolean,
    public haccpReportStartDay?: number,
    public printServerPort?: number,
    public userManagement?: number,
    public unitsOfMeasureId?: number,
    public syncPrinterSettings?: boolean,
    public syncPrinterSettingsParams?: number[],
    public clearSearchAfterPrint?: boolean,
    public timersPerRow?: number,
    public epcValidation?: boolean,
    public autoDisplayPrinterDialog?: boolean,
    public selectedPrinters?: number[]
  ) {}
}
