import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BreadCrumbItem } from './../models/breadcrumb.model';
import { IBreadcrumb } from './../models/IBreadcrumb.model';
import { BreadcrumbsService } from './breadcrumbs.service';
import { NavigationService } from './navigation.service';
@Injectable({
  providedIn: 'root'
})
export class NomeBreadcrumbService implements OnDestroy {
  breadcrumbs: IBreadcrumb[] = [];
  appPath;
  private storedBreadCrumbs: IBreadcrumb[] = [];
  ngOnDestroy(): void {}

  constructor(public breadcrumbsService: BreadcrumbsService, private translate: TranslateService, private navigationService: NavigationService) {
    this.breadcrumbsService.breadcrumbsChanged$.subscribe((breadCrumbsArray) => {
      while (breadCrumbsArray.length > 0) {
        breadCrumbsArray.pop();
      }
      this.breadcrumbs.forEach((s) => breadCrumbsArray.push(s));
    });
  }

  store(breadcrumbs: IBreadcrumb[]) {
    this.breadcrumbs = JSON.parse(JSON.stringify(breadcrumbs || []));
    this.breadcrumbsService.store(breadcrumbs);
  }

  /**
   * Function used to return the Subcategories breadCrumbs;
   */
  getstoredBreadCrumbs(): IBreadcrumb[] {
    return this.storedBreadCrumbs;
  }

  /**
   * Function used to set the the Subcategories breadCrumbs
   * @param subCategoriesBreadCrumbs IBreadcrumb[]
   */
  setStoredBreadCrumbs(subCategoriesBreadCrumbs: IBreadcrumb[]) {
    this.storedBreadCrumbs = subCategoriesBreadCrumbs;
  }

  clearBreadcrumbs() {
    this.breadcrumbs = [];
    this.storedBreadCrumbs = [];
  }

  initiateBreadcrumbs(items: BreadCrumbItem[]) {
    this.appPath = this.navigationService.getPath();
    const initialCrumb = { label: this.appPath.path, url: '/dashboard', params: [] };
    let breadcrumbs: IBreadcrumb[] = [initialCrumb];
    items.forEach((item) => {
      if (item.translate) {
        this.translate.get(item.label).subscribe((translatedLabel) => {
          item.label = translatedLabel;
          breadcrumbs.push(item);
        });
      } else {
        if (item.url !== initialCrumb.url) breadcrumbs.push(item);
      }
    });
    this.store(breadcrumbs);
    this.setStoredBreadCrumbs(breadcrumbs);
  }
}
