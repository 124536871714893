import { Pipe, PipeTransform } from '@angular/core';
import { DesignerSectionItem } from '../models/designer-section.model';

@Pipe({
  name: 'customFieldTypeId'
})
export class CustomFieldTypeIdPipe implements PipeTransform {
  transform(value: any, custom_fields: DesignerSectionItem[]): number {
    return getCustomeFieldTypeId(value, custom_fields);
  }
}
export function getCustomeFieldTypeId(value: any, custom_fields: DesignerSectionItem[]): number {
  return getCustomeFieldTypeIdByValue(value.selectedType, custom_fields);
}

export function getCustomeFieldTypeIdByValue(value: any, custom_fields: DesignerSectionItem[]): number {
  const map_value = value.split(',')[0];
  return custom_fields.find((x) => x.map_value === map_value)?.typeId;
}
