export function getNewDate(string?: string): Date {
  if (string) {
    return new Date(string);
  }
  return new Date();
}

export function getDateWeekFromNow(): Date {
  const now = getNewDate();
  now.setDate(now.getDate() + 7);
  return now;
}

export function getDateMonthFromNow(): Date {
  const now = getNewDate();
  now.setMonth(now.getMonth() + 1);
  return now;
}

export function setTimeFromTimeString(date: Date, timeString: string) {
  const time = timeString.split(':');
  const hours = parseInt(time[0], 10);
  const minutes = parseInt(time[1], 10);
  const seconds = time[2] ? parseInt(time[2], 10) : 0;
  date.setHours(hours, minutes, seconds);
}

export function secondsToHoursAndMinutes(seconds: number): number[] {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  return [h, m];
}

export function secondsToHoursAndMinutesText(seconds: number) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  return [h, m > 9 ? m : h ? '0' + m : m || '0'];
}
