import { TitleCasePipe as AngularsTitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { capitalize, defined, notDefinedOrEmptyString } from '../../shared/helpers/app.helpers';
import { Case } from '../../shared/models/enums/case.enum';
import { LocalizationService } from '../../shared/services/localization/localization.service';

@Pipe({
  name: 'titlecase'
})
export class TitleCasePipe extends AngularsTitleCasePipe implements PipeTransform {
  constructor(private localizationService: LocalizationService) {
    super();
  }

  transform(title: string): any {
    const activeLocale = this.localizationService.getActiveLocale();
    const activeLocaleCase = activeLocale.case;
    const filter = activeLocale.titleCaseFilter;
    switch (activeLocaleCase) {
      case Case.Sentence:
        return this.sentenceCase(title);
      case Case.Title:
      default:
        return this.titleCase(title, filter);
    }
  }

  private sentenceCase(title: string): string {
    const titleNotDefinedOrEmptyString = notDefinedOrEmptyString(title?.trim());

    if (titleNotDefinedOrEmptyString) return '';

    const lowercaseTitle = title.toLowerCase();
    const lowercaseTitleParts = lowercaseTitle.split(' ');
    const lowercaseFirstWordInTitle = lowercaseTitleParts[0];
    const capitalizedFirstWordInTitle = capitalize(lowercaseFirstWordInTitle);
    const capitalizedTitleParts = [capitalizedFirstWordInTitle, ...lowercaseTitleParts.slice(1)];
    const capitalizedTitle = capitalizedTitleParts.join(' ');

    return capitalizedTitle;
  }

  private titleCase(title: string, filter: string[]): string {
    if (defined(filter)) {
      return this.titleCaseWithFiltering(title, filter);
    } else {
      return super.transform(title);
    }
  }

  private titleCaseWithFiltering(title: string, filter: string[]): string {
    if (notDefinedOrEmptyString(title)) return '';

    let words = title.split(' ');
    for (var i = 0; i < words.length; i++) {
      let word = words[i];
      if (filter.includes(word.toLocaleLowerCase()) && i != 0) {
        // Words that need to remain lower case when not the first word in a string
        words[i] = word.toLowerCase() + ' ';
      } else if (filter.includes(word.toLocaleUpperCase())) {
        // Words that need to always remain in all upper case
        words[i] = word.toUpperCase() + ' ';
      } else if (!notDefinedOrEmptyString(word)) {
        words[i] = this.capitalizeFirstLetter(word);
      }
    }
    return words.join(' ');
  }

  private capitalizeFirstLetter(word: string): string {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }
}
