import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  translSubscription: Subscription;
  constructor(private snackBar: MatSnackBar, private translate: TranslateService) {}

  openSnackBar(otext: string, action: string = null, position: MatSnackBarVerticalPosition = 'bottom', duration: number = 2500, replacements = [], checklist = false, interpolateParams?: object) {
    if (!checklist) {
      this.translate.get(otext, interpolateParams).subscribe((res) => {
        otext = res;
        if (replacements.length > 0) {
          replacements.forEach((replacement) => {
            otext = otext.replace(replacement.old_value, replacement.new_value);
          });
        }
        this.snackBar.open(otext, action, { duration: duration, panelClass: 'successful-snackbar', horizontalPosition: 'center', verticalPosition: position });
      });
    } else {
      this.snackBar.open(otext, action, { duration: duration, panelClass: 'successful-snackbar', horizontalPosition: 'center', verticalPosition: position });
    }
  }

  openWarningSnackBar(otext: string, action: string = 'ok', checklist = false, interpolateParams?: Object) {
    this.openWarningSnackBarWithDuration(otext, action, checklist, 5000, interpolateParams);
  }

  openWarningSnackBarWithDuration(otext: string, action: string = 'ok', checklist = false, duration: number = 5000, interpolateParams?: Object) {
    if (!checklist) {
      this.translSubscription = forkJoin(this.translate.get(otext, interpolateParams), this.translate.get(action)).subscribe(([res1, res2]) => {
        otext = res1;
        action = res2;
        this.snackBar.open(otext, action, { duration: duration, panelClass: 'warn-snackbar', horizontalPosition: 'center', verticalPosition: 'bottom' });
      });
    } else {
      this.snackBar.open(otext, action, { duration: duration, panelClass: 'warn-snackbar', horizontalPosition: 'center', verticalPosition: 'bottom' });
    }
  }

  disposeWarningSnackbarSubscription() {
    if (this.translSubscription) this.translSubscription.unsubscribe();
  }
}
