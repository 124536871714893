import { ReportSubject } from './ReportSubject.enum';
import { TempStatus, TempV2Status } from './TempStatus.enum';
import { Color, ColorHelper } from '@swimlane/ngx-charts';
import { Subscription, interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { format } from 'date-fns';
import { CurveFactory } from 'd3-shape';
import { deepClone, defined } from '../../../shared/helpers/app.helpers';
import { Calculator } from '@google/markerclustererplus/dist/markerclusterer';
import { MapLocation, MapLegend, CLUSTER_CUSTOM_CALCULATOR } from '../components/nome-google-map-chart/models/gmap.models';
import { TempSessionStatus } from './SessionStatusEnum';
import { Align } from './align.enum';
import { TooltipTemplate } from '../enums/tootip-template.enum';
import { ReportingInputType, ReportingInputTypeId } from '../../../shared/models/ReportingInputType.model';
import { ReportFilterBy } from '../enums/report-filter-by.enum';
import { ReportGroupBy } from './ReportGroupBy.enum';
import { UserPreferenceEnum } from '../../../shared/models/enums/user-preference.enum';
import { DashboardSectionDetails } from './dashboard-section.model';
import { DashboardSectionCode } from '../enums/dashboard-section-code.enum';

export enum ReportModule {
  LABELING = 1,
  TEMP = 2,
  TEMP_V2 = 4
}

export class ScheduledReportResModel {
  locationName: string;
  createdAt: Date;
  group_by: number;
  startDate: Date;
  endDate: Date;
  locationId: number;
  timezone: number;
  isAggregated: boolean;
  email: string;
  statusId: ScheduledReportStatusEnum;
  category: ReportSubject;
}

export class ScheduledReport {
  constructor(subject?: ReportSubject) {
    this.subject = subject;
  }
  name: string;
  nameUOM?: string;
  subject: number;
  groupBy: ReportGroupBy;
  tableDisplayColumns?: TableDisplayColumn[] = [];
  titlecaseName?: boolean = true;
  disabled?: boolean = false;
}

export class Report extends ScheduledReport {
  xAxis?: boolean;
  isFullScreen?: boolean;
  label?: string;
  chartType?: ChartType = ChartType.BAR;
  chartLegendTitle?: string;
  gridWidth?: string = '6';
  classes?: string;
  screenState?: string = 'normal';
  viewMode?: ViewMode = ViewMode.CHART;
  nonFilteredTableDisplayColumns?: TableDisplayColumn[] = [];
  tableDataSource?: any;
  originalTableDataSource?: any;
  chartData?: any[]; //(ChartModel[] | ChartSerie[]);
  nonFilteredChartData?: ChartModel[];
  hasChart?: boolean = true;
  hasTable: boolean = true;
  customColors?: { name: string | number; value: string; id?: any }[] = [];
  customSectionConfig?: { name: string | number; value: string; icon?: string; titleMargin?: string }[] = [];
  isVisible: boolean = true;
  legendPosition: string = 'right';
  chartState?: string = 'normal';
  xAxisFormat?: any;
  yAxisFormat?: any;
  scheme?: Color;
  isExtended?: boolean = false;
  dimensions?: number[] = [];
  hasPagination?: boolean;
  //hasBackEndPagination?: boolean;//either true for all reports in the same card or false, else should be set for each report explicitly
  pagination?: ChartPagination;
  originalChartData?: any[]; //(ChartModel[] | ChartSerie[]);
  minWidth?: number;
  showYAxis?: boolean;
  hasCustomLegend?: boolean;
  customLegendColors?: ColorHelper;
  customLegendTitles?: (string | number)[];
  yScaleMax?: number;
  yScaleMin?: number;
  hasMultiCharts: boolean = false;
  containerWidth?: number;
  filterBy?: ReportFilterBy;
  onGroupClick?: Function;
  groupTitlePrefix?: string;
  maxXAxisTickLength?: number;
  disableExport?: boolean;
  disableFullscreen?: boolean;
  extra?: ReportExtra;
  realTime?: RealTime;
  showTimeInDate?: boolean;
  reportsSwitch?: ReportSwitch[];
  originalRenderFunction?: Function;
  onCustomLegendClick?: Function;
  activeFilter?: any;
  autoScale?: boolean;
  onPointClick?: void;
  onGridClick?: void;
  bubbleChartMargin?: number[];
  mapData?: MapData;
  interval?: string;
  requiresAPIExport?: boolean;
  generateCsv?: any;
  style?: {
    height?: string;
    maxHeight?: string;
    minHeight?: string;
    marginBottom?: string;
  };
  view?: number[];
  doesRefreshWithTopToggle?: boolean;
  topToggleRefreshFunction?: CallableFunction;
  canSendReportViaEmail?: boolean = false; //either true for all reports in the same card or false, else should be set for each report explicitly
  showYAxisLabel?: boolean;
  yAxisLabel?: string;
  yAxisTicks?: any[] = undefined; // same as above, value affect all reports exist in the same card
  barPadding?: number;
  groupPadding?: number;
  hasCustomTooltip?: boolean;
  bubbleMinRadius?: number;
  bubbleMaxRadius?: number;
  bubbleChartTooltipDisabled?: boolean;
  showAccountSettingsLink?: boolean;
  tooltipHasOutOfSuffix?: boolean = false; // used to display tooltip in this form value {2 out of 3} where chartserie.extra={total:3} and chartserie.value=2
  error?: string;
  tooltipHasFixedUnitSuffix?: boolean = false; // used for % , min , seconds : single unit suffix
  tooltipFixedUnitSuffix?: string = ''; // used for % , min , seconds : single unit suffix
  customTooltipTitle?: string;
  titleSpecialWidth?: string = null; // used only for long titles considered special cases, default null used when no special case has to be used
  responseData?: any;
  showExportBtn?: boolean;
  featureNotAvailableYet?: boolean; // this is for report that are not available yet (not implemented) but card container should be displayed
  isReportHidden?: boolean;
  doesSupportFilteringByLegends?: boolean; // true , if this report support filtering by legends, when clicked, show only the chart related to that legend
  legendFilter?: string; // the legend clicked that the report is currently displaying, if undefined => no filter is applied and all charts are shown
  expandedContentHasDifferentSetOfColumns?: boolean;
  customNoDataMessage?: string;
  activeEntries?: any[] = [];
  series?: string[];
  supportsMultipleUnits?: boolean;
  selectedUnit?: ReportingInputType;
  tooltipTemplate?: TooltipTemplate;
  subjectsPerReportingUnit?: Map<ReportingInputTypeId, ReportSubject>;
  sectionCode?: DashboardSectionCode;
  section?: DashboardSectionDetails;
  indexInMetaDataArray?: number;
  xScaleMin?: any;
  xScaleMax?: any;
  xAxisTicks?: any[];
  // used to selected ingredient + workflow in a report
  ingredientAndWorkflowFilter?: IngredientAndWorkflowFilter;
  hasCustomExport?: boolean;
  exportFileName?: string;
  customeDropDown?: customDropDown;
  csvApi?: string;
  chartApi?: string;

  doughnutGridConfig?: doughnutGridConfig;
  axisFormatting?: AxisFormatting[];
  disableTablePagination?: boolean;
  tableHasCustomeLegend?: boolean;
  weeklyDataConfig?: {
    weeklyAllDataColumHolder: string;
    weeklyDataColumHolder: string;
    mappedData?: string;
    weeklyDataLength: number;
    weeklyPaginator?: { style?: any; columnNamePosition: string };
  };
  tableDataKey?: string;
  customLegendConfig?: CustomLegendConfig;
  customLinkSettings?: CustomLinkSettings;
  titleSpecialStyle?: object;
  customViewConfigs?: CustomViewConfig[] = [];
  showSenseReadingExportButton?: boolean;
  dataIsCurrentlyLoading?: boolean;
  groupTitlePosition?: GroupTitlePosition = GroupTitlePosition.Above;
  customMappingFunction?: Function;
}

export enum GroupTitlePosition {
  Below = 0,
  Above = 1
}
export interface CustomViewConfig {
  viewMode: ViewMode;
  icon: string;
}
export interface CustomLegendConfig {
  hasCustomIcons?: boolean;
  isDynamic?: boolean;
  legendWithLetterInside?: boolean;
}

export interface CustomLinkSettings {
  link: string;
  linkParam: string;
  tooltip: string;
}

export interface AxisFormatting {
  field: string;
  stringFunction: string;
}
export interface customDropDown {
  api?: string;
  title: string;
  data: any[];
  style: any;
  dataStructure: any;
  defaultItem?: any;
  staticSearch?: boolean;
  onSelectionChange?: (selectedValue: any) => void;
  selectedOptionValue?: any;
  selectedOptionTitle?: any;
}

export interface doughnutGridConfig {
  chartCountPerRow: number;
}
export interface IngredientAndWorkflowFilter {
  requiresSelectingIngredientsAndWorkflowFromPopUp?: boolean;
  ingredientsAndWorkflowSelected?: boolean;
  ingredientsAndWorkflowMessage?: string;
  ingredient?: any;
  workflow?: any;
}

export class ExpandableReport extends ScheduledReport {
  subtitle?: string;
  hasPagination?: boolean = true;
  tableDisplayColumns?: TableDisplayColumn[] = [];
  tableDataSource?: any;
  originalTableDataSource?: any;
  isFullScreen?: boolean;
  disableExport?: boolean;
  disableFullscreen?: boolean;
  extra?: ReportExtra;
}

export class MapData {
  constructor(public locations: MapLocation[] = [], public legendRows: MapLegend[] = [], public clusterCalculator: CLUSTER_CUSTOM_CALCULATOR = null, public zoom: number = 3) {}
}
export class ReportExtra {
  total?: number;
  customLegendContent?: ReportCustomLegend[];
  info?: { text: string };
  line?: {
    interpolation: CurveFactory;
  };
  tabular?: {
    childTable?: {
      displayColumns: any;
    };
  };
  containerCssClass?: string;
}

export class ReportCustomLegend {
  constructor(public key: any, public name: string, public value: string, public color: string, public isClickable: boolean, public isHovered?: boolean, public isClicked?: boolean) {}

  handleCustomLegendClick(customLegends: ReportCustomLegend[], report: Report, callback: Function) {
    this.isClicked = !this.isClicked;
    this.isHovered = false;
    customLegends.forEach((cl) => {
      if (cl != this) {
        cl.isClicked = false;
      }
    });
    report.activeFilter = this.isClicked ? this.key : null;
    callback(report);
  }
}

export class ReportSwitch {
  constructor(public report: Report, public renderFunction: Function) {}

  switchToReport(parentReportsRef: Report[], index: number, silently: boolean = false) {
    if (this.report && this.renderFunction) {
      //put child as parent and parent as child
      let newParentReport: Report;
      let parentReportCopy: Report = /*deepClone(*/ parentReportsRef[index]; /*)*/
      let childReportCopy: Report = /*deepClone(*/ this.report; /*)*/
      this.report = /*deepClone(*/ parentReportCopy /*)*/;
      //this.report.reportsSwitch = []; // temporary clear switchers
      this.report.reportsSwitch = this.report.reportsSwitch.filter((rs) => rs.report.name != parentReportCopy.name);
      newParentReport = childReportCopy;
      newParentReport.reportsSwitch = [new ReportSwitch(parentReportCopy, parentReportCopy.originalRenderFunction)]; // this needs to identify the parent renderfunction
      parentReportCopy.reportsSwitch.forEach((rs) => {
        if (rs.report.name != newParentReport.name) newParentReport.reportsSwitch.push(rs);
      });
      newParentReport.reportsSwitch.forEach((rs) => {
        rs.report.reportsSwitch = [];
      });
      newParentReport.originalRenderFunction = this.renderFunction;
      newParentReport.isFullScreen = parentReportCopy.isFullScreen;
      newParentReport.viewMode = parentReportCopy.viewMode;
      newParentReport.label = parentReportCopy.label;
      parentReportsRef[index] = newParentReport;
      newParentReport.style = this.report.style;
      if (!silently) this.renderFunction(newParentReport);
      if (defined(parentReportCopy.section)) parentReportCopy.section.displayedReport = newParentReport;
    }
  }
}

export class RealTime {
  constructor(
    public refreshRate: number, // in milliseconds
    public refreshFunction: Function,
    public destroySubject: Subject<any>,
    public oldInstance: RealTime,
    public report: Report
  ) {
    this.updateLastRefreshTime();
    this.startInterval();
  }

  public intervalSubscription: Subscription;
  public lastRefreshTime: string;
  public lastRefreshDate: string;

  public updateLastRefreshTime(date: Date = null) {
    this.lastRefreshTime = format(date ? date : new Date(), 'HH:mm');
    this.lastRefreshDate = format(new Date(), 'YYYY-MM-DD HH:mm:ss');
  }

  startInterval() {
    if (this.oldInstance && this.oldInstance.intervalSubscription) this.oldInstance.intervalSubscription.unsubscribe();
    if (this.refreshFunction && this.refreshRate && this.destroySubject) {
      this.intervalSubscription = interval(this.refreshRate)
        .pipe(takeUntil(this.destroySubject))
        .subscribe((r) => {
          //console.log(`${r}:${this.refreshFunction}`);
          this.refreshFunction(this.report);
          this.updateLastRefreshTime();
        });
    }
  }

  getRefreshRateInMinutes() {
    return this.refreshRate ? (this.refreshRate / 1000 / 60).toString() : 'N/A';
  }
}

export class ChartPagination {
  constructor(
    //public max: number,
    public type: string, //days, locations etc...
    public itemsPerPage: number = 1,
    public backendPagination: boolean = false,
    public showTypes: boolean = false,
    public itemsPerPageOptions = []
  ) {}
  public start: number = 1;
  public end: number;
  public get currentPageEnd(): number {
    let currentPageEnd = this.start + this.itemsPerPage - 1;
    if (currentPageEnd >= this.end) currentPageEnd = this.end;
    return currentPageEnd;
  }
  public getNextData?: Function; //call api to get next data

  public paginate(direction: string, callBack?: Function) {
    switch (direction) {
      case 'right':
      default:
        this.getRightData(callBack);
        break;
      case 'left':
        this.getLeftData(callBack);
        break;
      case 'mostleft':
        this.getMostLeftData(callBack);
        break;
      case 'mostright':
        this.getMostRightData(callBack);
        break;
    }
  }
  public getRightData(callBack?: Function) {
    this.start += this.itemsPerPage;
    this.getData(callBack);
  }
  public getLeftData(callBack?: Function) {
    // TODO: callBack to set pagination from FE, move it to here
    this.start -= this.itemsPerPage;
    this.getData(callBack);
  }
  public getMostRightData(callBack?: Function) {
    let lastPageItems = this.end % this.itemsPerPage == 0 ? this.itemsPerPage : this.end % this.itemsPerPage;
    this.start = this.end - lastPageItems + 1;
    this.getData(callBack);
  }
  public getMostLeftData(callBack?: Function) {
    this.start = 1;
    this.getData(callBack);
  }
  private getData(callBack?: Function) {
    if (this.backendPagination) {
      this.getNextData();
    } else if (callBack) {
      callBack();
    }
  }
}

export enum ViewMode {
  CHART = 'chart',
  TABULAR = 'tabular'
}

export enum LegendPosition {
  BELOW = 'below',
  RIGHT = 'right'
}

export interface TableDisplayColumn {
  name: string;
  title: string;
  excelTitle?: string;
  hideFromExcel?: boolean;
  hidden?: boolean;
  extra?: {
    dynamicBackgroundColorField?: string;
    dynamicColorField?: string;
    dynamicTextField?: string;
    disableDynamicTextCasing?: boolean;
    dynamicTextColor?: string;
    isBold?: boolean;
    onlyIfDynamicColorIs?: string;
    style?: object;
  };
  suffix?;
  dataSuffix?;
  clickable?: boolean;
  isIconButton?: boolean;
  icon?: string;
  width?: number;
  align?: Align;
  backendColumnKey?: string;
  backendDataIndex?: number;
  class?: string;
  tooltip?: boolean;
  tooltipSuffix?: string;
  columnConcatinated?: string;
  sendWithTranslatedColumnsEvenIfHidden?: boolean;
  chartKey?: string; // key of the chart this columns represent => to be used in case of multi line charts and each line chart is displayed as a single column
  hideOnSmallScreen?: boolean;
  displayOnlyIfValueIsNotNull?: boolean; //used when column is icon and we need to show the icon only when the value is not null ex: rssi wifi icon
  customIcon?: CustomIconConfig;
  dynamicIcon?: boolean;
  relatedConfig?: string;
  relatedConfigKey?: string;
  value?: string;
  nullableValue?: any;
  multiValueColumn?: MultiValueColumn[];
  isSortable?: boolean;
}

export interface MultiValueColumn {
  type: MultiValueColumnEnum;
  value: string;
}
export enum MultiValueColumnEnum {
  backendColumnKey,
  translation
}
export interface CustomIconConfig {
  style?: string;
  type: CustomIconEnum;
}
export enum CustomIconEnum {
  singleLetter = 'singleLetter',
  material = 'material'
}

export enum ChartType {
  BAR = 'bar',
  LINE = 'line',
  STACKED_BAR = 'stacked_bar',
  AREA = 'area',
  STACKED_AREA = 'stacked_area',
  NORMALIZED_BAR = 'normalized_bar',
  GROUPED_VERTICAL_BAR = 'grouped_vertical_bar',
  MULTI_STACKED_BAR = 'multi_stacked_bar',
  DOUGHNUT = 'doughnut',
  WEEK_GRID_DOUGHNUT = 'week-grid-doughnut',
  CUSTOM_CHART = 'custom_chart',
  CUSTOM_DOUGHNUT_GRID = 'custom_doughnut_grid',
  MAP = 'map',
  MAP_TEMP_V2 = 'map_temp_v2',
  RANKING = 'ranking'
}

export enum TableDisplayColumnNameEnum {
  ItemCode = 'itemCode',
  ItemName = 'itemName',
  CommissionedFields = 'commissionedFields',
  Date = 'date',
  DayOfTheWeek = 'dayOfTheWeek',
  Hour = 'hour',
  Count = 'count',
  DayOfTheWeekName = 'dayOfTheWeekName',
  FullName = 'fullName',
  MediaName = 'mediaName',
  Code = 'code',
  Plays = 'plays',
  HourFormated = 'hourFormated',
  Status = 'status',
  DisplayHeight = 'displayHeight',
  DisplayWidth = 'displayWidth',
  ReportDate = 'reportDate',
  ReportDayOfWeek = 'reportDayOfWeek',
  LocationNumber = 'locationNumber',
  LocationName = 'locationName',
  IsFullService = 'isFullService',
  ParentLocationName = 'parentLocationName',
  CategoryName = 'categoryName',
  ItemPrice = 'itemPrice',
  ShelfLifeHours = 'shelfLifeHours',
  Made = 'made',
  ActualMade = 'actualMade',
  BadLabels = 'badLabels',
  CarriedOverFromPreviousDay = 'carriedOverFromPreviousDay',
  EveningLeft = 'eveningLeft',
  EveningLeftDate = 'eveningLeftDate',
  MorningLeft = 'morningLeft',
  PackagesSold = 'packagesSold',
  SalesAmount = 'salesAmount',
  ThrowAway = 'throwAway',
  CarryOver = 'carryOver',
  ShrinkPercentage = 'shrinkPercentage',
  EPC = 'EPC',
  LabelName = 'labelName',
  PrintedOn = 'printedOn',
  ItemIdentifier = 'itemIdentifier',
  //PrintTime = 'printTime',
  Currency = 'currency',
  LabelSize = 'labelSize',
  Barcode = 'barcode',
  Caption = 'caption',
  CurrentPrice = 'currentPrice',
  OriginalPrice = 'originalPrice',
  PricingScheme = 'pricingScheme'
}

export class ChartModel {
  constructor(public name: string, public series: ChartSerie[] = [], public extra?: any) {}
}

export class MultiChartModel {
  constructor(public group: string, public data: ChartModel[]) {}
  public maxXAxisTickLength?: number = null;
}

export class ChartSerie {
  constructor(public name: any, public value: any, public extra?: any, public x?: any, public y?: any, public r?: number) {}
}

export interface ReportModel {
  count: number;
  date: Date;
  dayOfTheWeek: number;
  dayOfTheWeekName: string;
  hour: number;
  hourFormated: string;
  total: number;
}

export class ReportPDFModel {
  constructor() {}

  public brandId: string;
  public brandName: string;
  public print: string;
  public date: Date;
}

export class ReportExcelModel {
  constructor() {}
  public date: Date;
  public olsonTimeZone: string;
  public temperatureUOM: string;
}

export class TempSessionsStatusReportModel<T> {
  constructor(public date: T, public completedSessionCount: number, public inCompletedSessionCount: number, public missedSessionCount: number, public totalSessionCount: number, public index?: number) {}
}
export class TempSessionsStatusModel<T> {
  constructor(public date: T, public count: number, public type: TempSessionStatus, public total: number) {}
}

export class TempComplianceRateReportModel<T> {
  constructor(public date: T, public compliancePercentage: number) {}
}

export class TempReportModel<T> {
  constructor(public count: number, public date: T, public status: TempStatus) {}
}
export class TempV2ReportModel<T> {
  constructor(public count: number, public totalCount: number, public totalMeasuredCount: number, public date: T, public status: TempV2Status) {}
}

export class TempSessionsTimeResponse<T> {
  constructor(public date: T, public sessionTime: any, public session: string, public sessionId: string, public missedAtDate: boolean) {}
  public sameValues: {}[] = [];
}

export class LocationTempReportModel<T> extends TempReportModel<T> {
  constructor(public count: number, public date: T, public status: TempStatus, public location: string, public session?: string, public employee?: string) {
    super(count, date, status);
  }

  public intervalOrder: number = 0;
}

export class LocationTempV2ReportModel<T> extends TempV2ReportModel<T> {
  constructor(public count: number, public date: T, public status: TempV2Status, public location: string, public session?: string, public employee?: string) {
    super(count, 0, 0, date, status);
  }

  public intervalOrder: number = 0;
}

export class LocationMissingSessionsModel<T> {
  constructor(public date: T, public totalLocationsCount: number, public totalLocations: number, public status: TempV2Status) {}
}

export interface MenuItemReportModel extends ReportModel {
  itemCode: string;
  itemName: string;
}

export interface UserReportModel extends ReportModel {
  fullName: string;
}

export interface MediaReportModel extends ReportModel {
  code: string;
  mediaName: string;
}

export interface LabelReportModel extends MenuItemReportModel {}

export enum ScheduledReportStatusEnum {
  PENDING = 1,
  SENT = 2,
  FAILED = 3,
  SMTP_ERROR = 4
}

export class ManualInputPercentageModel {
  constructor(public date: Date, public percentage?: number) {}
}
