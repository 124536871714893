<div class="container login-container">
  <div class="login-form">
    <div class="text-center logos-container">
      <img id="logo-container" [src]="brandImagesAssets + 'FullLogo.svg'" />
    </div>

    <form class="rounded" [formGroup]="formGroup" (ngSubmit)="navigateToLogIn()">
      <div>
        <div class="col">
          <div class="text-center"><img id="group-container" [src]="brandImagesAssets + 'Group.svg'" /></div>
          <div *ngIf="showBlockedUserInfoMessage" class="login-change-message-container warning mb-3 text-center">
            <div class="login-change-message-title dialog-title warning mb-1" [innerHtml]="'login_page.account_locked_title' | translate"></div>
            <div class="login-change-message" [innerHtml]="'login_page.account_locked_message' | translate"></div>
          </div>
          <div class="col">
            <mat-form-field class="login-full-width">
              <input id="email" matInput type="email" formControlName="email" [placeholder]="'login_page.enter_email' | translate" matTooltip="{{ 'login_page.enter_email' | translate }}" required />
            </mat-form-field>
          </div>
          <div class="text-center">
            <button mat-flat-button color="accent" type="submit" class="login-button btn btn-def btn-block" [disabled]="!formGroup.valid || disableNextButton">{{ 'next' | translate }}</button>
          </div>
        </div>
      </div>
    </form>
    <!--Language Selection-->
    <div class="col-3 mr-auto ml-auto mt-3 no-padding">
      <nome-languages-dropdown class="outside-toolbar"></nome-languages-dropdown>
    </div>
  </div>
  <nome-login-footer-message />
</div>
