import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoaderService } from '../../shared/services/loader.service';

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {
  constructor(private router: Router, private loaderService: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          if (event.url.includes('/api/maintenance')) return;

          if (event.headers.get('AppMaintenanceMode') === 'True') {
            this.router.navigateByUrl('maintenance');
            // We are hiding the loader because
            // there might be pending requests
            // that got cancelled/unsubscribed by the navigation
            this.loaderService.clearLoaders();
          }
        }
      })
    );
  }
}
