import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'nome-login-warning-dialog',
  templateUrl: './login-warning-dialog.component.html',
  styleUrls: ['./login-warning-dialog.component.scss']
})
export class LoginWarningDialogComponent implements OnInit {
  hostUrl: string = '';
  constructor(public dialogRef: MatDialogRef<LoginWarningDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.hostUrl = this.data.resellerTenantName;
  }

  close() {
    this.dialogRef.close();
  }
}
