import { ConfirmationModalData } from './../../models/confirmation-dialog.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AfterViewInit, Component, ComponentFactoryResolver, HostBinding, Inject, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'nome-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements AfterViewInit {
  @HostBinding('id') id = 'confirmationPopup';
  @ViewChild('dynamicComponentPlaceholder', { read: ViewContainerRef, static: false }) private dynamicComponentPlaceholder: ViewContainerRef;

  useTitleCase: boolean;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, public dialogRef: MatDialogRef<ConfirmationComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmationModalData) {
    this.useTitleCase = data.useTitleCase;
  }

  ngAfterViewInit(): void {
    if (this.data.dynamicComponent) {
      const { content: componentType, data: componentData } = this.data.dynamicComponent;

      const component = this.dynamicComponentPlaceholder.createComponent(componentType);
      // const component = this.dynamicComponentPlaceholder.createComponent(componentFactory);
      Object.entries(componentData).forEach(([inputName, inputValue]) => {
        component.instance[inputName] = inputValue;
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
