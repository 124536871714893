export enum Permissions {
  VIEW = 1,
  ADD = 2,
  EDIT = 3,
  DELETE = 4,
  IMPORT = 5,
  ASSIGN = 6,
  PUBLISH = 7,
  ADVANCED_ACCESS = 8,
  MANAGE_USER_LOCATION = 9,
  EXPORT = 10,
  FORCE_EDIT = 11,
  FLAG_DEVICE = 14,
  Restore = 15,
  SET_PASSWORD = 16,
  Bulk = 17,
  MOVE = 18,
  BulkAdd = 19,
  EXPORT_PRICING = 20,
  RESET_PASSWORD = 21,
  //RECEIVE_NOTIFICATION = 22,
  REFRESH_LOCATION_CODE = 23,
  FORCE_APPROVE = 24,
  CUSTOM_CODE = 25,
  MANAGE_USERS_MLC = 26
}
