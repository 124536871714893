export enum SenseStatus {
  InRange = 'InRange',
  OutOfRange = 'OutOfRange',
  Inactive = 'Inactive',
  Unlinked = 'Unlinked',
  Others = 'Others',
  NoReadings = 'NoReadings'
}

export interface SignalStatus {
  min: number;
  max: number;
  color: string;
  text: string;
}
export const SignalStatusData: SignalStatus[] = [
  { min: -95, max: null, color: 'green', text: 'excellent' },
  { min: -110, max: -95, color: 'blue', text: 'good' },
  { min: -125, max: -110, color: '#FFD033', text: 'fair' },
  { min: null, max: -125, color: 'red', text: 'weak' }
];

export const senseStatusColor = new Map<SenseStatus, string>([
  [SenseStatus.InRange, 'var(--in-range)'],
  [SenseStatus.OutOfRange, 'var(--out-of-range)'],
  [SenseStatus.Inactive, 'var(--inactive)'],
  [SenseStatus.Unlinked, 'var(--unlinked)'],
  [SenseStatus.Others, 'var(--others)'],
  [SenseStatus.NoReadings, 'var(--no-readings)']
]);

export const senseStatusOrderCards = new Map<SenseStatus, number>([
  [SenseStatus.InRange, 1],
  [SenseStatus.OutOfRange, 2],
  [SenseStatus.Inactive, 3],
  [SenseStatus.Unlinked, 4],
  [SenseStatus.Others, 5],
  [SenseStatus.NoReadings, 6]
]);

export const senseStatusOrder = new Map<SenseStatus, number>([
  [SenseStatus.OutOfRange, 1],
  [SenseStatus.Inactive, 2],
  [SenseStatus.NoReadings, 2],
  [SenseStatus.Unlinked, 3],
  [SenseStatus.InRange, 4],
  [SenseStatus.Others, 5]
]);

export const senseStatusOrderPerEquipment = new Map<SenseStatus, number>([
  [SenseStatus.OutOfRange, 1],
  [SenseStatus.InRange, 2],
  [SenseStatus.Inactive, 3],
  [SenseStatus.NoReadings, 4],
  [SenseStatus.Unlinked, 5],
  [SenseStatus.Others, 6]
]);
