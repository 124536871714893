export enum TempWorkflowTemplateInputTypes {
  Temperature = 1,
  PPM = 2,
  YesNo = 3,
  Text = 4,
  None = 5,
  Duration = 6,
  ExpiryDate = 7,
  PH = 8
}

export const tempWorkflowInputTypeText = new Map<TempWorkflowTemplateInputTypes, string>([
  [TempWorkflowTemplateInputTypes.Temperature, 'settings-module.temp-task-input-types.temperature'],
  [TempWorkflowTemplateInputTypes.PPM, 'settings-module.temp-task-input-types.ppm'],
  [TempWorkflowTemplateInputTypes.YesNo, 'settings-module.temp-task-input-types.yes_no'],
  [TempWorkflowTemplateInputTypes.Text, 'settings-module.temp-task-input-types.text'],
  [TempWorkflowTemplateInputTypes.None, 'settings-module.temp-task-input-types.none'],
  [TempWorkflowTemplateInputTypes.Duration, 'settings-module.temp-task-input-types.duration'],
  [TempWorkflowTemplateInputTypes.ExpiryDate, 'settings-module.temp-task-input-types.expiry_date'],
  [TempWorkflowTemplateInputTypes.PH, 'pH']
]);
