import { ListToOtherPagesConfigObject } from '../../shared/models/list-to-other-pages-config-object.model';

export const filterDataDictionary = {
  '/inventory-manager/orders': new ListToOtherPagesConfigObject(
    [new RegExp('^/inventory-manager/orders/edit/[0-9]+.*$'), new RegExp('^/inventory-manager/orders/new.*$'), new RegExp('^/inventory-manager/orders/details/[0-9]+.*$')],
    null
  ),
  '/inventory-manager/templates': new ListToOtherPagesConfigObject(
    [
      new RegExp('^/inventory-manager/templates/new.*$'),
      new RegExp('^/inventory-manager/templates/edit/[0-9]+.*$'),
      new RegExp('^/inventory-manager/templates/design/[0-9]+.*$'),
      new RegExp('^/inventory-manager/templates/details/[0-9]+.*$'),
      new RegExp('^/inventory-manager/orders/new.*$')
    ],
    null
  ),
  '/inventory-manager/order-guides': new ListToOtherPagesConfigObject([new RegExp('^/inventory-manager/order-guides/.*$')], null),
  '/inventory-manager/invoices': new ListToOtherPagesConfigObject([new RegExp('^/inventory-manager/invoices/details/[0-9]+.*$')], null)
};
