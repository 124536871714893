import { tempStatusColor, TempStatus, TempV2Status, tempV2StatusColor, tempV2SessionStatusColor, tempV2TempingHistoryColor } from '../models/TempStatus.enum';
import { senseStatusColor, SenseStatus, senseStatusOrder, senseStatusOrderCards, SignalStatusData, SignalStatus, senseStatusOrderPerEquipment } from '../models/SenseStatus.enum';
import { senseAlertColor, SenseAlert, senseAlertOrder } from '../models/SenseAlert.enum';
import { TempSessionStatus } from '../models/SessionStatusEnum';
import { ReportSubject } from '../models/ReportSubject.enum';
import { tempBarChartColor } from '../models/TempStatus.enum';
import { Temperature, TemperatureUomId } from '../models/Temperature.enum';
import { defined, definedAndNotEmptyString, notDefined } from '../../../../../src/app/shared/helpers/app.helpers';
import { ChartModel } from '../models/Report.model';
import * as moment from 'moment';
import * as Enumerable from 'linq-es2015';

export function getTempV2SessionStatusesColors(groups: string[]) {
  let colors: { name: string; value: string }[] = [];
  if (groups) {
    groups.forEach((status) => {
      colors.push({ name: status, value: tempV2SessionStatusColor.get(TempSessionStatus[status]) });
    });
  }
  return colors;
}

export function getTempV2TempingHistoryColor(groups: string[]) {
  let colors: { name: string; value: string }[] = [];
  if (groups) {
    groups.forEach((status) => {
      colors.push({ name: status, value: tempV2TempingHistoryColor.get(TempV2Status[status]) });
    });
  }
  return colors;
}

export function getTempStatusesColors(groups: string[]) {
  let colors: { name: string; value: string }[] = [];
  if (groups) {
    groups.forEach((status) => {
      colors.push({ name: status, value: tempStatusColor.get(TempStatus[status]) });
    });
  }
  return colors;
}

export function getTempV2StatusesColors(groups: string[]) {
  let colors: { name: string; value: string }[] = [];
  if (groups) {
    groups.forEach((status) => {
      colors.push({ name: status, value: tempV2StatusColor.get(TempV2Status[status]) });
    });
  }
  return colors;
}
export function getTempV2StatusColor(status: TempV2Status) {
  return tempV2StatusColor.get(TempV2Status[status]);
}

export function getTempStatusColor(status: TempStatus) {
  return tempStatusColor.get(status);
}

export function getTempV2IngredientColor() {
  return '#e55239';
}
export function getTempV2BarChartColor(subject: ReportSubject) {
  return tempBarChartColor.get(subject);
}
//SENSE
//SENSORS STATUSES
export function getSenseStatusesColors(groups: string[]) {
  let colors: { name: string; value: string }[] = [];
  if (groups) {
    groups.forEach((status) => {
      colors.push({ name: status, value: senseStatusColor.get(SenseStatus[status]) });
    });
  }
  return colors;
}

export function getSenseStatusColor(status: SenseStatus) {
  return senseStatusColor.get(status);
}
export function getSignalStatusColor(rssiValue: number): { color: string; text: string } {
  let color = '';
  let text = '';
  if (defined(rssiValue))
    SignalStatusData.forEach((element: SignalStatus) => {
      const valueWithinRange = (rssiValue >= element.min || notDefined(element.min)) && (rssiValue < element.max || notDefined(element.max));
      if (valueWithinRange) {
        color = element.color;
        text = element.text;
      }
    });
  return { color: color, text: text };
}

export function getSenseStatusOrder(status: SenseStatus) {
  return senseStatusOrder.get(status);
}

export function getSenseStatusOrderCards(status: SenseStatus) {
  return senseStatusOrderCards.get(status);
}
export function getSenseStatusOrderPerEquipmen(status: SenseStatus) {
  return senseStatusOrderPerEquipment.get(status);
}

//SENSORS ALERTS
export function getSenseAlertsColors(groups: string[]) {
  let colors: { name: string; value: string }[] = [];
  if (groups) {
    groups.forEach((alert) => {
      colors.push({ name: alert, value: senseAlertColor.get(SenseAlert[alert]) });
    });
  }
  return colors;
}

export function getSenseAlertColor(alert: SenseAlert) {
  return senseAlertColor.get(alert);
}

export function getSenseAlertOrder(alert: SenseAlert) {
  return senseAlertOrder.get(alert);
}

export function getExportedFileNameFormatted(reportName: string, locationName: string, startDate: string, endDate: string, fileExtension: string, selectedFilterToBeMentionedInTheFileName?: string): string {
  let exportedFileName = reportName;
  exportedFileName = concatenateDefinedStringWithFileName(exportedFileName, locationName);
  exportedFileName = concatenateDefinedStringWithFileName(exportedFileName, selectedFilterToBeMentionedInTheFileName);
  exportedFileName = concatenateDefinedStringWithFileName(exportedFileName, startDate);
  if (defined(endDate)) exportedFileName = concatenateDefinedStringWithFileName(exportedFileName, endDate);
  if (defined(fileExtension)) return `${exportedFileName}.${fileExtension}`;
  return exportedFileName;
}

export function concatenateDefinedStringWithFileName(fileName: string, extra: string) {
  if (definedAndNotEmptyString(extra)) return `${fileName}_${extra}`;
  return fileName;
}

export function generateRandomColors(): string {
  const randomColor = Math.random().toString(16);
  return '#' + randomColor.substring(randomColor.length - 6);
}

export function createGap(charts: ChartModel[], gapValue: number = 1, gapUnit: moment.unitOfTime.Diff = 'day'): ChartModel[] {
  const modifiedCharts = [];
  charts.forEach((chart) => {
    var copyChart = new ChartModel(chart.name, [], chart.extra);
    const orderedData = Enumerable.asEnumerable(chart.series)
      .OrderBy((point) => new Date(point.name))
      .ToArray();
    orderedData.forEach((point, index) => {
      var nextPoint = orderedData[index + 1];
      var nextPointIsBeginningOfGap = defined(nextPoint) && isGap(point.name, nextPoint.name, gapValue, gapUnit);
      var lastPointOfTheChart = notDefined(nextPoint);
      copyChart.series.push(point);
      if (nextPointIsBeginningOfGap || lastPointOfTheChart) {
        modifiedCharts.push(copyChart);
        copyChart = new ChartModel(chart.name, [], chart.extra);
      }
    });
  });
  return modifiedCharts;
}

export function isGap(date1: Date, date2: Date, gapValue: number, gapUnit: moment.unitOfTime.Diff): boolean {
  var a = moment(date1);
  var b = moment(date2);
  var difference = b.diff(a, gapUnit);
  return difference > gapValue;
}
