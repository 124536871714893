import { HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ErrorHandlingService } from '../services/error-handling.service';

@Injectable()
export class BlobErrorInterceptorService {
  constructor(private errorHandlingService: ErrorHandlingService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      map((response: HttpResponse<any>) => {
        if (this.isJSONWrappedInBlob(response)) {
          this.unwrapJSONWrappedInBlob(response, request);
        } else {
          return response;
        }
      })
    );
  }

  private isJSONWrappedInBlob(response: HttpResponse<any>): boolean {
    return response && response.body && response.body instanceof Blob && response.body.type === 'application/json';
  }

  private unwrapJSONWrappedInBlob(response: HttpResponse<any>, request: HttpRequest<any>): void {
    const reader = new FileReader();
    reader.onload = (e: Event) => {
      const json = JSON.parse((<any>e.target).result);
      this.handleJSONResponse(json, request);
    };
    reader.readAsText(response.body);
  }

  private handleJSONResponse(json: any, req: HttpRequest<any>): void {
    this.errorHandlingService.handleError(json, req.url, req.method);
  }
}
