import { Pipe, PipeTransform } from '@angular/core';
import { errorMessages } from './error-mapper';
import { TranslateService } from '@ngx-translate/core';
import { validationParams, errorBlock } from './error-model';
import { toTitleCase } from '../../shared/helpers/app.helpers';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'formErrors'
})
export class FormErrorsPipe implements PipeTransform {
  constructor(private translate: TranslateService, private datePipe: DatePipe) {}
  transform(errors: object, lang: string, name?: string, params: validationParams[] = []): unknown {
    if (!errors) return;
    // currently we only display 1 error at a time thats why we only target the first error provided by the form control
    const errorKey = Object.keys(errors)[0];
    const errorParams = errors[errorKey];
    // we formulate the key for the error block that should be present inside the provided params
    const errorKeyBlock = { name: name, error: errorKey };
    const param = getParam(errorKeyBlock, params);
    // we will always add the values provided by the params  to  the validation data inside the error block
    // we will aim the need of this override by making sure our translation message have variables that are consistent with the erro block values
    // to reduce the need for custome configuration
    const paramsValues = typeof errorParams === 'object' && errorParams !== null ? { ...errorParams, ...param?.value } : param?.value || {};
    // this will be improoved to accept more dynamic config lets same value formatting translation ..
    Object.keys(paramsValues).forEach((key) => {
      if (typeof paramsValues[key] === 'string') {
        paramsValues[key] = paramsValues[key] ? toTitleCase(this.translate.instant(paramsValues[key])) : '';
      }
    });
    // if the provided param exsist and has an override error message it will take priority over the default error message , and there is no default message provided , it will result in an empty string
    const errorMessage = this.translate.instant(param?.overrideErrorMessage || errorMessages.get(errorKey) || ' ', paramsValues);
    return errorMessage;
  }
}

export function getParam(errorBlock: errorBlock, params: validationParams[]): validationParams {
  return params.find((param) => (!param.key.name || param.key.name === errorBlock.name) && param.key.error === errorBlock.error);
}
