export function getResellerApiUrl(environment: any): string {
  const resellerPortalUrl = location.host;
  const resellersUrls = environment.resellersUrls;

  const resellerUrl = resellersUrls.find((resellerUrls) => resellerUrls.portalUrl.indexOf(resellerPortalUrl) !== -1);

  if (resellerUrl) {
    return resellerUrl.apiUrl.url;
  } else {
    const fallbackApiUrl = resellersUrls.find((resellerUrls) => resellerUrls.apiUrl.fallback);
    return fallbackApiUrl.apiUrl.url;
  }
}
export function getResellerUrl(environment: any): string {
  let url = new URL(getResellerApiUrl(environment));
  return url.origin;
}
