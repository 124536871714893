<p class="mat-headline-6 dialog-title" *ngIf="data">{{ ((this.isTransact ? 'refresh_title' : 'update_title') | translate).replace('$$', data.appVersion) }}</p>
<div class="row">
  <div class="col-12 text-center">
    <p>{{ (this.isTransact ? 'refresh_question' : 'update_question') | translate }}</p>
  </div>
</div>
<div class="row">
  <div *ngIf="data.showReleaseNotes" class="col-12 text-center">
    <p class="clickable-accent accent-text-color" (click)="redirectToReleaseNotes()">{{ 'release_manager.release_notes' | translate | titlecase }}</p>
  </div>
</div>
<div class="row mt-4">
  <div class="col-12">
    <div mat-dialog-actions class="button-row text-center d-block">
      <button (click)="updateLater()" mat-stroked-button class="pointer main-button text-center rounded mr-2">{{ 'update_later' | translate | titlecase }}</button>
      <button (click)="getUpdatedApp()" mat-flat-button color="accent" class="pointer main-button text-center rounded" cdkFocusInitial>
        {{ (this.isTransact ? 'refresh_now' : 'update_now') | translate | titlecase }}
      </button>
      <!-- </div> -->
    </div>
  </div>
</div>
