import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserPreferenceEnum } from '../models/enums/user-preference.enum';
import { UserPreference } from '../models/user-preference.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiService } from './api/api.service';
import { defined } from '../helpers/app.helpers';

@Injectable({
  providedIn: 'root'
})
export class UserPreferenceService {
  baseServiceUrl = `${environment.baseAPIUrl}${environment.version}/accounts/`;

  constructor(private apiService: ApiService) {}

  setUserPreference(accountId: number, UserPreferences: UserPreference[]): Observable<any> {
    let url = this.baseServiceUrl + `${accountId}/users/preference`;
    const payload = {
      UserPreferences: UserPreferences
    };
    return this.apiService.postRequest(url, payload, null, false).pipe(map((response) => response.results));
  }

  getUserPreference(accountId: number, filterBy?: UserPreferenceEnum[], saveInLocalStorage: boolean = true, callBackFunction?: () => {}): void {
    let url = this.baseServiceUrl + `${accountId}/users/preferences/list`;
    this.apiService
      .postRequest(url, { filterBy: filterBy }, false)
      .pipe(map((response) => response.results))
      .subscribe((next: Object) => {
        if (saveInLocalStorage) {
          const keys = Object.keys(next);
          keys.forEach((key) => {
            localStorage.setItem(key, next[key]);
          });
        }
        if (defined(callBackFunction)) callBackFunction();
      });
  }

  getUserPreferenceResponse(accountId: number, filterBy?: UserPreferenceEnum[], showLoader: boolean = true): Observable<any> {
    let url = this.baseServiceUrl + `${accountId}/users/preferences/list`;
    return this.apiService.postRequest(url, { filterBy: filterBy }, false, showLoader).pipe(map((response) => response.results));
  }

  getUserPreferenceByKey(key: UserPreferenceEnum) {
    return localStorage.getItem(key);
  }
}
