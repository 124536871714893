import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivateService } from '../services/activate.service';
import { NavigationService } from './../../shared/services/navigation.service';
import { APIResponseModel } from './../../shared/models/api-response';
import { ApiResponseCodeEnum } from './../../shared/models/enums/ApiResponseCode.enum';
import { ThemeService } from './../../shared/services/theme.service';
import { validationParamsData } from './activate-validations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nome-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit, OnDestroy {
  brandImagesAssets: string;
  accountId: number;
  accountName: string;
  userId: number;
  token: string = null;
  formGroup: UntypedFormGroup;
  showContent = false;
  showForm = false;
  validationParams = [...validationParamsData];
  userLinked: boolean = false;
  constructor(
    private authService: AuthService,
    private activateService: ActivateService,
    private navigationService: NavigationService,
    private themeService: ThemeService,
    private route: ActivatedRoute,
    public translateService: TranslateService
  ) {
    this.brandImagesAssets = this.themeService.getbrandImagesAssets();

    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];
      if (!this.token) {
        this.authService.redirectToInvalidLink(ApiResponseCodeEnum.INVALID_LINK_TOKEN);
      }

      if (this.token) {
        this.activateService.validateLinkToken(this.token).subscribe(
          (res) => {
            this.accountId = res.results.accountId;
            this.accountName = res.results.accountName;
            this.userId = res.results.userId;
            this.setBackgroundColor();
            this.showContent = true;
            this.showForm = true;
          },
          (error: APIResponseModel<any>) => {
            this.authService.redirectToInvalidLink(error.results.code);
          }
        );
      }

      this.userLinked = params['userLinked'] === 'True';
    });

    this.formGroup = new UntypedFormGroup(
      {
        password: new UntypedFormControl(null, [Validators.required, Validators.minLength(6)]),
        confirmPassword: new UntypedFormControl(null, [Validators.required, Validators.minLength(6)])
      },
      this.matchValidator
    );

    if (this.userLinked === true) {
      this.formGroup.get('password').clearValidators();
      this.formGroup.get('password').updateValueAndValidity();
      this.formGroup.get('confirmPassword').clearValidators();
      this.formGroup.get('confirmPassword').updateValueAndValidity();
    }
  }
  matchValidator(frm: UntypedFormGroup) {
    const password = frm.get('password').value;
    const confirmPassword = frm.get('confirmPassword').value;

    if (password !== confirmPassword) {
      frm.get('confirmPassword').setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsetBackgroundColor();
    this.navigationService.setBlnDisplayBreadCrumb(true);
  }

  setBackgroundColor() {
    this.themeService.setBackgroundColor();
  }
  unsetBackgroundColor() {
    this.themeService.unsetBackgroundColor();
  }

  submitForm() {
    const password = this.formGroup.getRawValue().password;
    const confirmPassword = this.formGroup.getRawValue().confirmPassword;

    this.activateService.activate(password, confirmPassword, this.token, this.userLinked).subscribe((response) => {
      this.showForm = false;
    });
  }

  goToLoginPage() {
    this.authService.redirectToLoginFromActivate(this.accountName);
  }
}
