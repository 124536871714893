import { catchError, map } from 'rxjs/operators';
import { LoginResponse } from './../../shared/models/loging-response.model';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { AuthService } from '../../core/auth/auth.service';
import { ApiService } from '../../shared/services/api/api.service';
import { LoginErrorHandlingService } from './login-error-handling.service';
import { Tenant } from '../../shared/models/tenant.model';
import { APIResponseModel } from '../../shared/models/api-response';
import { forkJoin, observable, Observable, of, Subject } from 'rxjs';
import { RoleService } from './role.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { BrandService } from '../../shared/services/brand.service';
import { NomeBreadcrumbService } from '../../shared/services/nome-custom-breadcrumb.service';
import { appendQueryParamIfDefined, definedAndNotEmptyString, setTempUoMId, stringIsDefinedAndNotEmpty } from '../../shared/helpers/app.helpers';
import { ResellerCheckService } from '../../shared/services/reseller-check.service';
import { Router } from '@angular/router';
import { AccountValidationResponse, EmailValidationResponse } from '../../shared/models/account-validation.model';
import { BrandingInfoResponse } from '../../shared/models/branding-info-response.model';
import { UserPreferenceService } from '../../shared/services/user-preference.service';
import { KnowledgeBaseService } from '../../shared/services/knowledge-base.service';
import { HttpClient } from '@angular/common/http';
import { AccountManagerService } from '../../modules/account-manager/services/account-manager.service';
import { UserLinkedAccount } from '../../shared/models/user-linked-account-model';
import { ResellerTenantType } from '../../modules/account-manager/models/reseller-tenant-type.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  loginApiService = '/Login';
  punchoutLoginService = this.loginApiService + '/punchout';
  stepsPreviouslyDefined = this.authService.hasInitStepsDefined();
  punchoutSession = false;

  constructor(
    private resellerCheckService: ResellerCheckService,
    private apiService: ApiService,
    private authService: AuthService,
    private ams: AccountManagerService,
    private roleService: RoleService,
    private kbService: KnowledgeBaseService,
    private loginErrorHandler: LoginErrorHandlingService,
    private navigationService: NavigationService,
    private brandService: BrandService,
    private nomeBreadcrumbsService: NomeBreadcrumbService,
    private router: Router,
    private userPreferenceService: UserPreferenceService,
    private httpClient: HttpClient
  ) {}

  login(email: string, password: string, accountId: number, name: string, recaptcha?: string, resellerLoginId?: number) {
    // this.authService.clearLocalStorage();
    const jsonBody = { email: email, password: password, name: name.trim() };
    if (recaptcha) jsonBody['recaptcha'] = recaptcha; // to be verified at BE
    // const jsonBody = { 'email': email, 'password': password, 'accountId': accountId };

    let url = `${environment.baseAPIUrl}${environment.version}${this.loginApiService}`;

    if (resellerLoginId != null) {
      url += `?resellerLoginId=${resellerLoginId}`;
    }

    return this.apiService
      .postRequest(url, jsonBody, true)
      .pipe(
        map((response: APIResponseModel<LoginResponse>) => {
          return this.pipeLoginSuccess(response);
        })
      )
      .pipe(
        catchError((err: any) => {
          return this.loginErrorHandler.handleError(err);
        })
      );
  }

  punchoutLogin(code, requestId, resellerName) {
    const jsonBody = { code: code, requestId: requestId, resellerLoginName: resellerName };
    return this.apiService
      .postRequest(`${environment.baseAPIUrl}${environment.version}${this.punchoutLoginService}`, jsonBody, true)
      .pipe(
        map((response: APIResponseModel<LoginResponse>) => {
          return this.pipeLoginSuccess(response);
        })
      )
      .pipe(
        catchError((err: any) => {
          return this.loginErrorHandler.handleError(err);
        })
      );
  }

  clearData() {
    // remove user from local storage to log user out
    this.authService.removeAuthToken();
    this.navigationService.clearPath();
    this.brandService.clearBrand();
    this.nomeBreadcrumbsService.clearBreadcrumbs();
    this.authService.setPunchoutSession(false);
    this.authService.removePunchOutMode();
    this.authService.removePunchOutRequestId();
  }

  validateAccount(accountInfo: string, resellerLoginId?: number): Observable<AccountValidationResponse> {
    const url = `${environment.baseAPIUrl}/${environment.version}/` + `accounts/validateaccount`;

    const jsonBody = { accountInfo: accountInfo, resellerLoginId: resellerLoginId };

    return this.apiService.postRequest(url, jsonBody, true).pipe(
      map((response: APIResponseModel<AccountValidationResponse>) => {
        return response.results;
      })
    );
  }

  getBrandingInfo(accountInfo: string, resellerLoginId?: number): Observable<BrandingInfoResponse> {
    let url = `${environment.baseAPIUrl}/${environment.version}/accounts/BrandingInfo?accountInfo=${accountInfo}`;
    url = appendQueryParamIfDefined(url, 'resellerLoginId', resellerLoginId);

    return this.apiService.getRequest<BrandingInfoResponse>(url, null, true).pipe(
      map((response: APIResponseModel<BrandingInfoResponse>) => {
        return response.results;
      })
    );
  }

  getAccount(searchValue: string, showLoader: boolean = false): Observable<Tenant[]> {
    const url = `${environment.baseAPIUrl}/${environment.version}/` + `accounts?name=${searchValue}`;
    return this.apiService.getRequest(url, showLoader, true).pipe(
      map((response: APIResponseModel<Tenant[]>) => {
        return response.results;
      })
    );
  }

  getAccountInfoById(accountId: number) {
    const url = `${environment.baseAPIUrl}${environment.version}/accounts/${accountId}`;
    return this.apiService.getRequest(url).pipe(map((response) => response.results));
  }

  forgotPassword(email: string, accountId: number, name: string, resellerLoginId?: number) {
    const body = { email: email, name: name };

    let url = `${environment.baseAPIUrl}${environment.version}${this.loginApiService}/forgotpassword`;
    if (resellerLoginId != null) {
      url += `?resellerLoginId=${resellerLoginId}`;
    }
    // const body = { 'email': email, 'accountId': accountId, accountName: name };
    return this.apiService.postRequest(url, body, true).pipe(
      map((response: APIResponseModel<LoginResponse>) => {
        return response;
      })
    );
  }

  pipeLoginSuccess(response) {
    // login successful if there's a jwt token in the response
    if (response && response.results.token) {
      this.authService.setAuthToken(response.results.token);
      this.authService.setRefreshToken(response.results.refreshToken);
      this.authService.setZYToken(response.results.zyToken);

      this.authService.setCurrentUser(response.results.firstName, response.results.lastName);
      this.authService.setIsReseller(response.results.isReseller);
      this.authService.setLabelingAndMediaDeactivationButtonConfig(response.results.allowLabelingAndMediaDeactivation);
      this.authService.setInitStepsConfig(this.stepsPreviouslyDefined ? this.authService.hasInitSteps() : true);
      // Navigation service related info
      this.navigationService.setPath(this.authService.getTenantName(), '');
      this.getUserLinkedAccounts(this.authService.getUserId()).subscribe((next) => {
        this.authService.setLinkedAccounts(next);
      });
    } else {
      this.authService.removeAuthToken();
    }

    return response;
  }

  loginSuccess(response, preservedURL?) {
    const impersonationMode = this.authService.getImpersonationMode();
    if (impersonationMode !== null) {
      this.authService.clearParentTokens();
      this.roleService.resetModulesAndPermissions();
      this.authService.clearImpersonationMode();
    }
    this.userPreferenceService.getUserPreference(this.authService.getCurrentUserAccountId());
    const isReseller = this.authService.getIsReseller();
    let route: string;
    if (this.roleService.getAllowedSections().length > 0) {
      route = '/dashboard';
      if (definedAndNotEmptyString(preservedURL)) {
        route = preservedURL;
      }
    } else {
      route = '/unauthorized';
    }

    forkJoin([
      this.getAccountInfoById(response.results.accountId),
      this.resellerCheckService.isZYReseller() && !this.authService.getIsReseller() ? this.kbService.getKBTokenObservable(response.results.accountId, this.authService.getCurrentUserEmail()) : of('')
    ]).subscribe(([data, kbToken]) => {
      this.authService.setCompanyNameInc(data.companyNameInc);
      this.authService.setFaceBookLink(data.faceBookLink);
      this.authService.setLinkedInLink(data.linkedInLink);
      this.authService.setTwitterLink(data.twitterLink);
      this.authService.setWebsiteLink(data.websiteLink);
      this.authService.setPrivacyPolicyLink(data.privacyPolicyLink);
      this.authService.setTermsAndConditionsLink(data.termConditionLink);
      this.authService.setCanSetPasswordsFlag(data.canSetPasswords);
      /* Set account flags on login */
      this.authService.setSubLocationConfig(data.hasSubLocation);
      setTempUoMId(data.tempUoMId);

      this.authService.setTenantCode(data.code);
      this.authService.saveAccountConfigurations(data);
      this.kbService.onGetKBToken(kbToken);
      if (isReseller) {
        this.authService.setResellerUomId(data.tempUoMId);
      }

      this.authService.setTenantName(data.name);

      this.router.navigate([route]);
      if (!data.supportChatEnabled) {
        this.authService.logoutZyChatUser();
        this.authService.removeZYSupportChatFeature();
      } else {
        this.authService.setZYSupportChatFeature();
      }

      if (isReseller) {
        const currentUserId = this.authService.getCurrentUserAccountId();
        this.ams.getTenantTypesByReseller(currentUserId).subscribe((res: ResellerTenantType[]) => {
          this.authService.setResellerAllowedBusinessTypes(res);
        });
      }
    });
  }

  validateEmail(email: string, resellerId?: number): Observable<EmailValidationResponse> {
    let url = `${environment.baseAPIUrl}/${environment.version}/` + `reseller/validateUser`;

    url = appendQueryParamIfDefined(url, 'email', email);
    url = appendQueryParamIfDefined(url, 'resellerId', resellerId);

    return this.apiService.getRequest(url, true, true).pipe(
      map((response: APIResponseModel<EmailValidationResponse>) => {
        return response.results;
      })
    );
  }

  switchAccount(userId: number, accountId: number) {
    const jsonBody = { userId: userId, accountId: accountId };

    let url = `${environment.baseAPIUrl}${environment.version}${this.loginApiService}/switchaccount`;
    return this.apiService.postRequest(url, jsonBody, true).pipe(
      map((response: APIResponseModel<LoginResponse>) => {
        if (!this.authService.getAuthenticateUsingKeycloakOIDC()) {
          return this.pipeLoginSuccess(response);
        }
        return;
      })
    );
  }

  getUserLinkedAccounts(userId: number): Observable<UserLinkedAccount[]> {
    let url = `${environment.baseAPIUrl}${environment.version}${this.loginApiService}/linkedaccounts?userId=${userId}`;
    return this.apiService.getRequest(url, true, true).pipe(
      map((response: APIResponseModel<UserLinkedAccount[]>) => {
        return response.results;
      })
    );
  }
}
