import { Modules } from './enums/Modules.enum';
import { Permissions } from './enums/Permissions.enum';
import { TenantTypeEnum } from './tenant-type.model';

export class UserModules {
  constructor(public moduleName: string) {}
}

export class Module {
  constructor(public moduleName: string, public pages: ModulePage[], public permisions?: number[]) {}
  public isAllowedToAll?: boolean;
  public isForPublisher?: boolean;
  public isForNewSubscriptionFlow?: boolean;
  public isForOldSubscriptionFlow?: boolean;
  public isAllowedForReseller?: boolean = true;
}

export class ModulePage {
  constructor(public title: string, public link?: string, public userModulesPermissions?: UserModulePermissions[], public pageId?: number) {}
  public authorizedBylocalKey?: [string];
  public authorizedByEnvironmentKey?: [string];
}

export class UserModulePermissions {
  constructor(public module: Modules, public permissions: Permissions[]) {}
}
