<h1 mat-dialog-title class="dialog-title mat-headline-6">{{ 'sure_want_cancel_title' | translate }}</h1>
<div mat-dialog-content>
  <p>{{ 'sure_want_cancel' | translate }}</p>
</div>
<div class="row mt-4">
  <div class="col-12">
    <div mat-dialog-actions class="button-row text-center d-block">
      <button (click)="onNoClick()" mat-stroked-button class="main-button text-center rounded mr-2">{{ 'no' | translate | titlecase }}</button>
      <button (click)="onYesClick()" mat-flat-button color="accent" class="main-button text-center rounded" cdkFocusInitial>{{ 'yes' | translate | titlecase }}</button>
    </div>
  </div>
</div>
