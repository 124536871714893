<div class="row" #breadcrumbContainer>
  <div class="col">
    <div class="breadcrumbcontainer">
      <div [ngClass]="{ 'container-fluid': allowBootstrap, 'fluid-bread': true }">
        <ol [ngClass]="{ breadcrumb: allowBootstrap }" class="{{ addClass ? '' + addClass : '' }}">
          <li *ngFor="let breadcrumb of breadcrumbs; let last = last; let i = index" [ngClass]="{ 'breadcrumb-item': allowBootstrap, list: true, 'active accent-text-color': last }">
            <a *ngIf="!last && !breadcrumb.muted" class="accent-hover-color" [queryParams]="hasQueryParams(breadcrumb)" [routerLink]="hasParams(breadcrumb)">
              {{ breadcrumb.label }}
            </a>
            <span *ngIf="breadcrumb.muted">{{ breadcrumb.label }}</span>
            <span *ngIf="last"
              ><strong> {{ breadcrumb.label }}</strong></span
            >
            <mat-icon svgIcon="breadcrumb-arrow" *ngIf="!last"></mat-icon>
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>
