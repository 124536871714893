import { ComponentType } from './component-type.enum';
import { RecipeType } from './recipe-type.enum';
import { FactProfile } from '../../../shared/models/facts-profile.model';
import { UnitOfMeasure } from '../../../shared/models/UnitOfMeasure.model';

export class RecipeComponentsModel {
  constructor(
    public componentName: string,
    public typeId: ComponentType,
    public componentId?: number,
    public isSelected?: boolean,
    public displayOrder?: number,
    public unit?: number,
    public uom?: number,
    public componentCaption?: string,
    public recipeType?: RecipeType,
    public componentDisplayName?: string,
    public dynamicRecipeDetails?: DynamicRecipeDetails,
    public defaultUoMUnit?: UnitOfMeasure[],
    public profiles?: FactProfile[],
    public isCheckedToGroup: boolean = false,
    public isAlreadySelected?: boolean,
    public hasSelection?: boolean,
    public isDisabledForSubscriber?: boolean,
    public isTouched: boolean = false
  ) {}
  public componentName_Secondary?: string;
  public componentDisplayName_Secondary?: string;
  public isCreatedAtLocation?: boolean;
  public canBeAssigned?: boolean;
}

export class DynamicRecipeDetails {
  constructor(public name: string, public name_Secondary: string, public selectionType: number, public minSelection: number, public maxSelection: number, public assignedComponents: RecipeComponentsModel[]) {}
  public isCreatedAtLocation?: boolean;
}
