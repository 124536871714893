import { Pipe, PipeTransform } from '@angular/core';
import { ActionTypeEnum, EshaLogItem } from '../models/esha-log-entry.model';

@Pipe({
  name: 'logItemActionType'
})
export class LogItemActionTypePipe implements PipeTransform {
  transform(items: EshaLogItem[], filter?: ActionTypeEnum): any {
    if (!items || !filter) {
      return items;
    }
    return items.filter((x) => x.actionType === filter);
  }
}
