export interface TenantType {
    tenantTypeId: number;
    name: string;
    translationKey: string;
}

export enum TenantTypeEnum {
    FOOD = 1,
    DISTRIBUTION_COMPANY = 2,
    RETAIL = 3,
    Logistics=4
}
