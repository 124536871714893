import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFloatButtonComponent } from './custom-float-button.component';
import { MatIconModule } from '@angular/material/icon';
import { CustomFloatItemButtonComponent } from './custom-float-item-button/custom-float-item-button.component';

@NgModule({
  declarations: [CustomFloatButtonComponent, CustomFloatItemButtonComponent],
  imports: [CommonModule, MatIconModule],
  exports: [CustomFloatButtonComponent, CustomFloatItemButtonComponent]
})
export class CustomFloatButtonModule {}
