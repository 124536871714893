import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { AuthService } from '../../../core/auth/auth.service';
import { LabelDetailsModel } from '../../../modules/label-designer/models/label-model';
import { LabelDesignerService } from '../../../modules/label-designer/services/label-designer.service';
import { setControlValidators } from '../../classes/form.validation.helper';
import { GetLimits } from '../../models/enums/GetRequestLimits.enum';
import { ICONS_PATH } from '../../../shared/constants.config';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nome-labels-dropdown',
  templateUrl: './labels-dropdown.component.html',
  styleUrls: ['./labels-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LabelsDropdownComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelsDropdownComponent implements ControlValueAccessor, OnChanges {
  control = new UntypedFormControl();
  @Input() required: boolean;
  @Input() labels: LabelDetailsModel[] = [];
  @Input() private locationId: number;
  previewImageUrl: string;
  @Output('selectionChange') private selectionChangeEventEmitter = new EventEmitter<number>();
  private _onChange: (_: any) => void;
  @Input() private labelsFetchInternal = true;
  @Input() supportClearSelection: true;
  @Input() includeRfidLabels: boolean = true;
  @Input() useKeyAsValue: boolean = false;

  warningIconPath = ICONS_PATH.WARNING;
  assets: string = environment.assets;

  constructor(private changeDetectorRef: ChangeDetectorRef, private labelDesignerService: LabelDesignerService, private authService: AuthService, public translateService: TranslateService) {}

  writeValue(value: any): void {
    this.control.setValue(value);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.labelsFetchInternal && changes['locationId']?.currentValue) {
      this.getLabels();
    }

    if (changes['required']) {
      setControlValidators(this.control, changes['required'].currentValue == true ? Validators.required : null);
    }
  }

  isLabelDisabledForSubscriber(selectedLabelId: number): boolean {
    if (!selectedLabelId) return false;

    const matchingLabel = this.labels.find((label) => label.labelId === selectedLabelId);
    return matchingLabel && matchingLabel.isDisabledForSubscriber;
  }

  private getLabels(): void {
    this.labelDesignerService.getAllLabels(this.authService.getCurrentUserAccountId(), 0, GetLimits.CARDS, this.locationId, false, null, null, null, false, this.includeRfidLabels).subscribe((labelsResponse) => {
      this.labels = labelsResponse.records;
      this.changeDetectorRef.markForCheck();
    });
  }

  previewImage(url: string): void {
    this.previewImageUrl = url;
  }

  hideImagePreview(): void {
    this.previewImageUrl = null;
  }

  onSelectedValueChange(event: MatSelectChange): void {
    this._onChange(event.value || null); // added `|| null` because in menu bulk edit if the label is initially `null` and "clear selection" button is clicked, the current value which is `undefined` is considered different than `null`
    this.selectionChangeEventEmitter.emit(event.value);
  }

  updateControlState() {
    this.control.markAllAsTouched();
    this.control.updateValueAndValidity();
  }

  isLabelWithInvalidNFProfile(): boolean {
    const value = this.control.value;
    const selectedLabel = this.labels.find((label) => label.labelId === value);
    return selectedLabel && selectedLabel.hasInvalidNFProfile;
  }

  getLabelKeyFromId(labelId: number): string {
    const foundLabel = this.labels.find((x) => x.labelId === labelId);
    return foundLabel ? foundLabel.key : null;
  }
}
