import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { defined } from '../helpers/app.helpers';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public loaderVisibilityChange = new BehaviorSubject(false);
  public pendingRequests = [];

  showLoader() {
    this.pendingRequests.push(1);

    if (this.pendingRequests.length === 1) {
      this.loaderVisibilityChange.next(true);
    }
  }

  hideLoader() {
    this.pendingRequests.pop();

    if (this.pendingRequests.length === 0) {
      this.loaderVisibilityChange.next(false);
    }
  }

  clearLoaders(count?: number) {
    Array.from({ length: defined(count) ? count : this.pendingRequests.length }, (v, k) => k).forEach((k) => {
      this.hideLoader();
    });
  }
}
