import { TempSessionStatus } from './SessionStatusEnum';
import { ReportSubject } from './ReportSubject.enum';

export enum TempStatus {
  InRange = 'InRange',
  Missed = 'Missed',
  OutOfRange = 'OutOfRange'
}

export const tempStatusColor = new Map<TempStatus, string>([
  [TempStatus.InRange, 'var(--in-range)'],
  [TempStatus.Missed, 'var(--missed)'],
  [TempStatus.OutOfRange, 'var(--out-of-range)']
]);

export const tempV2SessionStatusColor = new Map<TempSessionStatus, string>([
  [TempSessionStatus.Completed, 'var(--completed)'],
  [TempSessionStatus.Missed, 'var(--missed)'], // color swapped based on the ticket documentation, aggregated and store report will be affected
  [TempSessionStatus.Incompleted, 'var(--incomplete)']
]);

export const tempV2SessionsTime = new Map<TempSessionStatus, string>([[TempSessionStatus.Missed, '#b2b8b4']]);

export const tempStatusOrder = new Map<TempStatus, number>([
  [TempStatus.InRange, 1],
  [TempStatus.OutOfRange, 2],
  [TempStatus.Missed, 3]
]);

export enum TempV2Status {
  InRange = 'InRange',
  Missed = 'Missed',
  OutOfRange = 'OutOfRange',
  MissingRequiredSessions = 'MissingRequiredSessions',
  App = 'App',
  Manually = 'Manually'
}

export const tempV2StatusColor = new Map<TempV2Status, string>([
  [TempV2Status.InRange, 'var(--in-range)'],
  [TempV2Status.Missed, 'var(--missed)'],
  [TempV2Status.OutOfRange, 'var(--out-of-range)'],
  [TempV2Status.MissingRequiredSessions, 'var(--missing-required-sessions)'],
  [TempV2Status.App, 'var(--app)'],
  [TempV2Status.Manually, 'var(--manually)']
]);

export const tempV2StatusOrder = new Map<TempV2Status, number>([
  [TempV2Status.InRange, 1],
  [TempV2Status.OutOfRange, 3],
  [TempV2Status.Missed, 5]
]);

export const tempTableColumnColor = new Map<string, string>([
  ['red', '#FF3300'],
  ['green', '#00A600'],
  ['blue', '#4B93E8']
]);

export const tempBarChartColor = new Map<ReportSubject, string>([
  [ReportSubject.TEMP_AVERAGE_SESSION_TIME_FLAT, tempTableColumnColor.get('blue')],
  [ReportSubject.CORPORATE_UNAGGREGATED_REQUIRED_TASKS_COMPLETION_RATE, tempTableColumnColor.get('blue')]
]);

export const tempV2TempingHistoryColor = new Map<TempV2Status, string>([
  [TempV2Status.InRange, 'var(--in-range)'],
  [TempV2Status.OutOfRange, 'var(--out-of-range)']
]);
