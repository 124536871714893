import { Injectable } from '@angular/core';
import { MatStepperIntl } from '@angular/material/stepper';

@Injectable({
  providedIn: 'root'
})
export class CustomMatStepperIntlService extends MatStepperIntl {
  optionalLabel = null;

  constructor() {
    super();
  }
}
