import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiResponseCodeEnum } from '../../shared/models/enums/ApiResponseCode.enum';
import { ThemeService } from './../../shared/services/theme.service';

@Component({
  selector: 'nome-invalid-link',
  templateUrl: './invalid-link.component.html',
  styleUrls: ['./invalid-link.component.scss']
})
export class InvalidLinkComponent implements OnInit, OnDestroy {
  brandImagesAssets: string;
  apiResponseType = ApiResponseCodeEnum;
  reason: number = ApiResponseCodeEnum.INVALID_LINK_TOKEN;
  appTitle: string = '';
  invalidLinkComponentImageBackgroundColorCSSClass = this.themeService.invalidLinkComponentImageBackgroundColorCSSClass;

  constructor(private route: ActivatedRoute, private themeService: ThemeService) {
    this.brandImagesAssets = this.themeService.getbrandImagesAssets();
    this.appTitle = this.themeService.getPortalTitle();
    this.route.queryParams.subscribe((qParams) => {
      if (qParams['reason']) {
        this.reason = Number(atob(qParams['reason']));
        switch (this.reason) {
          case ApiResponseCodeEnum.LINK_TOKEN_EXPIRED:
          case ApiResponseCodeEnum.INVALID_LINK_TOKEN:
            break;
          default:
            this.reason = ApiResponseCodeEnum.INVALID_LINK_TOKEN;
            break;
        }
      } else {
        this.reason = ApiResponseCodeEnum.INVALID_LINK_TOKEN;
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
