import { Injectable } from '@angular/core';
import { ApiService } from '../../shared/services/api/api.service';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../environments/environment';
import { APIResponseModel } from '../../shared/models/api-response';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActivateService {
  constructor(private apiService: ApiService, private authService: AuthService) {}

  validateLinkToken(token: string) {
    const url = `${environment.baseAPIUrl}/${environment.version}/auth/validatelinktoken`;
    const body = { token: token };
    return this.apiService.postRequest(url, body, true).pipe(
      map((response: APIResponseModel<any>) => {
        return response;
      })
    );
  }

  activate(password: string, confirmPassword: string, token: string, userLinked: boolean) {
    const url = `${environment.baseAPIUrl}/${environment.version}/auth/activate`;
    const body = { password: password, confirmPassword: confirmPassword, token: token, userLinked: userLinked };

    return this.apiService.postRequest(url, body, true).pipe(
      map((response: APIResponseModel<any>) => {
        return response;
      })
    );
  }
}
