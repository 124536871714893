import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from './../../shared/services/navigation.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { LoginService } from '../services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiResponseCodeEnum } from './../../shared/models/enums/ApiResponseCode.enum';
import { AuthService } from '../auth/auth.service';
import { ThemeService } from './../../shared/services/theme.service';

@Component({
  selector: 'nome-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  brandImagesAssets: string;
  accountId: number;
  accountName: string;
  formGroup: UntypedFormGroup;
  showForm = true;
  errMessage: string;

  resellerLoginId?: number = null;

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private authService: AuthService,
    private themeService: ThemeService,
    private loginService: LoginService,
    private route: ActivatedRoute
  ) {
    //Get Reseller login Id
    let loginId = this.authService.getResellerLoginId();
    this.resellerLoginId = loginId != null ? +this.authService.getResellerLoginId() : null;

    this.brandImagesAssets = this.themeService.getbrandImagesAssets();
    this.route.queryParams.subscribe((qParams) => {
      if (qParams['account']) {
        try {
          // this.accountId = Number(atob(qParams['id']));
          this.accountName = atob(qParams['account']);
        } catch (error) {
          this.authService.redirectToPageNotFound();
        }
      } else {
        this.authService.redirectToPageNotFound();
      }
    });

    this.formGroup = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required, Validators.email])
    });
  }

  ngOnInit() {
    this.setBackgroundColor();
    this.navigationService.setBlnDisplayBreadCrumb(false);
  }
  setBackgroundColor() {
    this.themeService.setBackgroundColor();
  }
  unsetBackgroundColor() {
    this.themeService.unsetBackgroundColor();
  }
  ngOnDestroy() {
    this.unsetBackgroundColor();
    this.navigationService.setBlnDisplayBreadCrumb(true);
  }

  submitForm() {
    const email = this.formGroup.getRawValue().email;

    this.loginService.forgotPassword(email, this.accountId, this.accountName, this.resellerLoginId).subscribe(
      (x) => {
        this.showForm = !this.showForm;
      },
      (err) => {
        switch (err.results.code) {
          case ApiResponseCodeEnum.USER_DOES_NOT_EXIST:
            this.errMessage = 'error.USER_DOES_NOT_EXIST';
            break;
          case ApiResponseCodeEnum.USER_INACTIVE:
            this.errMessage = 'error.USER_INACTIVE';
            break;
          default:
            this.errMessage = 'forgot_password_page.error.something-wrong';
            break;
        }
      }
    );
  }
}
