import { BadgeModel } from './badge.model';

export class SectionModel {
  constructor(public name: string, public displayOrder: number, public items?: ItemModel[], public id?: number) {}
}

export class ItemModel {
  constructor(
    public ingredientId: number,
    public suffix: string,
    public displayOrder: number,
    public recurrences?: RecurrenceModel[],
    public ingredientWorkflowScheduleAnyTime?: boolean,
    public id?: number,
    public taskId?: number,
    public isTask?: boolean
  ) {}
}

export class ItemModelRow {
  constructor(
    public name: string,
    public detailName: string,
    public suffix: string,
    public displayOrder: number,
    public ingredientId: number,
    public recurrences?: RecurrenceModel[],
    public ingredientWorkflowScheduleAnyTime?: boolean,
    public badges?: BadgeModel[],
    public id?: number,
    public taskId?: number,
    public sectionName?: string,
    public isTask?: boolean
  ) {}
}

export class RecurrenceModel {
  constructor(public taskScheduleAnytime?: boolean, public cronExpression?: string, public sessions?: SessionDetails[], public badges?: BadgeModel[]) {}
}

export class SessionDetails {
  constructor(public session?: number, public id?: number) {}
}

export class GroupedItemsInEvent {
  constructor(public sectionName: string, public items: ItemModelRow[]) {}
}
