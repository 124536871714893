import {
  ChartModel,
  ChartSerie,
  MenuItemReportModel,
  ReportModel,
  UserReportModel,
  MediaReportModel,
  TempReportModel,
  LocationTempReportModel,
  MultiChartModel,
  LocationMissingSessionsModel,
  TempSessionsStatusModel,
  TempSessionsStatusReportModel,
  TempV2ReportModel,
  ManualInputPercentageModel,
  TempComplianceRateReportModel,
  TempSessionsTimeResponse,
  TableDisplayColumn,
  Report,
  TableDisplayColumnNameEnum
} from '../models/Report.model';

import * as Enumerable from 'linq-es2015';
import { isNullOrEmpty, deepClone, getNumberRange, defined, definedAndNotEmptyString, notDefined, getPercentage } from '../../../shared/helpers/app.helpers';
import { TempStatus, tempStatusOrder, TempV2Status, tempV2StatusOrder } from '../models/TempStatus.enum';
import { IGrouping } from 'linq-es2015/lib/enumerable';
import { Injectable } from '@angular/core';
import { DateFormatService } from '../../../shared/services/date-format.service';
import { LocalizationService } from '../../../shared/services/localization/localization.service';
import { languageDefaultDateFormat } from '../../../shared/constants.config';
import { TempSessionStatus } from '../models/SessionStatusEnum';
import {
  IngredientAverageSessionTemp,
  NonAggregatedIngredientStatus,
  NonAggregatedTempLocationCompliancePercentage,
  NotAggregatedCorpComplianceRateMapAlert,
  BrandConfiguration,
  LocationMissingSessionsNonAggregated,
  OccurencesOfMissedSessionsPerLocationNonAggregated,
  TempV2InputsModel,
  NonAggregatedIngredientStatusStore,
  AverageSessionTimeFlatReportModel,
  TempV2ManualOrAutoStoreRecordResponse,
  LocationOutOfRangeCountsReportModel,
  IngredientTempingHistory,
  RequiredTaskCompletionRateCorporateNonAggregated,
  RequiredTaskCompletionRateCorporateAggregated,
  EquipmentTypeTemperatureStatusAggregateModel,
  EquipmentTypeTemperatureStatusFlatModel,
  EquipmentUnitTemperatureStatusStoreModel,
  MeasurementsByWorkflowAggregated,
  MeasurementsByWorkflowFlat,
  ActionsCompletionRate
} from '../models/TemperatureV2.model';
import { MapLocation, MapLegend } from '../components/nome-google-map-chart/models/gmap.models';
import { TranslateService } from '@ngx-translate/core';
import { LocationDetailsModel } from '../models/LocationDetails.model';
import { createGap } from './reports-helper';
import { ReportingInputTypeId } from '../../../shared/models/ReportingInputType.model';

@Injectable({
  providedIn: 'root'
})
export class ReportsMappingHelpers {
  constructor(private dfs: DateFormatService, private localizationService: LocalizationService, private translateService: TranslateService) {}

  MapToChartModelByDate(data: ReportModel[]): { chart: ChartModel[]; series: string[]; total: number } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    data = Enumerable.asEnumerable(data)
      .OrderBy((item) => item.date)
      .ToArray();
    let grouppedArray = Enumerable.asEnumerable(data)
      .GroupBy((g: ReportModel) => this.dfs.formatDate(g.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage()))
      .ToArray();
    if (grouppedArray) {
      grouppedArray.forEach((group) => {
        let chartGroup = new ChartModel(group.key);
        chartGroup.series = group.map((s) => new ChartSerie(this.getModelNameWithCodeIfAvailable(s), s.count));
        series = series.concat(group.map((s) => this.getModelNameWithCodeIfAvailable(s)));
        chartData.push(chartGroup);
      });
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series, total: data[0]?.total };
  }

  MapToChartModelByDOW(data: ReportModel[]): { chart: ChartModel[]; series: string[]; total: number } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    data = Enumerable.asEnumerable(data)
      .OrderBy((item) => item.dayOfTheWeek)
      .ToArray();
    let grouppedArray = Enumerable.asEnumerable(data)
      .GroupBy((g: ReportModel) => g.dayOfTheWeekName)
      .ToArray();
    if (grouppedArray) {
      grouppedArray.forEach((group) => {
        let chartGroup = new ChartModel(this.translateService.instant(group.key.toLocaleLowerCase()));
        chartGroup.series = group.map((s) => new ChartSerie(this.getModelNameWithCodeIfAvailable(s), s.count));
        series = series.concat(group.map((s) => this.getValueOf(s, 'name')));
        chartData.push(chartGroup);
      });
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series, total: data[0]?.total };
  }

  MapToChartModelByHour(data: ReportModel[]): { chart: ChartModel[]; series: string[]; total: number } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let grouppedArray = Enumerable.asEnumerable(data)
      .GroupBy((g: ReportModel) => this.getModelNameWithCodeIfAvailable(g))
      .ToArray();
    if (grouppedArray) {
      grouppedArray.forEach((group) => {
        let chartGroup = new ChartModel(group.key);
        chartGroup.series = group.map((s) => new ChartSerie(s.hour, s.count));
        series = series.concat(group.key);
        chartData.push(chartGroup);
      });
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series, total: data[0]?.total };
  }

  getModelNameWithCodeIfAvailable(data: ReportModel): string {
    const itemCode = this.getValueOf(data, 'code')?.trim()?.replace('NULL', '');
    return definedAndNotEmptyString(itemCode) ? `${itemCode} - ${this.getValueOf(data, 'name')}` : this.getValueOf(data, 'name');
  }

  getValueOf(
    item: ReportModel | TempReportModel<Date | string> | LocationTempReportModel<Date | string> | TempV2ReportModel<Date | string> | NonAggregatedIngredientStatus,
    field: string,
    isNewVersion?: boolean
  ): string {
    switch (field) {
      case 'name':
        return (<MenuItemReportModel>item).itemName ? (<MenuItemReportModel>item).itemName : (<MediaReportModel>item).mediaName ? (<MediaReportModel>item).mediaName : (<UserReportModel>item).fullName;
      case 'code':
        return (<MenuItemReportModel>item).itemCode ? (<MenuItemReportModel>item).itemCode : null;
      case 'status':
        if (isNewVersion) {
          return (<TempV2ReportModel<Date | string>>item).status ? (<TempV2ReportModel<Date | string>>item).status : null;
        } else {
          return (<TempReportModel<Date | string>>item).status ? (<TempReportModel<Date | string>>item).status : null;
        }
      case 'session':
        return (<LocationTempReportModel<Date | string>>item).session ? (<LocationTempReportModel<Date | string>>item).session : null;
      case 'employee':
        return (<LocationTempReportModel<Date | string>>item).employee ? (<LocationTempReportModel<Date | string>>item).employee : null;
      case 'location':
        return (<LocationTempReportModel<Date | string>>item).location ? (<LocationTempReportModel<Date | string>>item).location : null;
      default:
        return '';
    }
  }

  updateTemperatureColumnUnit(report: Report, unitOfMeasure: string): void {
    const valueColum = report.tableDisplayColumns.find((e) => e.name === 'temperature');
    valueColum.suffix = unitOfMeasure;
    report.tooltipFixedUnitSuffix = unitOfMeasure;
  }

  mapToTempMeasurementsByWorkflowAggregatedChart(data: MeasurementsByWorkflowAggregated[]): { chart: ChartModel[]; series: string[] } {
    const inRangeChartModel = new ChartModel(TempV2Status.InRange);
    const missedChartModel = new ChartModel(TempV2Status.Missed);
    const outOfRangeChartModel = new ChartModel(TempV2Status.OutOfRange);
    const charts: ChartModel[] = [outOfRangeChartModel, missedChartModel, inRangeChartModel];
    const series: string[] = charts.sort((a, b) => tempStatusOrder.get(a.name as TempStatus) - tempStatusOrder.get(b.name as TempStatus)).map((chart) => chart.name);

    Enumerable.asEnumerable(data)
      .OrderBy((d) => new Date(d.oneDay))
      .ToArray()
      .forEach((item) => {
        const total = item.inRangeCount + item.outOfRangeCount + item.missedCount;
        const totalMeasured = item.inRangeCount + item.outOfRangeCount;
        inRangeChartModel.series.push(new ChartSerie(new Date(item.oneDay), item.inRangeCount, { total: totalMeasured }));
        outOfRangeChartModel.series.push(new ChartSerie(new Date(item.oneDay), item.outOfRangeCount, { total: totalMeasured }));
        missedChartModel.series.push(new ChartSerie(new Date(item.oneDay), item.missedCount, { total: total }));
      });
    return { chart: charts, series: series };
  }

  mapToTempMeasurementsByWorkflowTable(data: MeasurementsByWorkflowAggregated[] | MeasurementsByWorkflowFlat[]): any[] {
    const dataMapped = data.map((e) => ({
      day: defined(e.oneDay) ? `${this.dfs.formatDate(e.oneDay, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}` : '',
      location: defined(e.locationNumber) ? `${e.locationNumber} - ${e.locationName}` : null,
      InRange: e.inRangeCount,
      OutOfRange: e.outOfRangeCount,
      Missed: e.missedCount
    }));
    return dataMapped;
  }

  MapToTempV2SessionsStatusChartModelByDate(data: TempSessionsStatusReportModel<Date>[]): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let sessions: TempSessionsStatusModel<Date>[] = [];
    let newDisplayData: any[] = [];
    let displayData: any[] = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.date)
      .ToArray();

    displayData.reduce((res: any, value: any) => {
      if (!res[value.date]) {
        res[value.date] = { date: value.date, completedSessionCount: 0, inCompletedSessionCount: 0, missedSessionCount: 0, totalSessionCount: value.totalSessionCount };
        newDisplayData.push(res[value.date]);
      }
      res[value.date].completedSessionCount += value.completedSessionCount;
      res[value.date].missedSessionCount += value.missedSessionCount;
      res[value.date].inCompletedSessionCount += value.inCompletedSessionCount;
      return res;
    }, {});

    newDisplayData.forEach((e) => {
      sessions.push(
        new TempSessionsStatusModel<Date>(e.date, e.completedSessionCount, TempSessionStatus.Completed, e.totalSessionCount),
        new TempSessionsStatusModel<Date>(e.date, e.inCompletedSessionCount, TempSessionStatus.Incompleted, e.totalSessionCount),
        new TempSessionsStatusModel<Date>(e.date, e.missedSessionCount, TempSessionStatus.Missed, e.totalSessionCount)
      );
    });

    let grouppedStatus = Enumerable.asEnumerable(sessions)
      .GroupBy((g: TempSessionsStatusModel<Date>) => g.type)
      .ToArray();

    if (grouppedStatus) {
      grouppedStatus.forEach((group) => {
        let chartGroup = new ChartModel(group.key);
        let _data = group.map((s) => new ChartSerie(new Date(s.date), s.count, { total: s.total }));
        chartGroup.series = _data;
        series = series.concat(group.key);
        chartData.push(chartGroup);
      });
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  MapToTempV2SessionsTimeChartModelByDate(data: TempSessionsTimeResponse<Date>[]): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let groupedByDateAndValue = Enumerable.asEnumerable(data)
      .GroupBy((g: TempSessionsTimeResponse<Date>) => g.date)
      .ToArray();
    groupedByDateAndValue.forEach((groupByDate) => {
      let groupByValue = Enumerable.asEnumerable(groupByDate.values())
        .GroupBy((g: TempSessionsTimeResponse<Date>) => g.sessionTime)
        .ToArray();
      groupByValue.forEach((points) => {
        let tooltip: {}[] = [];
        points.forEach((singlePoint) => {
          tooltip.push({
            name: singlePoint.date,
            value: singlePoint.sessionTime,
            series: singlePoint.session,
            missedText: 'analytics.temp_v2_dashboard.session-status.Missed',
            missed: singlePoint.missedAtDate
          });
        });
        points.forEach((singlePoint) => {
          singlePoint.sameValues = tooltip;
        });
      });
    });
    let displayData: any[] = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => new Date(item.date))
      .ToArray();
    let grouppedStatus = Enumerable.asEnumerable(displayData)
      .GroupBy((g: TempSessionsTimeResponse<Date>) => g.session)
      .ToArray();
    if (grouppedStatus) {
      grouppedStatus.forEach((group) => {
        let chartGroup = new ChartModel(group.key);
        let _data = group.map((s) => {
          const point = new ChartSerie(new Date(s.date), s.sessionTime, {
            missed: s.missedAtDate,
            missedText: 'analytics.temp_v2_dashboard.session-status.Missed',
            sameValues: s.sameValues
          });
          if (s.missedAtDate) {
            point.x = new Date(s.date);
            point.y = s.sessionTime;
            point.r = 15;
          }
          return point;
        });
        chartGroup.series = _data;
        series = series.concat(group.key);
        chartData.push(chartGroup);
      });
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  MapToTempV2SessionStatusTableModelByDate(data: TempSessionsStatusReportModel<Date>[]) {
    let tableData: { day: string; completedSessionCount: number; inCompletedSessionCount: number; missedSessionCount: number; totalSessionCount: number }[] = [];
    let displayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.date)
      .ToArray();

    if (displayData) {
      displayData.forEach((d) => {
        let row = {
          day: `${this.dfs.formatDate(d.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`,
          completedSessionCount: d.completedSessionCount,
          inCompletedSessionCount: d.inCompletedSessionCount,
          missedSessionCount: d.missedSessionCount,
          totalSessionCount: d.totalSessionCount
        };
        tableData.push(row);
      });
    }

    return tableData;
  }

  MapToTempV2SessionStatusGridPieModelByDate(data: TempSessionsStatusReportModel<Date>[]) {
    let chartData: ChartModel[] = [];
    let series: string[] = [TempSessionStatus.Completed, TempSessionStatus.Incompleted, TempSessionStatus.Missed];
    let indexedData = Enumerable.asEnumerable(data)
      .OrderBy((item) => item.date)
      .ToArray();
    var addEmptyChart = false;
    if (indexedData.length >= 7) {
      indexedData.forEach((e, i) => {
        e.index = i;
        if ((i + 1) % 7 == 0) {
          e.index = i - 4;
        }
      });
    } else if (indexedData.length >= 4) addEmptyChart = true;
    let grouppedStatus = Enumerable.asEnumerable(indexedData)
      .OrderBy((item) => item.index)
      .GroupBy((g: any) => g.date)
      .ToArray();
    if (grouppedStatus) {
      grouppedStatus.forEach((group, i) => {
        var day = `${this.dfs.formatDate(group.key, 'EEEE', this.localizationService.getCurrentLanguage(), true)}`;
        var date = `${this.dfs.formatDate(group.key, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`;
        let chartGroup = new ChartModel(date, [], { day });
        group.forEach((eDate) => {
          chartGroup.series.push(new ChartSerie(TempSessionStatus.Completed, eDate.completedSessionCount));
          chartGroup.series.push(new ChartSerie(TempSessionStatus.Incompleted, eDate.inCompletedSessionCount));
          chartGroup.series.push(new ChartSerie(TempSessionStatus.Missed, eDate.missedSessionCount));
          if (eDate.completedSessionCount + eDate.inCompletedSessionCount + eDate.missedSessionCount === 0) chartGroup.extra.isEmptyChart = true;
        });

        if (i === 3 && addEmptyChart) chartData.push(new ChartModel(''));
        chartData.push(chartGroup);
      });
    }

    return { chart: chartData, series: series };
  }

  MapToTempV2IngredientChartModelByDate(data: LocationMissingSessionsModel<Date>[], tempStatus: TempV2Status): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let displayData: any[] = [];

    displayData = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.date)
      .ToArray();

    let grouppedArray = Enumerable.asEnumerable(displayData)
      .GroupBy((g: LocationMissingSessionsModel<Date>) => g.status)
      .SelectMany((group) => {
        return Enumerable.From(group)
          .GroupBy((g: LocationMissingSessionsModel<Date>) => g.date)
          .Select(
            (gg: IGrouping<Date, LocationMissingSessionsModel<Date>>) =>
              new LocationMissingSessionsModel<Date>(
                gg.key,
                Enumerable.From(gg)
                  .Select((gg) => gg.totalLocationsCount)
                  .SingleOrDefault(),
                Enumerable.From(gg)
                  .Select((gg) => gg.totalLocations)
                  .SingleOrDefault(),
                group.key
              )
          )
          .ToArray();
      })
      .GroupBy((g: LocationMissingSessionsModel<Date>) => g.status)
      .ToArray();

    if (grouppedArray) {
      grouppedArray.forEach((group) => {
        let chartGroup = new ChartModel(group.key);
        chartGroup.series = group.map((s) => new ChartSerie(new Date(s.date), s.totalLocationsCount, { total: s.totalLocations }));
        chartData.push(chartGroup);
      });
    }
    series = [TempV2Status.MissingRequiredSessions];
    return { chart: chartData, series: series };
  }

  MapManualInputPercentageByDate(data: ManualInputPercentageModel[]): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = ['manual_input_percentage'];
    let displayData: { day: string; percentage: number }[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.date)
      .ToArray()
      .map((dd) => {
        return {
          day: `${dd.date}`,
          percentage: dd.percentage
        };
      });
    if (displayData) {
      let chartGroup = new ChartModel('manual_input_percentage');
      displayData.forEach((item) => {
        var s = new ChartSerie(new Date(item.day), item.percentage);
        chartGroup.series.push(s);
      });
      chartData.push(chartGroup);
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  mapRequiredTasksCompletionRateAggregatedToChart(data: RequiredTaskCompletionRateCorporateAggregated[]): { chart: ChartModel[]; series: string[] } {
    const chartData: ChartSerie[] = data.map((item) => new ChartSerie(new Date(item.date), item.compliancePercentage, { total: item.requiredTasksCount, value: item.completedTasksCount }));
    return { chart: [new ChartModel('completion_rate', chartData)], series: ['completion_rate'] };
  }

  mapRequiredTasksCompletionRateAggregatedToTable(data: RequiredTaskCompletionRateCorporateAggregated[]): { day: string; rate: number; completedTasksCount: number; requiredTasksCount: number }[] {
    return data.map((dd) => ({
      day: `${this.dfs.formatDate(dd.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`,
      rate: dd.compliancePercentage,
      completedTasksCount: dd.completedTasksCount,
      requiredTasksCount: dd.requiredTasksCount
    }));
  }

  mapActionsCompletionRateToChart(data: ActionsCompletionRate[]): { chart: ChartModel[]; series: string[] } {
    const chartData: ChartSerie[] = data.map((item) => new ChartSerie(new Date(item.date), item.compliancePercentage, { total: item.actionsCount, value: item.completedActionsCount }));
    return { chart: [new ChartModel('completion_rate', chartData)], series: ['completion_rate'] };
  }

  mapActionsCompletionRateToTable(data: ActionsCompletionRate[]): { day: string; rate: number; completedActionsCount: number; actionsCount: number }[] {
    return data.map((dd) => ({
      day: `${this.dfs.formatDate(dd.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`,
      rate: dd.compliancePercentage,
      completedActionsCount: dd.completedActionsCount,
      actionsCount: dd.actionsCount
    }));
  }

  MapManualInputPercentageTableByDate(data: ManualInputPercentageModel[]) {
    let tableData: { day: string; percentage: number }[] = [];
    Enumerable.asEnumerable(deepClone(data))
      .OrderBy((g) => g.date)
      .ToArray()
      .forEach((dd) => {
        let row = {
          day: `${this.dfs.formatDate(dd.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`,
          percentage: dd.percentage
        };
        tableData.push(row);
      });
    return tableData;
  }

  MapToTempV2ComplianceRateChartModelByDate(data: TempComplianceRateReportModel<Date>[], store = false): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let displayData: any[] = [];

    displayData = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.date)
      .ToArray();

    if (displayData) {
      let chartGroup = new ChartModel(store ? 'compliance_rate' : 'average_compliance_rate');
      displayData.forEach((item: TempComplianceRateReportModel<Date>) => {
        chartGroup.series.push(new ChartSerie(new Date(item.date), item.compliancePercentage));
      });
      chartData.push(chartGroup);
    }
    return { chart: chartData, series: series };
  }

  MapToTempV2ComplianceRateTableModel(data: TempComplianceRateReportModel<Date>[], store = false) {
    let tableData: { day: string; average_compliance_rate: number }[] = [];
    let displayData: any[] = [];

    displayData = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.date)
      .ToArray();

    if (displayData) {
      displayData.forEach((item: TempComplianceRateReportModel<Date>) => {
        let row = {
          day: `${this.dfs.formatDate(item.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`,
          average_compliance_rate: item.compliancePercentage
        };
        tableData.push(row);
      });
    }
    return tableData;
  }

  MapToTempV2LocationWithMissedSessionsFlatReportChartModel(data: LocationMissingSessionsNonAggregated[]): { chart: ChartModel[]; series: string[] } {
    return {
      chart: data.map(
        (item: LocationMissingSessionsNonAggregated) =>
          new ChartModel(`${item.noPrefixLocationNumber}`, [
            new ChartSerie(TempSessionStatus.Missed, item.missed),
            new ChartSerie(TempSessionStatus.Completed, item.completed),
            new ChartSerie(TempSessionStatus.Incompleted, item.incomplete)
          ])
      ),
      series: [TempSessionStatus.Missed, TempSessionStatus.Completed, TempSessionStatus.Incompleted]
    };
  }

  mapToTempV2RequiredTasksCompletionRateFlatReportChartModel(data: RequiredTaskCompletionRateCorporateNonAggregated[]): { chart: ChartSerie[] } {
    const chartData: ChartSerie[] = data.map((item) => new ChartSerie(`${item.noPrefixLocationNumber}`, item.compliancePercentage, { total: item.requiredTasksCount, value: item.completedTasksCount }));
    return { chart: chartData };
  }

  mapToTempV2RequiredTasksCompletionRateFlatReportTableModel(data: RequiredTaskCompletionRateCorporateNonAggregated[], accountId: number): { location: string; rate: number; locationNumber: string; tenantId: number }[] {
    return data.map((item) => ({
      location: item.noPrefixLocationNumber + ' - ' + item.locationName,
      rate: item.compliancePercentage,
      locationNumber: item.locationNumber,
      tenantId: accountId,
      completedTasksCount: item.completedTasksCount,
      requiredTasksCount: item.requiredTasksCount
    }));
  }

  mapActionsCompletionRateFlatToChart(data: ActionsCompletionRate[]): { chart: ChartSerie[] } {
    const chartData: ChartSerie[] = data.map((item) => new ChartSerie(`${item.noPrefixLocationNumber}`, item.compliancePercentage, { total: item.actionsCount, value: item.completedActionsCount }));
    return { chart: chartData };
  }

  mapActionsCompletionRateFlatToTable(data: ActionsCompletionRate[], accountId: number): any[] {
    return data.map((item) => ({
      location: item.noPrefixLocationNumber + ' - ' + item.locationName,
      rate: item.compliancePercentage,
      completedActionsCount: item.completedActionsCount,
      actionsCount: item.actionsCount,
      locationNumber: item.locationNumber,
      tenantId: accountId
    }));
  }

  MapToTempV2LocationWithMissedSessionsFlatReportTableModel(data: LocationMissingSessionsNonAggregated[]) {
    let tableData: { location: string; missed: number; completed: number; incomplete: number }[] = [];
    let displayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data)).ToArray();
    if (displayData) {
      displayData.forEach((item: LocationMissingSessionsNonAggregated) => {
        tableData.push({
          location: item.noPrefixLocationNumber + ' - ' + item.locationName,
          missed: item.missed,
          completed: item.completed,
          incomplete: item.incomplete
        });
      });
    }
    return tableData;
  }

  MapToTempV2OccurencesOfMissedSessionsPerLocationFlatReportTableModel(data: OccurencesOfMissedSessionsPerLocationNonAggregated[]) {
    let tableData: { location: string; locationNumber: string; occurrences: number; tenantId: number }[] = [];
    let displayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data)).ToArray();
    if (displayData) {
      displayData.forEach((item: OccurencesOfMissedSessionsPerLocationNonAggregated) => {
        tableData.push({
          location: item.noPrefixLocationNumber + ' - ' + item.locationName,
          locationNumber: item.locationNumber,
          occurrences: item.occurrences,
          tenantId: item.tenantId
        });
      });
    }
    return tableData;
  }

  MapToTempV2AverageSessionTimeFlatReportChartModel(data: AverageSessionTimeFlatReportModel[]): { chart: ChartSerie[]; series: string[] } {
    return { chart: data.map((item) => new ChartSerie(item.noPrefixLocationNumber, item.sessionAverageTime)), series: ['sessionAverageTime'] };
  }

  MapToTempV2AverageSessionTimeFlatReportTableModel(data: AverageSessionTimeFlatReportModel[]) {
    const _data = Enumerable.asEnumerable(data)
      .Select((x) => {
        return {
          location: x.noPrefixLocationNumber + ' - ' + x.locationName,
          sessionAverageTime: x.sessionAverageTime
        };
      })
      .ToArray();
    return _data;
  }

  MapToTempV2IngredientTableModelByDate(data: LocationMissingSessionsModel<Date>[]) {
    let tableData: { day: string; location_missing_sessions: number; total_locations: number }[] = [];
    let groups = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((g) => g.date)
      .GroupBy((g: LocationMissingSessionsModel<Date>) => g.date)
      .ToArray();

    groups.forEach((group) => {
      let row: { day: string; location_missing_sessions: number; total_locations: number } = {
        day: `${this.dfs.formatDate(group.key, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`,
        location_missing_sessions: 0,
        total_locations: 0
      };
      row.location_missing_sessions = Enumerable.asEnumerable(group)
        .Select((g) => g.totalLocationsCount)
        .Single();
      row.total_locations = Enumerable.asEnumerable(group)
        .Select((g) => g.totalLocations)
        .Single();
      tableData.push(row);
    });
    return tableData;
  }

  MapToTempIngredientChartModelByDate(data: TempReportModel<Date>[]): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let displayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data))
      .Where((item) => !isNullOrEmpty(this.getValueOf(item, 'status')))
      .OrderBy((item) => item.date)
      .ToArray();
    displayData.forEach((dd) => {
      dd.date = `${this.dfs.formatDate(dd.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`;
    });
    let grouppedArray = Enumerable.asEnumerable(displayData)
      .GroupBy((g: TempReportModel<string>) => g.date)
      .ToArray();
    if (grouppedArray) {
      grouppedArray.forEach((group) => {
        let chartGroup = new ChartModel(group.key);
        chartGroup.series = group.map((s) => new ChartSerie(this.getValueOf(s, 'status'), s.count));
        chartGroup.series = Enumerable.asEnumerable(chartGroup.series)
          .OrderBy((s) => tempStatusOrder.get(s.name))
          .ThenBy((s) => s)
          .ToArray();
        series = series.concat(group.map((s) => this.getValueOf(s, 'status')));
        chartData.push(chartGroup);
      });
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  MapToTempV2InputsNonAggregatedChartModelByLocation(data: TempV2InputsModel[]): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let displayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data))
      .OrderByDescending((item) => item.manualInput)
      .ToArray();
    let grouppedArray = Enumerable.asEnumerable(displayData)
      .GroupBy((g: TempV2InputsModel) => g.noPrefixLocationNumber)
      .ToArray();
    if (grouppedArray) {
      grouppedArray.forEach((group) => {
        let chartGroup = new ChartModel(group.key);
        group.forEach((s) => {
          chartGroup.series.push(new ChartSerie(TempV2Status.App, s.autoInput));
          chartGroup.series.push(new ChartSerie(TempV2Status.Manually, s.manualInput));
        });
        chartGroup.series = Enumerable.asEnumerable(chartGroup.series)
          .OrderBy((s) => tempV2StatusOrder.get(s.name))
          .ThenBy((s) => s)
          .ToArray();
        chartData.push(chartGroup);
      });
      series.push(TempV2Status.App, TempV2Status.Manually);
    }
    return { chart: chartData, series: series };
  }

  mapToTempMeasurementsByWorkflowFlatChart(data: MeasurementsByWorkflowFlat[]): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [TempV2Status.InRange, TempV2Status.OutOfRange, TempV2Status.Missed];
    data.forEach((location) => {
      let chartGroup = new ChartModel(location.locationNumber);
      chartGroup.series.push(new ChartSerie(TempV2Status.InRange, location.inRangeCount));
      chartGroup.series.push(new ChartSerie(TempV2Status.OutOfRange, location.outOfRangeCount));
      chartGroup.series.push(new ChartSerie(TempV2Status.Missed, location.missedCount));
      chartData.push(chartGroup);
    });
    return { chart: chartData, series: series };
  }

  MapToTempV2InputsAggregatedChartModelByDate_LocationManager(data: TempV2ManualOrAutoStoreRecordResponse[]): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let displayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.date)
      .ToArray();
    let grouppedArray = Enumerable.asEnumerable(displayData)
      .GroupBy((g: TempV2ManualOrAutoStoreRecordResponse) => g.date)
      .ToArray();
    if (grouppedArray) {
      grouppedArray.forEach((group) => {
        const date = `${this.dfs.formatDate(new Date(group.key), languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`;
        let chartGroup = new ChartModel(date);
        group.forEach((s) => {
          chartGroup.series.push(new ChartSerie(TempV2Status.App, s.autoInput));
          chartGroup.series.push(new ChartSerie(TempV2Status.Manually, s.manualInput));
        });
        chartGroup.series = Enumerable.asEnumerable(chartGroup.series)
          .OrderBy((s) => tempV2StatusOrder.get(s.name))
          .ThenBy((s) => s)
          .ToArray();
        chartData.push(chartGroup);
      });
      series.push(TempV2Status.App, TempV2Status.Manually);
    }
    return { chart: chartData, series: series };
  }

  MapToTempV2InputsNonAggregatedTableModelByLocation(data: TempV2InputsModel[]) {
    let tableData: { location: string; Manually: number; App: number }[] = [];
    let displayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data))
      .OrderByDescending((item) => item.manualInput)
      .ToArray();
    if (displayData) {
      displayData.forEach((d) => {
        let row = { location: `${d.noPrefixLocationNumber} - ${d.locationName}`, Manually: d.manualInput, App: d.autoInput };
        tableData.push(row);
      });
    }
    return tableData;
  }

  MapToTempV2InputsAggregatedTableModelByDate_LocationManager(data: TempV2ManualOrAutoStoreRecordResponse[]) {
    let tableData: { day: string; Manually: number; App: number }[] = [];
    let displayData: TempV2ManualOrAutoStoreRecordResponse[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.date)
      .ToArray();
    if (displayData) {
      displayData.forEach((d) => {
        let row = {
          day: `${this.dfs.formatDate(d.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`,
          Manually: d.manualInput,
          App: d.autoInput
        };
        tableData.push(row);
      });
    }
    return tableData;
  }

  MapToTempV2IngredientStatusChartModelByDate(data: TempV2ReportModel<Date>[], tempStatus?: TempV2Status | TempV2Status[]): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let displayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data))
      .Where(
        (item) =>
          !isNullOrEmpty(this.getValueOf(item, 'status', true)) &&
          (tempStatus ? (Array.isArray(tempStatus) ? tempStatus.indexOf(<TempV2Status>this.getValueOf(item, 'status')) >= 0 : this.getValueOf(item, 'status').includes(tempStatus)) : true)
      )
      .OrderBy((item) => item.date)
      .ToArray();
    displayData.forEach((dd) => {
      dd.date = `${this.dfs.formatDate(dd.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`;
    });
    let grouppedArray = Enumerable.asEnumerable(displayData)
      .GroupBy((g: TempV2ReportModel<string>) => g.date)
      .ToArray();
    if (grouppedArray) {
      grouppedArray.forEach((group) => {
        let chartGroup = new ChartModel(group.key);
        chartGroup.series = group.filter((s) => s.count >= 0).map((s) => new ChartSerie(this.getValueOf(s, 'status'), getPercentage(s.count, s.totalCount), { value: s.count, total: s.totalCount }));
        chartGroup.series = Enumerable.asEnumerable(chartGroup.series)
          .OrderBy((s) => tempV2StatusOrder.get(s.name))
          .ThenBy((s) => s)
          .ToArray();
        series = series.concat(chartGroup.series.map((s) => s.name));
        chartData.push(chartGroup);
      });
    }

    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  MapToTempV2NonAggregatedIngredientStatusChart(data: NonAggregatedIngredientStatus[], tempStatus?: TempV2Status): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let displayData = Enumerable.asEnumerable(deepClone(data))
      .GroupBy((i) => `${i.noPrefixLocationNumber}`)
      .ToArray();
    if (displayData) {
      displayData.forEach((grouppedLocation) => {
        let groupSeries = [];
        const grouppedStatus = Enumerable.asEnumerable(grouppedLocation)
          .GroupBy((l) => l.status)
          .ToArray();
        const total = grouppedLocation.length > 0 ? grouppedLocation[0].totalCount : 0;
        let chartGroup = new ChartModel(grouppedLocation.key, [], { total });
        if (grouppedStatus) {
          grouppedStatus.forEach((gStatus) => {
            groupSeries = [...groupSeries, ...gStatus.map((s) => new ChartSerie(s.status, getPercentage(s.count, s.totalCount), { value: s.count, total: s.totalCount }))];
          });
        }
        groupSeries = Enumerable.asEnumerable(groupSeries)
          .OrderBy((s) => tempV2StatusOrder.get(s.name))
          .ToArray();
        chartGroup.series = groupSeries;
        series = series.concat(chartGroup.series.map((s) => s.name));
        chartData.push(chartGroup);
      });
    }
    if (tempStatus === TempV2Status.InRange) {
      chartData.sort((a, b) => b.series[0].value - a.series[0].value);
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  MapToTempV2NonAggregatedIngredientStatusChart_Store(data: NonAggregatedIngredientStatusStore[]): { chart: MultiChartModel[]; series: string[]; maxValue: number } {
    let chartData: MultiChartModel[] = [];
    let series: string[] = [];
    const grouppedByDate = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((x) => x.date)
      .ThenBy((x) => tempV2StatusOrder.get(<TempV2Status>x.status))
      .GroupBy((g) => g.date)
      .ToArray();
    if (grouppedByDate) {
      grouppedByDate.forEach((group) => {
        let groupData: ChartModel[] = [];
        const grouppedBySession = Enumerable.asEnumerable(group)
          .GroupBy((g) => g.session)
          .ToArray();
        grouppedBySession.forEach((_groupedSession) => {
          const groupedSession = Enumerable.asEnumerable(_groupedSession).ToArray();
          let chartGroup = new ChartModel(_groupedSession.key == null ? ' ' : _groupedSession.key);
          const totalCount = Enumerable.asEnumerable(_groupedSession).Sum((item) => item.count);
          groupedSession.forEach((s) => {
            chartGroup.series.push(new ChartSerie(s.status, getPercentage(s.count, totalCount), { value: s.count, total: totalCount }));
          });
          // chartGroup.series = groupedSession.map(s => new ChartSerie(s.status, s.count));
          groupData.push(chartGroup);
          series = series.concat(group.map((s) => s.status));
        });
        const date = `${this.dfs.formatDate(new Date(group.key), languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`;
        chartData.push(new MultiChartModel(date, groupData));
      });
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    const maxValue = Enumerable.asEnumerable(chartData)
      .Where((chart) => chart.data.length > 0)
      .Max((cd) => {
        return Enumerable.asEnumerable(cd.data).Max((d) => {
          return Enumerable.asEnumerable(d.series).Sum((s) => s.value);
        });
      });
    return { chart: chartData, series: series, maxValue };
  }

  MapToTempV2TempIngredientHistory(data: IngredientTempingHistory[]): { chart: MultiChartModel[]; series: string[]; maxValue: number } {
    let chartData: MultiChartModel[] = [];
    let series: string[] = [];
    let displayData: Enumerable.IEnumerable<IngredientTempingHistory> = Enumerable.asEnumerable(deepClone(data));
    let grouppedByDate = Enumerable.asEnumerable(displayData)
      .GroupBy((g) => g.date)
      .ToArray();
    var max = 0;
    if (grouppedByDate) {
      grouppedByDate.forEach((group) => {
        let groupData: ChartModel[] = [];
        let index = 0;
        group.forEach((s) => {
          if (s.sessionName) {
            let chartGroup = new ChartModel(s.sessionName + '_' + index); // index is added so that each measuremtn is cnsidered a chart
            chartGroup.series.push(
              new ChartSerie(s.isOutOfRange ? TempV2Status.OutOfRange : TempV2Status.InRange, s.totalValue, {
                name: s.sessionName,
                status: s.isOutOfRange ? 'analytics.temp_v2_dashboard.status.OutOfRange' : 'analytics.temp_v2_dashboard.status.InRange'
              })
            );
            index++;
            if (s.totalValue > max) max = s.totalValue;
            groupData.push(chartGroup);
          }
        });
        const date = `${this.dfs.formatDate(new Date(group.key), 'EEEE', this.localizationService.getCurrentLanguage(), true)} \n ${this.dfs.formatDate(
          new Date(group.key),
          languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate,
          this.localizationService.getCurrentLanguage()
        )}`;
        chartData.push(new MultiChartModel(date, groupData));
      });
    }
    series = [TempV2Status.InRange, TempV2Status.OutOfRange];
    return { chart: chartData, series: series, maxValue: max };
  }

  MapToTempV2NonAggregatedIngredientStatusTable(data: NonAggregatedIngredientStatus[], tempStatus?: TempV2Status) {
    let displayData = Enumerable.asEnumerable(deepClone(data))
      .GroupBy((i) => `${i.noPrefixLocationNumber} - ${i.locationName}`)
      .ToArray();
    let table = [];
    if (displayData) {
      displayData.forEach((grouppedLocation) => {
        table.push({
          location: grouppedLocation.key,
          InRange: Enumerable.asEnumerable(grouppedLocation)
            .Where((g) => g.status == TempV2Status.InRange)
            .Sum((g) => g.count),
          OutOfRange: Enumerable.asEnumerable(grouppedLocation)
            .Where((g) => g.status == TempV2Status.OutOfRange)
            .Sum((g) => g.count),
          Missed: Enumerable.asEnumerable(grouppedLocation)
            .Where((g) => g.status == TempV2Status.Missed)
            .Sum((g) => g.count)
        });
      });
    }
    if (tempStatus === TempV2Status.InRange) {
      table.sort((a, b) => b.InRangeAfterAction - a.InRangeAfterAction);
    }
    return table;
  }

  MapToTempV2NonAggregatedIngredientStatusTable_Store(data: NonAggregatedIngredientStatusStore[]) {
    let displayData = Enumerable.asEnumerable(deepClone(data))
      .GroupBy((i) => `${i.date}-${i.session}`)
      .ToArray();
    let table = [];
    if (displayData) {
      displayData.forEach((dataset) => {
        const data = Enumerable.asEnumerable(deepClone(dataset)).ToArray();
        if (data.length > 0) {
          table.push({
            day: `${this.dfs.formatDate(data[0].date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`,
            session: data[0].session,
            InRange: Enumerable.asEnumerable(data)
              .Where((g) => g.status == TempV2Status.InRange)
              .Sum((g) => g.count),
            OutOfRange: Enumerable.asEnumerable(data)
              .Where((g) => g.status == TempV2Status.OutOfRange)
              .Sum((g) => g.count),
            Missed: Enumerable.asEnumerable(data)
              .Where((g) => g.status == TempV2Status.Missed)
              .Sum((g) => g.count)
          });
        }
      });
    }

    return table;
  }

  MapToTempIngredientTableModelByDate(data: TempReportModel<Date>[]) {
    let tableData: { day: string; InRange: number; OutOfRange: number; Missed: number }[] = [];
    let groups = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((g) => g.date)
      .GroupBy((g: TempReportModel<Date>) => g.date)
      .ToArray();
    groups.forEach((group) => {
      let row: { day: string; InRange: number; OutOfRange: number; Missed: number } = {
        day: `${this.dfs.formatDate(group.key, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`,
        InRange: 0,
        OutOfRange: 0,
        Missed: 0
      };
      row.InRange = Enumerable.asEnumerable(group)
        .Where((g) => g.status == TempStatus.InRange)
        .Sum((g) => g.count);
      row.OutOfRange = Enumerable.asEnumerable(group)
        .Where((g) => g.status == TempStatus.OutOfRange)
        .Sum((g) => g.count);
      row.Missed = Enumerable.asEnumerable(group)
        .Where((g) => g.status == TempStatus.Missed)
        .Sum((g) => g.count);
      tableData.push(row);
    });
    return tableData;
  }

  MapToTempV2IngredientStatusTableModelByDate(data: TempV2ReportModel<Date>[]) {
    let tableData: { day: string; InRange: number; OutOfRange: number; Missed: number }[] = [];
    let groups = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((g) => g.date)
      .GroupBy((g: TempV2ReportModel<Date>) => g.date)
      .ToArray();
    groups.forEach((group) => {
      let row: { day: string; InRange: number; InRangeAfterAction: number; OutOfRange: number; OutOfRangeAfterAction: number; Missed: number } = {
        day: `${this.dfs.formatDate(group.key, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`,
        InRange: 0,
        InRangeAfterAction: 0,
        OutOfRange: 0,
        OutOfRangeAfterAction: 0,
        Missed: 0
      };
      row.InRange = Enumerable.asEnumerable(group)
        .Where((g) => g.status == TempV2Status.InRange)
        .Sum((g) => g.count);
      row.OutOfRange = Enumerable.asEnumerable(group)
        .Where((g) => g.status == TempV2Status.OutOfRange)
        .Sum((g) => g.count);
      row.Missed = Enumerable.asEnumerable(group)
        .Where((g) => g.status == TempV2Status.Missed)
        .Sum((g) => g.count);
      tableData.push(row);
    });
    return tableData;
  }

  MapToTempIngredientTotalByStatusModel(data: TempReportModel<Date>[], tempStatus: TempStatus): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let displayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data))
      .Where((item) => !isNullOrEmpty(this.getValueOf(item, 'status')) && this.getValueOf(item, 'status') == tempStatus)
      .OrderBy((item) => item.date)
      .ToArray();
    let grouppedArray = Enumerable.asEnumerable(displayData)
      .GroupBy((g: TempReportModel<Date>) => g.status)
      .SelectMany((group) => {
        return Enumerable.From(group)
          .GroupBy((g: TempReportModel<Date>) => g.date)
          .Select(
            (gg: IGrouping<Date, TempReportModel<Date>>) =>
              new TempReportModel<Date>(
                Enumerable.From(gg).Sum((gg) => gg.count),
                gg.key,
                group.key
              )
          )
          .ToArray();
      })
      .GroupBy((g: TempReportModel<Date>) => g.status)
      .ToArray();
    if (grouppedArray) {
      grouppedArray.forEach((group) => {
        let chartGroup = new ChartModel(group.key);
        chartGroup.series = group.map((s) => new ChartSerie(new Date(s.date), s.count));
        series = series.concat(group.map((s) => this.getValueOf(s, 'status')));
        chartData.push(chartGroup);
      });
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  MapToTempIngredientTotalByStatusTable(data: TempReportModel<Date>[], tempStatus: TempStatus) {
    let displayData = Enumerable.asEnumerable(deepClone(data))
      .Where((item) => !isNullOrEmpty(this.getValueOf(item, 'status')) && this.getValueOf(item, 'status') == tempStatus)
      .OrderBy((item) => item.date)
      .GroupBy((g: TempReportModel<Date>) => g.status)
      .SelectMany((group) => {
        return Enumerable.From(group)
          .GroupBy((g: TempReportModel<Date>) => g.date)
          .Select(
            (gg: IGrouping<Date, TempReportModel<Date>>) =>
              new TempReportModel<Date>(
                Enumerable.From(gg).Sum((gg) => gg.count),
                gg.key,
                group.key
              )
          )
          .ToArray();
      }) //.GroupBy((g: TempReportModel<Date>) => (g.status))
      .ToArray()
      .map((item: TempReportModel<Date>) => {
        switch (tempStatus) {
          case TempStatus.InRange:
            return {
              day: `${this.dfs.formatDate(item.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`,
              InRange: item.count
            };
          case TempStatus.OutOfRange:
            return {
              day: `${this.dfs.formatDate(item.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`,
              OutOfRange: item.count
            };
          case TempStatus.Missed:
            return { day: `${this.dfs.formatDate(item.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`, Missed: item.count };
        }
      });
    return displayData;
  }

  MapToTempV2IngredientTotalByStatusNonAggregatedModel(data: NonAggregatedIngredientStatus[], tempStatus: TempV2Status, setMeasuredCountAsTotal: boolean = false): { chart: ChartSerie[]; series: string[] } {
    //totalLocations not in api yet!!
    let chartData: ChartSerie[] = [];
    let series: string[] = [];
    let displayData: any[] = [];
    let newDisplayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data))
      .OrderByDescending((item) => item.count)
      .ToArray();
    displayData.reduce((res: any, value: any) => {
      if (!res[value.locationNumber]) {
        res[value.locationNumber] = {
          noPrefixLocationNumber: value.noPrefixLocationNumber,
          locationNumber: value.locationNumber,
          locationName: value.locationName,
          status: tempStatus,
          count: 0,
          totalCount: value.totalCount,
          totalMeasuredCount: value.totalMeasuredCount
        };
        newDisplayData.push(res[value.locationNumber]);
      }
      res[value.locationNumber].count += value.count;
      return res;
    }, {});
    let grouppedArray = Enumerable.asEnumerable(newDisplayData)
      .GroupBy((g: NonAggregatedIngredientStatus) => g.status)
      .SelectMany((group) => {
        return Enumerable.From(group)
          .GroupBy((g: NonAggregatedIngredientStatus) => g.noPrefixLocationNumber)
          .Select(
            (gg: IGrouping<string, NonAggregatedIngredientStatus>) =>
              new NonAggregatedIngredientStatus(
                gg.key,
                Enumerable.From(gg)
                  .Select((gg) => gg.locationName)
                  .SingleOrDefault(),
                group.key,
                Enumerable.From(gg).Sum((gg) => gg.count),
                Enumerable.From(gg)
                  .Select((gg) => gg.totalCount)
                  .SingleOrDefault(),
                Enumerable.From(gg)
                  .Select((gg) => gg.totalMeasuredCount)
                  .SingleOrDefault()
              )
          )
          .ToArray();
      })
      .GroupBy((g: NonAggregatedIngredientStatus) => g.status)
      .ToArray();
    if (grouppedArray) {
      grouppedArray.forEach((group) => {
        chartData = group.map((s) => new ChartSerie(`${s.locationNumber}`, s.count, { total: setMeasuredCountAsTotal ? s.totalMeasuredCount : s.totalCount }));
        series = series.concat(group.map((s) => this.getValueOf(s, 'status')));
      });
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  MapToTempV2IngredientTotalByStatusNonAggregatedTable(data: NonAggregatedIngredientStatus[], tempStatus: TempV2Status) {
    let newDisplayData: any[] = [];
    let displayData = Enumerable.asEnumerable(deepClone(data))
      .Where((item) => !isNullOrEmpty(this.getValueOf(item, 'status')) && this.getValueOf(item, 'status').includes(tempStatus))
      .OrderByDescending((item) => item.count)
      .ToArray();

    displayData.reduce((res: any, value: any) => {
      if (!res[value.locationNumber]) {
        res[value.locationNumber] = {
          noPrefixLocationNumber: value.noPrefixLocationNumber,
          locationNumber: value.locationNumber,
          locationName: value.locationName,
          status: tempStatus,
          count: 0,
          totalCount: value.totalCount,
          totalMeasuredCount: value.totalMeasuredCount
        };
        newDisplayData.push(res[value.locationNumber]);
      }
      res[value.locationNumber].count += value.count;
      return res;
    }, {});

    let grouppedArray = Enumerable.asEnumerable(newDisplayData)
      .GroupBy((g: NonAggregatedIngredientStatus) => g.status)
      .SelectMany((group) => {
        return Enumerable.From(group)
          .GroupBy((g: NonAggregatedIngredientStatus) => g.noPrefixLocationNumber)
          .Select(
            (gg: IGrouping<string, NonAggregatedIngredientStatus>) =>
              new NonAggregatedIngredientStatus(
                gg.key,
                Enumerable.From(gg)
                  .Select((gg) => gg.locationName)
                  .SingleOrDefault(),
                group.key,
                Enumerable.From(gg).Sum((gg) => gg.count),
                Enumerable.From(gg)
                  .Select((gg) => gg.totalCount)
                  .SingleOrDefault(),
                Enumerable.From(gg)
                  .Select((gg) => gg.totalMeasuredCount)
                  .SingleOrDefault()
              )
          )
          .ToArray();
      })
      .ToArray()
      .map((item: NonAggregatedIngredientStatus) => {
        switch (tempStatus) {
          case TempV2Status.InRange:
            return { location: `${item.locationNumber} - ${item.locationName}`, InRange: item.count, total_measurements: item.totalMeasuredCount };
          case TempV2Status.OutOfRange:
            return { location: `${item.locationNumber} - ${item.locationName}`, OutOfRange: item.count, total_measurements: item.totalMeasuredCount };
          case TempV2Status.Missed:
            return { location: `${item.locationNumber} - ${item.locationName}`, Missed: item.count, total_measurements: item.totalCount };
        }
      });
    return grouppedArray;
  }

  MapToTempV2IngredientTotalByStatusModel(data: TempV2ReportModel<Date>[], tempStatus: TempV2Status, useTotalMeasurementsCountInTooltip: boolean = false): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let displayData: any[] = [];
    let newDisplayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data))
      .Where((item) => !isNullOrEmpty(this.getValueOf(item, 'status')) && this.getValueOf(item, 'status').includes(tempStatus))
      .OrderBy((item) => item.date)
      .ToArray();
    displayData.reduce((res: any, value: any) => {
      if (!res[value.date]) {
        res[value.date] = { date: value.date, status: tempStatus, count: 0, totalCount: value.totalCount };
        if (useTotalMeasurementsCountInTooltip) res[value.date].totalMeasuredCount = value.totalMeasuredCount;
        newDisplayData.push(res[value.date]);
      }
      res[value.date].count += value.count;
      return res;
    }, {});

    let grouppedArray = Enumerable.asEnumerable(newDisplayData)
      .GroupBy((g: TempV2ReportModel<Date>) => g.status)
      .SelectMany((group) => {
        return Enumerable.From(group)
          .GroupBy((g: TempV2ReportModel<Date>) => g.date)
          .Select(
            (gg: IGrouping<Date, TempV2ReportModel<Date>>) =>
              new TempV2ReportModel<Date>(
                Enumerable.From(gg).Sum((gg) => gg.count),
                Enumerable.From(gg)
                  .Select((gg) => gg.totalCount)
                  .SingleOrDefault(),
                Enumerable.From(gg)
                  .Select((gg) => gg.totalMeasuredCount)
                  .SingleOrDefault(),
                gg.key,
                group.key
              )
          )
          .ToArray();
      })
      .GroupBy((g: TempV2ReportModel<Date>) => g.status)
      .ToArray();
    if (grouppedArray) {
      grouppedArray.forEach((group) => {
        let chartGroup = new ChartModel(group.key);
        chartGroup.series = group.map((s) => new ChartSerie(new Date(s.date), s.count, { total: useTotalMeasurementsCountInTooltip ? s.totalMeasuredCount : s.totalCount }));
        series = series.concat(group.map((s) => this.getValueOf(s, 'status')));
        chartData.push(chartGroup);
      });
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  MapToTempV2SessionsTimeTable(data: TempSessionsTimeResponse<Date>[]) {
    let tableData: { day: string; session: string; sessionTime: number }[] = [];
    let displayData: TempSessionsTimeResponse<Date>[] = [];

    displayData = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.date)
      .ToArray();

    if (displayData) {
      displayData.forEach((d) => {
        let row = {
          day: `${this.dfs.formatDate(d.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`,
          session: d.session,
          sessionTime: d.missedAtDate ? this.translateService.instant('analytics.temp_v2_dashboard.table-columns.missed_sessions') : d.sessionTime
        };
        tableData.push(row);
      });
    }

    return tableData;
  }

  MapToTempV2IngredientTotalByStatusTable(data: TempV2ReportModel<Date>[], tempStatus: TempV2Status) {
    let newDisplayData: any[] = [];
    let displayData = Enumerable.asEnumerable(deepClone(data))
      .Where((item) => !isNullOrEmpty(this.getValueOf(item, 'status')) && this.getValueOf(item, 'status').includes(tempStatus))
      .OrderBy((item) => item.date)
      .ToArray();

    displayData.reduce((res: any, value: any) => {
      if (!res[value.date]) {
        res[value.date] = { date: value.date, status: tempStatus, count: 0, totalCount: value.totalCount, totalMeasuredCount: value.totalMeasuredCount };
        newDisplayData.push(res[value.date]);
      }
      res[value.date].count += value.count;
      return res;
    }, {});

    let grouppedArray = Enumerable.asEnumerable(newDisplayData)
      .GroupBy((g: TempV2ReportModel<Date>) => g.status)
      .SelectMany((group) => {
        return Enumerable.From(group)
          .GroupBy((g: TempV2ReportModel<Date>) => g.date)
          .Select(
            (gg: IGrouping<Date, TempV2ReportModel<Date>>) =>
              new TempV2ReportModel<Date>(
                Enumerable.From(gg).Sum((gg) => gg.count),
                Enumerable.From(gg)
                  .Select((gg) => gg.totalCount)
                  .SingleOrDefault(),
                Enumerable.From(gg)
                  .Select((gg) => gg.totalMeasuredCount)
                  .SingleOrDefault(),
                gg.key,
                group.key
              )
          )
          .ToArray();
      }) //.GroupBy((g: TempReportModel<Date>) => (g.status))
      .ToArray()
      .map((item: TempV2ReportModel<Date>) => {
        switch (tempStatus) {
          case TempV2Status.InRange:
            return {
              day: `${this.dfs.formatDate(item.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`,
              InRange: item.count,
              total_measurements: item.totalMeasuredCount
            };
          case TempV2Status.OutOfRange:
            return {
              day: `${this.dfs.formatDate(item.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`,
              OutOfRange: item.count,
              total_measurements: item.totalMeasuredCount
            };
          case TempV2Status.Missed:
            return {
              day: `${this.dfs.formatDate(item.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`,
              Missed: item.count,
              total_measurements: item.totalCount
            };
        }
      });
    return grouppedArray;
  }

  MapToTempV2AverageSessionTime(data: IngredientAverageSessionTemp[]) {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let displayData: IngredientAverageSessionTemp[] = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((element) => element.sessionDate)
      .ToArray();

    let chartGroup = new ChartModel('average_session_time');
    chartGroup.series = displayData.map((s) => new ChartSerie(new Date(s.sessionDate), s.sessionAverageTime));
    chartData.push(chartGroup);
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  MapToTempV2AverageSessionTimeTable(data: IngredientAverageSessionTemp[]) {
    return Enumerable.asEnumerable(deepClone(data))
      .OrderBy((item) => item.sessionDate)
      .Select((item) => {
        return {
          sessionAverageTime: item.sessionAverageTime,
          sessionDate: `${this.dfs.formatDate(new Date(item.sessionDate), languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`
        };
      })
      .ToArray();
  }

  // Location level reports

  MapToTempIngredientChartModelByLocation(data: LocationTempReportModel<Date>[]): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let displayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data))
      .Where((item) => !isNullOrEmpty(this.getValueOf(item, 'status')))
      .OrderBy((item) => item.location)
      .ToArray();
    let grouppedArray = Enumerable.asEnumerable(displayData)
      .GroupBy((g: LocationTempReportModel<Date>) => g.location)
      .SelectMany((group) => {
        return Enumerable.From(group)
          .GroupBy((g: LocationTempReportModel<Date>) => g.status)
          .Select(
            (gg: IGrouping<TempStatus, LocationTempReportModel<Date>>) =>
              new LocationTempReportModel<Date>(
                Enumerable.From(gg).Sum((gg) => gg.count),
                null,
                gg.key,
                group.key
              )
          )
          .ToArray();
      })
      .GroupBy((g: LocationTempReportModel<Date>) => g.location)
      .ToArray();
    if (grouppedArray) {
      grouppedArray.forEach((group) => {
        let chartGroup = new ChartModel(group.key);
        chartGroup.series = group.map((s) => new ChartSerie(this.getValueOf(s, 'status'), s.count));
        chartGroup.series = Enumerable.asEnumerable(chartGroup.series)
          .OrderBy((s) => tempStatusOrder.get(s.name))
          .ThenBy((s) => s)
          .ToArray();
        series = series.concat(group.map((s) => this.getValueOf(s, 'status')));
        chartData.push(chartGroup);
      });
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  MapToTempIngredientTableModelByLocation(data: LocationTempReportModel<string>[]): { location: string; InRange: number; OutOfRange: number; Missed: number }[] {
    let tableData: { location: string; InRange: number; OutOfRange: number; Missed: number }[] = [];
    let groups = Enumerable.asEnumerable(deepClone(data))
      .OrderBy((g) => g.location)
      .GroupBy((g: LocationTempReportModel<string>) => g.location)
      .ToArray();
    groups.forEach((group) => {
      let row: { location: string; InRange: number; OutOfRange: number; Missed: number } = { location: group.key, InRange: 0, OutOfRange: 0, Missed: 0 };
      row.InRange = Enumerable.asEnumerable(group)
        .Where((g) => g.status == TempStatus.InRange)
        .Sum((g) => g.count);
      row.OutOfRange = Enumerable.asEnumerable(group)
        .Where((g) => g.status == TempStatus.OutOfRange)
        .Sum((g) => g.count);
      row.Missed = Enumerable.asEnumerable(group)
        .Where((g) => g.status == TempStatus.Missed)
        .Sum((g) => g.count);
      tableData.push(row);
    });
    return tableData;
  }

  MapToTempIngredientTotalByLocation(data: LocationTempReportModel<Date>[], tempStatus: TempStatus): { chart: ChartSerie[]; series: string[] } {
    let chartData: ChartSerie[] = [];
    let series: string[] = [];
    let displayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data))
      .Where((item) => !isNullOrEmpty(this.getValueOf(item, 'status')) && this.getValueOf(item, 'status') == tempStatus)
      .OrderBy((item) => item.location)
      .ToArray();

    let grouppedArray = Enumerable.asEnumerable(displayData)
      .GroupBy((g: LocationTempReportModel<Date>) => g.location)
      .SelectMany((group) => {
        return Enumerable.From(group)
          .GroupBy((g: LocationTempReportModel<Date>) => g.status)
          .Select(
            (gg: IGrouping<TempStatus, LocationTempReportModel<Date>>) =>
              new LocationTempReportModel<Date>(
                Enumerable.From(gg).Sum((gg) => gg.count),
                null,
                gg.key,
                group.key
              )
          )
          .ToArray();
      })
      .GroupBy((g: LocationTempReportModel<Date>) => g.location)
      .ToArray();
    if (grouppedArray) {
      grouppedArray.forEach((group) => {
        chartData.push(group.map((s) => new ChartSerie(group.key, s.count))[0]);
        series = series.concat(group.map((s) => this.getValueOf(s, 'status')));
      });
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  MapToTempIngredientTotalByLocationTable(data: LocationTempReportModel<Date>[], tempStatus: TempStatus) {
    let displayData = Enumerable.asEnumerable(deepClone(data))
      .Where((item) => !isNullOrEmpty(this.getValueOf(item, 'status')) && this.getValueOf(item, 'status') == tempStatus)
      .OrderBy((item) => item.location)
      .GroupBy((g: LocationTempReportModel<Date>) => g.location)
      .SelectMany((group) => {
        return Enumerable.From(group)
          .GroupBy((g: LocationTempReportModel<Date>) => g.status)
          .Select(
            (gg: IGrouping<TempStatus, LocationTempReportModel<Date>>) =>
              new LocationTempReportModel<Date>(
                Enumerable.From(gg).Sum((gg) => gg.count),
                null,
                gg.key,
                group.key
              )
          )
          .ToArray();
      })
      .ToArray()
      .map((item) => {
        switch (tempStatus) {
          case TempStatus.InRange:
            return { location: item.location, InRange: item.count };
          case TempStatus.OutOfRange:
            return { location: item.location, OutOfRange: item.count };
          case TempStatus.Missed:
            return { location: item.location, Missed: item.count };
        }
      });
    return displayData;
  }

  // Single Store

  MapToTempIngredientChartModel_ByDate_By(data: LocationTempReportModel<Date>[], groupField: string = 'session', tempStatus: TempStatus = null): { chart: MultiChartModel[]; series: string[]; maxValue: number } {
    let chartData: MultiChartModel[] = [];
    let series: string[] = [];
    let displayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data))
      .Where((item) => !isNullOrEmpty(this.getValueOf(item, 'status')) && (this.getValueOf(item, 'status') == tempStatus || !tempStatus))
      .OrderBy((item) => item.date)
      .ToArray();
    displayData.forEach((dd) => {
      dd.date = `${this.dfs.formatDate(dd.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`;
    });
    let grouppedArray = Enumerable.asEnumerable(displayData)
      .GroupBy((g: LocationTempReportModel<string>) => g.date)
      .ToArray();
    if (grouppedArray) {
      grouppedArray.forEach((group) => {
        let groupData: ChartModel[] = [];
        let groupbyParam = Enumerable.asEnumerable(group)
          .OrderBy((gd: LocationTempReportModel<string>) => gd.intervalOrder /*gd.session*/)
          .GroupBy((gd: LocationTempReportModel<string>) => this.getValueOf(gd, groupField) /*gd.session*/)
          .SelectMany((gd) => {
            return Enumerable.From(gd)
              .GroupBy((gd: LocationTempReportModel<string>) => gd.status)
              .Select(
                (gg: IGrouping<TempStatus, LocationTempReportModel<string>>) =>
                  new LocationTempReportModel<string>(
                    Enumerable.From(gg).Sum((gg) => gg.count),
                    group.key,
                    gg.key,
                    null,
                    groupField == 'session' ? gd.key : null,
                    groupField == 'employee' ? gd.key : null
                  )
              )
              .ToArray();
          })
          .GroupBy((g: LocationTempReportModel<string>) => this.getValueOf(g, groupField) /*g.session*/)
          .ToArray();
        if (groupbyParam) {
          groupbyParam.forEach((gbs) => {
            let chartGroup = new ChartModel(gbs.key);
            chartGroup.series = gbs.map((s) => new ChartSerie(this.getValueOf(s, 'status'), s.count));
            chartGroup.series = Enumerable.asEnumerable(chartGroup.series)
              .OrderBy((s) => tempStatusOrder.get(s.name))
              .ThenBy((s) => s)
              .ToArray();
            series = series.concat(gbs.map((s) => this.getValueOf(s, 'status')));
            groupData.push(chartGroup);
          });
        }
        chartData.push(new MultiChartModel(group.key, groupData));
      });
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    const maxValueInSeries = Enumerable.asEnumerable(chartData)
      .Where((chart) => chart.data.length > 0)
      .Max((cd) => {
        return Enumerable.asEnumerable(cd.data).Max((d) => {
          return Enumerable.asEnumerable(d.series).Sum((s) => s.value);
        });
      });
    return { chart: chartData, series: series, maxValue: maxValueInSeries };
  }

  MapToTempIngredientTableModelByLocation_ByDate_By(data: LocationTempReportModel<Date>[], groupField: string = 'session', tempStatus: TempStatus = null): any[] {
    let displayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data))
      .Where((item) => !isNullOrEmpty(this.getValueOf(item, 'status')) && (this.getValueOf(item, 'status') == tempStatus || !tempStatus))
      .OrderBy((item) => item.date)
      .ToArray();
    displayData.forEach((dd) => {
      dd.date = `${this.dfs.formatDate(dd.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`;
    });
    let tableData: any[] = [];
    let groups = Enumerable.asEnumerable(displayData)
      .GroupBy((g: LocationTempReportModel<string>) => g.date)
      .ToArray();
    groups.forEach((group) => {
      let groupbyParam = Enumerable.asEnumerable(group)
        .OrderBy((gd: LocationTempReportModel<string>) => gd.intervalOrder /*gd.session*/)
        .GroupBy((gd: LocationTempReportModel<string>) => this.getValueOf(gd, groupField) /*gd.session*/)
        .SelectMany((gd) => {
          return Enumerable.From(gd)
            .GroupBy((gd: LocationTempReportModel<string>) => gd.status)
            .Select(
              (gg: IGrouping<TempStatus, LocationTempReportModel<string>>) =>
                new LocationTempReportModel<string>(
                  Enumerable.From(gg).Sum((gg) => gg.count),
                  group.key,
                  gg.key,
                  null,
                  groupField == 'session' ? gd.key : null,
                  groupField == 'employee' ? gd.key : null
                )
            )
            .ToArray();
        })
        .GroupBy((g: LocationTempReportModel<string>) => this.getValueOf(g, groupField) /*g.session*/)
        .ToArray();
      if (groupbyParam) {
        groupbyParam.forEach((gbs) => {
          // let row = {
          //     day: group.key
          // };
          let row: any = {};
          row['day'] = group.key;
          row[groupField] = gbs.key;
          switch (tempStatus) {
            default:
              row['InRange'] = Enumerable.asEnumerable(gbs)
                .Where((g) => g.status == TempStatus.InRange)
                .Sum((g) => g.count);
              row['OutOfRange'] = Enumerable.asEnumerable(gbs)
                .Where((g) => g.status == TempStatus.OutOfRange)
                .Sum((g) => g.count);
              row['Missed'] = Enumerable.asEnumerable(gbs)
                .Where((g) => g.status == TempStatus.Missed)
                .Sum((g) => g.count);
              break;
            case TempStatus.InRange:
              row['InRange'] = Enumerable.asEnumerable(gbs)
                .Where((g) => g.status == TempStatus.InRange)
                .Sum((g) => g.count);
              break;
            case TempStatus.OutOfRange:
              row['OutOfRange'] = Enumerable.asEnumerable(gbs)
                .Where((g) => g.status == TempStatus.OutOfRange)
                .Sum((g) => g.count);
              break;
            case TempStatus.Missed:
              row['Missed'] = Enumerable.asEnumerable(gbs)
                .Where((g) => g.status == TempStatus.Missed)
                .Sum((g) => g.count);
              break;
          }
          tableData.push(row);
        });
      }
    });
    return tableData;
  }

  MapToTempIngredientTotalByStatusModel_GroupBy(data: LocationTempReportModel<Date>[], tempStatus: TempStatus, groupField: string = 'employee'): { chart: ChartModel[]; series: string[] } {
    let chartData: ChartModel[] = [];
    let series: string[] = [];
    let displayData: any[] = [];
    displayData = Enumerable.asEnumerable(deepClone(data))
      .Where((item) => !isNullOrEmpty(this.getValueOf(item, 'status')) && this.getValueOf(item, 'status') == tempStatus)
      .OrderBy((item) => item.date)
      .ToArray();

    displayData.forEach((dd) => {
      dd.date = `${this.dfs.formatDate(dd.date, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage())}`;
    });

    let grouppedArray = Enumerable.asEnumerable(displayData)
      .GroupBy((g: LocationTempReportModel<string>) => g.date)
      .SelectMany((gd) => {
        return Enumerable.From(gd)
          .GroupBy((gd: LocationTempReportModel<string>) => this.getValueOf(gd, groupField))
          .Select(
            (gg: IGrouping<string, LocationTempReportModel<string>>) =>
              new LocationTempReportModel<string>(
                Enumerable.From(gg).Sum((gg) => gg.count),
                gd.key,
                tempStatus,
                null,
                groupField == 'session' ? gg.key : null,
                groupField == 'employee' ? gg.key : null
              )
          )
          .ToArray();
      })
      .GroupBy((g: LocationTempReportModel<string>) => g.date)
      .ToArray();
    if (grouppedArray) {
      grouppedArray.forEach((group) => {
        let chartGroup = new ChartModel(group.key);
        chartGroup.series = group.map((s) => new ChartSerie(s.employee, s.count));
        series = series.concat(group.map((s) => this.getValueOf(s, 'status')));
        chartData.push(chartGroup);
      });
    }
    series = Enumerable.asEnumerable(series).Distinct().ToArray();
    return { chart: chartData, series: series };
  }

  //Corporate View
  //Temp v2 Corp Non Aggregated
  MapTo_CorporateNonAggregatedComplianceRate(data: NonAggregatedTempLocationCompliancePercentage[]): MapLocation[] {
    let mappedData: MapLocation[];
    let grouppedData = Enumerable.asEnumerable(deepClone(data))
      .Where((l) => l.long != null && l.lat != null && l.nodeId != null)
      .GroupBy((l) => `${l.long}_${l.lat}_${l.nodeId}`)
      .Select((gd) => {
        let groupData = Enumerable.asEnumerable(gd);
        let key = gd.key.split('_');
        const { locationNumber, lat, long, nodeId, brandId, compliancePercentage, complianceStatus } = groupData.FirstOrDefault();

        return new NotAggregatedCorpComplianceRateMapAlert(
          groupData
            .Select((d) => d.noPrefixLocationNumber)
            .ToArray()
            .join(', '),
          +key[0],
          +key[1],
          +key[2],
          groupData.FirstOrDefault(),
          groupData.Count() > 1 ? null : brandId
        );
      })
      .ToArray();
    mappedData = grouppedData.map((d) => {
      return new MapLocation(
        d.locationName,
        d.locationName,
        {
          percentage: d.tempLocationCompliancePercentage.compliancePercentage,
          count: 1,
          total: 1,
          title: d.locationName
        },
        { lat: d.lat, lng: d.long },
        d.nodeId,
        d.brandId
      );
    });
    return mappedData;
  }

  MapTo_CorporateNonAggregatedComplianceRate_Table(
    _data: NonAggregatedTempLocationCompliancePercentage[],
    _brandConfiguration: BrandConfiguration[]
  ): { locationName: string; averageComplianceRate: number; complianceStatus: string }[] {
    const data = deepClone(_data);
    return data.map((element) => {
      const brandConfiguration = this.getBrandConfiguration(_brandConfiguration, element.compliancePercentage);
      const complianceName = brandConfiguration.complianceName ? this.translateService.instant(`brand_configuration.${brandConfiguration.complianceName.toLowerCase()}`) : '';
      return {
        locationName: `${element.noPrefixLocationNumber}`,
        averageComplianceRate: element.compliancePercentage,
        complianceStatus: brandConfiguration ? `${brandConfiguration.rangeStart} - ${brandConfiguration.rangeEnd} % ${complianceName}` : '',
        color: brandConfiguration ? brandConfiguration.color : null
      };
    });
  }

  MapTo_CorporateNonAggregatedComplianceRate_LegendRowsBrandConfiguration(brandConfiguration: BrandConfiguration[]): MapLegend[] {
    let displayData = Enumerable.asEnumerable(deepClone(brandConfiguration))
      .OrderBy((l) => l.displayOrder)
      .Select((row) => {
        const complianceName = row.complianceName ? this.translateService.instant(`brand_configuration.${row.complianceName.toLowerCase()}`) : '';
        return {
          class: `custom-clustericon-${row.displayOrder}`,
          text: `${row.rangeStart} - ${row.rangeEnd}% ${complianceName}`,
          customTextPercentages: `${row.rangeStart} - ${row.rangeEnd}%`,
          customTextComplianceName: complianceName,
          displayOrder: row.displayOrder,
          rangeStart: row.rangeStart,
          rangeEnd: row.rangeEnd
        };
      })
      .ToArray();
    return displayData;
  }

  getBrandConfiguration(brandConfiguration: BrandConfiguration[], percentage: number): BrandConfiguration {
    const found = Enumerable.asEnumerable(brandConfiguration)
      .Where((l) => getNumberRange(l.rangeStart, l.rangeEnd, percentage))
      .OrderByDescending((l) => l.rangeEnd)
      .FirstOrDefault();
    return found ? found : null;
  }

  LocationDetailsTableMapper(rows: LocationDetailsModel[]): { storeNumber: string; addressLine1: string; city: string; zipCode: string; state: string; country: string; phoneNumber: string }[] {
    return rows.map((element) => {
      return {
        storeNumber: element.storeNumber && element.storeName ? `${element.storeNumber} - ${element.storeName}` : !element.storeName ? `${element.storeNumber}` : !element.storeNumber ? element.storeName : '',
        addressLine1: element.addressLine1,
        city: element.city,
        zipCode: element.zipCode,
        state: element.state,
        country: element.country,
        phoneNumber: element.phoneNumber
      };
    });
  }
  private getEquipmentUnitName(equipmentTypeName: string, unitName: string): string {
    return definedAndNotEmptyString(unitName) ? equipmentTypeName + ' - ' + unitName : equipmentTypeName;
  }

  private isAggregateEquipment(
    equipment: EquipmentTypeTemperatureStatusAggregateModel | EquipmentTypeTemperatureStatusFlatModel | EquipmentUnitTemperatureStatusStoreModel
  ): equipment is EquipmentTypeTemperatureStatusAggregateModel {
    return 'date' in equipment;
  }

  private isStoreEquipment(
    equipment: EquipmentTypeTemperatureStatusAggregateModel | EquipmentTypeTemperatureStatusFlatModel | EquipmentUnitTemperatureStatusStoreModel
  ): equipment is EquipmentUnitTemperatureStatusStoreModel {
    return 'equipmentUnitKey' in equipment;
  }

  getTheEquipmentTemperatureStatusChartData(
    data: (EquipmentTypeTemperatureStatusAggregateModel | EquipmentTypeTemperatureStatusFlatModel | EquipmentUnitTemperatureStatusStoreModel)[],
    selectedEquipmentKey: string
  ): { chart: ChartModel[]; series: string[] } {
    const equipmentData = data.filter((item) => (this.isStoreEquipment(item) ? item.equipmentUnitKey === selectedEquipmentKey : item.equipmentTypeKey === selectedEquipmentKey));

    const groupedEquipmentData = Enumerable.asEnumerable(equipmentData)
      .GroupBy((item) => (this.isAggregateEquipment(item) || this.isStoreEquipment(item) ? item.date : item.noPrefixLocationNumber))
      .ToArray();

    const series = [];
    const chart = groupedEquipmentData.map((equipmentData) => {
      return new ChartModel(
        this.isAggregateEquipment(equipmentData[0]) || this.isStoreEquipment(equipmentData[0]) ? this.formatDate(equipmentData.key) : equipmentData[0].noPrefixLocationNumber,
        equipmentData
          .sort((a, b) => tempStatusOrder.get(a.status) - tempStatusOrder.get(b.status)) // sorts the series by status
          .map((seriesData) => {
            const { status, count, equipmentTypeName } = seriesData;
            const statusText = this.translateService.instant(`analytics.temp_dashboard.status.${status}`);
            const seriesPercentage = getPercentage(
              count,
              Enumerable.asEnumerable(equipmentData).Sum((sData) => sData.count)
            );

            series.push(status);

            return new ChartSerie(statusText, count, {
              firstName: this.isStoreEquipment(seriesData) ? `${equipmentTypeName}${definedAndNotEmptyString(seriesData.equipmentUnitName) ? ` - ${seriesData.equipmentUnitName}` : ''}` : equipmentTypeName,
              percentage: seriesPercentage,
              secondName: statusText,
              value: count
            });
          })
      );
    });

    return { chart, series: Enumerable.asEnumerable(series).Distinct().ToArray() };
  }

  getAllEquipmentTemperatureStatusChartData(data: (EquipmentTypeTemperatureStatusAggregateModel | EquipmentTypeTemperatureStatusFlatModel | EquipmentUnitTemperatureStatusStoreModel)[]): {
    chart: MultiChartModel[];
    series: string[];
  } {
    const translateStatus = (status: TempStatus): string => this.translateService.instant(`analytics.temp_dashboard.status.${status}`);
    const getSeriesPercentage = (
      eqStatus: EquipmentTypeTemperatureStatusAggregateModel | EquipmentTypeTemperatureStatusFlatModel | EquipmentUnitTemperatureStatusStoreModel,
      eqStatuses: (EquipmentTypeTemperatureStatusAggregateModel | EquipmentTypeTemperatureStatusFlatModel | EquipmentUnitTemperatureStatusStoreModel)[]
    ): number =>
      getPercentage(
        eqStatus.count,
        Enumerable.asEnumerable(eqStatuses).Sum((eqStatus) => eqStatus.count)
      );
    const getTooltip = (
      eqStatus: EquipmentTypeTemperatureStatusAggregateModel | EquipmentTypeTemperatureStatusFlatModel | EquipmentUnitTemperatureStatusStoreModel,
      eqStatuses: (EquipmentTypeTemperatureStatusAggregateModel | EquipmentTypeTemperatureStatusFlatModel | EquipmentUnitTemperatureStatusStoreModel)[]
    ): { [prop: string]: any } => ({
      firstName: this.getEquipmentName(eqStatus),
      percentage: getSeriesPercentage(eqStatus, eqStatuses),
      secondName: translateStatus(eqStatus.status),
      value: eqStatus.count
    });

    const groups = Enumerable.asEnumerable(data)
      .GroupBy((item) => (this.isAggregateEquipment(item) || this.isStoreEquipment(item) ? item.date : item.noPrefixLocationNumber))
      .ToArray();

    const chart = [];
    const series = [];
    groups.forEach((group) => {
      const groupKey = this.isAggregateEquipment(group[0]) || this.isStoreEquipment(group[0]) ? this.formatDate(group.key) : group.key;
      const groupChart = Enumerable.asEnumerable(group)
        .OrderBy((eqStatus) => tempStatusOrder.get(eqStatus.status))
        .GroupBy((eqStatus) => (this.isStoreEquipment(eqStatus) ? eqStatus.equipmentUnitKey : eqStatus.equipmentTypeKey))
        .ToArray()
        .map(
          (eqStatuses) =>
            new ChartModel(
              eqStatuses.key,
              eqStatuses.map((eqStatus) => {
                series.push(eqStatus.status);
                return new ChartSerie(translateStatus(eqStatus.status), getSeriesPercentage(eqStatus, eqStatuses), getTooltip(eqStatus, eqStatuses));
              })
            )
        );

      chart.push(new MultiChartModel(groupKey, groupChart));
    });

    return { chart, series: Enumerable.asEnumerable(series).Distinct().ToArray() };
  }

  getCountOfChartsPerPage(charts: MultiChartModel[]): number {
    const numberOfBarsAllowedPerPage = 32;
    const numberOfCharts = charts.length;
    let chartMaximumNumberOfBars = 0;
    charts.forEach((c) => {
      if (c.data.length > chartMaximumNumberOfBars) chartMaximumNumberOfBars = c.data.length;
    });
    const recommendedChartsPerPage = Math.ceil(numberOfBarsAllowedPerPage / chartMaximumNumberOfBars);
    return recommendedChartsPerPage > numberOfCharts ? numberOfCharts : recommendedChartsPerPage;
  }

  getTheEquipmentTemperatureStatusTableData(
    data: (EquipmentTypeTemperatureStatusAggregateModel | EquipmentTypeTemperatureStatusFlatModel | EquipmentUnitTemperatureStatusStoreModel)[],
    selectedEquipmentKey: string
  ): any[] {
    const equipmentData = data.filter((item) => (this.isStoreEquipment(item) ? item.equipmentUnitKey === selectedEquipmentKey : item.equipmentTypeKey === selectedEquipmentKey));

    const tableData = Enumerable.asEnumerable(equipmentData)
      .GroupBy((item) => (this.isAggregateEquipment(item) || this.isStoreEquipment(item) ? item.date : item.noPrefixLocationNumber))
      .ToArray()
      .map((equipmentTypeData) => this.getEquipmentStatusTableRow(equipmentTypeData));

    return tableData;
  }

  getAllEquipmentTemperatureStatusTableData(data: (EquipmentTypeTemperatureStatusAggregateModel | EquipmentTypeTemperatureStatusFlatModel)[]): any[] {
    const groups = Enumerable.asEnumerable(data)
      .GroupBy((item) => (this.isAggregateEquipment(item) || this.isStoreEquipment(item) ? item.date : item.noPrefixLocationNumber))
      .ToArray();

    const tableRows = [];
    groups.forEach((group) => {
      Enumerable.asEnumerable(group)
        .GroupBy((eqStatus) => (this.isStoreEquipment(eqStatus) ? eqStatus.equipmentUnitKey : eqStatus.equipmentTypeKey))
        .ToArray()
        .forEach((eqStatuses) => {
          const row = this.getEquipmentStatusTableRow(eqStatuses);
          tableRows.push(row);
        });
    });

    return tableRows;
  }

  private getEquipmentStatusTableRow(eqStatuses: IGrouping<string, EquipmentTypeTemperatureStatusAggregateModel | EquipmentTypeTemperatureStatusFlatModel | EquipmentUnitTemperatureStatusStoreModel>) {
    const getStatusCount = (status) => eqStatuses.find((item) => item.status === status)?.count;
    const store = this.isStoreEquipment(eqStatuses[0]);
    const flat = !this.isAggregateEquipment(eqStatuses[0]) && !store;

    return {
      [flat ? 'location' : 'day']:
        this.isAggregateEquipment(eqStatuses[0]) || this.isStoreEquipment(eqStatuses[0]) ? this.formatDate(eqStatuses[0].date) : `${eqStatuses[0].noPrefixLocationNumber} - ${eqStatuses[0].locationName}`,
      [store ? 'equipment' : 'equipmentType']: this.getEquipmentName(eqStatuses[0]),
      [TempStatus.InRange]: getStatusCount(TempStatus.InRange),
      [TempStatus.OutOfRange]: getStatusCount(TempStatus.OutOfRange),
      [TempStatus.Missed]: getStatusCount(TempStatus.Missed)
    };
  }

  private getEquipmentName(eqStatus: EquipmentTypeTemperatureStatusAggregateModel | EquipmentTypeTemperatureStatusFlatModel | EquipmentUnitTemperatureStatusStoreModel): string {
    return this.isStoreEquipment(eqStatus) ? `${eqStatus.equipmentTypeName}${definedAndNotEmptyString(eqStatus.equipmentUnitName) ? ` - ${eqStatus.equipmentUnitName}` : ''}` : eqStatus.equipmentTypeName;
  }

  private formatDate(stringDate: string): string {
    return this.dfs.formatDate(stringDate, languageDefaultDateFormat.get(this.localizationService.getCurrentLanguage()).shortDate, this.localizationService.getCurrentLanguage());
  }
}
