import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { APIResponseModel, APIHttpResponseCode } from '../../shared/models/api-response';
import { AuthService } from '../auth/auth.service';
import { ApiResponseCodeEnum } from '../../shared/models/enums/ApiResponseCode.enum';
import { LoaderService } from '../../shared/services/loader.service';

@Injectable({
  providedIn: 'root'
})
export class LoginErrorHandlingService {
  constructor(private authService: AuthService, private loaderService: LoaderService) {}

  handleError(err: APIResponseModel<any>): Observable<any> {
    this.loaderService.hideLoader();
    let code;
    let data: any = {};
    if (err.status || err.statusCode) {
      const statusCode = err.statusCode || err.status;
      let errorMsg = '';
      code = statusCode;
      if (statusCode === APIHttpResponseCode.notfound) {
        errorMsg = 'login_page.error.something-wrong';
      } else {
        const errorCode = err.results.code;
        code = errorCode;
        if (err && err.results && err.results.data && err.results.data[0] != undefined) data = err.results.data[0];
        switch (errorCode) {
          case ApiResponseCodeEnum.LOGIN_INVALID_CREDENTIALS:
          case ApiResponseCodeEnum.INVALID_ACCOUNT_NAME_LOGIN:
            if (data && data.isPortalLoginLocked) errorMsg = 'error.USER_BLOCKED_CAN_NOT_LOGIN';
            else errorMsg = 'error.LOGIN_INVALID_CREDENTIALS';
            break;
          case ApiResponseCodeEnum.USER_BLOCKED_CAN_NOT_LOGIN:
            errorMsg = 'error.USER_BLOCKED_CAN_NOT_LOGIN';
            break;
          case ApiResponseCodeEnum.USER_NO_ACCESS:
            errorMsg = 'error.USER_NO_ACCESS';
            break;
          default:
            errorMsg = 'login_page.error.something-wrong';
            break;
        }
      }
      this.authService.removeAuthToken();
      return observableThrowError({ code: code, message: errorMsg, data: data });
    }
  }
}
