export enum DesignerSections {
  GENERAL = 1,
  LOCATION = 2,
  MENU_ITEM = 3,
  ADDON_INFO = 4,
  TIMER = 5,
  EMPLOYEE = 6,
  NUTRITION_FACTS = 7,
  NF_WIDGETS = 8,
  CUSTOM_WIDGETS = 9,
  USER_INPUTS = 10,
  CATALOG_ITEM = 11,
  RFID = 12,
  COMPOSITE_FIELDS = 13,
  BARCODES = 14
}
