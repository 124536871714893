import { NFStyle } from './nf-style.model';

export class NewNutritionFact {
  constructor(
    public nutritionFactId: number,
    public measurementUnitId: number,
    public parentId: number,
    public displayOrder: number,
    public required: boolean,
    public isHeader: boolean,
    public isVitamin: boolean,
    public valueType: number,
    public children?: NewNutritionFact[],
    public styleId?: number,
    public isSimplified?: boolean,
    public hasDualValue?: boolean,
    public dailyValue?: number
  ) {}
}
