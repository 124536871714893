import { LOCALES, languageDefaultDateFormat } from './../constants.config';
import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { formatDate, DatePipe } from '@angular/common';
import * as moment from 'moment';
import { LocalizationService } from './localization/localization.service';
import { DateFormat } from '../models/enums/date-format.enum';
import { defined, notDefined, stringIsDefinedAndNotEmpty } from '../helpers/app.helpers';
import { TitleCasePipe } from '../../pipes/title-case/title-case.pipe';
import { DATE_LOCALES, SUPPORTED_DATE_FROMATS } from '../../modules/label-designer/constants.config';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {
  LOCAL_DATE_TRANSLATIONS = new Map<string, { month: string; year: string; day: string }>([]);
  constructor(@Inject(LOCALE_ID) private locale: string, private titleCasePipe: TitleCasePipe, private datePipe: DatePipe, private localizationService: LocalizationService, private http: HttpClient) {
    this.fillLocalDateTranslations();
  }

  fillLocalDateTranslations() {
    DATE_LOCALES.forEach((language) => {
      const url = `assets/i18n/${language.jsonFileCode}.json`;
      this.http.get(url).subscribe((translationsData) => {
        this.LOCAL_DATE_TRANSLATIONS.set(language.jsonFileCode, { year: translationsData['year'], month: translationsData['month'], day: translationsData['day'] });
      });
    });
  }
  // using momment.js: Convert date from utc(coming from server) to local time(machine time zone)
  dateFromUTCToLocal(date: Date): Date {
    const localDate = moment.utc(date.toLocaleString()).local().toDate();
    return localDate;
  }

  dateFromUTCToLocalUsingUtcOffSet(date: Date): Date {
    const utcOffSet = moment().utcOffset();
    const localDate = moment(date).add(utcOffSet, 'minutes').toDate();
    return localDate;
  }

  formatDate(date: string | number | Date, dateFormat: string, locale: string = this.locale, titleCaseResult: boolean = false, dateFormatSeparator: string = null) {
    if (isJapaneseDateFormat(dateFormat) && defined(dateFormatSeparator)) return '';
    if (isJapaneseDateFormat(dateFormat)) {
      dateFormat = this.getCustomDateFormatForJapaneseLanguage(dateFormat, locale);
    }
    if (dateFormat.includes('julian')) {
      return this.returnJulianDate(dateFormat, locale);
    } else {
      if (dateFormatSeparator) {
        dateFormat = CombineDateFormateWithSeperator(dateFormat, dateFormatSeparator);
      }
      return titleCaseResult ? this.titleCasePipe.transform(formatDate(date, dateFormat, locale)) : formatDate(date, dateFormat, locale);
    }
  }

  formatCustomDate(date, formatData) {
    const day = formatDate(date, 'EEEE', this.locale).toLocaleLowerCase();
    return formatData[day];
  }

  formatDateShorthand(date: string | number | Date, dateFormatName: DateFormat): string {
    const locale = this.localizationService.getCurrentLanguage();
    const localeCode = this.localizationService.getMappedLocale();
    const dateFormat = languageDefaultDateFormat.get(locale)[dateFormatName];
    return this.formatDate(date, dateFormat, localeCode);
  }

  returnJulianDate(format: string, locale: string): string {
    const now: any = new Date();
    const start: any = new Date(now.getFullYear(), 0, 0);
    const diff = now - start + (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
    const oneDay = 1000 * 60 * 60 * 24;
    const day = Math.floor(diff / oneDay);
    const yy = this.formatDate(now, 'yy', locale);
    const y = this.formatDate(now, 'y', locale) % 10;
    const time_12 = this.formatDate(now, 'hh:mm a', locale);
    const time_24 = this.formatDate(now, 'HH:mm', locale);
    const yyddd = `${yy}${day}`;
    const dddyy = `${day}${yy}`;
    const dddy = `${day}${y}`;
    switch (format.toLowerCase()) {
      case 'julian.yyddd':
        return `${yyddd}`;

      case 'julian.yyddd hh:mm a':
        return `${yyddd} ${time_12}`;

      case 'julian.yyddd hh:mm':
        return `${yyddd} ${time_24}`;

      case 'julian.dddyy':
        return `${dddyy}`;

      case 'julian.dddy':
        return `${dddy}`;

      case 'julian.dddyy hh:mm a':
        return `${dddyy} ${time_12}`;

      case 'julian.dddyy hh:mm':
        return `${dddyy} ${time_24}`;
    }
  }

  isDate(input: string): boolean {
    return moment(input, [moment.ISO_8601], true).isValid();
  }

  parseDate(dateString: string, parseFormat: string = null) {
    return moment(dateString, parseFormat).toDate();
  }

  getTimeZone(date?: Date) {
    if (!date) date = new Date();
    return date.getTimezoneOffset();
  }

  getTimeZoneArea() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  convertTimeTo12Hours(time: string) {
    // Check correct time format and split into components
    if (stringIsDefinedAndNotEmpty(time)) {
      let _time = time.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      if (_time.length > 1) {
        // If time format correct
        _time = _time.slice(1) as RegExpMatchArray; // Remove full string match value
        _time[5] = +_time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        _time[0] = (+_time[0] % 12 || 12).toString(); // Adjust hours
      }
      return _time.join(''); // return adjusted time or original string
    }
    return '';
  }

  convertDateStringToDateStringWithZeroTime(dateString: string): string {
    return this.datePipe.transform(new Date(dateString), 'yyyy-MM-ddT00:00:00');
  }

  convertDateStringToDateStringWith23h59m59sTime(dateString: string): string {
    return this.datePipe.transform(new Date(dateString), 'yyyy-MM-ddT23:59:59');
  }

  // output will be like: Friday, May 14
  convertDateStringToDayAndMonthNameAndDayNumber(dateString: string): string {
    return stringIsDefinedAndNotEmpty(dateString) ? this.datePipe.transform(new Date(dateString), 'EEEE, MMM d') : null;
  }

  convertDateTimeStringToISOString(localDateTimeString: string): string {
    const localDatetime = new Date(localDateTimeString);
    return localDatetime.toISOString();
  }

  getCustomDateFormatForJapaneseLanguage(format: string, localeId: string): string {
    var locale = DATE_LOCALES.find((l) => l.value === localeId).jsonFileCode;
    var translation = this.LOCAL_DATE_TRANSLATIONS.get(locale);
    // special case in case the language used in local does not have a translation file under i18n
    if (notDefined(translation)) translation = this.LOCAL_DATE_TRANSLATIONS.get(DATE_LOCALES.find((l) => l.translationKey === LOCALES.get('en').translationKey).jsonFileCode);
    return format.replace('{{Year}}', `'${translation.year}'`).replace('{{Month}}', `'${translation.month}'`).replace('{{Day}}', `'${translation.day}'`);
  }
}
export function CombineDateFormateWithSeperator(dateFormat: string, dateFormatSeperator: string): string {
  const localDateFormats = [...SUPPORTED_DATE_FROMATS];
  const initialDateFormat = localDateFormats.find((x) => x.value === dateFormat);
  if (initialDateFormat && dateFormatSeperator !== initialDateFormat.defaultSeperator) {
    const initialDateFormatValue = initialDateFormat.value.split(' ');
    initialDateFormatValue[0] = initialDateFormatValue[0].split(initialDateFormat.defaultSeperator).join(dateFormatSeperator);
    dateFormat = initialDateFormatValue.join(' ');
  }
  return dateFormat;
}

export function isJapaneseDateFormat(dateFormat: string): boolean {
  return SUPPORTED_DATE_FROMATS.find((format) => format.value === dateFormat)?.isCustomFormatForJapaneseLanguage;
}
