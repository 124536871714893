import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormErrorsPipe } from './form-errors.pipe';

@NgModule({
  declarations: [FormErrorsPipe],
  imports: [CommonModule],
  exports: [FormErrorsPipe]
})
export class FormErrorsModule {}
