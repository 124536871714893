import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService implements OnDestroy {
  constructor(private router: Router) {
    window.addEventListener('storage', this.handleStorageEvent, false);
  }

  handleStorageEvent = (event: StorageEvent): void => {
    if (event.key === 'impersonationMode') {
      this.onImpersonationModeChange(event);
    }
  };

  onImpersonationModeChange(event: StorageEvent) {
    if (event.newValue === null) {
      this.router.navigate(['/dashboard'], { queryParams: { windowReload: 1 } });
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.handleStorageEvent, false);
  }
}
