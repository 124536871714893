import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../shared/services/api/api.service';

export const maintenanceGuard: CanActivateFn = (route, state) => {
  const apiService = inject(ApiService);
  const router = inject(Router);

  return apiService.getRequest(`${environment.baseAPIUrl}maintenance`).pipe(
    map((response) => {
      const maintenanceOver = response.results.maintenanceMode === false;

      if (maintenanceOver) {
        return router.createUrlTree(['']);
      } else {
        return true;
      }
    })
  );
};
