import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '../../services/localization/localization.service';
import { SharedModule } from '../../shared.module';
import { DropdownOption } from '../../models/dropdown-option.model';
import { PortalLanguage } from '../../models/portal-language.model';

@Component({
  selector: 'nome-languages-dropdown',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './languages-dropdown.component.html',
  styleUrls: ['./languages-dropdown.component.scss']
})
export class LanguagesDropdownComponent implements OnInit {
  languages = this.localizationService.getPortalLanguages().map((l) => new DropdownOption(l.languageCode, l.language));
  language: PortalLanguage;

  constructor(private localizationService: LocalizationService) {}

  ngOnInit(): void {
    this.setLanguage();
  }

  private setLanguage(): void {
    this.language = this.localizationService.getPortalLanguages().find((l) => l.languageCode === this.localizationService.getCurrentLanguage());
  }

  onLanguageSwitch(languageCode: string): void {
    this.localizationService.switchLanguage(languageCode);
    this.setLanguage();
  }
}
