import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { filterDataDictionary } from '../../core/config/filters-data-dictionnary';
import { defined } from '../helpers/app.helpers';
import { ListToOtherPagesConfigObject } from '../models/list-to-other-pages-config-object.model';

@Injectable({
  providedIn: 'root'
})
export class ListToOtherPagesNavigationService {
  private filterDataDictionary: { [key: string]: ListToOtherPagesConfigObject } = filterDataDictionary;
  private currentKey: string;

  constructor(private router: Router) {}

  private verifyOtherToListPageNavigation(previousRoute: string, currentRoute: string): boolean {
    return this.verifyUrlMatchItemPageRegex(currentRoute, previousRoute);
  }

  private verifyListToOtherPageNavigation(previousRoute: string, currentRoute: string): boolean {
    return this.verifyUrlMatchItemPageRegex(previousRoute, currentRoute);
  }

  private verifyUrlMatchItemPageRegex(urlKey: string, itemPageUrl: string): boolean {
    const dataObject: ListToOtherPagesConfigObject = this.filterDataDictionary[urlKey];
    if (defined(dataObject)) {
      return dataObject.otherPagesUrlRegexes.some((regex) => regex.test(itemPageUrl));
    }
    return false;
  }

  private isListToOtherOrOtherToList(previousRoute: string, currentRoute: string): boolean {
    return this.verifyListToOtherPageNavigation(previousRoute, currentRoute) || this.verifyOtherToListPageNavigation(previousRoute, currentRoute);
  }

  private clearDataAndCurrentKey(): void {
    this.setData(null);
    this.currentKey = null;
  }

  public subscribeToRouteChanges(): void {
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        const previousRoute: string = events[0].urlAfterRedirects;
        const currentRoute: string = events[1].urlAfterRedirects;
        if (!this.isListToOtherOrOtherToList(previousRoute, currentRoute)) {
          this.clearDataAndCurrentKey();
        }
      });
  }

  setDataAndCurrentKey(urlKey: string, data: any): void {
    this.currentKey = urlKey;
    this.setData(data);
  }

  private setData(data: any): void {
    if (defined(this.filterDataDictionary[this.currentKey])) {
      this.filterDataDictionary[this.currentKey].data = data;
    }
  }

  getData(urlKey: string): any {
    if (defined(this.filterDataDictionary[urlKey])) {
      return this.filterDataDictionary[urlKey].data;
    }
  }
}
