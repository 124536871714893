import { PaginationResponse } from '../../../shared/models/pagination-response.model';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Country } from '../../../shared/models/country.model';
import { UnitOfMeasure } from '../../../shared/models/UnitOfMeasure.model';
import { NutritionFact } from '../models/nutrition-fact.model';
import { environment } from './../../../../environments/environment';
import { ApiService } from './../../../shared/services/api/api.service';
import { NFService } from './../../../shared/services/nutrition-facts.service';
import { FactProfile } from '../../../shared/models/facts-profile.model';
import { NewNutritionFact } from './../models/new-Fact.model';
import { FactTranslation } from './../models/translation.model';
import { addPaginationParametersToUrl } from '../../../shared/helpers/app.helpers';
import { AccountNutritionFact } from '../models/account-nutrition-fact.model';

@Injectable({
  providedIn: 'root'
})
export class NutritionFactsService {
  private selectedFacts: NutritionFact[];
  public profile: FactProfile;
  data: any;
  baseServiceUrl = `${environment.baseAPIUrl}${environment.version}/nutritionfacts/`;
  public accountHasDualPanels: boolean;
  constructor(private apiservice: ApiService, public NFService: NFService) {}

  getAccountNutritionFacts(): Observable<AccountNutritionFact[]> {
    const url = `${this.apiservice.v1Accounts}NutritionFacts`;
    return this.apiservice.getRequest<AccountNutritionFact[]>(url).pipe(map((response) => response.results));
  }

  setProfile(selection: FactProfile): void {
    this.profile = selection;
  }

  getProfile(): FactProfile {
    return this.profile;
  }

  setData(data) {
    this.data = data;
  }

  getData() {
    return this.data;
  }

  // setSelectedFacts(facts: NutritionFact[]): void {
  //   this.selectedFacts = facts;
  // }

  getSelectedFacts(): NutritionFact[] {
    return this.selectedFacts;
  }

  getCountriesList(): Observable<Country[]> {
    return this.NFService.getCountriesList();
  }

  setUnitsOfMeasure(unitsOfMeasure: UnitOfMeasure[]): void {
    this.NFService.unitsOfMeasure = unitsOfMeasure;
  }

  getUnitsOfMeasure(): Observable<UnitOfMeasure[]> {
    return this.NFService.getUnitsOfMeasure();
  }

  getTimeUnitOfMeasure(accountId: number): Observable<UnitOfMeasure[]> {
    const url = `${environment.baseAPIUrl}${environment.version}/accounts/${accountId}/time/measurementunits`;
    return this.apiservice.getRequest(url).pipe(map((response) => response.results));
  }

  getPredefinedNutritionalFacts(): Observable<NutritionFact[]> {
    const url = `${this.baseServiceUrl}list`;
    return this.apiservice.getRequest(url).pipe(
      map((response) => {
        response.results.forEach((fact) => {
          fact.measurementUnitId = fact.measurementUnit.id;
        });
        return response.results;
      })
    );
  }

  createNutritionFact(nutritionFact: NutritionFact): Observable<NutritionFact> {
    const url = `${this.baseServiceUrl}`;
    return this.apiservice.postRequest(url, nutritionFact).pipe(map((response) => response.results));
  }

  updateNutritionFact(nutritionFact: NutritionFact): Observable<NutritionFact> {
    const url = `${this.baseServiceUrl}${nutritionFact.id}`;
    return this.apiservice.putRequest(url, nutritionFact).pipe(map((response) => response.results));
  }

  deleteNutritionFact(nutritionFactId: number) {
    const url = `${this.baseServiceUrl}${nutritionFactId}`;
    return this.apiservice.deleteRequest(url).pipe(map((response) => response.results));
  }

  addFactTranslation(nutritionFactId: number, translation: FactTranslation) {
    const url = `${this.baseServiceUrl}${nutritionFactId}/translation`;
    return this.apiservice.postRequest(url, translation).pipe(map((response) => response.results));
  }

  updateFactTranslation(nutritionFactId: number, translation: FactTranslation) {
    const url = `${this.baseServiceUrl}${nutritionFactId}/translation`;
    return this.apiservice.putRequest(url, translation).pipe(map((response) => response.results));
  }

  getFactsProfiles(accountId: number, start?: number, limit?: number, searchKeyword?: string): Observable<PaginationResponse<FactProfile[]>> {
    let url = `${this.baseServiceUrl}/${accountId}/profile`;
    url = addPaginationParametersToUrl(url, start, limit, searchKeyword);
    return this.apiservice.getRequest(url).pipe(map((response) => response.results));
  }

  getFactsProfileById(factProfileId: number): Observable<FactProfile> {
    const url = `${this.baseServiceUrl}profile/${factProfileId}`;
    return this.apiservice.getRequest(url).pipe(map((response) => response.results));
  }

  addFactProfile(profile: FactProfile) {
    const url = `${this.baseServiceUrl}profile`;
    return this.apiservice.postRequest(url, profile).pipe(map((response) => response.results));
  }

  updateFullProfile(profile: FactProfile) {
    const url = `${this.baseServiceUrl}profile/${profile.id}`;
    return this.apiservice.putRequest(url, profile).pipe(map((response) => response.results));
  }

  deleteFactProfile(profileId: number) {
    const url = `${this.baseServiceUrl}profile/${profileId}`;
    return this.apiservice.deleteRequest(url).pipe(map((response) => response.results));
  }

  updateProfileFacts(profileId: number, facts: NewNutritionFact[]) {
    const url = `${this.baseServiceUrl}profile/${profileId}`;
    return this.apiservice.putRequest(url, facts).pipe(map((response) => response.results));
  }

  cloneProfile(profileId: number) {
    const url = `${this.baseServiceUrl}profile/${profileId}/clone`;
    return this.apiservice.postRequest(url, {}).pipe(map((response) => response.results));
  }

  linkFactToProfile(profileId: number, fact: NutritionFact) {
    const q = new NewNutritionFact(fact.id, fact.measurementUnitId, fact.parentId, fact.displayOrder, fact.enabled, fact.isHeader, fact.isVitamin, fact.valueType);
    const url = `${this.baseServiceUrl}profile/${profileId}/fact`;
    return this.apiservice.postRequest(url, q).pipe(map((response) => response.results));
  }

  updateNutritionFactInProfile(profileId: number, fact: NutritionFact) {
    const url = `${this.baseServiceUrl}profile/${profileId}/fact/${fact.id}`;
    return this.apiservice.putRequest(url, fact).pipe(map((response) => response.results));
  }

  removeFactFromProfile(profileId: number, factId: number) {
    const url = `${this.baseServiceUrl}profile/${profileId}/fact/${factId}`;
    return this.apiservice.deleteRequest(url).pipe(map((response) => response.results));
  }

  clearSelection() {
    // this.setSelectedFacts(null);
    this.setProfile(null);
    this.data = null;
  }

  getIngredientsUnitsOfMeasure(accountId: number): Observable<UnitOfMeasure[]> {
    const url = `${environment.baseAPIUrl}${environment.version}/accounts/${accountId}/ingredients/measurementunits`;
    return this.apiservice.getRequest(url).pipe(map((response) => response.results));
  }

  getRecipesUnitsOfMeasure(accountId: number): Observable<UnitOfMeasure[]> {
    const url = `${environment.baseAPIUrl}${environment.version}/accounts/${accountId}/recipes/measurementunits`;
    return this.apiservice.getRequest(url).pipe(map((response) => response.results));
  }

  getRecipeSizeUnitsOfMeasure(accountId: number, recipeId: number): Observable<UnitOfMeasure[]> {
    const url = `${environment.baseAPIUrl}${environment.version}/accounts/${accountId}/recipes/${recipeId}/recipesizemeasurementunits`;
    return this.apiservice.getRequest(url).pipe(map((response) => response.results));
  }

  getDefaultFactsProfile(accountId: number): Observable<FactProfile[]> {
    const url = `${environment.baseAPIUrl}${environment.version}/accounts/${accountId}/profile`;
    return this.apiservice.getRequest(url).pipe(map((response) => response.results));
  }

  getProfileValues(profileId: number, ingredientOrRecipeId: number, isRecipe: boolean): Observable<FactProfile> {
    let url = `${this.baseServiceUrl}profile/${profileId}/`;
    if (isRecipe) {
      url = `${url}recipe/`;
    } else {
      url = `${url}ingredient/`;
    }
    url = `${url}${ingredientOrRecipeId}/values`;
    return this.apiservice.getRequest(url).pipe(map((response) => response.results));
  }
}
