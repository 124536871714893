export enum Modules {
  ROLES = 'Roles and Permissions',
  ACCOUNTS = 'Accounts',
  ACCOUNT_INFO = 'Account Info',
  MENUS = 'Menus',
  MENU_ITEMS = 'Menu Items',
  LOCATIONS = 'Locations',
  BRANDS = 'Brands',
  USERS = 'Users',
  DEVICES = 'Devices',
  INGREDIENTS = 'Ingredients',
  STYLES = 'Styles',
  LABEL_DESIGNER = 'Label Designer',
  BATCH_PRINT = 'Batch Print',
  ADDONS = 'Addons',
  MEDIA_MANAGER = 'Media Manager',
  CUSTOM_FIELDS = 'Custom Fields',
  LIST_OPTIONS = 'List Options',
  NUTRITION_FACTS = 'Nutrition Facts',
  ALLOW_EXCEPTION = 'Allow Exception',
  MEDIAS = 'Medias',
  ANALYTICS = 'Analytics',
  LABEL_SIZES = 'Label Sizes',
  RESELLER_LABEL_SIZES = 'Reseller Label Sizes',
  WIDGET_DESIGNER = 'Widget Designer',
  TEMPLATE_DESIGNER = 'Template Designer',
  TEMP_WORKFLOWS_TEMPLATES = 'Temp Workflows Templates',
  TEMP_WORKFLOW_TASKS = 'Temp Workflow Tasks',
  TEMP_SCHEDULE = 'Temp Schedule',
  CHECKLISTS = 'Checklists',
  TASKS = 'Tasks',
  TEMP_WORKFLOWS = 'Temp Workflows',
  EQUIPMENT_TYPES = 'Equipment Types',
  GROUP_TAGS = 'GroupTag',
  RECIPES = 'Recipes',
  RESELLERS = 'Reseller Manager',
  SUBSCRIPTIONS = 'Subscriptions',
  WORKFLOW = 'Workflow Designer',
  LABEL_FONT = 'Label Fonts',
  ORDERS = 'Orders',
  INVOICES = 'Invoices',
  ORDERING_TEMPLATES = 'Ordering Templates',
  MESSAGE_CENTER = 'Message Center',
  ORDER_GUIDES = 'Order Guides',
  CHECKLIST_REPORT = 'Checklist Report',
  REPORTING_HIERARCHY = 'Reporting Hierarchy',
  DATA_TRANSLATION = 'Data Translation',
  TIMER_MANAGER = 'Timer Manager',
  TRANSLATION = 'Translation',
  PRINTER_TYPES = 'Printer Types',
  TEMP_DASHBOARD = 'Temp Dashboard',
  APP_UPDATES_AND_NOTIFICATIONS = 'App Release Notes',
  LOCATION_SUBSCRIPTIONS = 'Location Subscription',
  CHECKLIST_OPTIONS = 'Checklist Options',
  SCALE_MODELS = 'Scale Models',
  SCALE_CONTAINERS = 'Scale Containers',
  RELEASE_NOTES = 'Release Notes',
  MEDIA_DASHBOARD = 'Media Dashboard',
  BROWSE_LOCATION = 'Browse Location',
  MASTER_LOCATION_CODE = 'Master Location Code',
  DATA_RECOVERY = 'Data Recovery',
  REPORTS_ON_DEMAND = 'Reports On Demand',
  AUDIT_TRAIL = 'Audit Trail',
  DISTRIBUTION_CENTERS = 'Distribution Centers',
  DISTRIBUTION_USERS = 'Distribution Users',
  ORDER_MANAGEMENT = 'Order Management',
  RETAIL_CATALOGS = 'Retail Catalogs',
  RETAIL_CATALOG_PRODUCTS = 'Retail Catalog Products',
  FIXED_READERS = 'Fixed RFID Readers',
  READER_TYPES = 'Reader Types',
  RETAIL_PRICING_DASHBOARD = 'Pricing Dashboard',
  RETAIL_WORKFLOW_TEMPLATES = 'Retail Workflow Templates'
}
