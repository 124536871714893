<p class="mat-headline-6 dialog-title text-left">{{ 'cookies_confirmation.title' | translate }}</p>
<div class="row">
  <div class="col-12 text-left">
    <p>{{ 'cookies_confirmation.content' | translate }}</p>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div mat-dialog-actions class="button-row d-block text-right">
      <button (click)="acceptCookies()" mat-flat-button color="accent" class="pointer main-button rounded" cdkFocusInitial>{{ 'cookies_confirmation.button' | translate | titlecase }}</button>
    </div>
  </div>
</div>
