import { MenuItemModel } from './../../modules/menu-manager/models/menu-item-model';

export class TreeNode {
  constructor(
    public id: any,
    public name: string,
    public level: number,
    public hasChildren: boolean, // if has children should be expandable
    public expanded = false,
    public selected = false, // if it's checkbox is selected
    public children: TreeNode[] = [],
    public type: number, // 1 Folder, 2 store , 3 area
    public highlightOnLoad: boolean, // Flag node to be selected on load,
    public items?: MenuItemModel[][],
    public order?: number,
    public suffix?: string,
    public path?: string,
    public pathName?: string,
    public icon?: string,
    public subLocations?: { name: string; storeNumber: string }[],
    public menuItemsCount?: number,
    public disabledMenuItemsCount?: number,
    public assignedMenuItemsCount?: number,
    public originalId?: number,
    public subscriptionCode?: string,
    public moveLocationStatusId?: number,
    public parentNodeId?: any
  ) {}
  // public path?: string;
  public code?: string;
  public storeNumber?: string;
  public loading?: boolean;
  public key?: string;
  public hasAssociations: boolean;
  public nodeHighlighted?: boolean;
  public selectedItemsCount?: number; //This attribute is used to store the selected items count under the node items
  public rightIcons?: { name: string; tooltip: string }[];
}

// public class PreSelectedNodeResDTO
//     {
//         public SelectedNodeDTO SelectedNode { get; set; }
//         public IEnumerable<TreeDTO> Tree { get; set; }
//     }

export class SelectedNode {
  constructor(public id: number, public name: string) {}
  public type?: number;
}

export class PreSelectedNode {
  constructor(public tree: TreeNode[], public selectedNode: SelectedNode) {}
}

export class TreeCmp {
  constructor(
    public type: number,
    public accountId: number,
    public brandId: number,
    public showCheckbox: boolean,
    public darkTheme: boolean,
    public showTitle: boolean = true,
    public errorMessage = 'no_content_found',
    public hideSearch?: boolean,
    public maxHeight?: number,
    public folderSelectionType?: number[],
    public selectedNodeBackgroundHighlighted?: boolean,
    public selectionType?: 'radio',
    public hoveredNodeBackgroundHighlighted?: boolean,
    public boldenLocation: boolean = true
  ) {}
}
