<div class="container login-container" *ngIf="showForm">
  <div class="login-form">
    <div class="text-center"><img id="logo-container" [src]="brandImagesAssets + 'FullLogo.svg'" /></div>
    <div class="col">
      <form class="rounded" [formGroup]="formGroup" (ngSubmit)="submitForm()">
        <mat-form-field class="login-full-width">
          <input matInput type="password" formControlName="password" [placeholder]="'change_password_page.password' | translate | titlecase" autocomplete="off" />
          <mat-error>
            {{ formGroup.get('password').errors | formErrors : translateService.currentLang : 'password' : validationParams }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="login-full-width">
          <input matInput type="password" formControlName="confirmPassword" [placeholder]="'change_password_page.confirm-password' | translate" autocomplete="off" />
          <mat-error>
            {{ formGroup.get('confirmPassword').errors | formErrors : translateService.currentLang : 'confirm-password' : validationParams }}
          </mat-error>
        </mat-form-field>
        <button mat-flat-button color="accent" [disabled]="!formGroup.valid" type="submit" id="login-button" class="btn btn-def btn-block">{{ 'change_password_page.submit_button' | translate }}</button>
      </form>
    </div>
  </div>
</div>
