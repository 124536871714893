import { MenuButtons } from './custom-card.model ';
import { RowBaseModel } from './row-base-model';
import { Icon } from '../../shared/models/icon.model';
export class CommonTableRow extends RowBaseModel {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public bottomItems: MenuButtons[],
    public date?: string,
    public dateIcon?: string,
    public isAssigned?: Boolean,
    public isCustomized?: Boolean,
    public isInherited?: Boolean,
    public isCreatedAtLocation?: Boolean,
    public syncStatusId?: number,
    public isSubscription?: boolean,
    public name_icons?: Icon[],
    public isEditable?: boolean,
    public size?: string
  ) {
    super();
  }
  public name_Secondary: string;
  public inUse: Icon;
  public isAvailableForRemotePrinting?: Icon;
  public isDisabledForSubscriber?: boolean;
  public hasInvalidNFProfile?: boolean;
  public mediaIcon?: string;
}
