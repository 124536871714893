import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { ApiService } from './api/api.service';
import { CountryCodesModel } from '../models/country-codes.model';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  baseServiceUrl = `${environment.baseAPIUrl}${environment.version}/countries/`;

  countries: CountryCodesModel[];
  constructor(private apiservice: ApiService) {}

  getCountriesList(): Observable<CountryCodesModel[]> {
    const url = `${this.baseServiceUrl}`;
    if (!this.countries || this.countries.length < 1) {
      return this.apiservice.getRequest(url).pipe(
        map((response) => {
          this.countries = response.results;
          return response.results;
        })
      );
    } else {
      return of(this.countries);
    }
  }
}
