<div class="row">
  <div class="col text-center">
    <mat-icon class="white float-right pointer dialog-close-btn" (click)="close()">close</mat-icon>
  </div>
</div>
<h1 mat-dialog-title>
  <strong class="title">Start Time:</strong> {{ data.item.startAt }}
  <br />
  <strong class="title">End Time:</strong> {{ data.item.endAt }}
</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-12">
      <nome-event-log-item [title]="'Added'" [logs]="data.logs" [actionType]="ActionTypeEnum.Added"> </nome-event-log-item>
    </div>
    <div class="col-12">
      <nome-event-log-item [title]="'Updated'" [logs]="data.logs" [actionType]="ActionTypeEnum.Updated"> </nome-event-log-item>
    </div>
  </div>
</div>
