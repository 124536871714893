<div class="container login-container">
  <div class="login-form">
    <div class="text-center logos-container">
      <img id="logo-container" [src]="brandImagesAssets + 'FullLogo.svg'" />
    </div>
    <div>
      <div class="col">
        <div class="text-center"><img id="group-container" [src]="brandImagesAssets + 'Group.svg'" /></div>
        <div class="col">
          <mat-form-field class="login-full-width">
            <input matInput [(ngModel)]="accountName" (keyup.enter)="navigateToLogIn()" [placeholder]="'login_page.select_account' | translate" matTooltip="{{ 'login_page.select_account' | translate }}" />
          </mat-form-field>
        </div>
        <div class="text-center">
          <button mat-flat-button color="accent" class="login-button btn btn-def btn-block" (click)="navigateToLogIn()" [disabled]="!accountName || accountName == ''">{{ 'next' | translate }}</button>
        </div>
      </div>
    </div>
    <!--Language Selection-->
    <div class="col-3 mr-auto ml-auto mt-3 no-padding">
      <nome-languages-dropdown class="outside-toolbar"></nome-languages-dropdown>
    </div>
  </div>
  <nome-login-footer-message />
</div>
