import { Weekday, RRule } from 'rrule';

export enum DaysOfWeek {
  MONDAY = 'MON',
  TUESDAY = 'TUE',
  WEDNESDAY = 'WED',
  THURSDAY = 'THU',
  FRIDAY = 'FRI',
  SATURDAY = 'SAT',
  SUNDAY = 'SUN'
}

export const dayOfWeekEnumToDayTranslationKeyMap = new Map<DaysOfWeek, string>([
  [DaysOfWeek.MONDAY, 'monday'],
  [DaysOfWeek.TUESDAY, 'tuesday'],
  [DaysOfWeek.WEDNESDAY, 'wednesday'],
  [DaysOfWeek.THURSDAY, 'thursday'],
  [DaysOfWeek.FRIDAY, 'friday'],
  [DaysOfWeek.SATURDAY, 'saturday'],
  [DaysOfWeek.SUNDAY, 'sunday']
]);

export const MonthOfYear = [
  { name: 'january', index: 1 },
  { name: 'february', index: 2 },
  { name: 'march', index: 3 },
  { name: 'april', index: 4 },
  { name: 'may', index: 5 },
  { name: 'june', index: 6 },
  { name: 'july', index: 7 },
  { name: 'august', index: 8 },
  { name: 'september', index: 9 },
  { name: 'october', index: 10 },
  { name: 'november', index: 11 },
  { name: 'december', index: 12 }
];

export const daysOfWeekIndices = new Map<string, number>([
  [DaysOfWeek.MONDAY, 1],
  [DaysOfWeek.TUESDAY, 2],
  [DaysOfWeek.WEDNESDAY, 3],
  [DaysOfWeek.THURSDAY, 4],
  [DaysOfWeek.FRIDAY, 5],
  [DaysOfWeek.SATURDAY, 6],
  [DaysOfWeek.SUNDAY, 0]
]);

export const daysOfWeekRRULE = new Map<string, Weekday>([
  [DaysOfWeek.MONDAY, RRule.MO],
  [DaysOfWeek.TUESDAY, RRule.TU],
  [DaysOfWeek.WEDNESDAY, RRule.WE],
  [DaysOfWeek.THURSDAY, RRule.TH],
  [DaysOfWeek.FRIDAY, RRule.FR],
  [DaysOfWeek.SATURDAY, RRule.SA],
  [DaysOfWeek.SUNDAY, RRule.SU]
]);

export const weekDaysRRULE = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
export const allWeekLongRRULE = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU];

export class DaysOfWeekModel {
  constructor(public name: DaysOfWeek, public isChecked: boolean, public id: number) { }
}

export class DayOccurenceModel {
  day: number;
  isEnabled?: boolean;
}

export class DayOccurrenceValidationModel extends DayOccurenceModel {
  private statusId: number;

  setStatusId(status: boolean): void {
    this.statusId = status ? 1 : 2;
  }
}
