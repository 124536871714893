import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { TranslateAndCaseModule } from '../../../pipes/translate-and-case/translate-and-case.module';
import { ButtonsGeneratorComponent } from './components/buttons-generator/buttons-generator.component';

@NgModule({
  declarations: [ButtonsGeneratorComponent],
  imports: [CommonModule, MatButtonModule, TranslateAndCaseModule],
  exports: [ButtonsGeneratorComponent]
})
export class ButtonsGeneratorModule {}
