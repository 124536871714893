<!--Header Message-->
<p>{{ headerMessage | translate }}</p>

<!--List of data to be shown under the header-->
<ul>
  <li *ngFor="let itemName of conflictingData">{{ itemName | translate }}</li>
</ul>

<!--The Footer Message-->
<p class="my-1">{{ footerMessage | translate }}</p>
