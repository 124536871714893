import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ResellerCheckService {
  isADReseller(): boolean {
    return window.location.pathname.startsWith('/AveryDennison');
  }

  isZYReseller(): boolean {
    return window.location.origin && window.location.origin.includes(environment.zippyYumPortalDomain);
  }

  isTransactReseller(): boolean {
    return window.location.origin && window.location.origin.includes(environment.transactPortalDomain);
  }
}
