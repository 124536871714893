import { TempStatus, TempV2Status } from './TempStatus.enum';
import { NoPrefixLocationNumber } from './no-prefix-location-number.model';

export class IngredientNotTempedLocation {
  constructor(public storeNumber: string, public addressLine1: string, public city: string, public zipCode: string, public state: string, public country: string, public phoneNumber: string) {}
}

export class IngredientAverageSessionTimeLocation {
  constructor(public storeNumber: string, public storeName: string, public addressLine1: string, public city: string, public zipCode: string, public state: string, public country: string, public phoneNumber: string) {}
}

export class IngredientAverageSessionTemp {
  constructor(public sessionDate: string, public sessionAverageTime: number) {}
}

export class IngredientAverageSessionDrillDownTemp {
  constructor(public locationNumber: string, public locationName: string, public sessionAverageTime: number) {}
}

export class IngredientStatusAggregatedTemp {
  constructor(public count: number, public date: string, public status: string, public totalCount: number) {}
}

export class MeasurementsByWorkflowAggregated {
  constructor(public oneDay: string, public inRangeCount: number, public outOfRangeCount: number, public missedCount: number) {}
}
export class MeasurementsByWorkflowFlat {
  constructor(public locationNumber: string, public locationName: string, public inRangeCount: number, public outOfRangeCount: number, public missedCount: number) {}
}

export class LocationMissingAggregated {
  constructor(public date: string, public totalLocations: number, public totalLocationsCount: number) {}
}

export class LocationMissingSessionsNonAggregated implements NoPrefixLocationNumber {
  noPrefixLocationNumber: string;
  constructor(public locationNumber: string, public locationName: string, public missed: number, public completed: number, public incomplete: number) {}
}

export class RequiredTaskCompletionRateCorporateNonAggregatedResponse {
  constructor(public metaData: PaginationMetaResponse, public records: RequiredTaskCompletionRateCorporateNonAggregated[]) {}
}

export class RequiredTaskCompletionRateCorporateNonAggregated implements NoPrefixLocationNumber {
  noPrefixLocationNumber: string;
  constructor(public locationNumber: string, public locationName: string, public compliancePercentage: number, public completedTasksCount: number, public requiredTasksCount: number) {}
}

export class RequiredTaskCompletionRateCorporateAggregated {
  constructor(public date: Date, public compliancePercentage: number, public completedTasksCount: number, public requiredTasksCount: number) {}
}

export class ActionsCompletionRate implements NoPrefixLocationNumber {
  noPrefixLocationNumber: string;
  locationNumber: string;
  locationName: string;
  constructor(public date: Date, public compliancePercentage: number, public completedActionsCount: number, public actionsCount: number) {}
}

export class OccurencesOfMissedSessionsPerLocationNonAggregated implements NoPrefixLocationNumber {
  noPrefixLocationNumber: string;
  constructor(public locationNumber: string, public locationName: string, public occurrences: number, public tenantId: number) {}
}

export class NonAggregatedIngredientResponse {
  constructor(public metaData: PaginationMetaResponse, public records: NonAggregatedIngredientStatus[]) {}
}

export class PaginationMetaResponse {
  constructor(public start: number, public limit: number, public totalCount: number) {}
}
export class TempV2InputsModel implements NoPrefixLocationNumber {
  noPrefixLocationNumber: string;
  constructor(public locationNumber: string, public locationName: string, public manualInput: number, public autoInput: number, public recordsCount: number) {}
}

export class NonAggregatedTempV2InputsResponse {
  constructor(public metaData: PaginationMetaResponse, public records: TempV2InputsModel[]) {}
}

export class TempV2ManualOrAutoStoreRecordResponse {
  public constructor(public date: Date, public manualInput: number, public autoInput: number) {}
}

export class TempV2ManualOrAutoStoreResponse {
  constructor(public results: TempV2ManualOrAutoStoreRecordResponse[]) {}
}

export class NonAggregatedIngredientStatus implements NoPrefixLocationNumber {
  noPrefixLocationNumber: string;
  constructor(public locationNumber: string, public locationName: string, public status: string, public count: number, public totalCount?: number, public totalMeasuredCount?: number) {}
}

export class TempV2BrandConfiguration {
  constructor(public rangeStart: number, public rangeEnd: number, public complianceName: string, public complianceId: number, public displayOrder: number, public color: string) {}
}

export class BrandConfiguration {
  constructor(public complianceName: string, public color: string, public displayOrder: number, public complianceId: number, public rangeStart: number, public rangeEnd: number) {}
}

export class NotAggregatedCorpComplianceRateMapAlert {
  constructor(
    public locationName: string,
    public long: number,
    public lat: number,
    public nodeId: number,
    public tempLocationCompliancePercentage: NonAggregatedTempLocationCompliancePercentage,
    public brandId?: number
  ) {}
}

export class NonAggregatedTempLocationCompliancePercentage implements NoPrefixLocationNumber {
  noPrefixLocationNumber: string;
  constructor(
    public locationNumber: string,
    public locationName: string,
    public lat: number,
    public long: number,
    public nodeId: number,
    public brandId: number,
    public compliancePercentage: number,
    public complianceStatus: string
  ) {}
}

export class NonAggregatedTempLocationCompliancePercentageResponse {
  constructor(public locationsPercentages: NonAggregatedTempLocationCompliancePercentage[], public brandConfiguration: BrandConfiguration[]) {}
}

export class NonAggregatedIngredientStatusStore {
  constructor(public session: string, public date: Date, public status: string, public count: number) {}
}

export class IngredientTempingHistory {
  constructor(public sessionName: string, public date: string, public dayOfTheWeek: string, public isOutOfRange: string, public totalValue: number) {}
}

export class LocationMissingSessionsDrillDownTemp {
  constructor(public locationNumber: string, public locationName: string, public missed: number, public total: number, public completed: number, public tenantId: number) {}
}

export class ResultLocationMissingSessionsDrillDownTemp {
  constructor(public location: string, public locationNumber: string, public missed: number, public total: number, public completed: number, public colorRed: string, public colorGreen: string, public tenantId: number) {}
}

export class AverageSessionTimeFlatReportModel implements NoPrefixLocationNumber {
  noPrefixLocationNumber: string;
  constructor(public locationNumber: string, public locationName: string, public sessionAverageTime: number) {}
}

export class LocationOutOfRangeCountsReportModel {
  constructor(public date: string, public outOfRange: number, public totalMeasuredCount: number) {}
}

export class TempGenericResponse<T> {
  constructor(public results: T, public error: string) {}
}

interface EquipmentTypeTemperatureStatusModel {
  equipmentTypeKey: string;
  equipmentTypeName: string;
  status: TempStatus;
  count: number;
  totalCount: number;
  totalMeasuredCount: number;
}

export interface EquipmentTypeTemperatureStatusAggregateModel extends EquipmentTypeTemperatureStatusModel {
  date: string;
}

export interface EquipmentTypeTemperatureStatusFlatModel extends EquipmentTypeTemperatureStatusModel, NoPrefixLocationNumber {
  locationNumber: string;
  locationName: string;
}

export interface EquipmentUnitTemperatureStatusStoreModel extends EquipmentTypeTemperatureStatusModel {
  date: string;
  equipmentUnitKey: string;
  equipmentUnitName: string;
}
