export enum TenantModuleOptions {
  LABELING = 1,
  MEDIA_MANAGER = 2,
  ADDONS = 3,
  BATCH_PRINTING = 4,
  RECIPES = 5,
  TEMP = 6,
  TEMP_CONFIGURATION = 7,
  SENSE = 8,
  SENSE_CONFIGURATION = 9,
  OPS_CHECKLIST = 10,
  ADVANCED_CHECKLIST = 11,
  ORDERS = 12,
  ORDER_GUIDES = 13,
  MESSAGE_CENTER = 14,
  INVOICES = 15,
  SECONDARY_LANGUAGE = 16,
  DUAL_NUTRITIONAL_PANELS = 17,
  EXTERNAL_INTERFACE = 18,
  DAILY_PRODUCTION = 19,
  SUB_LOCATION = 20,
  ALLOW_MULTI_CODE = 21,
  CONSUME_ADVANCED_OPS_CHECKLIST = 22,
  TIMERS = 23,
  REMOTE_PRINTING = 26,
  SHOW_SUBSCRIBER_TEMP_ANALYTICS = 29,
  SHOW_SUBSCRIBER_SENSE_ANALYTICS = 30,
  SHOW_SUBSCRIBER_DEVICES = 31,
  CUSTOM_CODE = 32
}

export const tenantModuleOptionsGroup = new Map<TenantModuleOptions, string>([
  [TenantModuleOptions.LABELING, 'hasLabeling'],
  [TenantModuleOptions.MEDIA_MANAGER, 'hasMediaManager'],
  [TenantModuleOptions.ADDONS, 'hasAddons'],
  [TenantModuleOptions.BATCH_PRINTING, 'hasBatchPrinting'],
  [TenantModuleOptions.RECIPES, 'hasRecipes'],
  [TenantModuleOptions.TEMP, 'hasTempActivated'],
  [TenantModuleOptions.TEMP_CONFIGURATION, 'hasTempConfActivated'],
  [TenantModuleOptions.SENSE, 'hasSenseActivated'],
  [TenantModuleOptions.SENSE_CONFIGURATION, 'hasSenseConfActivated'],
  [TenantModuleOptions.OPS_CHECKLIST, 'hasChecklistActivated'],
  [TenantModuleOptions.ADVANCED_CHECKLIST, 'hasAdvancedChecklistActivated'],
  [TenantModuleOptions.ORDERS, 'hasOrdersActivated'],
  [TenantModuleOptions.ORDER_GUIDES, 'hasOrderGuidesActivated'],
  [TenantModuleOptions.MESSAGE_CENTER, 'hasMessageCenterActivated'],
  [TenantModuleOptions.INVOICES, 'hasInvoicesActivated'],
  [TenantModuleOptions.SECONDARY_LANGUAGE, 'hasSecondaryLanguage'],
  [TenantModuleOptions.DUAL_NUTRITIONAL_PANELS, 'hasDualNutrionalPanels'],
  [TenantModuleOptions.EXTERNAL_INTERFACE, 'hasExternalInterface'],
  [TenantModuleOptions.DAILY_PRODUCTION, 'hasDailyProduction'],
  [TenantModuleOptions.SUB_LOCATION, 'hasSubLocation'],
  [TenantModuleOptions.ALLOW_MULTI_CODE, 'allowMultiCode'],
  [TenantModuleOptions.TIMERS, 'hasTimersActivated'],
  [TenantModuleOptions.REMOTE_PRINTING, 'hasRemotePrinting'],
  [TenantModuleOptions.SHOW_SUBSCRIBER_TEMP_ANALYTICS, 'showSubscriberTempAnalytics'],
  [TenantModuleOptions.SHOW_SUBSCRIBER_SENSE_ANALYTICS, 'showSubscriberSenseAnalytics'],
  [TenantModuleOptions.CUSTOM_CODE, 'allowCustomCode']
]);
