import { Injectable } from '@angular/core';
import { JoyrideService, JoyrideStepService } from 'ngx-joyride';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth/auth.service';
import { HelpPage, Help } from '../models/help.model';
import { Subject } from 'rxjs';

@Injectable()
export class HelpService {
  private readonly HELP = 'help';
  help: Help;
  pageReady: Subject<boolean> = new Subject<boolean>();
  constructor(private joyrideService: JoyrideService, private router: Router, private authService: AuthService) {}

  init() {
    this.getHelp();
  }

  initHelp(): Help {
    const pages = [
      new HelpPage('dashboard', ['dashboard', 'sidenav', 'userProfile'], 'dashboard', true, false),
      new HelpPage('accountmanager/accounts', ['accountsList', 'addButton'], 'accountsList', true, false),
      new HelpPage(
        'accountmanager/account-info',
        ['generalInformation', 'deviceFeatures', 'complianceConfiguration', 'saveCancel'],
        'generalInformation',
        true,
        false,
        null,
        ['complianceConfiguration'],
        ['complianceConfiguration']
      ),
      new HelpPage('accountmanager/users', ['locationSelection', 'usersList', 'addButton'], 'locationSelection', true, false, null, ['locationSelection']),
      new HelpPage('accountmanager/brands', ['brandsList', 'addButton'], 'brandsList', true, false),
      new HelpPage('accountmanager/roles', ['rolesList', 'addButton'], 'rolesList', true, false),
      new HelpPage('accountmanager/subscriptions', ['brandSelection', 'subscriptionsList'], 'brandSelection', true, false),
      new HelpPage('menumanager/menus', ['brandSelection', 'locationSelection', 'contentCreation', 'menus', 'menuStatus', 'addButton'], 'brandSelection', true, false),
      new HelpPage('menumanager/recipes', ['recipesList', 'addButton'], 'recipesList', true, false),
      new HelpPage('menumanager/new-recipe', ['generalRecipeInfo', 'recipeDetails', 'associatedMedia', 'noSaving', 'saving'], 'generalRecipeInfo', true, false),
      new HelpPage('menumanager/menus/menuitems', ['locationSelection', 'tree', 'menuItemList', 'menuCustomizations'], 'locationSelection', true, false),
      new HelpPage('menumanager/menuitems', ['productsList', 'tree', 'addButton'], 'productsList', true, false),
      new HelpPage('menumanager/menuitem-form', ['info', 'associatingMediaFile', 'saving', 'noSaving'], 'info', true, false),
      new HelpPage('menumanager/ingredients', ['ingredientsList', 'tree', 'addButton'], 'ingredientsList', true, false),
      new HelpPage('menumanager/ingredient-form', ['info', 'associatingMediaFile', 'saving', 'noSaving'], 'info', true, false),
      new HelpPage('menumanager/addons', ['addOnsList', 'addButton'], 'addOnsList', true, false),
      new HelpPage('locationsmanager/locations', ['tree', 'storeDetails', 'locationInfo', 'addLocation', 'exportLocations', 'assignments'], 'tree', true, false),
      new HelpPage('locationsmanager/devices', ['devicesList'], 'devicesList', true, false),
      new HelpPage('labeldesigner/labels', ['locationSelection', 'labelsList', 'addLabel'], 'locationSelection', true, false),
      new HelpPage('labeldesigner/labels/editor', ['labelProperties', 'canvas', 'labelVisualization', 'components', 'saving'], 'labelProperties', true, false),
      new HelpPage('mediamanager/media', ['brandSelection', 'locationSelection', 'mediaList', 'tree', 'addMedia'], 'brandSelection', true, false),
      new HelpPage('analytics/report', ['reportCriteria', 'generateReport', 'variousReports', 'reportContent'], 'reportCriteria', true, false),
      new HelpPage('analytics/report-on-demand', ['reportCriteria', 'sendEmail', 'reportHistoric'], 'reportCriteria', true, false),
      new HelpPage('settings/styles', ['stylesList', 'addButton'], 'stylesList', true, false),
      new HelpPage('settings/custom-fields', ['customFieldsList', 'addButton'], 'customFieldsList', true, false),
      new HelpPage('settings/tempschedule', ['brandSelector', 'tempScheduleCalendar', 'addButton'], 'brandSelector', true, false),
      new HelpPage('nutritionfacts/profiles', ['profilesList', 'addButton'], 'profilesList', true, false),
      new HelpPage('profilemanager/profile', ['profileInformation', 'changePassword', 'saveReset'], 'profileInformation', true, false),
      new HelpPage('invoices', ['invoicesList'], 'invoicesList', true, false) //when url change after adding the new module, todo update this
    ];
    return new Help(pages, '');
  }

  getHelp() {
    const helpStr = localStorage.getItem(this.HELP);
    this.help = this.initHelp();
    if (helpStr === null) {
      this.setHelp();
    } else {
      try {
        const storedHelp = JSON.parse(helpStr);
        this.help.pages.forEach((p) => (p.firstRun = storedHelp.pages.find((x) => x.name === p.name).firstRun));
        this.setHelp();
      } catch (error) {
        this.help = this.initHelp();
        this.setHelp();
      }
    }
  }

  setHelp(page?: HelpPage) {
    if (page) {
      this.help.pages = this.help.pages.map((p) => {
        if (p.name === page.name) {
          p = { ...p, firstRun: page.firstRun, completed: page.completed };
        }
        return p;
      });
    }

    const helpStr = JSON.stringify(this.help);
    localStorage.setItem(this.HELP, helpStr);
  }

  setPageReady(ready: boolean) {
    if (this.authService.hasInitSteps()) {
      this.pageReady.next(ready);
    }
  }

  resume(forceIt?: boolean, atStep?: string) {
    this.getHelp();
    const page = this.getPageFromUrl();
    if (page) {
      const delay = page.wait && !forceIt ? page.wait : 200;
      window.setTimeout(() => {
        if (page !== null) {
          let steps = page.steps;
          if (this.authService && this.authService.getIsReseller()) {
            if (page.removeWhenReseller) {
              page.removeWhenReseller.forEach((x) => {
                const index = steps.indexOf(x);
                steps.splice(index, 1);
              });
            }
          }
          if (this.authService && !this.authService.hasSenseActivated()) {
            if (page.removeWhenSenseNotActive) {
              page.removeWhenSenseNotActive.forEach((x) => {
                const index = steps.indexOf(x);
                steps.splice(index, 1);
              });
            }
          }
          if (forceIt || page.firstRun) {
            this.help.currentStep = `${atStep ? atStep : page.currentStep}`;
            this.joyrideService
              .startTour({
                steps: steps,
                startWith: this.help.currentStep,
                waitingTime: 250,
                themeColor: '#000000'
              })
              .subscribe(
                (step) => {
                  this.onStepChange(step);
                },
                (error) => {},
                () => {
                  this.onClose();
                }
              );
            page.firstRun = false;
            if (page.completed) {
              page.completed = false;
            }
            this.setHelp(page);
          }
        }
      }, delay);
    }
  }

  onStepChange(step) {
    const page = this.getPageFromUrl();
    if (page !== null) {
      this.help.currentStep = `${step.name}`;
      this.setHelp();
    }
  }

  getPageFromUrl(): HelpPage {
    const url = this.router.url;
    let page = null;
    switch (true) {
      case url.indexOf('dashboard') >= 0:
        page = 'dashboard';
        break;
      case url.indexOf('accountmanager/accounts') >= 0:
        page = 'accountmanager/accounts';
        break;
      case url.indexOf('accountmanager/account-info') >= 0:
        page = 'accountmanager/account-info';
        break;
      case url.indexOf('accountmanager/users') >= 0:
        page = 'accountmanager/users';
        break;
      case url.indexOf('accountmanager/brands') >= 0:
        page = 'accountmanager/brands';
        break;
      case url.indexOf('accountmanager/roles') >= 0:
        page = 'accountmanager/roles';
        break;
      case url.indexOf('accountmanager/subscriptions') >= 0:
        page = 'accountmanager/subscriptions';
        break;
      case url.indexOf('menumanager/menus/menuitems') >= 0:
        page = 'menumanager/menus/menuitems';
        break;
      case url.indexOf('menumanager/menus') >= 0:
        page = 'menumanager/menus';
        break;
      case url.indexOf('menumanager/recipes') >= 0:
        page = 'menumanager/recipes';
        break;
      case url.indexOf('menumanager/new-recipe') >= 0:
        page = 'menumanager/new-recipe';
        break;
      case url.indexOf('menumanager/menuitems') >= 0:
        page = 'menumanager/menuitems';
        break;
      case url.indexOf('menumanager/menuitem-form') >= 0:
        page = 'menumanager/menuitem-form';
        break;
      case url.indexOf('menumanager/ingredients') >= 0:
        page = 'menumanager/ingredients';
        break;
      case url.indexOf('menumanager/ingredient-form') >= 0:
        page = 'menumanager/ingredient-form';
        break;
      case url.indexOf('menumanager/addons') >= 0:
        page = 'menumanager/addons';
        break;
      case url.indexOf('locationsmanager/locations') >= 0:
        page = 'locationsmanager/locations';
        break;
      case url.indexOf('locationsmanager/devices') >= 0:
        page = 'locationsmanager/devices';
        break;
      case url.indexOf('labeldesigner/labels/editor') >= 0:
        page = 'labeldesigner/labels/editor';
        break;
      case url.indexOf('labeldesigner/labels') >= 0:
        page = 'labeldesigner/labels';
        break;
      case url.indexOf('mediamanager/media') >= 0:
        page = 'mediamanager/media';
        break;
      case url.indexOf('analytics/report-on-demand') >= 0:
        page = 'analytics/report-on-demand';
        break;
      case url.indexOf('analytics/report') >= 0:
        page = 'analytics/report';
        break;
      case url.indexOf('settings/styles') >= 0:
        page = 'settings/styles';
        break;
      case url.indexOf('settings/custom-fields') >= 0:
        page = 'settings/custom-fields';
        break;
      case url.indexOf('settings/tempschedule') >= 0:
        page = 'settings/tempschedule';
        break;
      case url.indexOf('nutritionfacts/profiles') >= 0:
        page = 'nutritionfacts/profiles';
        break;
      case url.indexOf('profilemanager/profile') >= 0:
        page = 'profilemanager/profile';
        break;
      case url.indexOf('invoices') >= 0: //when url change after adding the new module, todo update this
        page = 'invoices';
        break;
    }

    return page !== null ? this.help.pages.find((x) => x.name === page) : null;
  }

  onClose() {
    const page = this.getPageFromUrl();
    if (page !== null) {
      // Tour is completed for the current page
      if (this.help.currentStep === `${page.steps[page.steps.length - 1]}`) {
        page.currentStep = page.steps[0];
        page.completed = true;
        this.authService.setInitStepsConfig(true);
      } else {
        // Persist current step for resume
        page.currentStep = this.help.currentStep;
        this.authService.setInitStepsConfig(false);
      }

      this.setHelp(page);
    }
  }
}
