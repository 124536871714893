<nome-dropdown-with-search
  [matTooltip]="language.language"
  [ngModel]="language.languageCode"
  [labelHiddenIfOptionSelected]="true"
  [options]="languages"
  [selectTriggerTemplate]="selectTriggerTemplate"
  (selectionChange)="onLanguageSwitch($event.value)"
></nome-dropdown-with-search>

<ng-template #selectTriggerTemplate>
  {{ language.cultureLanguageCode.toUpperCase() }}
</ng-template>
