export enum ComponentTypes {
  DATE = 'currentDate',
  LABEL = 'label',
  IMAGE = 'image',
  BARCODE = 'barcode',
  NEWBARCODE = 'newBarcode',
  LINE = 'line',
  LIST = 'list',
  RECTANGLE = 'rectangle',
  BATCH_COUNT = 'batchCount',
  VOICE_PICK = 'voicePick',
  VOICE_PICK_LOW = 'voicePickLow',
  VOICE_PICK_HIGH = 'voicePickHigh',

  STORE_NUMBER = 'storeNumber',
  STORE_NAME = 'storeName',
  STORE_ADDRESS = 'addressLine1',
  STORE_PHONE = 'storePhone',
  STORE_ADDRESS_2 = 'addressLine2',
  STORE_CITY = 'city',
  STORE_REGION = 'region',
  STORE_ZIP = 'zipCode',
  STORE_EMAIL = 'storeEmail',
  STORE_LOGO_1 = 'labelingLogo1',
  STORE_LOGO_2 = 'labelingLogo2',

  RFID_EPC = 'RFID.EPC',

  NUTRITION_FACT = 'nutritionFacts',
  DAY_OF_WEEK = 'dayOfWeek',
  DYNAMIC_DAY_OF_WEEK = 'dynamic_dayOfWeek',

  ITEM_PRINT_NAME = 'NomeItem.printName', // Recipe caption || Ingredient caption
  ITEM_SEC_PRINT_NAME = 'NomeItem.secPrintName', // Secondary Caption
  ITEM_DISPLAY_NAME = 'NomeItem.displayName', // Recipe Name || Ingredient name
  ITEM_SEC_DISPLAY_NAME = 'NomeItem.secDisplayName', // secondary Recipe Name || Ingredient name
  ITEM_NET_WEIGHT = 'NomeItem.netWeightValue', // will print netweight + uom
  VALUE = 'value',
  ITEM_TARE_WEIGHT = 'NomeItem.tareWeightValue', //
  ITEM_SCALE_WEIGHT = 'NomeItem.scaleWeight',
  ITEM_PRICE = 'NomeItem.price',
  BARCODE_VALUE = 'NomeItem.barcodeValue',
  NOME_ITEM_BARCODE = 'NomeItem.barcode',
  ITEM_SHELF_LIFE_HOURS = 'NomeItem.shelfLifeHours',
  ITEM_VARIANT_PARENT_NAME = 'NomeItem.variantParentName', // Product Name only, not supported in ingredients.
  ITEM_SEC_VARIANT_PARENT_NAME = 'NomeItem.secVariantParentName', // secondary Product Name only, not supported in ingredients.
  ITEM_SHELF_LIFE = 'NomeItem.shelfLife',
  ITEM_DESCRIPTION = 'NomeItem.description',
  ITEM_SEC_DESCRIPTION = 'NomeItem.secDescription',
  ITEM_PREP_DESCRIPTION = 'NomeItem.prepDescription',
  ITEM_USE_BY_DESCRIPTION = 'NomeItem.useByDescription',
  CENTER_CHECK_DIGIT = 'NomeItem.centerCheckDigit',

  ADDON_PRINT_NAME = 'NomeExtra.printName', // Addon Caption
  ADDON_SEC_PRINT_NAME = 'NomeExtra.secPrintName', // Addon secondary caption
  ADDON_DISPLAY_NAME = 'NomeExtra.displayName', // Addon Name
  ADDON_SEC_DISPLAY_NAME = 'NomeExtra.secDisplayName',
  ADDON_NET_WEIGHT = 'NomeExtra.netWeightValue',
  ADDON_PRICE = 'NomeExtra.price',
  ADDON_SHELF_LIFE_HOURS = 'NomeExtra.shelfLifeHours',
  ADDON_CALCULATED_FIELD = 'NomeExtra.calculatedField',
  NEW_ADDON_CALCULATED_FIELD = 'NomeExtra.newCalculatedField',
  ADDON_BARCODE_VALUE = 'NomeExtra.barcode',

  // when the user set a shelf life, he should be able to set a date format to that field.

  MANAGER_ON_DUTY = 'managerOnDuty',
  EMPLOYEE_NAME = 'employeeName',
  EMPLOYEE_ID = 'employeeId',
  CURRENT_DAY = 'currentDay',

  CUSTOM_FIELDS = 'NomeItem.CustomField',
  KILL_DATE = 'killDate',
  REFERENCE_DISCARD_DATE = 'referenceDiscardDate',
  DISCARD_DATE = 'discardDate',
  CUSTOM_FIELDS_DELIMITER = '$',

  INGREDIENTS = 'ingredients',
  ALLERGENS = 'allergens',
  CATEGORY = 'NomeItem.category',
  SEC_CATEGORY = 'NomeItem.secCategory',

  WIDGET_NF = 'NutritionFact',
  WIDGET = 'widget',
  COMPOSITE_FIELD = 'compositeField',
  ADDON_COMPOSITE_FIELD = 'addonCompositeField',
  ADDON_COMPOSITE_FIELD_CAPTION = 'addonCompositeFieldCaption',
  WIDGET_INFO_HOLDER = 'widgetInfoHolder',
  GROUP_TAGS = 'groupTags',
  PRICE_PER_WEIGHT = 'pricePerWeight',
  RECIPES_FIELD = 'recipesField',
  CALCULATED_FIELD = 'calculatedField',
  NEW_CALCULATED_FIELD = 'newCalculatedField',
  CONDITIONAL_LABEL = 'conditionalLabel',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  TIMER_NAME = 'timerName',

  MENUITEM_CALORIES = 'NomeItem.calories',
  // retail
  PRODUCT_CAPTION = 'RetailProduct.caption',
  PRODUCT_DESCRIPTION = 'RetailProduct.description',
  PRODUCT_SKU = 'RetailProduct.SKU',
  PRODUCT_ORIGINAL_PRICE = 'RetailProduct.originalPrice',
  PRODUCT_CURRENT_PRICE = 'RetailProduct.currentPrice',
  PRODUCT_DISCOUNT_RATE = 'RetailProduct.discountRate',
  PRODUCT_BARCODE_VALUE = 'RetailProduct.barcodeValue',
  PRODUCT_SIZE = 'RetailProduct.size',
  PRODUCT_COLOR = 'RetailProduct.color',
  PRODUCT_DEPARTMENT = 'RetailProduct.department',
  PRODUCT_CALCULATED_FIELD = 'RetailProduct.calculatedField',
  NEW_PRODUCT_CALCULATED_FIELD = 'RetailProduct.newCalculatedField',
  PRODUCT_DISCOUNTED_CALCULATED_FIELD = 'RetailProduct.discountedCalculatedField',
  PRODUCT_START_DATE = 'RetailProduct.startDate',
  PRODUCT_END_DATE = 'RetailProduct.endDate'
}
