import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  clearSelectionSubject: Subject<boolean> = new Subject<boolean>();

  constructor() {}
  // In case we are deleting the last item in our current list and our starting item is larger then 0
  // it means we are not in the first page and we need to go back one page by removing the
  // limit (indicates the current page size ) from the starting item
  getStartingItemAfterDelete(data: any[], startingItem, limit): number {
    if (data.length === 1 && startingItem > 0) {
      return startingItem - limit;
    } else {
      return startingItem;
    }
  }

  clearSelection() {
    this.clearSelectionSubject.next(true);
  }

  checkIfPaginatorNeedToBeFixed(
    table // to detect when the end of table is reached
  ) {
    if (table && table._elementRef) {
      let prop = table._elementRef.nativeElement.getBoundingClientRect();
      return Math.floor(prop.top + prop.height) > window.innerHeight;
    }
  }

  translateTheTableHeader(scrollableContainer: HTMLElement, table: HTMLElement): void {
    const scrollableContainerTop = scrollableContainer.getBoundingClientRect().top;
    const tableTop = table?.getBoundingClientRect().top + scrollableContainer.scrollTop;
    const amountBeforeStartingToTranslate = tableTop - scrollableContainerTop;
    const scrollableContainerScrollTop = scrollableContainer.scrollTop;

    const translateTheTableHeader = (amount) => (table.children[0]['style'].transform = `translateY(${amount}px)`);

    if (scrollableContainerScrollTop > amountBeforeStartingToTranslate) {
      translateTheTableHeader(scrollableContainerScrollTop - amountBeforeStartingToTranslate);
    } else if (scrollableContainerScrollTop <= amountBeforeStartingToTranslate) {
      translateTheTableHeader(0);
    }
  }
}
