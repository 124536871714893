import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';
import { TitleCaseModule } from '../../../pipes/title-case/title-case.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'nome-icon-with-label-button',
  templateUrl: './icon-with-label-button.component.html',
  styleUrls: ['./icon-with-label-button.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, TranslateModule, TitleCaseModule]
})
export class IconWithLabelButtonComponent {
  @Input() label: string;
  @Input() icon: string = 'add';
  @Input() iconColor: string = 'accent';
  @Input() iconClass: string = '';
  @Input() disabled: boolean;
  @Output('buttonClick') private buttonClickEventEmitter = new EventEmitter<void>();

  emitButtonClickEvent(): void {
    this.buttonClickEventEmitter.emit();
  }
}
