import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { appendQueryParamIfDefined } from '../helpers/app.helpers';
import { environment } from './../../../environments/environment';
import { TreeTypeEnum } from './../models/enums/TreeTypes.enum';
import { TreeNode, PreSelectedNode } from './../models/Tree-Node.model';
import { ApiService } from './api/api.service';

@Injectable({
  providedIn: 'root'
})
export class TreeService {
  baseUrl = `${environment.baseAPIUrl}/${environment.version}/accounts/`;
  treeTitleSubject: Subject<string> = new Subject<string>();
  treeVisibleSubject: Subject<boolean> = new Subject<boolean>();
  treeItemsSubject: Subject<TreeNode[]> = new Subject<TreeNode[]>();
  treeSubtitleSubject: Subject<string> = new Subject<string>();
  treeSelectedNodeSubject: Subject<TreeNode> = new Subject<TreeNode>();
  // selectedNodeId: BehaviorSubject<TreeNode> = new BehaviorSubject<TreeNode>(null);

  constructor(private apiService: ApiService) {}

  getRootNode(
    accountId: number,
    treeType: number,
    brandId?: number,
    menuId?: number,
    locationId?: number,
    enableLocationFilter?: boolean,
    includeAllParents?: boolean,
    searchKeyword?: string,
    preselect?: number
  ): Observable<PreSelectedNode> {
    let url = this.baseUrl + `${accountId}/${this.getPageEndpoint(treeType, menuId)}/tree`;
    if (brandId != undefined) {
      url = url + '?BrandId=' + `${brandId}`;

      if (locationId != undefined) {
        if (enableLocationFilter) {
          url = url + '&check_by=locationId';
        }
        url = url + '&LocationId=' + `${locationId}`;
      }

      if (includeAllParents != undefined) {
        url = url + `&includeAllParents=${includeAllParents}`;
      }

      if (searchKeyword) {
        url = url + `&searchKeyword=${searchKeyword}`;
      }
    }

    url = appendQueryParamIfDefined(url, 'preselect', preselect);

    url = this.setItemTypeInUrl(url, treeType);
    return this.apiService.getRequest(url).pipe(map((res) => res.results));
  }

  getMenuBuilderNode(accountId: number, treeType: number, brandId?: number): Observable<PreSelectedNode> {
    let url = this.baseUrl + `${accountId}/${this.getPageEndpoint(treeType)}/tree`;
    if (brandId != undefined) {
      url = url + '?BrandId=' + `${brandId}`;
    }
    return this.apiService.getRequest(url).pipe(map((res) => res.results));
  }

  expandNode(nodeId: number, accountId: number, brandId: number, treeType: number): Observable<PreSelectedNode> {
    let url = this.baseUrl + `${accountId}/${this.getPageEndpoint(treeType)}/${nodeId}/tree`;
    url = this.setItemTypeInUrl(url, treeType);
    return this.apiService.getRequest(url).pipe(map((res) => res.results));
  }

  getTreeByNodeId(
    nodeId: number,
    accountId: number,
    brandId: number,
    treeType: number,
    menuId?: number,
    locationId?: number,
    enableLocationFilter?: boolean,
    searchKeyword?: string,
    includeStores?: boolean,
    showLoader?: boolean
  ): Observable<PreSelectedNode> {
    if (accountId) {
      let url = this.baseUrl + `${accountId}/${this.getPageEndpoint(treeType, menuId)}/tree`;
      url = appendQueryParamIfDefined(url, 'preselect', nodeId);
      url = appendQueryParamIfDefined(url, 'BrandId', brandId);
      if (locationId !== undefined) {
        if (enableLocationFilter) {
          url = url + '&check_by=locationId&LocationId=' + `${locationId}`;
        } else {
          url = url + '&LocationId=' + `${locationId}`;
        }
      }
      url = this.setItemTypeInUrl(url, treeType);
      url = appendQueryParamIfDefined(url, 'searchKeyword', searchKeyword);
      url = appendQueryParamIfDefined(url, 'includeStores', includeStores);
      return this.apiService.getRequest(url, showLoader).pipe(map((res) => res.results));
    }
  }

  getPageEndpoint(treeType: number, menuId?: number): string {
    switch (treeType) {
      case +TreeTypeEnum.MenuItemCategory:
      case +TreeTypeEnum.IngredientCategory:
      case +TreeTypeEnum.ProductCategory:
        if (menuId) {
          return `menus/${menuId}/menuitemcategories`;
        } else {
          return 'menuItemCategories';
        }
      case +TreeTypeEnum.Location:
        return 'locations';
      case +TreeTypeEnum.MediaCategory:
        return 'mediacategories';
    }
  }

  setItemTypeInUrl(url: string, treeType: number) {
    switch (treeType) {
      case TreeTypeEnum.ProductCategory:
        url = url + '&itemType=1';
        break;
      case TreeTypeEnum.IngredientCategory:
        url = url + '&itemType=2';
        break;
      case TreeTypeEnum.MenuItemCategory:
        break;
      default:
        break;
    }
    return url;
  }

  setTreeTitle(title: string) {
    setTimeout(() => {
      this.treeTitleSubject.next(title);
    }, 100);
  }

  setTreeVisible(value: boolean) {
    setTimeout(() => {
      this.treeVisibleSubject.next(value);
    }, 100);
  }

  setTreeItems(treeItems: TreeNode[]) {
    setTimeout(() => {
      this.treeItemsSubject.next(treeItems);
    }, 100);
  }

  setTreeSubtitle(subtitle: string) {
    setTimeout(() => {
      this.treeSubtitleSubject.next(subtitle);
    }, 100);
  }

  emitSelectedNodeChangedEvent(node: TreeNode) {
    this.treeSelectedNodeSubject.next(node);
  }

  loopOverTreeNodes(nodes: TreeNode[], condition: (node: TreeNode) => boolean, action: (node: TreeNode) => void) {
    nodes.forEach((node) => {
      if (condition(node)) {
        action(node);
      }
      this.loopOverTreeNodes(node.children, condition, action);
    });
  }

  setNodeChildren(nodes: TreeNode[], id: number, children: TreeNode[]): void {
    this.loopOverTreeNodes(
      nodes,
      (node) => node.id === id,
      (node) => {
        node.expanded = true;
        node.children = children;
      }
    );
  }

  addSubscriptionIconToPublisherNodes(nodes: TreeNode[]): void {
    const condition = (node: TreeNode) => node['isSubscription'];
    const action = (node: TreeNode) => (node.icon = 'subscription');

    this.loopOverTreeNodes(nodes, condition, action);
  }

  getDescendants(node: TreeNode): TreeNode[] {
    const descendants = [];

    this.loopOverTreeNodes(
      [node],
      (n) => true,
      (n) => {
        if (n.id !== node.id) {
          // not itself
          descendants.push(n);
        }
      }
    );

    return descendants;
  }
}
