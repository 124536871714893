export interface EshaLogEntry {
  id: number;
  startAt: string;
  endAt: string;
  duration: string;
  status: string;
  totalItems: number;
  itemsAdded: number;
  itemsUpdated: number;
}

export interface EshaLogItem {
  id: number;
  name: string;
  itemType: ItemTypeEnum;
  actionType: ActionTypeEnum;
  recordsCounts: number;
}

export enum ItemTypeEnum {
  RECIPE = 1,
  INGREDIENT = 2
}

export enum ActionTypeEnum {
  Added = 1,
  Updated = 2
}
