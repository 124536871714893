import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from './../../shared/services/theme.service';
import { environment } from '../../../environments/environment';
import { RoleService } from '../services/role.service';

@Component({
  selector: 'nome-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  assets: string = environment.assets;
  brandImagesAssets: string;
  constructor(public router: Router, public roleService: RoleService, private themeService: ThemeService) {
    this.brandImagesAssets = this.themeService.getbrandImagesAssets();
  }

  ngOnInit() {}

  navigateToDashboard() {
    this.router.navigate(['dashboard']);
  }
}
