import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'nome-add-button-content',
  templateUrl: './add-button-content.component.html',
  styleUrls: ['./add-button-content.component.scss'],
  standalone: true,
  imports: [TranslateModule, CommonModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddButtonContentComponent {
  @Input() text?: string;
}
