import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';
import { RuntimeConfiguration } from '../../shared/models/runtime-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class RuntimeConfigurationService {
  runtimeConfiguration: RuntimeConfiguration;

  constructor(private http: HttpClient) {}

  getRuntimeConfiguration(): Observable<RuntimeConfiguration> {
    const runtimeConfigurationFileName = environment.runtimeConfigurationFileName;
    const timestamp = new Date().getTime();
    return this.http.get<RuntimeConfiguration>(`assets/runtime-configurations/${runtimeConfigurationFileName}.json?timestamp=${timestamp}`).pipe(
      tap((runtimeConfiguration) => {
        this.runtimeConfiguration = runtimeConfiguration;
      })
    );
  }
}
