import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivateService } from '../services/activate.service';
import { NavigationService } from './../../shared/services/navigation.service';
import { APIResponseModel } from './../../shared/models/api-response';
import { ApiResponseCodeEnum } from './../../shared/models/enums/ApiResponseCode.enum';
import { AccountManagerService } from './../../modules/account-manager/services/account-manager.service';
import { ThemeService } from './../../shared/services/theme.service';
import { validationParamsData } from './change-password-validations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nome-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  brandImagesAssets: string;
  accountId: number;
  accountName: string;
  userId: number;
  token: string = null;
  formGroup: UntypedFormGroup;
  showForm: boolean = false;
  validationParams = [...validationParamsData];
  constructor(
    private authService: AuthService,
    private activateService: ActivateService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private themeService: ThemeService,
    private accountManagerService: AccountManagerService,
    public translateService: TranslateService
  ) {
    this.brandImagesAssets = this.themeService.getbrandImagesAssets();
    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];
      if (!this.token) {
        this.authService.redirectToInvalidLink(ApiResponseCodeEnum.INVALID_LINK_TOKEN);
      }

      if (this.token) {
        this.activateService.validateLinkToken(this.token).subscribe(
          (res) => {
            this.accountId = res.results.accountId;
            this.accountName = res.results.accountName;
            this.userId = res.results.userId;
            this.setBackgroundColor();
            this.showForm = true;
          },
          (error: APIResponseModel<any>) => {
            this.authService.redirectToInvalidLink(error.results.code);
          }
        );
      }
    });
    this.formGroup = new UntypedFormGroup(
      {
        password: new UntypedFormControl(null, [Validators.required, Validators.minLength(6)]),
        confirmPassword: new UntypedFormControl(null, [Validators.required, Validators.minLength(6)])
      },
      this.matchValidator
    );
  }
  matchValidator(frm: UntypedFormGroup) {
    const password = frm.get('password').value;
    const confirmPassword = frm.get('confirmPassword').value;

    if (password !== confirmPassword) {
      frm.get('confirmPassword').setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsetBackgroundColor();
    this.navigationService.setBlnDisplayBreadCrumb(true);
  }

  setBackgroundColor() {
    this.themeService.setBackgroundColor();
  }
  unsetBackgroundColor() {
    this.themeService.unsetBackgroundColor();
  }

  submitForm() {
    const password = this.formGroup.getRawValue().password;
    const confirmPassword = this.formGroup.getRawValue().confirmPassword;

    this.accountManagerService.changeUserPassword(password, confirmPassword, this.token).subscribe((response) => {
      this.authService.logout(this.accountName);
    });
  }
}
